import { get, patch, post, del } from 'utils/api';

const getNotes = (payload) => {
  return get({
    path: 'notes',
    parameters: payload,
  });
};

const getAccessTypes = () => {
  return get({
    path: 'access_types',
  });
};

const createNote = (payload) => {
  return post({ path: 'notes', body: payload });
};

const updateNote = (id, payload) => {
  return patch({ path: `notes/${id}`, body: payload });
};

const deleteNotes = (ids) =>
  del({
    path: 'notes',
    body: {
      is_deleted_ids: ids,
    },
  });

const noteService = {
  getNotes,
  getAccessTypes,
  createNote,
  updateNote,
  deleteNotes,
};

export default noteService;
