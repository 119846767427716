import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { H2, H5 } from 'components/Typography';
import { DropdownContent } from 'components/Dropdown';
import { Pagination } from 'components/Pagination';
import PhotoExtensionModal from '../modals/PhotoExtensionModal';
import MediaEditModal from '../modals/MediaEditModal';
import PhotoUploadModal from '../modals/PhotoUploadModal';
import { getPropertyMedia, getPublicPropertyMedia } from 'store/propertyStore';
import { MEDIA_TYPES } from 'utils/constants';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as ThreeDotsIcon } from 'assets/icons/threeDots.svg';

const responsive = {
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    paritialVisibilityGutter: 35,
  },
};

const Photos = ({ isPublic = false }) => {
  const { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const { selectedPropertyMedia } = useSelector(
    (state) => state.propertyReducer,
  );

  const [visibleData, setVisibleData] = useState([]);
  const [isPhotoExtension, setIsPhotoExtension] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isMenuDropdown, setIsMenuDropdown] = useState(false);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [page, setPage] = useState(1);
  const location = useLocation();

  const handleSelectImage = (index) => {
    setSelectedIndex(index);
    setIsPhotoExtension(true);
  };

  useEffect(() => {
    if (selectedPropertyMedia?.photos) {
      setVisibleData(
        selectedPropertyMedia?.photos.filter((item) => item.hidden === 0),
      );
    }
  }, [selectedPropertyMedia]);

  useEffect(() => {
    if (location.pathname.includes('public') || isPublic) {
      dispatch(
        getPublicPropertyMedia({
          id,
          type: MEDIA_TYPES.PHOTO,
        }),
      );
    } else {
      dispatch(
        getPropertyMedia({
          id,
          type: MEDIA_TYPES.PHOTO,
        }),
      );
    }
  }, [dispatch, id, isPublic, location.pathname]);

  const handleEdit = () => {
    setIsEditModal(true);
    setIsMenuDropdown(false);
  };

  const handleUpload = () => {
    setIsUploadModal(true);
    setIsMenuDropdown(false);
  };

  const photosLength = visibleData.length;

  return (
    <div className={classes.container}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <H2>Photos</H2>
        {!isPublic && isMobile && (
          <Box display="flex" alignItems="center" position="relative">
            <ThreeDotsIcon
              className="cursor-pointer"
              onClick={() => setIsMenuDropdown(true)}
            />
            {isMenuDropdown && (
              <>
                <DropdownContent
                  handleClickAway={() => setIsMenuDropdown(false)}
                >
                  <div className={classes.menuContainer}>
                    <H5 className="cursor-pointer" onClick={handleEdit}>
                      Edit
                    </H5>
                    <H5 className="cursor-pointer" onClick={handleUpload}>
                      Upload
                    </H5>
                  </div>
                </DropdownContent>
              </>
            )}
          </Box>
        )}
        {!isPublic && !isMobile && (
          <Box display="flex" alignItems="center" gridColumnGap={20}>
            <UploadIcon className="cursor-pointer" onClick={handleUpload} />
            <EditIcon
              className="cursor-pointer"
              onClick={() => setIsEditModal(true)}
            />
          </Box>
        )}
      </Box>
      {isMobile && (
        <div className={classes.carousel}>
          <Carousel
            responsive={responsive}
            partialVisbile
            arrows={false}
            draggable={true}
            itemClass="slider-item"
          >
            {!!visibleData.length &&
              visibleData.map((item, index) => (
                <img
                  draggable={false}
                  className="cursor-pointer slider_img"
                  src={item.watermark_url || item.original_url}
                  key={index}
                  onDoubleClick={() => handleSelectImage(index)}
                  alt=""
                />
              ))}
          </Carousel>
        </div>
      )}
      {!isMobile && !!visibleData.length && (
        <div>
          <div className={classes.content}>
            {new Array(6)
              .fill(0)
              .map(
                (_, index) =>
                  visibleData[(page - 1) * 6 + index] && (
                    <img
                      key={index}
                      src={
                        visibleData[(page - 1) * 6 + index].watermark_url ||
                        visibleData[(page - 1) * 6 + index].original_url ||
                        visibleData[(page - 1) * 6 + index].media_url
                      }
                      className="big"
                      alt=""
                      onClick={() => handleSelectImage((page - 1) * 6 + index)}
                    />
                  ),
              )}
          </div>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Pagination
              page={page}
              setPage={setPage}
              lastPage={Math.ceil(photosLength / 6)}
            />
          </Box>
        </div>
      )}
      {isPhotoExtension && (
        <PhotoExtensionModal
          isModalOpen={isPhotoExtension}
          closeModal={() => setIsPhotoExtension(false)}
          photos={visibleData}
          selectedIndex={selectedIndex}
        />
      )}
      {isEditModal && (
        <MediaEditModal
          isModalOpen={isEditModal}
          closeModal={() => setIsEditModal(false)}
          medias={selectedPropertyMedia?.photos}
          type={MEDIA_TYPES.PHOTO}
          propertyId={id}
        />
      )}
      {isUploadModal && (
        <PhotoUploadModal
          isModalOpen={isUploadModal}
          closeModal={() => setIsUploadModal(false)}
          propertyId={id}
        />
      )}
    </div>
  );
};

export default Photos;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: 30,
      background: theme.palette.background.white,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 30,
      [theme.breakpoints.down('sm')]: {
        padding: 20,
        rowGap: 20,
        borderRadius: 0,
      },
      '& .slider_img': {
        width: '100%',
        maxHeight: 140,
      },
    },
    carousel: {
      '& .slider-item': {
        padding: 20,
        paddingLeft: 0,
      },
      '& .react-multiple-carousel__arrow': {
        minWidth: 30,
        minHeight: 30,
        '&::before': {
          fontSize: 16,
        },
      },
    },
    content: {
      display: 'flex',
      justifyContent: 'flex-start',
      columnGap: 20,
      rowGap: 10,
      flexWrap: 'wrap',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      '& .big': {
        height: 300,
        objectFit: 'contain',
      },
      '& .small': {
        width: 'calc(50% - 8px)',
        maxHeight: 150,
        objectFit: 'contain',
      },
      '& > img': {
        cursor: 'pointer',
        objectFit: 'contain',
      },
    },
    menuContainer: {
      background: theme.palette.background.white,
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      padding: 10,
    },
  }),
);
