import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const ViewTab = ({ activeTab, setTab }) => {
  const classes = useStyles();

  return (
    <div className={classes.tabsWrapper}>
      <div
        className={cx(
          classes.tabItem1,
          activeTab === 'list' && classes.activeTab,
        )}
        onClick={() => setTab('list')}
      >
        List
      </div>
      <div
        className={cx(
          classes.tabItem2,
          activeTab === 'map' && classes.activeTab,
        )}
        onClick={() => setTab('map')}
      >
        Map
      </div>
    </div>
  );
};

export default ViewTab;

const useStyles = makeStyles((theme) =>
  createStyles({
    tabsWrapper: {
      display: 'flex',
    },
    tabItem1: {
      background: theme.palette.background.white,
      padding: '10px 20px;   ',
      border: `1px solid ${theme.palette.action.active}`,
      borderRight: 'none',
      boxSizing: 'border-box',
      borderRadius: '5px 0px 0px 5px',
      color: theme.palette.action.active,
      cursor: 'pointer',
      width: 69,
      height: 42,
    },
    tabItem2: {
      background: theme.palette.background.white,
      padding: '10px 20px',
      border: `1px solid ${theme.palette.action.active}`,
      boxSizing: 'border-box',
      borderRadius: '0px 5px 5px 0px',
      color: theme.palette.action.active,
      cursor: 'pointer',
      width: 69,
      height: 42,
    },
    activeTab: {
      background: `${theme.palette.grey.A700}`,
    },
  }),
);
