import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useDropzone } from 'react-dropzone';

import { EnhancedModal } from 'components/Modal';
import { Button } from 'components/Buttons';
import { H5 } from 'components/Typography';
import { VARIANTS } from 'utils/constants';
import { createMedias } from 'store/propertyStore';

import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';

const PhotoUploadModal = ({ isModalOpen, closeModal, propertyId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [photos, setPhotos] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      return;
    }
    setPhotos((previosVales) => [...previosVales, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const removePhoto = (id) => {
    const temp = [...photos].filter((_, index) => index !== id);
    setPhotos(temp);
  };

  const handleUpload = () => {
    const formData = new FormData();
    photos.forEach((element) => {
      formData.append('imgFiles[]', element);
    });
    formData.append('title', '');
    formData.append('media_subject', 'unit');
    formData.append('caption', '');
    formData.append('media_format', 'photo');
    formData.append('media_usage', 'general');
    dispatch(
      createMedias({ propertyId, formData, success: () => closeModal() }),
    );
  };

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={'Upload Photos'}
      mobileFullWidth={true}
    >
      <div className={classes.contiainer}>
        <div {...getRootProps({ className: classes.dropzone })}>
          <input {...getInputProps()} />
          <H5 bold className={classes.title}>
            Upload image(s)
          </H5>
        </div>
        <div className={classes.content}>
          {photos.map((item, index) => {
            const image = URL.createObjectURL(item);
            return (
              <Box
                display="flex"
                alignItems="center"
                gridColumnGap={10}
                key={index}
              >
                <img src={image} alt="" className={classes.photo} />
                <H5>{item.name}</H5>
                <Box
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  gridColumnGap={12}
                  mr={1}
                >
                  <RemoveIcon
                    className="cursor-pointer"
                    onClick={() => removePhoto(index)}
                  />
                </Box>
              </Box>
            );
          })}
        </div>
        <Box className={classes.actionButtons}>
          <Button onClick={closeModal} variant={VARIANTS.SECONDARY}>
            Cancel
          </Button>
          <Button onClick={handleUpload} disabled={!photos.length}>
            Save
          </Button>
        </Box>
      </div>
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    contiainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 500,
      rowGap: 10,
      [theme.breakpoints.down('xs')]: {
        rowGap: 20,
      },
    },
    dropzone: {
      width: '100%',
      height: 180,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px dashed ${theme.palette.action.active}`,
      outline: 'none',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        height: 100,
      },
    },
    title: {
      color: theme.palette.action.active,
    },
    content: {
      maxHeight: 250,
      marginTop: 20,
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
      [theme.breakpoints.down('xs')]: {
        maxHeight: '100%',
      },
      '&::-webkit-scrollbar': {
        width: 6,
        background: theme.palette.background.white,
        borderRadius: 5,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
    photo: {
      width: 100,
      height: 50,
      background: theme.palette.grey.A100,
    },
    actionButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
      columnGap: 20,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        background: theme.palette.background.white,
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.15)',
        height: 80,
        width: '100%',
      },
    },
  }),
);

export default PhotoUploadModal;
