import { get, patch } from 'utils/api';

const getReviews = () => {
  return get({
    path: 'review_items',
  });
};

const reviewItem = (id, reviewTypeId, reviewStatus) => {
  return patch({
    path: `review_items/${id}`,
    body: {
      review_type_id: reviewTypeId,
      review_status: reviewStatus,
    },
  });
};

const reviewService = {
  getReviews,
  reviewItem,
};

export default reviewService;
