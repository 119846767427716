import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const Spinner = ({ loading }) => (
  <MoonLoader
    css={override}
    size={60}
    color="#457c79"
    loading={loading}
    speedMultiplier={1}
  />
);
