import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
// import { H4 } from 'components/Typography';
// import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';

const SubHeader = () => {
  const classes = useStyles();
  // const hostname = window.location.origin;

  // const copyLink = () => {
  //   const url = `${hostname}/public_client_url_list?client_url_key=${client_url_key}`;
  //   navigator.clipboard.writeText(url);
  // };

  return (
    <div className={classes.subHeader}>
      {/* <H4 className={classes.title}>Client URL - Name :</H4>
      <H4 className={classes.clientName} bold>
        {clientUrlName}
      </H4>
      <div>
        <LinkIcon onClick={copyLink} className={classes.link} />
      </div> */}
    </div>
  );
};

export default SubHeader;

const useStyles = makeStyles((theme) =>
  createStyles({
    subHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      background: theme.palette.background.white,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
      height: 70,
      borderRadius: 5,
      [theme.breakpoints.down('xs')]: {},
    },
    menuItemGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    title: {
      textAlign: 'center',
      lineHeight: '70px',
      marginLeft: 110,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 170,
        paddingLeft: 10,
        paddingRight: 10,
        width: 'auto',
        marginLeft: 10,
      },
    },
    clientName: {
      textAlign: 'center',
      lineHeight: '70px',
      marginLeft: 5,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 170,
        paddingLeft: 10,
        paddingRight: 10,
        width: 'auto',
      },
    },
    link: {
      marginTop: 25,
      marginLeft: 10,
      cursor: 'pointer',
    },
  }),
);
