import React from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import propertyImage from 'assets/images/propertyImg.jpg';
import { Box } from '@material-ui/core';
import { H4, H5 } from 'components/Typography';

import { ReactComponent as BedIcon } from 'assets/icons/bed.svg';
import { ReactComponent as BathIcon } from 'assets/icons/bath.svg';

const OverlayItem = ({ data, isClicked }) => {
  const classes = useStyles({ isClicked });
  const history = useHistory();

  const goToDetailPage = (id) => {
    history.push(`/property/${id}#details`);
  };

  return (
    <div
      className={classes.overlayItemContainer}
      onClick={() => goToDetailPage(data.concept_property_id)}
    >
      <img
        src={propertyImage}
        className={classes.propertyImage}
        alt="property"
      />

      <div className={classes.detailsContainer}>
        <div>
          <H5 bold display="block" className={classes.name}>
            # {data.unit} {data.address}
          </H5>
          <H5>{data.city}</H5>
        </div>
        <div>
          <H5 bold display="block" className={classes.name}>
            ${data.rent}
          </H5>
          <H5>Rent</H5>
        </div>
        <div>
          <H5 bold display="block" className={classes.name}>
            {data.available_date}
          </H5>
          <H5>Avail.Date</H5>
        </div>
        <Box display="flex" justifyContent="space-between">
          <div>
            <H5 bold display="block" className={classes.name}>
              Beds
            </H5>
            <H4>
              {Number(data.beds)} <BedIcon />
            </H4>
          </div>
          <div>
            <H5 bold display="block" className={classes.name}>
              Baths
            </H5>
            <H5>
              {Number(data.baths)} <BathIcon />
            </H5>
          </div>
        </Box>
        <div>
          <H5 bold display="block" className={classes.name}>
            {data.fee || 'Null'}
          </H5>
          <H5>Fee</H5>
        </div>
        <div>
          <H5 bold display="block" className={classes.name}>
            {data.landlord_name}
          </H5>
          <H5>Landlord Name</H5>
        </div>
      </div>
    </div>
  );
};

export default OverlayItem;
const useStyles = makeStyles((theme) =>
  createStyles({
    overlayItemContainer: {
      display: 'flex',
      gap: 20,
      background: (props) =>
        props.isClicked
          ? theme.palette.grey.A200
          : theme.palette.background.white,
      height: 140,
      padding: 10,
      marginTop: 10,
      marginBottom: 10,

      '&:hover': {
        background: theme.palette.grey.A200,
      },
    },
    detailsContainer: {
      display: 'grid',
      gridTemplateColumns: '200px 120px 150px',
      gridTemplateRows: '52px 52px',
      columnGap: 30,
      rowGap: 15,
      alignSelf: 'start',
    },
    name: {
      marginBottom: 8,
    },
    propertyImage: {
      width: 180,
      height: 120,
    },
  }),
);
