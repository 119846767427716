import React from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { Button } from 'components/Buttons';
import { EnhancedModal } from 'components/Modal';
import { H3, H5 } from 'components/Typography';
import { CONFIRM_TYPES, SIZE_TYPES, VARIANTS } from 'utils/constants';
import { reviewItem } from 'store/reviewStore';

const ReviewConfirmModal = ({
  isModalOpen,
  closeModal,
  type = CONFIRM_TYPES.APPROVE,
  review = null,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!review) return null;
    dispatch(
      reviewItem({
        id: review.review_item_id,
        reviewTypeId: review.review_type_id,
        reviewStatus: type,
        success: () => {
          closeModal();
        },
      }),
    );
  };

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      hiddenHeader={true}
      className={classes.modal}
      variant={VARIANTS.SECONDARY}
    >
      <div className={classes.contiainer}>
        <H3 bold>
          {type === CONFIRM_TYPES.APPROVE
            ? 'Approve review?'
            : 'Decline review?'}
        </H3>
        <H5>{review?.title}</H5>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gridColumnGap={20}
          marginTop={1}
        >
          <Button
            variant={VARIANTS.SECONDARY}
            size={SIZE_TYPES.LARGE}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button size={SIZE_TYPES.LARGE} onClick={handleSubmit}>
            {type === CONFIRM_TYPES.APPROVE ? 'Approve' : 'Decline'}
          </Button>
        </Box>
      </div>
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      maxWidth: 400,
    },
    contiainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
    },
  }),
);

export default ReviewConfirmModal;
