import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';

import { H2, H4, H5 } from 'components/Typography';
import { Pagination } from 'components/Pagination';
import { DropdownContent } from 'components/Dropdown';
import { MEDIA_TYPES } from 'utils/constants';
import { getPropertyMedia, getPublicPropertyMedia } from 'store/propertyStore';
import VideoTourUploadModal from '../modals/VideoTourUploadModal';
import MediaEditModal from '../modals/MediaEditModal';

import { ReactComponent as ExternalLinkIcon } from 'assets/icons/externalLink.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as ThreeDotsIcon } from 'assets/icons/threeDots.svg';
// import virtualTourDefaultImage from 'assets/images/virtualTourDefault.png';

const VirtualTours = ({ isPublic = false }) => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { selectedPropertyMedia } = useSelector(
    (state) => state.propertyReducer,
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));

  const [visibleData, setVisibleData] = useState([]);
  const [page, setPage] = useState(1);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isMenuDropdown, setIsMenuDropdown] = useState(false);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (selectedPropertyMedia?.tours) {
      setVisibleData(
        selectedPropertyMedia?.tours.filter((item) => item.hidden === 0),
      );
    }
  }, [selectedPropertyMedia]);

  useEffect(() => {
    if (location.pathname.includes('public') || isPublic) {
      dispatch(
        getPublicPropertyMedia({
          id,
          type: MEDIA_TYPES.TOUR,
        }),
      );
    } else {
      dispatch(
        getPropertyMedia({
          id,
          type: MEDIA_TYPES.TOUR,
        }),
      );
    }
  }, [dispatch, id, isPublic, location.pathname]);

  const handleEdit = () => {
    setIsEditModal(true);
    setIsMenuDropdown(false);
  };

  const handleUpload = () => {
    setIsUploadModal(true);
    setIsMenuDropdown(false);
  };

  const pageSize = isTablet ? 3 : 6;

  return (
    <div className={classes.container}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <H2>Virtual tours</H2>
        {!isPublic && isMobile && (
          <Box display="flex" alignItems="center" position="relative">
            <ThreeDotsIcon
              className="cursor-pointer"
              onClick={() => setIsMenuDropdown(true)}
            />
            {isMenuDropdown && (
              <>
                <DropdownContent
                  handleClickAway={() => setIsMenuDropdown(false)}
                >
                  <div className={classes.menuContainer}>
                    <H5 className="cursor-pointer" onClick={handleEdit}>
                      Edit
                    </H5>
                    <H5 className="cursor-pointer" onClick={handleUpload}>
                      Upload
                    </H5>
                  </div>
                </DropdownContent>
              </>
            )}
          </Box>
        )}
        {!isPublic && !isMobile && (
          <Box display="flex" alignItems="center" gridColumnGap={20}>
            <UploadIcon className="cursor-pointer" onClick={handleUpload} />
            <EditIcon
              className="cursor-pointer"
              onClick={() => setIsEditModal(true)}
            />
          </Box>
        )}
      </Box>
      {!!visibleData.length && (
        <div className={classes.content}>
          {visibleData
            .slice((page - 1) * pageSize, page * pageSize)
            .map((item, index) => (
              <Box
                flex={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gridColumnGap={20}
                key={index}
              >
                {/* <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={isMobile ? 'space-between' : 'flex-start'}
                  gridColumnGap={10}
                >
                  <H5 bold className={classes.tourTitle}>
                    {item.title || item.media_subject}
                  </H5>
                  <ExternalLinkIcon
                    width={16}
                    height={16}
                    className="cursor-pointer"
                  />
                </Box> */}
                <H4 className={classes.link}>
                  {item.watermark_url || item.original_url || item.media_url}
                </H4>
                <a
                  href={
                    item.watermark_url || item.original_url || item.media_url
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{ height: 16 }}
                >
                  <ExternalLinkIcon
                    width={16}
                    height={16}
                    className="cursor-pointer"
                  />
                </a>
              </Box>
            ))}
        </div>
      )}
      {!!visibleData.length && (
        <Box display="flex" justifyContent="flex-end">
          <Pagination
            page={page}
            setPage={setPage}
            lastPage={Math.ceil(visibleData.length / pageSize)}
          />
        </Box>
      )}
      {isEditModal && (
        <MediaEditModal
          isModalOpen={isEditModal}
          closeModal={() => setIsEditModal(false)}
          medias={selectedPropertyMedia?.tours}
          type={MEDIA_TYPES.TOUR}
          propertyId={id}
        />
      )}
      {isUploadModal && (
        <VideoTourUploadModal
          propertyId={id}
          type={MEDIA_TYPES.TOUR}
          isModalOpen={isUploadModal}
          closeModal={() => setIsUploadModal(false)}
        />
      )}
    </div>
  );
};

export default VirtualTours;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: 30,
      background: theme.palette.background.white,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 30,
      [theme.breakpoints.down('sm')]: {
        padding: 20,
        rowGap: 20,
        borderRadius: 0,
      },
    },
    content: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      columnGap: 30,
      rowGap: 20,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      '& > div': {
        width: 'calc(50% - 15px)',
        maxWidth: 'calc(50% - 15px)',
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        columnGap: 20,
        [theme.breakpoints.down('sm')]: {
          columnGap: 10,
          width: '100%',
          maxWidth: '100%',
        },
        '&:hover': {
          background: theme.palette.grey.A200,
          borderRadius: 5,
        },
      },
    },
    tourImage: {
      width: 140,
      height: 80,
      [theme.breakpoints.down('sm')]: {
        width: 80,
        height: 45,
      },
    },
    link: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    tourTitle: {
      textTransform: 'capitalize',
    },
    menuContainer: {
      background: theme.palette.background.white,
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      padding: 10,
    },
  }),
);
