import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';

import { H3, H5 } from 'components/Typography';
import { CheckBox } from 'components/CheckBox';
import { DropdownContent } from 'components/Dropdown';

import { ReactComponent as TaskListIcon } from 'assets/icons/taskList.svg';
import { ReactComponent as NoteListIcon } from 'assets/icons/noteList.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowFillDown.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkMark.svg';
import { ReactComponent as MarkAsDoneIcon } from 'assets/icons/mark_as_done.svg';

const HomeTableHeader = ({
  title,
  selected = [],
  isSelectAll = false,
  handleSelectAll = null,
  handleDeleteItems = null,
  handleMarkasDone = null,
  itemType = null,
  itemTypes = [],
  setItemType = null,
  isTask = false,
  isNote = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [isOpenTypes, setIsOpenTypes] = useState(false);

  const handleChangeTaskType = (type) => {
    setItemType && setItemType(type);
    setIsOpenTypes(false);
  };

  const handleClickMarkasDone = () => {
    handleMarkasDone(selected);
  };

  return (
    <div className={classes.container}>
      <Box display="flex" flexDirection="column" gridRowGap={12} flex={1}>
        {!selected.length || isMobile ? (
          <Box display="flex" alignItems="center" gridColumnGap={20}>
            {isTask && <TaskListIcon />}
            {isNote && <NoteListIcon />}
            <H3 bold>{title}</H3>
          </Box>
        ) : null}
        {selected.length ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={1}
            maxWidth={450}
          >
            <Box display="flex" alignItems="center" gridColumnGap={10}>
              <CheckBox checked={isSelectAll} setChecked={handleSelectAll} />
              <H5>All</H5>
            </Box>
            <H5>{selected.length} Selected</H5>
            <Box
              className="cursor-pointer"
              display="flex"
              alignItems="center"
              gridColumnGap={10}
              onClick={handleDeleteItems}
            >
              <RemoveIcon width={20} height={20} />
              <H5>Delete</H5>
            </Box>
            {isTask && (
              <Box
                className="cursor-pointer"
                display="flex"
                alignItems="center"
                gridColumnGap={10}
                onClick={handleClickMarkasDone}
              >
                <MarkAsDoneIcon />
                <H5>Mark as done</H5>
              </Box>
            )}
          </Box>
        ) : null}
      </Box>
      {(isTask || isNote) && (
        <Box
          display="flex"
          alignItems="center"
          gridColumnGap={8}
          className={classes.itemType}
          onClick={() => !isOpenTypes && setIsOpenTypes(true)}
        >
          <H5>{itemType}</H5>
          <ArrowDownIcon />
          {isOpenTypes && (
            <>
              <DropdownContent
                handleClickAway={() => setIsOpenTypes(false)}
                customStyles={classes.typesDropdown}
              >
                <div className={classes.dropwdown}>
                  {itemTypes.map((item) => (
                    <Box
                      key={item.item_type_id}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      onClick={() => handleChangeTaskType(item)}
                    >
                      <H5>{item}</H5>
                      {item === itemType && <CheckMarkIcon />}
                    </Box>
                  ))}
                </div>
              </DropdownContent>
            </>
          )}
        </Box>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      columnGap: 80,
      padding: '15px 20px',
      background: theme.palette.background.white,
      borderRadius: '5px 5px 0px 0px',
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
      [theme.breakpoints.down('xs')]: {
        borderRadius: 0,
        padding: '10px 20px',
        columnGap: 20,
        marginLeft: -20,
        marginRight: -20,
        boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
      },
    },
    itemType: {
      position: 'relative',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    typesDropdown: {
      left: 'auto',
      right: 0,
      minWidth: 185,
      transform: 'translate(30px, 5px)',
    },
    dropwdown: {
      width: '100%',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      '& > div': {
        padding: '8px 15px',
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
        },
      },
    },
  }),
);

export default HomeTableHeader;
