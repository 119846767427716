import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';

import { EnhancedModal } from 'components/Modal';

import { H2, H5 } from 'components/Typography';
import { Button } from 'components/Buttons';
import { SIZE_TYPES, VARIANTS } from 'utils/constants';

const PropertyConflictModal = ({
  isModalOpen,
  closeModal,
  confirm,
  conflictProperty,
  foundInfo,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [titles, setTitles] = useState([]);
  const [contents, setContents] = useState([]);

  useEffect(() => {
    if (conflictProperty && foundInfo) {
      setTitles(['Address', 'Unit', 'Landlord']);
      setContents([
        [conflictProperty.address, foundInfo.address],
        [conflictProperty.unit, foundInfo.unit],
        [conflictProperty.landlord, foundInfo.landlord],
      ]);
    }
  }, [conflictProperty, foundInfo]);
  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      mobileFullWidth={true}
      hiddenHeader={true}
      className={classes.modal}
    >
      <div className={classes.contiainer}>
        <div className={classes.title}>
          <H2>
            It seems the property already exists. Please make a comparison.
          </H2>
        </div>
        <div className={classes.mainContent}>
          {isMobile ? (
            <>
              {titles.length &&
                contents.length &&
                titles.map((title, index) => (
                  <div key={index} className={classes.item}>
                    <Box display="flex" alignItems="center" columnGap={20}>
                      <Box width={1} maxWidth={70}></Box>
                      <Box flex={1}>
                        <H5 bold>{title}</H5>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" columnGap={20}>
                      <Box width={1} maxWidth={70}>
                        <H5 bold>New</H5>
                      </Box>
                      <Box flex={1}>
                        <H5>{contents[index][1] || 'NULL'}</H5>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" columnGap={20}>
                      <Box width={1} maxWidth={70}>
                        <H5 bold>Existing</H5>
                      </Box>
                      <Box flex={1}>
                        <H5>{contents[index][0] || 'NULL'}</H5>
                      </Box>
                    </Box>
                  </div>
                ))}
            </>
          ) : (
            <>
              <div className={classes.item}>
                <Box width={1} maxWidth={100}></Box>
                <Box flex={1} display="flex" columnGap={20}>
                  <Box flex={1}>
                    <H5 bold>New property</H5>
                  </Box>
                  <Box flex={1}>
                    <H5 bold>Existing property</H5>
                  </Box>
                </Box>
              </div>
              {titles.length &&
                contents.length &&
                titles.map((title, index) => (
                  <div key={index} className={classes.item}>
                    <Box width={1} maxWidth={100}>
                      <H5 bold>{title}</H5>
                    </Box>
                    <Box flex={1} display="flex" columnGap={20}>
                      <Box flex={1}>
                        <H5>{contents[index][0] || 'NULL'}</H5>
                      </Box>
                      <Box flex={1}>
                        <H5>{contents[index][1] || 'NULL'}</H5>
                      </Box>
                    </Box>
                  </div>
                ))}
            </>
          )}
        </div>
        <Box className={classes.actionButtons}>
          <H5 className={classes.actionTitle} bold>
            Do you still want to create a new property?
          </H5>
          <div>
            <Button
              onClick={closeModal}
              variant={VARIANTS.SECONDARY}
              size={SIZE_TYPES.LARGE}
            >
              Cancel
            </Button>
            <Button size={SIZE_TYPES.LARGE} onClick={confirm}>
              Accept
            </Button>
          </div>
        </Box>
      </div>
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      maxWidth: 840,
    },
    title: {
      paddingBottom: 10,
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
    },
    contiainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 720,
      paddingTop: 20,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 10,
      },
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
      padding: '20px 0',
      [theme.breakpoints.down('xs')]: {
        overflow: 'auto',
        height: 'calc(100% - 80px)',
        MsOverflowStyle: 'none' /* IE and Edge */,
        scrollbarWidth: 'none' /* Firefox */,
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    item: {
      display: 'flex',
      columnGap: 20,
      rowGap: 10,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    actionButtons: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      rowGap: 20,
      columnGap: 20,
      borderTop: `1px solid ${theme.palette.grey.A100}`,
      padding: 20,
      paddingBottom: 0,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        background: theme.palette.background.white,
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.15)',
        height: 120,
        width: '100%',
        rowGap: 10,
        paddingBottom: 20,
      },
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 20,
      },
    },
    actionTitle: {
      width: '100%',
      textAlign: 'center',
    },
  }),
);

export default PropertyConflictModal;
