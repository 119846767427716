import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ToastProvider } from 'react-toast-notifications';

import { history } from './utils/history';
import store from './store';
import Routes from './routes';

const App = () => (
  <ToastProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </Provider>
  </ToastProvider>
);

export default App;
