import React, { useRef } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useClickAway } from 'hooks/useClickAway';
import { Z_INDEXS } from 'utils/constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'absolute',
      top: '100%',
      left: '50%',
      minWidth: 185,
      transform: 'translate(-50%, 5px)',
      background: theme.palette.background.white,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)',
      borderRadius: 5,
      zIndex: Z_INDEXS.DROPDOWN,
      [theme.breakpoints.down('xs')]: {
        left: 'auto',
        right: 0,
        top: 0,
        transform: 'translate(0, 0)',
      },
    },
  }),
);

export const DropdownContent = ({
  children,
  handleClickAway,
  customStyles,
}) => {
  const classes = useStyles();
  const ref = useRef();
  useClickAway({ ref, handleClickAway });

  return (
    <div className={classNames(classes.container, customStyles)} ref={ref}>
      {children}
    </div>
  );
};
