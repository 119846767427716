import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import * as Yup from 'yup';

import { EnhancedModal } from 'components/Modal';
import { Button } from 'components/Buttons';
import { EnhancedForm, FormInputField } from 'components/Form';

const AddEditTaskModal = ({
  isModalOpen,
  closeModal,
  handleCancel,
  handleConfirm,
}) => {
  const classes = useStyles();

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={'Client URL'}
      mobileFullWidth={true}
    >
      <DialogContent className={classes.dialogContent}>
        <EnhancedForm
          initialValues={{
            clientUrl: '',
          }}
          validationSchema={Yup.object().shape({
            clientUrl: Yup.string().required('Required'),
          })}
          handleSubmit={handleConfirm}
        >
          {() => (
            <>
              <FormInputField label="Name" name="clientUrl" />
              <div className={classes.actions}>
                <Button
                  variant="secondary"
                  onClick={handleCancel}
                  style={{ width: 100 }}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="primary" style={{ width: 100 }}>
                  Save
                </Button>
              </div>
            </>
          )}
        </EnhancedForm>
      </DialogContent>
      <DialogActions className={classes.actions}></DialogActions>
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    actions: {
      display: 'flex',
      justifyContent: 'center',
      gap: 20,
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    dialogContent: {
      padding: '8px 0',
    },
  }),
);

export default AddEditTaskModal;
