import { get, post, del } from 'utils/api';

const getClientUrls = () => {
  return get({
    path: 'client_urls',
  });
};

const getClientUrlProperties = (id) => {
  return get({
    path: `client_urls/${id}`,
  });
};

const createClientUrl = (params) => {
  return post({
    path: 'client_urls',
    body: params,
  });
};

const addProperties = (params) => {
  return post({
    path: 'client_url_properties',
    body: params,
  });
};

const removeProperty = (id) => {
  return del({
    path: `client_url_properties/${id}`,
  });
};

const deleteClientUrls = (ids) =>
  del({
    path: 'client_urls',
    body: {
      client_url_ID: ids,
    },
  });

const getPublicClientUrlProperties = (id) => {
  return get({
    path: `public_client_urls/${id}`,
  });
};

const clientService = {
  getClientUrls,
  getClientUrlProperties,
  createClientUrl,
  addProperties,
  removeProperty,
  deleteClientUrls,
  getPublicClientUrlProperties,
};

export default clientService;
