import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box, Tooltip } from '@material-ui/core';
import { H4, H5 } from 'components/Typography';
import { CheckBox } from 'components/CheckBox';
import { EnhancedTable, EnhancedMobileTable } from 'components/Table';
// import LightTooltip from 'components/Tooltip';
import Link from 'components/Link';
import ClientUrlNameModal from './ClientUrlNameModal';
import { StatusMark } from 'components/StatusMark';

import { ReactComponent as ShowCaseIcon } from 'assets/icons/showsheet.svg';
import { ReactComponent as ClientUrlIcon } from 'assets/icons/client_url.svg';
import { ReactComponent as StrokeIcon } from 'assets/icons/stroke.svg';
import { ReactComponent as BIcon } from 'assets/icons/bIcon.svg';
import { ReactComponent as BedIcon } from 'assets/icons/bed.svg';
import { ReactComponent as BathIcon } from 'assets/icons/bath.svg';
import { ReactComponent as AreaIcon } from 'assets/icons/area.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import { ReactComponent as PhotoIcon } from 'assets/icons/photo.svg';
import { ReactComponent as VirtualIcon } from 'assets/icons/virtual.svg';

import { SORTS, STATUSES, STATUS_TYPES } from 'utils/constants';
import { createClientUrl } from 'store/clientStore';
import { updateProperty, setPropertiesSort } from 'store/propertyStore';

const ListView = ({ properties }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const { propertiesSort, propertiesLoading } = useSelector(
    (state) => state.propertyReducer,
  );

  const [selected, setSelected] = useState([]);
  const [isSelectAll, setIsSectAll] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [mobileTableData, setMobileTableData] = useState([]);
  const [isOpenClientUrlModal, setIsOpenClientUrlModal] = useState(false);
  const [statusIds, setStatusIds] = useState({});

  const icons = [<BIcon />, <StrokeIcon />];

  const handleStatusClick = useCallback(
    (propertyId, id) => {
      const statusId = statusIds[propertyId] ? statusIds[propertyId] : id;
      const updatedStatusId = statusId === 3 ? 1 : statusId + 1;
      const data = Object.assign({}, statusIds, {
        [propertyId]: updatedStatusId,
      });

      setStatusIds(data);
      dispatch(
        updateProperty({
          id: propertyId,
          body: {
            property_status_id: updatedStatusId,
          },
          success: () => {},
        }),
      );
    },
    [dispatch, statusIds],
  );

  const goToLandlordDetail = (landlordId) => {
    history.push(`landlord/${landlordId}`);
  };

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      padding: '16px 24px',
      fontSize: 16,
    },
  }))(Tooltip);

  const getByText = (tourCnt, videoCnt, photoCnt) => {
    const array = [];

    if (photoCnt > 0) {
      array.push(`${photoCnt} Photos`);
    }
    if (videoCnt > 0) {
      array.push(`${videoCnt} Videos`);
    }
    if (tourCnt > 0) {
      array.push(`${tourCnt} Virtual Tours`);
    }

    return array.join(', ');
  };

  useEffect(() => {
    let newData = [];
    let newMobileData = [];
    properties.length > 0 &&
      properties.forEach((row, index) => {
        const newDataItem = {
          id: row.concept_property_id,
          cells: [
            {
              cell: (
                <H4 className="cursor-pointer">
                  {row.record_location === 'bookOfBusiness' && icons[0]}
                  {row.record_location === 'inThePond' && icons[1]}
                </H4>
              ),
            },
            {
              cell: (
                <Box
                  className="cursor-pointer"
                  onClick={() => goToDetailPage(row.concept_property_id)}
                >
                  <H4>{row.concept_property_id}</H4>
                </Box>
              ),
            },
            {
              cell: (
                <Box
                  onClick={(e) =>
                    handleStatusClick(
                      row?.concept_property_id,
                      row?.property_status_id,
                    )
                  }
                  className="cursor-pointer"
                >
                  <StatusMark
                    status={
                      STATUS_TYPES[
                        STATUSES[
                          (statusIds[row.concept_property_id] ||
                            row?.property_status_id) - 1
                        ]
                      ]
                    }
                    className={classes.rightSpace}
                  />
                </Box>
              ),
            },
            {
              cell: <H4>${Number(row.rent)}</H4>,
            },
            {
              cell: <H4>{row.city}</H4>,
            },
            {
              cell: (
                <Box
                  className="cursor-pointer"
                  onClick={() => goToDetailPage(row.concept_property_id)}
                >
                  <H4>{row.address}</H4>
                </Box>
              ),
            },
            {
              cell: <H4># {row.unit}</H4>,
            },
            {
              cell: (
                <H4>
                  {Number(row.beds)} <BedIcon />
                </H4>
              ),
            },
            {
              cell: (
                <H4>
                  {Number(row.baths)} <BathIcon />
                </H4>
              ),
            },
            {
              cell: <H4>{row.fee || 0}</H4>,
            },
            {
              cell: <H4>{row.available_date}</H4>,
            },
            {
              cell: (
                <LightTooltip
                  title={`Phone: ${row.primary_phone}`}
                  placement="top-start"
                >
                  <Box
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      goToLandlordDetail(row.landlord_id);
                    }}
                  >
                    <H4>{row.landlord_name}</H4>
                  </Box>
                </LightTooltip>
              ),
            },
            {
              cell: (
                <LightTooltip
                  title={getByText(
                    row.virtual_tour_count,
                    row.video_count,
                    row.photo_count,
                  )}
                  placement="top-end"
                >
                  <Box>
                    {row.virtual_tour_count > 0 && (
                      <H4>
                        <VirtualIcon width={20} />
                      </H4>
                    )}
                    {row.video_count > 0 && (
                      <H4>
                        <VideoIcon width={20} />
                      </H4>
                    )}
                    {row.photo_count > 0 && (
                      <H4>
                        <PhotoIcon width={20} />
                      </H4>
                    )}
                  </Box>
                </LightTooltip>
              ),
            },
            {
              cell: <H4>{row.source}</H4>,
            },
          ],
        };
        newData.push(newDataItem);
        const newMobileDataItem = {
          id: index,
          title: (
            <Box display="flex" flexDirection="column" gridRowGap={5}>
              <Box
                display="flex"
                gridColumnGap={43}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gridColumnGap={10}>
                  {icons[Math.round(Math.random())]}
                  <H4 bold>{row.concept_property_id}</H4>
                </Box>
                <H4 bold>${row.rent}</H4>
              </Box>
              <H4 bold>
                {row.address} {row.city}
              </H4>
            </Box>
          ),
          content: (
            <Box display="flex" flexDirection="column" gridRowGap={10}>
              <Box display="flex" gridColumnGap={20}>
                <Box display="flex" gridColumnGap={10}>
                  <Box flex={1}>
                    <H4 bold>{Number(row.baths)}</H4>
                  </Box>
                  <Box flex={1}>
                    <BathIcon />
                  </Box>
                </Box>
                <Box display="flex" gridColumnGap={10}>
                  <Box flex={1}>
                    <H4 bold>{Number(row.beds)}</H4>
                  </Box>
                  <Box flex={1}>
                    <BedIcon />
                  </Box>
                </Box>
                <Box display="flex" gridColumnGap={10}>
                  <Box flex={1}>
                    <H4 bold>1549</H4>
                  </Box>
                  <Box flex={1}>
                    <AreaIcon />
                  </Box>
                </Box>
              </Box>
              <Box display="flex" gridColumnGap={20}>
                <Box flex={1}>
                  <H4 bold>Fee:</H4>
                </Box>
                <Box flex={1}>
                  <H4>{Number(row.fee)}</H4>
                </Box>
              </Box>
              <Box display="flex" gridColumnGap={20}>
                <Box flex={1}>
                  <H4 bold>Avail.Date:</H4>
                </Box>
                <Box flex={1}>
                  <H4>{row.available_date}</H4>
                </Box>
              </Box>
              <Box display="flex" gridColumnGap={20}>
                <Box flex={1}>
                  <H4 bold>Landlord:</H4>
                </Box>
                <Box flex={1}>
                  <H4>{row.landlord_name}</H4>
                </Box>
              </Box>
              <Box display="flex" gridColumnGap={20} justifyContent="flex-end">
                <Box flex={1}></Box>
                <Box flex={1}>
                  <H4>{row.primary_phone}</H4>
                </Box>
              </Box>
              <Box display="flex" gridColumnGap={20}>
                <Link href={`property/${row.concept_property_id}#details`}>
                  See property details
                </Link>
              </Box>
            </Box>
          ),
        };
        newMobileData.push(newMobileDataItem);
      });
    setTableData(newData);
    setMobileTableData(newMobileData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties, handleStatusClick]);

  const handleSelectAll = () => {
    let newSelected = [];
    if (isSelectAll) {
      setIsSectAll(false);
    } else {
      newSelected = tableData.map((row) => row.id);
      setIsSectAll(true);
    }
    setSelected(newSelected);
  };

  const handleSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else {
      newSelected = selected.filter((item) => item !== id);
    }
    setSelected(newSelected);
    if (isSelectAll) {
      setIsSectAll(false);
    }
  };

  const handlePublicView = () => {
    setIsOpenClientUrlModal(true);
  };

  const handleSaveClientUrlName = (data) => {
    const clientName = data?.clientUrl;
    const date = new Date();
    const str = `${date.getFullYear().toString().slice(-2)}${
      date.getMonth() + 1
    }${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;

    setIsOpenClientUrlModal(false);

    dispatch(
      createClientUrl({
        body: {
          client_name: clientName,
          client_url_key: `${clientName.slice(-3)}${str}`,
          properties: selected,
        },
        success: (client_url_key) => {
          history.push({
            pathname: `/public_client_url_list`,
            search: `?client_url_key=${client_url_key}`,
            state: { clientUrl: clientName },
          });
        },
      }),
    );
  };

  const handleCloseClientUrlModal = () => {
    setIsOpenClientUrlModal(false);
  };

  const handleChangeOrder = (property) => {
    if (property === propertiesSort.sort) {
      if (propertiesSort.order === SORTS.DESC) {
        dispatch(setPropertiesSort({ order: SORTS.ASC, sort: property }));
      } else {
        dispatch(setPropertiesSort({ order: SORTS.DESC, sort: property }));
      }
    }
  };

  const handleChangeOrderBy = (value) => {
    dispatch(setPropertiesSort({ order: SORTS.DESC, sort: value }));
  };

  const goToDetailPage = (id) => {
    history.push(`/property/${id}#details`);
  };

  const handleClickShowSheet = () => {
    console.log('selected', selected);
    history.push({
      pathname: '/showsheets',
      state: { ids: selected },
    });
  };

  return (
    <div className={classes.container}>
      {selected.length > 0 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          maxWidth={550}
          width={1}
          className={classes.selectedBox}
        >
          <Box
            display="flex"
            alignItems="center"
            gridColumnGap={10}
            sx={{ mr: 2 }}
          >
            <CheckBox checked={isSelectAll} setChecked={handleSelectAll} />
            <H5>All</H5>
          </Box>
          <Box sx={{ mr: 5 }}>
            <H5>{selected.length} Selected</H5>
          </Box>
          <Box
            className="cursor-pointer"
            display="flex"
            alignItems="center"
            gridColumnGap={10}
            onClick={handleClickShowSheet}
            sx={{ mr: 5 }}
          >
            <ShowCaseIcon width={20} height={20} />
            <H5>Showsheet</H5>
          </Box>

          <Box
            className="cursor-pointer"
            display="flex"
            alignItems="center"
            gridColumnGap={10}
            onClick={handlePublicView}
          >
            <ClientUrlIcon width={20} height={20} />
            <H5>Client URL</H5>
          </Box>
        </Box>
      )}

      {isMobile ? (
        <EnhancedMobileTable
          rows={mobileTableData}
          enableSelect={true}
          selected={selected}
          handleSelect={handleSelect}
          className={classes.mobileTableStyles}
          loading={propertiesLoading}
        />
      ) : (
        <EnhancedTable
          headCells={headers}
          rows={tableData}
          order={propertiesSort.order}
          orderBy={propertiesSort.sort}
          enableSelect={true}
          selected={selected}
          handleSelect={handleSelect}
          className={classes.tableStyles}
          variant="secondary"
          handleChangeOrder={handleChangeOrder}
          handleChangeOrderBy={handleChangeOrderBy}
          loading={propertiesLoading}
          // handleRowClick={goToDetailPage}
        />
      )}

      <ClientUrlNameModal
        isModalOpen={isOpenClientUrlModal}
        closeModal={handleCloseClientUrlModal}
        handleCancel={handleCloseClientUrlModal}
        handleConfirm={handleSaveClientUrlName}
      />
    </div>
  );
};

export default ListView;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: 30,
    },
    selectedBox: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: 20,
      },
    },
    tableStyles: {
      '& .MuiTableHead-root': {
        background: theme.palette.grey.A400,
      },
      '& .MuiTableRow-root': {
        marginBottom: 10,
      },
      '& .MuiTableCell-head div span': {
        textTransform: 'initial',
      },
      '& .MuiTableCell-root.MuiTableCell-body': {
        borderBottom: `10px solid ${theme.palette.grey.A400}`,
      },
      '& .MuiTableCell-root MuiTableCell-head': {
        borderBottom: 'none',
      },
    },
    mobileTableStyles: {
      '& > div': {
        background: `${theme.palette.background.white} !important`,
        marginBottom: 10,
      },
    },
  }),
);

const headers = [
  {
    label: '',
  },
  {
    label: 'Property ID',
    sortBy: 'concept_property_id',
  },
  {
    label: 'Status',
    sortBy: 'status',
  },
  {
    label: 'Rent',
    sortBy: 'rent',
  },
  {
    label: 'City',
    sortBy: 'city',
  },
  {
    label: 'Address',
    sortBy: 'address',
  },
  {
    label: 'Unit',
    sortBy: 'unit',
  },
  {
    label: 'Bed',
    sortBy: 'beds',
  },
  {
    label: 'Bath',
    sortBy: 'baths',
  },
  {
    label: 'Fee',
    sortBy: 'fee',
  },
  {
    label: 'Avail.Date',
    sortBy: 'available_date',
  },
  {
    label: 'Landlord',
    sortBy: 'landlord_name',
  },
  {
    label: 'Media',
    sortBy: 'media',
  },
  {
    label: ' ',
    sortBy: 'source',
  },
];
