import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import taskService from 'services/taskService';

export const getTasks = createAsyncThunk(
  'taskSlice/getTasks',
  async (payload) => {
    const response = await taskService.getTasks(
      payload.userId,
      payload.propertyId,
      payload.landlordId,
    );
    return response;
  },
);

export const getTaskTypes = createAsyncThunk(
  'taskSlice/getTaskTypes',
  async () => {
    const response = await taskService.getTaskTypes();
    return response;
  },
);

export const createNewTask = createAsyncThunk(
  'taskSlice/createTask',
  async (payload) => {
    const { body, success } = payload;
    const response = await taskService.createTask(body);
    success();
    return response;
  },
);

export const updateTask = createAsyncThunk(
  'taskSlice/updateTask',
  async (payload) => {
    const { body, success } = payload;
    const response = await taskService.updateTask(body);
    success();
    return response;
  },
);

export const deleteTasks = createAsyncThunk(
  'taskSlice/deleteTasks',
  async (payload) => {
    const { body, success } = payload;
    await taskService.deleteTasks(body);
    success();
    return body;
  },
);

const initialState = {
  tasks: [],
  taskTypes: [],
  activeTasks: [],
  tasksLoading: false,
};

const taskSlice = createSlice({
  name: 'taskSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getTaskTypes.fulfilled]: (state, action) => {
      state.taskTypes = action.payload;
    },
    [getTasks.fulfilled]: (state, action) => {
      state.tasks = action.payload;
      state.activeTasks = action.payload.filter(
        (item) => item.status === 'active',
      );
      state.tasksLoading = false;
    },
    [getTasks.pending]: (state) => {
      state.tasksLoading = true;
    },
    [getTasks.rejected]: (state) => {
      state.tasksLoading = false;
    },
    [deleteTasks.fulfilled]: (state, action) => {
      const newTasks = [...state.activeTasks].filter(
        (item) => !action.payload.includes(item.task_id),
      );
      state.activeTasks = newTasks;
    },
  },
});

export default taskSlice.reducer;
