import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { DropdownContent } from 'components/Dropdown';
import { H1, H5 } from 'components/Typography';
import { Pagination } from 'components/Pagination';
import { attachProperties, getLandlordProperties } from 'store/propertyStore';
import { resetSearchedProperties } from 'store/landlordStore';
import AddPropertiesModal from '../modals/AddPropertiesModal';
import { getTaskTypes } from 'store/taskStore';
import PropertyItem from './PropertyItem';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowFillDown.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkMark.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

const OwnedProperties = ({ landlord_id }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [isOpenTypes, setIsOpenTypes] = useState(false);
  const [taskType, setTaskType] = useState('Last Updated');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const dispatch = useDispatch();
  const { landlordProperties, landlordTotalRecords } = useSelector(
    (state) => state.propertyReducer,
  );

  React.useEffect(() => {
    const params = {
      per_page: 8,
      page,
      landlord_id,
      order: 'asc',
    };

    // if (sortBy) {
    //   params.sort = sortBy;
    //   params.order = order;
    // }
    dispatch(getLandlordProperties(params));
    dispatch(getTaskTypes());
  }, [dispatch, page, landlord_id]);

  const handleChangeTaskType = (type) => {
    setTaskType(type);
    setIsOpenTypes(false);
  };

  const handleCloseModal = () => {
    dispatch(resetSearchedProperties());
    setIsModalOpen(false);
    setIsAdded(false);
  };

  const handleAddProperties = async (properties) => {
    const body = {
      landlord_id,
      concept_property_id: properties,
    };
    dispatch(
      attachProperties({
        body,
        success: () => {
          setIsAdded(true);
          dispatch(resetSearchedProperties());
          const params = {
            per_page: 8,
            page,
            landlord_id,
            order: 'asc',
          };
          dispatch(getLandlordProperties(params));
        },
      }),
    );
  };

  return (
    <div className={classes.container}>
      <H1 display="block">Owned Properties</H1>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        mb={2}
        gridColumnGap={8}
        className={classes.taskType}
        onClick={() => !isOpenTypes && setIsOpenTypes(true)}
      >
        <H5>{taskType}</H5>
        <ArrowDownIcon />
        {isOpenTypes && (
          <>
            <DropdownContent
              handleClickAway={() => setIsOpenTypes(false)}
              customStyles={classes.typesDropdown}
            >
              <div className={classes.dropwdown}>
                {sortTypes.map((item, index) => (
                  <Box
                    key={`sort-type-${index}`}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => handleChangeTaskType(item.title)}
                  >
                    <H5>{item.title}</H5>
                    {item.title === taskType && <CheckMarkIcon />}
                  </Box>
                ))}
              </div>
            </DropdownContent>
          </>
        )}
      </Box>
      <div className={classes.main}>
        {landlordProperties.map((property, index) => (
          <PropertyItem
            property={property}
            index={index}
            key={property.concept_property_id}
          />
        ))}
      </div>
      <div className={classes.footer}>
        <div
          className={classes.addProperty}
          onClick={() => setIsModalOpen(true)}
        >
          <PlusIcon />
          <H5 bold display="block" className={classes.add}>
            Add property
          </H5>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          lastPage={Math.ceil(landlordTotalRecords / 8)}
        />
      </div>
      <AddPropertiesModal
        isModalOpen={isModalOpen}
        closeModal={handleCloseModal}
        handleSave={handleAddProperties}
        isAdded={isAdded}
        setIsAdded={setIsAdded}
      />
    </div>
  );
};

export default OwnedProperties;

const sortTypes = [{ title: 'Last Updated' }, { title: 'Most recent' }];
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      background: theme.palette.background.white,
      borderRadius: 5,
      padding: '1px 20px',
      maxWidth: 380,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      maxHeight: 800,
      [theme.breakpoints.down('md')]: {
        maxWidth: 820,
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 500,
      },
    },
    main: {
      flex: 1,
      minHeight: 250,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 4,
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.grey.A100,
        borderRadius: 2,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.action.active,
        borderRadius: 2,
        '&:hover': {
          background: theme.palette.action.hover,
        },
      },
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 0,
      paddingBottom: 20,
    },
    addProperty: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
      cursor: 'pointer',
    },
    add: {
      color: `${theme.palette.action.active}`,
      marginTop: 3,
    },
    typesDropdown: {
      left: 'auto',
      right: 0,
      minWidth: 185,
      transform: 'translate(30px, 5px)',
    },
    taskType: {
      position: 'relative',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    dropwdown: {
      width: 200,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      '& > div': {
        padding: '8px 15px',
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
        },
      },
    },
  }),
);
