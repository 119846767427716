import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { VARIANTS, SIZE_TYPES } from 'utils/constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: (props) =>
        props.size === SIZE_TYPES.LARGE
          ? 50
          : props.size === SIZE_TYPES.MEDIUM
          ? 42
          : 38,
      padding: '0 30px',
      background: (props) =>
        props.variant === VARIANTS.PRIMARY
          ? theme.palette.action.active
          : theme.palette.background.white,
      color: (props) =>
        props.variant === VARIANTS.PRIMARY
          ? theme.palette.text.secondary
          : theme.palette.action.active,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.action.active,
      borderRadius: 5,
      fontSize: 16,
      lineHeight: '22px',
      transition:
        'color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s',
      '&:hover, &:focus': {
        background: (props) =>
          props.variant === VARIANTS.PRIMARY
            ? theme.palette.action.hover
            : theme.palette.background.white,
        color: (props) =>
          props.variant === VARIANTS.PRIMARY
            ? theme.palette.text.secondary
            : theme.palette.action.hover,
        borderColor: theme.palette.action.hover,
      },
      '&:disabled': {
        background: (props) =>
          props.variant === VARIANTS.PRIMARY
            ? theme.palette.action.disabled
            : theme.palette.background.white,
        color: (props) =>
          props.variant === VARIANTS.PRIMARY
            ? theme.palette.text.secondary
            : theme.palette.action.disabled,
        borderColor: theme.palette.action.disabled,
        cursor: 'not-allowed',
      },
    },
  }),
);

export const Button = ({
  children,
  className,
  onClick,
  variant = VARIANTS.PRIMARY,
  size = SIZE_TYPES.MEDIUM,
  ref = null,
  ...props
}) => {
  const classes = useStyles({ variant, size });

  return (
    <button
      className={classNames(classes.button, className)}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  );
};

export const ForwardButton = forwardRef(
  (
    {
      children,
      className,
      onClick,
      variant = VARIANTS.PRIMARY,
      size = SIZE_TYPES.MEDIUM,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles({ variant, size });

    return (
      <button
        className={classNames(classes.button, className)}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        {children}
      </button>
    );
  },
);
