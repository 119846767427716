import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { Button } from 'components/Buttons';
import { EnhancedModal } from 'components/Modal';
import { H3, H5 } from 'components/Typography';
import { VARIANTS } from 'utils/constants';

const DeleteConfirmModal = ({ isModalOpen, closeModal, confirm }) => {
  const classes = useStyles();

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      hiddenHeader={true}
      className={classes.modal}
      variant={VARIANTS.SECONDARY}
    >
      <div className={classes.contiainer}>
        <H3 bold>Delete this item?</H3>
        <H5>This item will be removed from the system.</H5>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gridColumnGap={20}
          marginTop={1}
        >
          <Button variant={VARIANTS.SECONDARY} onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={confirm}>Delete</Button>
        </Box>
      </div>
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      maxWidth: 400,
    },
    contiainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
    },
  }),
);

export default DeleteConfirmModal;
