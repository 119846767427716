import React from 'react';
import { useHistory } from 'react-router';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { H5 } from 'components/Typography';
import propertyImage from 'assets/images/propertyImg.jpg';

const PropertyItem = ({ property, index }) => {
  const classes = useStyles({ index });
  const history = useHistory();

  const handleClick = () => {
    history.push(`/property/${property.concept_property_id}#details`);
  };

  return (
    <div className={classes.container} onClick={handleClick}>
      <div className={classes.left}>
        <img src={property?.photo_url || propertyImage} alt="property" />
      </div>
      <div className={classes.right}>
        <H5 bold display="block" className={classes.itemTitle}>
          {property.address}, #{property.unit}
        </H5>
        <H5 display="block">{property.city}</H5>
      </div>
    </div>
  );
};

export default PropertyItem;
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      height: 68,
      marginBottom: 10,
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '10px 10px 5px 10px',
      cursor: 'pointer',
      background: (props) =>
        `${
          props.index % 2 === 1
            ? theme.palette.background.white
            : theme.palette.grey.A400
        }`,
    },
    left: {
      '& > img': {
        width: 83,
        height: 53,
      },
      marginRight: 20,
    },
    right: {},
    itemTitle: { marginBottom: 8 },
  }),
);
