import React, { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router';
// import ReactToPrint from 'react-to-print';

import { useMediaQuery, useTheme, Box } from '@material-ui/core';
import cx from 'classnames';
import { Button } from 'components/Buttons';
import { H4, H5 } from 'components/Typography';
import { PDFDownloader } from 'components/PDFDownloader';
import { StatusMark } from 'components/StatusMark';

import PropertyItem from 'pages/EditClientUrl/components/PropertyItem';
import { INPUT_TYPES, SIZE_TYPES, STATUS_TYPES } from 'utils/constants';
import propertyService from 'services/propertyService';

import { ReactComponent as PDFIcon } from 'assets/icons/pdf.svg';
// import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as ConfigIcon } from 'assets/icons/outline_config.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { EnhancedTable } from 'components/Table';
import { DropdownContent } from 'components/Dropdown';

const SharedPropertiesListComponent = () => {
  const [selectedTime, setSelectedTime] = useState();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const printRef = useRef();
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [mobileTableData, setMobileTableData] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const classes = useStyles();
  const { state = {} } = useLocation();
  const { ids = '' } = state;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    if (ids.length > 0) {
      Promise.all(
        ids.map(async (id) => {
          const property = await propertyService.getProperty(id);
          return property;
        }),
      ).then((values) => {
        console.log('selectedProperties', values);
        setSelectedProperties(values);
      });
    }
  }, [ids]);

  useEffect(() => {
    let newData = [];
    let newMobileData = [];
    selectedProperties.length > 0 &&
      selectedProperties.forEach((row, index) => {
        const newDataItem = {
          id: row.concept_property_id,
          cells: [
            {
              cell: (
                <div className={cx(classes.area1, classes.area)}>
                  <H5 display="block" bold className={classes.line}>
                    {row.address}
                  </H5>
                  <div className={classes.line}>
                    <H5 bold className={classes.rightSpace}>
                      ID
                    </H5>
                    <H5>{row.concept_property_id}</H5>
                  </div>
                  <div className={classes.line}>
                    <H5 className={classes.rightSpace}>Rent</H5>
                    <H5 bold className={classes.rightSpace}>
                      ${Number(row.rent)}
                    </H5>
                    <H5 className={classes.rightSpace}>Fee:</H5>
                    <H5 bold>{row.fee || 0}</H5>
                  </div>
                </div>
              ),
            },
            {
              cell: (
                <div className={cx(classes.area2, classes.area)}>
                  <H5 bold display="block" className={classes.line}>
                    {row.landlord_name}
                  </H5>
                  <H5 display="block" className={classes.line}>
                    {row.landlord_address}
                  </H5>
                  <H5 display="block" className={classes.line}>
                    {row.landlord_primary_phone}
                  </H5>
                </div>
              ),
            },
            {
              cell: (
                <div className={cx(classes.area3, classes.area)}>
                  <div className={classes.line}>
                    <H5 className={classes.rightSpace}>Building type:</H5>
                    <H5 bold>{row.building_type}</H5>
                  </div>
                  <div className={classes.line}>
                    <H5 className={classes.rightSpace}>Beds:</H5>
                    <H5 className={classes.rightSpace} bold>
                      {Number(row.beds)}
                    </H5>
                    <H5 className={classes.rightSpace}>Baths:</H5>
                    <H5 className={classes.rightSpace} bold>
                      {Number(row.baths)}
                    </H5>
                    <H5 className={classes.rightSpace}>Square Footage:</H5>
                    <H5 className={classes.rightSpace} bold>
                      {Number(row.square_footage)}
                    </H5>
                    <H5 className={classes.rightSpace}>Parking:</H5>
                    <H5 className={classes.rightSpace} bold>
                      {row.parking}
                    </H5>
                  </div>
                  <div className={classes.line}>
                    <H5 className={classes.rightSpace}>Utilities Included:</H5>
                    <H5 className={classes.rightSpace} bold>
                      {row.include_heat ? 'Heat' : ''}{' '}
                      {row.include_hot_water ? 'Hot Water' : ''}{' '}
                      {row.include_gas ? 'Gas' : ''}{' '}
                      {row.include_electricity ? 'Electricity' : ''}
                    </H5>
                    <H5 className={classes.rightSpace}>Pet policy:</H5>
                    <H5 className={classes.rightSpace} bold>
                      {row.pet_policy}
                    </H5>
                  </div>
                </div>
              ),
            },
            {
              cell: (
                <div className={cx(classes.area4, classes.area)}>
                  <div className={classes.line}>
                    <H5 className={classes.rightSpace}>
                      {row.showing_information}
                    </H5>
                  </div>
                </div>
              ),
            },
            {
              cell: (
                <div className={cx(classes.area5, classes.area)}>
                  <div style={{ display: 'flex' }} className={classes.line}>
                    <StatusMark
                      status={STATUS_TYPES.ACTIVE}
                      className={classes.rightSpace}
                    />
                    <H5 bold>{row.status}</H5>
                  </div>
                  <H5 display="block">{row.available_date}</H5>
                </div>
              ),
            },
            {
              cell: (
                <div className={cx(classes.area5, classes.remove)}>
                  <RemoveIcon
                    onClick={() => handleRemove(row.concept_property_id)}
                  />
                </div>
              ),
            },
          ],
        };
        newData.push(newDataItem);
        const newMobileDataItem = row;
        newMobileData.push(newMobileDataItem);
      });
    setTableData(newData);
    setMobileTableData(newMobileData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperties]);

  const handleRemove = (propertyId) => {
    setSelectedProperties((properties) =>
      properties.filter(
        (property) => property.concept_property_id !== propertyId,
      ),
    );
  };

  const handleDateChange = (e) => {
    console.log('date', e.target.value);
    setDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    console.log('time', e.target.value);
    setTime(e.target.value);
  };

  const handleSetTime = () => {
    if (!date) {
      setSelectedTime('');
      return;
    }
    let timeStr = '';

    if (date && time) {
      timeStr = `${date}T${time}`;
    }

    if (date && !time) {
      timeStr = date;
    }
    setSelectedTime(new Date(timeStr).toString());
    console.log('new Date(timeStr).toString()', new Date(timeStr).toString());
  };

  const handleOpenActions = () => {
    setShowActions(!showActions);
  };

  const handleCloseShowActions = () => {
    setShowActions(false);
  };

  return (
    <div className={classes.container}>
      {isMobile && (
        <div className={classes.mobileHeader}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Box>
              <H4 className={classes.address}>Showsheet: </H4>
              <H4 className={classes.address} bold>
                {selectedProperties.length} Results
              </H4>
            </Box>
            <Box onClick={handleOpenActions}>
              <DotsIcon />
              {showActions && (
                <DropdownContent
                  handleClickAway={handleCloseShowActions}
                  customStyles={classes.selectDropdown}
                >
                  {/* <Box display="flex" sx={{ columnGap: 10, marginBottom: 15 }}>
                    <ReactToPrint
                      trigger={() => (
                        <Box
                          className="cursor-pointer"
                          display="flex"
                          alignItems="center"
                          gridColumnGap={10}
                        >
                          <PrintIcon />
                          <H4>Print</H4>
                        </Box>
                      )}
                      content={() => printRef.current}
                    />
                  </Box> */}
                  <Box display="flex" sx={{ columnGap: 10 }}>
                    <PDFDownloader
                      downloadFileName="Showsheets"
                      rootElementId="showsheets"
                    >
                      <Box
                        className="cursor-pointer"
                        display="flex"
                        alignItems="center"
                        gridColumnGap={10}
                      >
                        <PDFIcon />
                        <H4>Export PDF</H4>
                      </Box>
                    </PDFDownloader>
                  </Box>
                </DropdownContent>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ width: '100%', mt: 1.25, columnGap: 10 }}
          >
            <input
              name="date"
              className={classes.input}
              placeholder="MM/DD/YYYY"
              type={INPUT_TYPES.DATE}
              onChange={handleDateChange}
            />
            <input
              name="time"
              className={classes.input}
              placeholder="HH:MM"
              type={INPUT_TYPES.TIME}
              onChange={handleTimeChange}
            />
          </Box>
          <Button
            onClick={handleSetTime}
            size={SIZE_TYPES.SMALL}
            className={classes.setTimeBtn}
          >
            Set time
          </Button>
        </div>
      )}
      {!isMobile && (
        <div className={classes.header}>
          <Box display="flex" alignItems="center" gridColumnGap={20}>
            <Box className={classes.address}>Showsheet: </Box>
            <H4 className={classes.address} bold>
              {selectedProperties.length} Results
            </H4>
            <input
              name="date"
              className={classes.input}
              placeholder="MM/DD/YYYY"
              type={INPUT_TYPES.DATE}
              onChange={handleDateChange}
            />
            <input
              name="time"
              className={classes.input}
              placeholder="HH:MM"
              type={INPUT_TYPES.TIME}
              onChange={handleTimeChange}
            />
            <Button
              onClick={handleSetTime}
              size={SIZE_TYPES.SMALL}
              className={classes.setTimeBtn}
            >
              Set time
            </Button>
          </Box>
          <Box display="flex" alignItems="center" gridColumnGap={10}>
            {/* <ReactToPrint
              trigger={() => <PrintIcon className="cursor-pointer" />}
              content={() => printRef.current}
            /> */}
            <PDFDownloader
              downloadFileName="Showsheets"
              rootElementId="showsheets"
            >
              <PDFIcon className="cursor-pointer" />
            </PDFDownloader>
            <ConfigIcon />
          </Box>
        </div>
      )}

      <div className={classes.content}>
        {/* {selectedProperties.map((property, index) => (
          <PropertyItem
            property={property}
            key={index}
            isShowsheetItem
            handleRemove={handleRemove}
          />
        ))} */}
        {isMobile ? (
          <Box display="flex" flexDirection="column" gridRowGap={10}>
            {mobileTableData.map((row, index) => (
              <PropertyItem
                property={row}
                key={index}
                isShowsheetItem
                handleRemove={handleRemove}
              />
            ))}
          </Box>
        ) : (
          <EnhancedTable
            headCells={headers}
            boldHeader
            rows={tableData}
            className={classes.tableStyles}
            variant="secondary"
          />
        )}
      </div>
      <div className={classes.printContent}>
        <div className={classes.content} id="showsheets" ref={printRef}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <H5 bold className={classes.printDate}>
              {selectedTime}
            </H5>
          </div>
          <EnhancedTable
            headCells={headers}
            boldHeader
            rows={tableData}
            className={classes.tableStyles}
            variant="secondary"
          />
        </div>
      </div>
    </div>
  );
};

export default SharedPropertiesListComponent;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      // padding: 30,
      [theme.breakpoints.down('xs')]: {},
    },
    mobileRowcontainer: {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: 130,
      padding: '20px 30px',
      borderLeft: `8px solid ${theme.palette.action.active}`,
      borderRadius: 5,
      backgroundColor: theme.palette.background.white,
      marginBottom: 15,
      [theme.breakpoints.down('xs')]: {
        padding: 10,
        height: 130,
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 30px',
      width: '100%',
      height: 70,
      columnGap: 20,
      rowGap: 20,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
      background: theme.palette.background.white,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: 'auto',
        padding: 20,
      },
    },
    mobileHeader: {
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
      background: theme.palette.background.white,
      flexDirection: 'column',
      height: 'auto',
      padding: 20,
    },
    content: {
      padding: '0 30px',
      marginTop: 30,
    },
    printContent: {
      minWidth: 1400,
      position: 'absolute',
      zIndex: -1,
    },
    printDate: {
      paddingTop: 20,
      textAlign: 'center',
    },
    setTimeBtn: {
      height: 40,
      margin: 10,
      width: 125,
    },
    input: {
      height: 40,
      border: `1px solid ${theme.palette.grey[300]}`,
      boxSizing: 'border-box',
      borderRadius: 5,
      padding: '9px 10px',
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
      [theme.breakpoints.down('xs')]: {
        width: '50%',
      },
    },
    tableStyles: {
      '& .MuiTableHead-root': {
        background: theme.palette.grey.A400,
      },
      '& .MuiTableRow-root': {
        marginBottom: 15,
      },
      '& .MuiTableBody-root .MuiTableRow-root': {
        borderLeft: `8px solid ${theme.palette.action.active}`,
        borderRadius: 5,
      },
      '& .MuiTableCell-head div span': {
        textTransform: 'initial',
      },
      '& .MuiTableCell-root.MuiTableCell-body': {
        borderBottom: `15px solid ${theme.palette.grey.A400}`,
      },
      '& .MuiTableCell-root MuiTableCell-head': {
        borderBottom: 'none',
      },
    },
    mobileTableStyles: {
      '& > div': {
        background: `${theme.palette.background.white} !important`,
        marginBottom: 10,
      },
    },
    remove: {
      padding: '25px 30px',
    },
    line: {
      marginBottom: 10,
    },
    rightSpace: {
      marginRight: 10,
    },
    selectDropdown: {
      transform: 'translate(-10%, 50px)',
      padding: 15,
    },
  }),
);

const headers = [
  {
    label: 'Property',
  },
  {
    label: 'Landlord Details',
  },
  {
    label: 'Listing Details',
  },
  {
    label: 'Showing Information',
  },
  {
    label: 'Status',
  },
  {
    label: '',
  },
];
