import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications';

import { H1, H5 } from 'components/Typography';
import { Button } from 'components/Buttons';
import { EnhancedForm, FormInputField } from 'components/Form';
import { INPUT_TYPES } from 'utils/constants';
import { login } from 'store/authStore';

import { ReactComponent as ProjectLogo } from 'assets/projectLogo.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    loginPage: {
      width: '100%',
      maxWidth: 440,
      background: theme.palette.background.white,
      borderRadius: 5,
    },
    header: {
      width: '100%',
      padding: '35px 60px',
      background: theme.palette.action.active,
      borderRadius: '5px 5px 0px 0px',
      [theme.breakpoints.down('xs')]: {
        padding: '25px 40px',
      },
      '& > svg': {
        width: 190,
        height: 50,
        [theme.breakpoints.down('xs')]: {
          width: 140,
          height: 36,
        },
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      padding: 60,
      paddingTop: 40,
      [theme.breakpoints.down('xs')]: {
        rowGap: 5,
        padding: 20,
        paddingTop: 0,
      },
    },
    loginButton: {
      width: '100%',
    },
    forgotPassword: {
      textAlign: 'center',
      color: theme.palette.action.active,
      cursor: 'pointer',
      marginTop: 12,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();

  const handleSubmit = (body) => {
    dispatch(
      login({
        body,
        success: () => {
          addToast('Successfully logged in.', {
            appearance: 'success',
            autoDismiss: true,
          });
          history.push('/');
        },
        failure: () => {
          addToast("Sorry, we don't recognize this account.", {
            appearance: 'error',
            autoDismiss: true,
          });
        },
      }),
    );
  };

  const forgotPassword = () => {
    history.push('/reset_password_request');
  };

  return (
    <div className={classes.loginPage}>
      <div className={classes.header}>
        <ProjectLogo />
      </div>
      <EnhancedForm
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Invalid email').required('Required'),
          password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('Required'),
        })}
        handleSubmit={handleSubmit}
        className={classes.content}
      >
        {() => (
          <>
            <H1>Log in.</H1>
            <FormInputField label="Email" name="email" />
            <FormInputField
              label="Password"
              name="password"
              type={INPUT_TYPES.PASSWORD}
            />
            <Button type="submit" className={classes.loginButton}>
              Log in
            </Button>
            <H5
              bold
              onClick={forgotPassword}
              className={classes.forgotPassword}
            >
              Forgot password?
            </H5>
          </>
        )}
      </EnhancedForm>
    </div>
  );
};

export default LoginPage;
