import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { H5 } from 'components/Typography';
import defaultAvatar from 'assets/defaultAvatar.svg';
import { CheckBox } from 'components/CheckBox';

const TeamMemberItem = ({ agent, index, checked, setChecked }) => {
  const classes = useStyles({ index, checked });
  const handleChecked = () => {
    setChecked(agent.user_id);
  };

  return (
    <div className={classes.container}>
      <CheckBox checked={checked} setChecked={handleChecked} />
      <div className={classes.member}>
        <div className={classes.left}>
          <img
            className={classes.image}
            src={agent.photo_url || defaultAvatar}
            alt="team member"
          />
        </div>
        <div className={classes.right}>
          <div className={classes.itemTitle}>
            <H5>{agent.name}</H5>
          </div>
          <div>
            <H5>
              {agent.email}
              {agent.phone && ` | ${agent.phone}`}{' '}
            </H5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberItem;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      columnGap: 20,
    },
    member: {
      width: '100%',
      height: 70,
      display: 'flex',
      justifyContent: 'flex-start',
      border: (props) =>
        props.checked
          ? `2px solid ${theme.palette.action.active}`
          : `2px solid ${theme.palette.action.disabled}`,
      borderRadius: 5,
      padding: 10,
    },
    right: {
      marginLeft: 10,
    },
    itemTitle: {},
    info: {
      display: 'flex',
      columnGap: 10,
      marginTop: 10,
    },
    image: {
      width: 50,
      height: 50,
      borderRadius: 50,
    },
  }),
);
