import React, { useState } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { H4 } from './Typography';
import { SORTS, VARIANTS } from 'utils/constants';
import { CheckBox } from './CheckBox';
import { Spinner } from './Spinner';

import { ReactComponent as SortIcon } from 'assets/icons/arrowFillDown.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowRight.svg';

export const EnhancedTable = ({
  className,
  headCells,
  rows = [],
  order = SORTS.DESC,
  orderBy = null,
  enableSelect = false,
  selected = [],
  variant = VARIANTS.PRIMARY,
  boldHeader = false,
  handleChangeOrder = null,
  handleSelect = null,
  handleRowClick = null,
  handleChangeOrderBy = null,
  loading = false,
}) => {
  const classes = useStyles({ order, variant });

  const handleRequestSort = (property) => {
    if (loading) return;
    if (property === orderBy) {
      handleChangeOrder && handleChangeOrder(property);
    } else {
      handleChangeOrderBy && handleChangeOrderBy(property);
    }
  };

  const handleClickCheckBox = (id) => {
    handleSelect && handleSelect(id);
  };

  const handleClickRow = (id) => {
    handleRowClick && handleRowClick(id);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <TableContainer className={classNames(classes.table, className)}>
      <Table aria-labelledby="tableTitle">
        <TableHead>
          <TableRow>
            {enableSelect && <TableCell padding="checkbox" />}
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.label}
                align={headCell.align || 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                onClick={() =>
                  handleRequestSort(headCell.sortBy || headCell.label)
                }
              >
                <Box display="flex" alignItems="center" gridColumnGap={10}>
                  <H4
                    className={classNames(classes.headerCell, {
                      sortHeader:
                        (headCell.sortBy || headCell.label) === orderBy,
                    })}
                    bold={boldHeader}
                  >
                    {headCell.label}
                  </H4>
                  {(headCell.sortBy || headCell.label) === orderBy && (
                    <SortIcon className={classes.sortIcon} />
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {!loading && (
          <TableBody>
            {rows.length
              ? rows.map((row) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <TableRow
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => handleClickRow(row.id)}
                    >
                      {enableSelect && (
                        <TableCell padding="checkbox">
                          <CheckBox
                            checked={isItemSelected}
                            setChecked={() => handleClickCheckBox(row.id)}
                          />
                        </TableCell>
                      )}
                      {row.cells.map((item, index) => (
                        <TableCell key={index} align={item.align || 'left'}>
                          {item.cell}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        )}
      </Table>
      {loading && (
        <Box className={classes.loadingContent}>
          <Spinner loading={loading} />
        </Box>
      )}
    </TableContainer>
  );
};

export const EnhancedMobileTable = ({
  className,
  rows = [],
  enableSelect = false,
  selected = [],
  variant = VARIANTS.PRIMARY,
  handleSelect = null,
  loading = false,
}) => {
  const classes = useStyles({ variant });
  const [openId, setOpenId] = useState(null);

  const handleOpenContent = (id) => {
    if (id === openId) {
      setOpenId(null);
    } else {
      setOpenId(id);
    }
  };

  const handleClickCheckBox = (id) => {
    handleSelect && handleSelect(id);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  if (loading) {
    return (
      <Box className={classes.loadingContent}>
        <Spinner loading={loading} />
      </Box>
    );
  }

  return (
    <div className={classNames(classes.mobileContainer, className)}>
      {rows.length
        ? rows.map((row) => {
            const isItemSelected = isSelected(row.id);
            return (
              <div key={row.id}>
                {enableSelect && (
                  <CheckBox
                    className={classes.mobileCheckBox}
                    checked={isItemSelected}
                    setChecked={() => handleClickCheckBox(row.id)}
                  />
                )}
                <Box
                  className="cursor-pointer"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  gridRowGap={10}
                  flex={1}
                  onClick={() => handleOpenContent(row.id)}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gridColumnGap={20}
                  >
                    <Box flex={1}>{row.title}</Box>
                    <ArrowIcon
                      className={
                        openId === row.id ? classes.arrowUp : classes.arrowDown
                      }
                    />
                  </Box>
                  {openId === row.id && row.content}
                </Box>
              </div>
            );
          })
        : null}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      '& .MuiTableHead-root': {
        background: theme.palette.background.white,
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root:nth-child(odd)': {
          background: (props) =>
            props.variant === VARIANTS.SECONDARY
              ? theme.palette.background.white
              : theme.palette.grey.A400,
        },
        '& .MuiTableRow-root:nth-child(even)': {
          background: theme.palette.background.white,
        },
      },
      '& .MuiTableCell-root': {
        padding: '15px 10px',
        [theme.breakpoints.down('xs')]: {
          padding: '10px 10px',
        },
        '&:first-child': {
          paddingLeft: 20,
        },
        '&:last-child': {
          paddingRight: 20,
        },
      },
    },
    headerCell: {
      textTransform: 'uppercase',
      '&:hover': {
        cursor: 'pointer',
      },
      '&.sortHeader': {
        color: theme.palette.action.active,
        cursor: 'pointer',
      },
    },
    sortIcon: {
      cursor: 'pointer',
      transform: (props) =>
        props.order === SORTS.DESC ? 'rotate(0deg)' : 'rotate(180deg)',
      '& > path': {
        fill: theme.palette.action.active,
      },
    },
    loadingContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 250,
      width: '100%',
      background: 'transparent',
    },
    mobileContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: -20,
      marginRight: -20,
      borderBottom: `1px solid ${theme.palette.grey.A200}`,
      '& > div': {
        padding: '10px 20px',
        display: 'flex',
        columnGap: 20,
        transition: 'all 0.3s ease-in-out',
        '&:nth-child(odd)': {
          background: (props) =>
            props.variant === VARIANTS.SECONDARY
              ? theme.palette.background.white
              : theme.palette.grey.A400,
        },
        '&:nth-child(even)': {
          background: theme.palette.background.white,
        },
      },
    },
    arrowDown: {
      transform: 'rotate(-90deg)',
    },
    arrowUp: {
      transform: 'rotate(90deg)',
    },
    mobileCheckBox: {
      height: 40,
    },
  }),
);
