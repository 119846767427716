import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as Yup from 'yup';

import { EnhancedModal } from 'components/Modal';
import { Button } from 'components/Buttons';

import { H5 } from 'components/Typography';
import { DropdownContent } from 'components/Dropdown';

import { EnhancedForm, FormInputField } from 'components/Form';
import {
  LANDLORD_NOTE_TYPE_ID,
  PROPERTY_NOTE_TYPE_ID,
  SIZE_TYPES,
  VARIANTS,
} from 'utils/constants';
import { CheckBox } from 'components/CheckBox';
import { createNewNote, updateNote } from 'store/noteStore';

const CreateEditNoteModal = ({
  isModalOpen,
  closeModal,
  onSuccess,
  landlord_id = null,
  propertyId,
  data,
}) => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.authReducer);
  const { accessTypes } = useSelector((store) => store.noteReducer);
  const [isOpenPrivacy, setIsOpenPrivacy] = useState(false);
  const [privacy, setPrivacy] = useState();
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const { title, body } = values;

    console.log('values', values);

    const payload = {
      user_key: user?.user_key,
      note_type_id: LANDLORD_NOTE_TYPE_ID,
      title,
      body,
      landlord_id,
      access_type_id: privacy.access_type_id,
    };

    if (propertyId) {
      payload.concept_property_id = propertyId;
      payload.note_type_id = PROPERTY_NOTE_TYPE_ID;
      delete payload.landlord_id;
    }

    if (data?.note_id) {
      payload.note_id = data?.note_id;
    }

    closeModal();
    if (!data?.note_id) {
      dispatch(
        createNewNote({
          body: payload,
          success: () => {
            if (onSuccess) {
              onSuccess();
            }
          },
        }),
      );
    } else {
      delete payload.user_key;
      dispatch(
        updateNote({
          body: payload,
          success: () => {
            if (onSuccess) {
              onSuccess();
            }
          },
        }),
      );
    }
  };

  const handleSetPrivacy = (item) => {
    setPrivacy(item);
  };

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={'Create New Note'}
      mobileFullWidth={true}
    >
      <div className={classes.contiainer}>
        <EnhancedForm
          initialValues={{
            title: data?.title,
            body: data?.body,
            contact: landlord_id,
            property: data?.property,
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required('Required'),
            body: Yup.string().required('Required'),
          })}
          handleSubmit={handleSubmit}
          className={classes.contiainer}
        >
          {(props) => (
            <>
              <FormInputField label="Title" name="title" />
              <FormInputField label="body" name="body" multiline />
              <Box
                display="flex"
                sx={{ columnGap: 20 }}
                className={classes.selectGrp}
              >
                <div
                  className={classes.inputWrapper}
                  onClick={() => setIsOpenPrivacy(true)}
                >
                  <H5>Privacy</H5>
                  <input
                    name="privacy"
                    className={classes.input}
                    placeholder="Privacy"
                    value={privacy?.title}
                    readOnly
                  />
                  {isOpenPrivacy && (
                    <DropdownContent
                      handleClickAway={() => setIsOpenPrivacy(false)}
                      customStyles={classes.selectDropdown}
                    >
                      {accessTypes.map((item, index) => (
                        <div
                          className={classes.listItem}
                          key={`privacy-${index}`}
                          onClick={() => handleSetPrivacy(item)}
                        >
                          <CheckBox
                            label={item.title}
                            checked={
                              privacy?.access_type_id === item?.access_type_id
                            }
                            setChecked={() => handleSetPrivacy(item)}
                          />
                        </div>
                      ))}
                    </DropdownContent>
                  )}
                </div>
              </Box>
              <Box className={classes.actionButtons}>
                <Button
                  onClick={closeModal}
                  variant={VARIANTS.SECONDARY}
                  size={SIZE_TYPES.LARGE}
                >
                  Cancel
                </Button>
                <Button type="submit" size={SIZE_TYPES.LARGE}>
                  Save
                </Button>
              </Box>
            </>
          )}
        </EnhancedForm>
      </div>
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    contiainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 500,
      rowGap: 10,
      overflow: 'auto',
      [theme.breakpoints.down('xs')]: {
        rowGap: 20,
        padding: 10,
        width: 'calc(100% + 20px)',
        marginLeft: -10,
        height: 600,
      },
    },
    taskType: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: 320,
      width: '100%',
      position: 'relative',
      padding: '0 14px',
      minHeight: 50,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 5,
      cursor: 'pointer',
    },
    typesDropdown: {
      left: 'auto',
      right: 0,
      minWidth: 185,
      transform: 'translate(0, 5px)',
    },
    dropwdown: {
      width: 320,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      '& > div': {
        padding: '8px 15px',
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
        },
      },
    },
    actionButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
      columnGap: 20,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        background: theme.palette.background.white,
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.15)',
        height: 80,
        width: '100%',
      },
    },
    inputWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: 10,
      },
      position: 'relative',
    },
    label: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '22px',
      color: theme.palette.text.primary,
      marginBottom: 5,
    },
    input: {
      height: 40,
      border: `1px solid ${theme.palette.grey[300]}`,
      boxSizing: 'border-box',
      borderRadius: 5,
      padding: '9px 10px',
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
    },
    selectDropdown: {
      width: '100%',
      border: `2px solid ${theme.palette.action.active}`,
      transform: 'translate(-50%, 0px)',
      maxHeight: 230,
      overflowY: 'auto',
      overflowX: 'hidden',
      top: '100%',
      left: '50%',
      '&::-webkit-scrollbar': {
        width: 4,
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.grey.A100,
        borderRadius: 2,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.action.active,
        borderRadius: 2,
        '&:hover': {
          background: theme.palette.action.hover,
        },
      },
    },
    listItem: {
      display: 'flex',
      gap: 10,
      margin: 10,
      width: '100%',

      '& span': {
        minHeight: 20,
        lineHeight: '25px',
      },
    },
    datetime: {
      width: '100%',
    },
    selectGrp: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        rowGap: 30,
      },
    },
  }),
);

export default CreateEditNoteModal;
