import React from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    typo: {
      fontStyle: 'normal',
      display: (props) => props.display,
      color: (props) =>
        props.grey
          ? theme.palette.grey[300]
          : props.white
          ? theme.palette.text.secondary
          : theme.palette.text.primary,
    },
    h1: {
      fontSize: 24,
      lineHeight: '34px',
      fontWeight: 700,
      margin: '20px 0',
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        lineHeight: '27px',
      },
    },
    h2: {
      fontSize: 22,
      lineHeight: '30px',
      fontWeight: 700,
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
        lineHeight: '25px',
      },
    },
    h3: {
      fontSize: 20,
      lineHeight: '27px',
      fontWeight: (props) => (props.bold ? 700 : 400),
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
        lineHeight: '22px',
      },
    },
    h4: {
      fontSize: 18,
      lineHeight: '25px',
      fontWeight: (props) => (props.bold ? 700 : 400),
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        lineHeight: '20px',
      },
    },
    h5: {
      fontSize: 16,
      lineHeight: '22px',
      fontWeight: (props) => (props.bold ? 700 : 400),
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        lineHeight: '20px',
      },
    },
    h6: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: (props) => (props.bold ? 700 : 400),
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
        lineHeight: '16px',
      },
    },
  }),
);

export const H1 = ({
  children,
  className,
  onClick,
  grey = false,
  white = false,
  display = 'initial',
}) => {
  const classes = useStyles({ grey, white, display });

  return (
    <h1
      className={classNames(classes.typo, classes.h1, className)}
      onClick={onClick}
    >
      {children}
    </h1>
  );
};

export const H2 = ({
  children,
  className,
  onClick,
  grey = false,
  white = false,
  display = 'initial',
}) => {
  const classes = useStyles({ grey, white, display });

  return (
    <h2
      className={classNames(classes.typo, classes.h2, className)}
      onClick={onClick}
    >
      {children}
    </h2>
  );
};

export const H3 = ({
  children,
  className,
  onClick,
  bold = false,
  grey = false,
  white = false,
  display = 'initial',
}) => {
  const classes = useStyles({ bold, grey, white, display });

  return (
    <h3
      className={classNames(classes.typo, classes.h3, className)}
      onClick={onClick}
    >
      {children}
    </h3>
  );
};

export const H4 = ({
  children,
  className,
  onClick,
  bold = false,
  grey = false,
  white = false,
  display = 'initial',
}) => {
  const classes = useStyles({ bold, grey, white, display });

  return (
    <span
      className={classNames(classes.typo, classes.h4, className)}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export const H5 = ({
  children,
  className,
  onClick,
  bold = false,
  grey = false,
  white = false,
  display = 'initial',
}) => {
  const classes = useStyles({ bold, grey, white, display });

  return (
    <span
      className={classNames(classes.typo, classes.h5, className)}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export const H6 = ({
  children,
  className,
  onClick,
  bold = false,
  grey = false,
  white = false,
  display = 'initial',
}) => {
  const classes = useStyles({ bold, grey, white, display });

  return (
    <span
      className={classNames(classes.typo, classes.h6, className)}
      onClick={onClick}
    >
      {children}
    </span>
  );
};
