import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';
import date from 'date-and-time';

import { H4 } from 'components/Typography';
import { EnhancedTable, EnhancedMobileTable } from 'components/Table';
import { Pagination } from 'components/Pagination';
import DeleteConfirmModal from 'components/DeleteConfirmModal';
import { DEFAULT_ROWS_PER_PAGE, SORTS } from 'utils/constants';
import { getClientUrls, deleteClientUrls } from 'store/clientStore';

import HomeTableHeader from './HomeTableHeader';

import { ReactComponent as CopyLinkIcon } from 'assets/icons/copyLink.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/externalLink.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { useHistory } from 'react-router';

const Task_Headers = [
  {
    label: 'Name',
  },
  {
    label: 'Who Created',
  },
  {
    label: 'Properties',
  },
  {
    label: 'Date',
  },
  {
    label: '',
  },
];

const checkOrder = (a, b, order) => {
  if ((a?.toLowerCase() || '') < (b?.toLowerCase() || '')) {
    if (order === SORTS.DESC) {
      return 1;
    }
    return -1;
  } else if ((a || '') > (b || '')) {
    if (order === SORTS.DESC) {
      return -1;
    }
    return 1;
  } else {
    return 0;
  }
};

const ClientUrl = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const dispatch = useDispatch();
  const { clients, clientsLoading } = useSelector(
    (state) => state.clientReducer,
  );
  const { user } = useSelector((state) => state.authReducer);

  const [tableData, setTableData] = useState([]);
  const [mobileTableData, setMobileTableData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isSelectAll, setIsSectAll] = useState(false);
  const [order, setOrder] = useState(SORTS.ASC);
  const [orderBy, setOrderBy] = useState('Name');
  const [page, setPage] = useState(1);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
  const [deleteIds, setDeleteIds] = useState(null);
  const history = useHistory();
  const hostname = window.location.origin;

  useEffect(() => {
    dispatch(getClientUrls());
  }, [dispatch]);

  useEffect(() => {
    let newData = [];
    let newMobileData = [];
    if (clients && clients.length) {
      const data = [...clients].sort((a, b) => {
        if (orderBy === 'Name') {
          return checkOrder(a.client_name, b.client_name, order);
        } else if (orderBy === 'Who Created') {
          return checkOrder(a.created_by, b.created_by, order);
        } else if (orderBy === 'Properties') {
          if ((a.property_count || 0) < (b.property_count || 0)) {
            if (order === SORTS.DESC) {
              return 1;
            }
            return -1;
          } else if ((a.property_count || 0) > (b.property_count || 0)) {
            if (order === SORTS.DESC) {
              return -1;
            }
            return 1;
          } else {
            return 0;
          }
        } else if (orderBy === 'Date') {
          if (
            new Date(a.created_at).getTime() > new Date(b.created_at).getTime()
          ) {
            if (order === SORTS.DESC) {
              return -1;
            }
            return 1;
          } else {
            if (order === SORTS.DESC) {
              return 1;
            }
            return -1;
          }
        }
        return 0;
      });
      data.forEach((row) => {
        const dueDate = new Date(row.created_at);
        const newDataItem = {
          id: row.client_url_id,
          cells: [
            {
              cell: <H4 className="cursor-pointer">{row.client_name}</H4>,
            },
            {
              cell: <H4>{row.created_by}</H4>,
            },
            {
              cell: <H4>{row.property_count} properties</H4>,
            },
            {
              cell: <H4>{date.format(dueDate, 'MMM DD, YYYY')}</H4>,
            },
            {
              align: 'right',
              cell: (
                <Box
                  display="inline-flex"
                  alignItems="center"
                  gridColumnGap={20}
                >
                  <CopyLinkIcon
                    onClick={(e) => copyLink(e, row)}
                    width={16}
                    height={16}
                    className="cursor-pointer"
                  />
                  <a
                    href={`${hostname}/public_client_url_list?client_url_key=${
                      row.client_url_key
                    }${user?.user_id ? '&a=' + user.user_id : ''}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ height: 16 }}
                  >
                    <ExternalLinkIcon
                      width={16}
                      height={16}
                      className="cursor-pointer"
                    />
                  </a>
                  <EditIcon
                    width={16}
                    height={16}
                    className="cursor-pointer"
                    onClick={() => handleEdit(row.client_url_id)}
                  />
                  <RemoveIcon
                    width={16}
                    height={16}
                    className="cursor-pointer"
                    onClick={() => {
                      setDeleteIds([row.client_url_id]);
                      setIsOpenDeleteConfirm(true);
                    }}
                  />
                </Box>
              ),
            },
          ],
        };
        newData.push(newDataItem);
        const newMobileDataItem = {
          id: row.client_url_id,
          title: (
            <Box display="flex" flexDirection="column" gridRowGap={5}>
              <H4>{row.client_name}</H4>
              <H4 bold>{row.property_count} properties</H4>
            </Box>
          ),
          content: (
            <Box display="flex" flexDirection="column" gridRowGap={10}>
              <Box display="flex" gridColumnGap={20}>
                <Box flex={1}>
                  <H4 bold>Who created:</H4>
                </Box>
                <Box flex={1}>
                  <H4>{row.created_by}</H4>
                </Box>
              </Box>
              <Box display="flex" gridColumnGap={20}>
                <Box flex={1}>
                  <H4 bold>Date:</H4>
                </Box>
                <Box flex={1}>
                  <H4>{date.format(dueDate, 'MMM DD, YYYY')}</H4>
                </Box>
              </Box>
              <Box display="flex" gridColumnGap={20}>
                <Box flex={1}>
                  <H4 bold>Actions:</H4>
                </Box>

                <Box
                  display="inline-flex"
                  alignItems="center"
                  gridColumnGap={20}
                  flex={1}
                >
                  <CopyLinkIcon
                    onClick={(e) => copyLink(e, row)}
                    width={16}
                    height={16}
                    className="cursor-pointer"
                  />
                  <a
                    href={`${hostname}/public_client_url_list?client_url_key=${
                      row.client_url_key
                    }${user?.user_id && '&a=' + user.user_id}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ height: 16 }}
                  >
                    <ExternalLinkIcon
                      width={16}
                      height={16}
                      className="cursor-pointer"
                    />
                  </a>
                  <EditIcon
                    width={16}
                    height={16}
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(row.client_url_id);
                    }}
                  />
                  <RemoveIcon
                    width={16}
                    height={16}
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteIds([row.client_url_id]);
                      setIsOpenDeleteConfirm(true);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          ),
        };
        newMobileData.push(newMobileDataItem);
      });
    }
    setTableData(newData);
    setMobileTableData(newMobileData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, order, orderBy, dispatch]);

  const handleEdit = (clientId) => {
    history.push(`/edit_client/${clientId}`);
  };

  const handleSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else {
      newSelected = selected.filter((item) => item !== id);
    }
    setSelected(newSelected);
    if (isSelectAll) {
      setIsSectAll(false);
    }
  };

  const handleSelectAll = () => {
    let newSelected = [];
    if (isSelectAll) {
      setIsSectAll(false);
    } else {
      newSelected = tableData.map((row) => row.id);
      setIsSectAll(true);
    }
    setSelected(newSelected);
  };

  const handleDeleteItems = () => {
    setDeleteIds(selected);
    setIsOpenDeleteConfirm(true);
  };

  const handleChangeOrder = (property) => {
    setPage(1);
    setOrder(order === SORTS.DESC ? SORTS.ASC : SORTS.DESC);
  };

  const handleChangeOrderBy = (property) => {
    setPage(1);
    setOrderBy(property);
  };

  const handleCloseDeleteConfirmModal = () => {
    setIsOpenDeleteConfirm(false);
    setDeleteIds(null);
  };

  const copyLink = (e, clientUrl) => {
    e.stopPropagation();

    navigator.clipboard.writeText(
      `${hostname}/public_client_url_list?client_url_key=${
        clientUrl.client_url_key
      }${user?.user_id ? '&a=' + user.user_id : ''}`,
    );
  };

  return (
    <div className={classes.container}>
      <HomeTableHeader
        title="Client URL"
        selected={selected}
        isSelectAll={isSelectAll}
        handleSelectAll={handleSelectAll}
        handleDeleteItems={handleDeleteItems}
      />
      {isMobile ? (
        <EnhancedMobileTable
          rows={mobileTableData.slice(
            (page - 1) * DEFAULT_ROWS_PER_PAGE,
            page * DEFAULT_ROWS_PER_PAGE,
          )}
          enableSelect={true}
          selected={selected}
          handleSelect={handleSelect}
          loading={clientsLoading}
        />
      ) : (
        <EnhancedTable
          headCells={Task_Headers}
          rows={tableData.slice(
            (page - 1) * DEFAULT_ROWS_PER_PAGE,
            page * DEFAULT_ROWS_PER_PAGE,
          )}
          order={order}
          orderBy={orderBy}
          enableSelect={true}
          selected={selected}
          handleSelect={handleSelect}
          handleChangeOrder={handleChangeOrder}
          handleChangeOrderBy={handleChangeOrderBy}
          loading={clientsLoading}
        />
      )}
      <div className={classes.footer}>
        <Pagination
          page={page}
          setPage={setPage}
          lastPage={Math.ceil(tableData.length / DEFAULT_ROWS_PER_PAGE)}
        />
      </div>
      {isOpenDeleteConfirm && (
        <DeleteConfirmModal
          isModalOpen={isOpenDeleteConfirm}
          closeModal={handleCloseDeleteConfirmModal}
          confirm={() => {
            deleteIds &&
              dispatch(
                deleteClientUrls({
                  body: deleteIds,
                  success: () => {
                    if (selected.length) {
                      setSelected && setSelected([]);
                      setIsSectAll(false);
                    }
                    setIsOpenDeleteConfirm(false);
                  },
                }),
              );
          }}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: 30,
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
      },
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 20px',
      height: 55,
      background: theme.palette.background.white,
      boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.down('xs')]: {
        height: 40,
        marginLeft: -20,
        marginRight: -20,
      },
    },
  }),
);

export default ClientUrl;
