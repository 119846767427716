import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { CheckBox } from 'components/CheckBox';
import { H1, H5 } from 'components/Typography';
import { Spinner } from 'components/Spinner';

import LandlordTable from './components/LandlordTable';
import PinnedContacts from './components/PinnedContacts';
import AddLandlordModal from './modals/AddLandlord';
import SearchField from './components/SearchField';

import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import SubHeader from 'pages/Property/components/PropertiesList/SubHeader';

const LandlordListPage = () => {
  const classes = useStyles();

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [isSelectAll, setIsSectAll] = useState(false);
  const [view, setView] = useState('all');
  const [list, setList] = useState([]);

  const { listLoading, landlordList, pinnedLandlordList } = useSelector(
    (store) => store.landlordReducer,
  );

  useEffect(() => {
    const tab =
      view === 'myBookOfBusiness'
        ? 'bookOfBusiness'
        : view === 'thePond'
        ? 'inThePond'
        : '';
    const filteredList =
      view === 'all'
        ? landlordList
        : landlordList.filter((landlord) => {
            return landlord.record_location === tab;
          });
    setList(filteredList);
  }, [landlordList, view]);

  const handleCloseAddModal = () => {
    setIsOpenAddModal(false);
  };

  const handleSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else {
      newSelected = selected.filter((item) => item !== id);
    }
    setSelected(newSelected);
    if (isSelectAll) {
      setIsSectAll(false);
    }
  };

  const handleSelectAll = () => {
    let newSelected = [];
    if (isSelectAll) {
      setIsSectAll(false);
    } else {
      newSelected = landlordList.map((row) => row.landlord_id);
      setIsSectAll(true);
    }
    setSelected && setSelected(newSelected);
  };

  const handleDeleteItems = () => {
    setSelected && setSelected([]);
    setIsSectAll(false);
  };

  const handleTabChange = (tab) => {
    console.log('tab', tab);
    setView(tab);
  };

  return (
    <>
      <SubHeader
        view={view}
        handleChangeView={handleTabChange}
        setIsOpenAddModal={setIsOpenAddModal}
        secondTabText="All Landlords"
        addBtnText="Add New Landlord"
      />
      {listLoading ? (
        <Box className={classes.loadingContent}>
          <Spinner loading={listLoading} />
        </Box>
      ) : (
        <div className={classes.landlordPage}>
          <H1 display="block">Landlord List</H1>
          <Box className={classes.content} marginBottom={3}>
            {selected.length ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                maxWidth={350}
              >
                <Box display="flex" alignItems="center" gridColumnGap={10}>
                  <CheckBox
                    checked={isSelectAll}
                    setChecked={handleSelectAll}
                  />
                  <H5>All</H5>
                </Box>
                <H5>{selected.length} Selected</H5>
                <Box
                  className="cursor-pointer"
                  display="flex"
                  alignItems="center"
                  gridColumnGap={10}
                  onClick={handleDeleteItems}
                >
                  <RemoveIcon width={20} height={20} />
                  <H5>Delete</H5>
                </Box>
              </Box>
            ) : (
              <Box className={classes.headerBar}>
                <Box flex={1}>
                  <SearchField data={list} />
                </Box>
              </Box>
            )}
            <Box width={1} maxWidth={380} />
          </Box>
          <Box className={classes.content}>
            <div className={classes.landloadList}>
              <LandlordTable
                data={list}
                handleSelect={handleSelect}
                selected={selected}
              />
            </div>
            <Box className={classes.pinndedContacts}>
              <PinnedContacts data={pinnedLandlordList} />
            </Box>
          </Box>
          {isOpenAddModal && (
            <AddLandlordModal
              isModalOpen={isOpenAddModal}
              closeModal={handleCloseAddModal}
            />
          )}
        </div>
      )}
    </>
  );
};

export default LandlordListPage;

const useStyles = makeStyles((theme) =>
  createStyles({
    loadingContent: {
      height: 'calc(100% - 70px)',
      display: 'flex',
      alignItems: 'center',
    },
    landlordPage: {
      padding: 30,
      [theme.breakpoints.down('xs')]: {
        padding: '0 20px',
      },
    },
    headerBar: {
      width: '100%',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: 20,
      rowGap: 10,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
        '& > div': {
          width: '100%',
        },
      },
    },
    content: {
      display: 'flex',
      columnGap: 30,
      rowGap: 30,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    landloadList: {
      flex: 1,
    },
    pinndedContacts: {
      width: '100%',
      maxWidth: 380,
      minWidth: 380,
      margin: '55px 0',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        minWidth: '100%',
      },
    },
  }),
);
