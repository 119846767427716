import React, { useState } from 'react';
import Geocode from 'react-geocode';
import { H1, H4 } from 'components/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import date from 'date-and-time';

import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as WorldIcon } from 'assets/icons/world.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import defaultAvatar from 'assets/defaultAvatar.svg';

import EditAgentModal from '../modals/EditAgent';

const DetailsInfo = ({ data, id }) => {
  const classes = useStyles();
  const [position, setPosition] = useState({});
  const [details, setDetails] = useState(data || {});
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    setDetails(data);
  }, [data]);

  React.useEffect(() => {
    if (details?.address) {
      Geocode.fromAddress(details?.address).then((res) => {
        const { lat, lng } = res.results[0].geometry.location;
        setPosition({ lat, lng });
      });
    }
  }, [details]);

  const handleCloseAddModal = () => {
    setIsOpenEdit(false);
  };

  const handleOpenEdit = () => {
    setIsOpenEdit(true);
  };

  if (!details) {
    return <div className={classes.container}></div>;
  }

  return (
    <div className={classes.container}>
      <Box display="flex" sx={{ mt: 5 }} justifyContent="space-between">
        <Box display="flex">
          {!isMobile && (
            <Box sx={{ mr: 3.75 }}>
              <img
                src={details.photo_url || defaultAvatar}
                className={classes.agentImage}
                alt="team member"
              />
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <H1 display="block" className={classes.name}>
              {details.first_name} {details.last_name}
            </H1>
            <H4 display="block">
              Updated on{' '}
              {details.last_updated &&
                date.format(new Date(details.last_updated), 'MMM DD, YYYY')}
            </H4>
          </Box>
        </Box>
        <EditIcon
          style={{ width: 20, marginLeft: 20, cursor: 'pointer' }}
          onClick={handleOpenEdit}
        />
      </Box>

      <div className={classes.divider} />

      <H4 display="block" bold>
        CONTACT INFO
      </H4>
      <div className={classes.infoBox}>
        <div className={classes.infoItem}>
          <PhoneIcon style={{ marginRight: 22 }} />
          <H4>{details.primary_phone}</H4>
        </div>
        <div className={classes.infoItem}>
          <MailIcon style={{ marginRight: 22 }} />
          <H4>{details.email}</H4>
        </div>
        <div className={classes.infoItem}>
          <PhoneIcon style={{ marginRight: 22 }} />
          <H4>{details.secondary_phone}</H4>
        </div>
        <div className={classes.infoItem}>
          <WorldIcon style={{ marginRight: 22 }} />
          <H4>{details.website}</H4>
        </div>
      </div>

      <div className={classes.divider} />

      <div>
        <Box className={classes.address}>
          <H4 bold>Address:</H4>
          <H4> {details.address && details.address.split(', ')[0]}</H4>
        </Box>
        <Box style={{ marginBottom: 20 }}>
          <H4 bold>City:</H4>
          <H4> {details.city}</H4>
        </Box>
      </div>

      <div style={{ height: 350 }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_MAP_KEY}` }}
          center={{
            lat: position.lat,
            lng: position.lng,
          }}
          defaultZoom={15}
          margin={[30, 30, 30, 30]}
        ></GoogleMapReact>
      </div>

      <EditAgentModal
        isModalOpen={isOpenEdit}
        closeModal={handleCloseAddModal}
        originalData={data}
        id={id}
      />
    </div>
  );
};

export default DetailsInfo;
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      background: theme.palette.background.white,
      borderRadius: 5,
      padding: '1px 20px 20px 20px',
      maxWidth: 820,
      width: '100%',
      height: 878,
      [theme.breakpoints.down('xs')]: {
        height: 805,
      },
    },
    infoBox: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      gap: '30px 20px',
      marginTop: 30,
      marginBottom: 30,
      [theme.breakpoints.down('md')]: {
        gap: '10px 20px',
        marginTop: 20,
      },
    },
    infoItem: {
      width: 300,
    },
    name: {
      margin: 0,
    },
    divider: {
      marginTop: 30,
      marginBottom: 30,
      height: 1,
      border: `1px solid ${theme.palette.grey.A100}`,
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
        marginBottom: 20,
      },
    },
    address: {
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
      },
    },
    agentImage: {
      width: 60,
      height: 60,
      objectFit: 'cover',
      borderRadius: 60,
    },
  }),
);
