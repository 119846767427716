import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import date from 'date-and-time';

import { H2, H4, H5 } from 'components/Typography';
import { XScrollBar } from 'components/XScrollBar';
import { StatusMark } from 'components/StatusMark';
import { STATUS_TYPES, STATUSES } from 'utils/constants';
import PropertyEditModal from '../modals/PropertyEditModal';
import Pin from '../../Property/components/PropertiesList/Pin';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import propertyImage from 'assets/images/propertyImg.jpg';

import { getPublicAgent } from 'store/agentStore';
import { getProperty } from 'store/propertyStore';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

const Overview = ({ property, features, tags, isPublic = false, userId }) => {
  const classes = useStyles({ isPublic });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [mainImg, setMainImg] = useState(propertyImage);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const { publicAgentInfo } = useSelector((state) => state.agentReducer);
  const { selectedPropertyMedia } = useSelector(
    (state) => state.propertyReducer,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedPropertyMedia?.photos && selectedPropertyMedia.photos.length) {
      setMainImg(
        selectedPropertyMedia.photos[0].watermark_url ||
          selectedPropertyMedia.photos[0].original_url ||
          selectedPropertyMedia.photos[0].media_url ||
          propertyImage,
      );
    }
  }, [selectedPropertyMedia]);

  useEffect(() => {
    if (isPublic && userId) {
      dispatch(getPublicAgent(userId));
    }
  }, [isPublic, dispatch, userId]);

  const initProperty = () => {
    property && dispatch(getProperty(property.concept_property_id));
    setIsOpenEditModal(false);
  };

  const details = isPublic
    ? [
        {
          label: 'Property ID',
          value: property?.concept_property_id || 'N/A',
        },
        {
          label: 'Avail.Date',
          value:
            property?.available_date && property.available_date !== '0000-00-00'
              ? date.format(new Date(property.available_date), 'MM/DD/YYYY')
              : 'N/A',
        },
        {
          label: 'Listing Status',
          value: property?.property_status || 'N/A',
        },
      ]
    : [
        {
          label: 'Property ID',
          value: property?.concept_property_id || 'N/A',
        },
        {
          label: 'Landlord',
          value: property?.landlord_name || 'N/A',
        },
        {
          value:
            property?.modified_at && property.modified_at !== '0000-00-00'
              ? date.format(new Date(property.modified_at), 'MM/DD/YYYY')
              : 'N/A',
          label: 'Updated',
        },
        {
          value:
            property?.available_date && property.available_date !== '0000-00-00'
              ? date.format(new Date(property.available_date), 'MM/DD/YYYY')
              : 'N/A',
          label: 'Avail.Date',
        },
        {
          value: property?.source || 'N/A',
          label: 'Data Entry',
        },
        {
          label: 'Listing Status',
          value: property?.property_status || 'N/A',
        },
      ];

  const listingDetails = [
    {
      label: 'Building type:',
      value: property?.building_type || 'N/A',
    },
    {
      label: 'Square Footage:',
      value: property?.square_footage || 'N/A',
    },
    {
      label: 'Unit level:',
      value: property?.unit_level || 'N/A',
    },
    {
      label: 'Beds:',
      value: property?.bed_information || 'N/A',
    },
    {
      label: 'Baths:',
      value: property?.baths || 'N/A',
    },
    {
      label: 'Parking:',
      value: property?.parking_availability || 'N/A',
    },
    {
      label: 'Pet policy',
      value: property?.pet_description || 'N/A',
    },
    {
      label: 'Student:',
      value: property?.student_policy || 'N/A',
    },
  ];

  const pricingDetails = [
    {
      label: 'Rent:',
      value: property?.rent ? `$${parseFloat(property.rent)}` : 'N/A',
    },
    {
      label: 'Fee:',
      value: property?.fee || 'N/A',
    },
  ];

  const listingDates = [
    {
      label: 'Avail.Date:',
      value:
        property?.available_date && property?.available_date !== '0000-00-00'
          ? date.format(new Date(property?.available_date), 'MM/DD/YYYY')
          : 'N/A',
    },
    {
      label: 'Updated:',
      value:
        property?.modified_at && property?.modified_at !== '0000-00-00'
          ? date.format(new Date(property?.modified_at), 'MM/DD/YYYY')
          : 'N/A',
    },
  ];

  const moveCost = [
    {
      label: 'First Month:',
      value: property?.move_in_cost_is_first_month_required
        ? `$${parseFloat(property.rent)}`
        : '$0',
    },
    {
      label: 'Last Month:',
      value: property?.move_in_cost_is_last_month_required
        ? `$${parseFloat(property.rent)}`
        : '$0',
    },
    {
      label: 'Security:',
      value: property?.move_in_cost_security_deposit || 'N/A',
    },
    {
      label: 'Application Fee:',
      value: property?.move_in_cost_application_fee || 'N/A',
    },
    {
      label: 'Move in Fee:',
      value: property?.move_in_cost_fee || 'N/A',
    },
    {
      label: 'Pet Deposit:',
      value: property?.move_in_cost_pet_deposit || 'N/A',
    },
    {
      label: 'Key deposit:',
      value: property?.move_in_cost_key_deposit || 'N/A',
    },
  ];

  const isRecentlyUpdated = (val = new Date()) => {
    const date = new Date(val);
    const today = new Date();
    const diff_in_time = today.getTime() - date.getTime();
    const diff_in_days = diff_in_time / (1000 * 3600 * 24);
    return diff_in_days < 7;
  };

  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <H2>Details</H2>
          {!isPublic && (
            <EditIcon
              className="cursor-pointer"
              width={24}
              height={24}
              onClick={() => setIsOpenEditModal(true)}
            />
          )}
        </Box>
        <Box className={classes.sectionContent}>
          <div>
            <img src={mainImg} className={classes.propertyImg} alt="Property" />
          </div>
          <div className={classes.detailsContent}>
            <Box display="flex" flexDirection="column">
              <Box display="flex" alignItems="center" gridColumnGap={10}>
                <StatusMark
                  status={
                    STATUS_TYPES[STATUSES[property?.property_status_id - 1]]
                  }
                />
                <H2>
                  {property?.address} #{property?.unit}
                </H2>
              </Box>
              <Box display="flex" alignItems="center" gridColumnGap={10}>
                <H4>Rent</H4>
                <div
                  className={cx(
                    classes.rentText,
                    isRecentlyUpdated(property?.price_last_modified_date) &&
                      classes.highLighted,
                  )}
                >
                  <H5 bold>${parseFloat(property?.rent)}</H5>
                  {isRecentlyUpdated(property?.price_last_modified_date) && (
                    <H5>Recent Price Updated</H5>
                  )}
                </div>
              </Box>
            </Box>
            <Box className={classes.propertyStats}>
              {details.map((item, index) => (
                <Box
                  key={`Property-Details-${index}`}
                  display="flex"
                  flexDirection="column"
                  gridRowGap={5}
                >
                  <H4 bold>{item.label}</H4>
                  <H4>{item.value}</H4>
                </Box>
              ))}
            </Box>
          </div>
          {isPublic && publicAgentInfo && publicAgentInfo.user_id && (
            <div className={classes.agentInfo}>
              <img
                src={publicAgentInfo.photo_url}
                className={classes.agentImage}
                alt="agent"
              />
              <H5 display="block">Listing Agent</H5>
              <H5 display="block" bold>
                {publicAgentInfo.first_name} {publicAgentInfo.last_name}
              </H5>
              <div style={{ marginBottom: 20 }} />
              <H5 display="block">{publicAgentInfo.primary_phone}</H5>
              <H5 display="block">{publicAgentInfo.email}</H5>
            </div>
          )}
        </Box>
      </div>
      <div className={classes.section}>
        {!isPublic && <H2>Listing Overview</H2>}

        <div className={classes.listingOverviewContent}>
          <div>
            <H4 bold>Listing Details</H4>
            {listingDetails.map((item, index) => (
              <Box
                key={`Property-listing-details-${index}`}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gridColumnGap={30}
              >
                <Box flex={1}>
                  <H5>{item.label}</H5>
                </Box>
                <Box flex={1}>
                  <H5 bold>{item.value}</H5>
                </Box>
              </Box>
            ))}
          </div>
          {!isPublic && (
            <div>
              <H4 bold>Pricing Details</H4>
              {pricingDetails.map((item, index) => (
                <Box
                  key={`Property-pricing-details-${index}`}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gridColumnGap={30}
                >
                  <Box flex={1}>
                    <H5>{item.label}</H5>
                  </Box>
                  <Box flex={1}>
                    <H5 bold>{item.value}</H5>
                  </Box>
                </Box>
              ))}
              <H4 bold>Listing Dates</H4>
              {listingDates.map((item, index) => (
                <Box
                  key={`Property-listing-dates-${index}`}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gridColumnGap={30}
                >
                  <Box flex={1}>
                    <H5>{item.label}</H5>
                  </Box>
                  <Box flex={1}>
                    <H5 bold>{item.value}</H5>
                  </Box>
                </Box>
              ))}
            </div>
          )}
          {!isPublic && (
            <div>
              <H4 bold>Move In Cost</H4>
              {moveCost.map((item, index) => (
                <Box
                  key={`Property-move-cost-${index}`}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gridColumnGap={30}
                >
                  <Box flex={1}>
                    <H5>{item.label}</H5>
                  </Box>
                  <Box flex={1}>
                    <H5 bold>{item.value}</H5>
                  </Box>
                </Box>
              ))}
            </div>
          )}
          <div className={classes.googleMap}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
              }}
              center={{
                lat: property?.latitude ? parseFloat(property?.latitude) : 0,
                lng: property?.longitude ? parseFloat(property?.longitude) : 0,
              }}
              defaultZoom={16}
              margin={[30, 30, 30, 30]}
            >
              <Pin
                isActive={true}
                lat={property?.latitude ? parseFloat(property?.latitude) : 0}
                lng={property?.longitude ? parseFloat(property?.longitude) : 0}
              />
            </GoogleMapReact>
          </div>
        </div>
      </div>
      <div className={classes.section}>
        {!isPublic && <H4 bold>Tags</H4>}
        {!isPublic &&
          (isMobile ? (
            <XScrollBar>
              <Box display="flex" alignItems="center" gridColumnGap={20}>
                {tags.map((item) => (
                  <div key={item.tag_id} className={classes.tag}>
                    <H5>{item.tag_name}</H5>
                  </div>
                ))}
              </Box>
            </XScrollBar>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              gridColumnGap={20}
              gridRowGap={10}
            >
              {tags.map((item) => (
                <div key={item.tag_id} className={classes.tag}>
                  <H5>{item.tag_name}</H5>
                </div>
              ))}
            </Box>
          ))}
        <H4 bold>Features</H4>
        {isMobile ? (
          <XScrollBar>
            <Box display="flex" alignItems="center" gridColumnGap={20}>
              {features.map((item) => (
                <div key={item.feature_id} className={classes.tag}>
                  <H5>{item.feature_name}</H5>
                </div>
              ))}
            </Box>
          </XScrollBar>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            gridColumnGap={20}
            gridRowGap={10}
          >
            {features.map((item) => (
              <div key={item.feature_id} className={classes.tag}>
                <H5>{item.feature_name}</H5>
              </div>
            ))}
          </Box>
        )}
      </div>
      {isOpenEditModal && (
        <PropertyEditModal
          isModalOpen={isOpenEditModal}
          closeModal={() => setIsOpenEditModal(false)}
          property={property}
          initProperty={initProperty}
        />
      )}
    </div>
  );
};

export default Overview;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: 30,
      background: theme.palette.background.white,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 30,
      [theme.breakpoints.down('sm')]: {
        padding: 20,
        rowGap: 20,
        borderRadius: 0,
      },
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 30,
      paddingBottom: 30,
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 10,
        rowGap: 10,
      },
      '&:last-child': {
        paddingBottom: 0,
        borderBottom: 'none',
      },
    },
    sectionContent: {
      display: 'flex',
      columnGap: 30,
      rowGap: 10,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    tag: {
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px',
      background: theme.palette.grey.A200,
      borderRadius: 35,
      '& > span': {
        whiteSpace: 'nowrap',
      },
    },
    propertyImg: {
      maxWidth: '100%',
      maxHeight: 250,
      objectFit: 'cover',
    },
    detailsContent: {
      flex: 1,
      maxWidth: 720,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: (props) =>
        props.isPublic ? 'flex-start' : 'space-between',
      rowGap: (props) => (props.isPublic ? 30 : 10),
    },
    listingOverviewContent: {
      display: 'grid',
      gap: 30,
      justifyContent: 'space-between',
      gridTemplateColumns: (props) =>
        props.isPublic
          ? 'repeat(2, minmax(0, 1fr))'
          : 'repeat(4, minmax(0, 1fr))',
      [theme.breakpoints.down(1200)]: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr)) !important',
        gap: 20,
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr)) !important',
      },
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 16,
        [theme.breakpoints.down('xs')]: {
          rowGap: 8,
        },
      },
    },
    rentText: {
      padding: '5px 10px',
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
    },
    highLighted: {
      background: theme.palette.grey.A700,
    },
    propertyStats: {
      display: 'grid',
      gap: 30,
      justifyContent: 'space-between',
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gap: 10,
      },
    },
    googleMap: {
      width: '100%',
      height: 330,
      [theme.breakpoints.down('xs')]: {
        display: 'none !important',
      },
    },
    agentInfo: {
      backgroundColor: theme.palette.grey.A200,
      padding: 20,
      borderRadius: 5,
      width: 309,
      height: 247,
    },
    agentImage: {
      marginBottom: 20,
      width: 60,
      height: 60,
      borderRadius: 30,
      objectFit: 'cover',
    },
  }),
);
