import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import authService from 'services/authService';

export const PrivateRoute = (props) => {
  const auth = authService.getAuthFromStorage();
  if (auth?.token) {
    return <Route {...props} />;
  }
  return <Redirect to="/login" />;
};
