import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SubHeader from './components/SubHeader';
import ClientUrlItem from './components/Item';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getPublicClientUrlProperties } from 'store/clientStore';

const PublicClientUrl = () => {
  const classes = useStyles();
  const { state = {}, search } = useLocation();
  const { clientUrl = '' } = state;
  const clienturlproperty = useSelector(
    (state) => state.clientReducer.publicClientUrlProperties,
  );

  const client_url_key = new URLSearchParams(search).get('client_url_key');
  const agent_id = new URLSearchParams(search).get('a') ?? '';
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (client_url_key) {
      dispatch(getPublicClientUrlProperties(client_url_key));
    }
  }, [dispatch, client_url_key]);

  return (
    <div className={classes.container}>
      <SubHeader clientUrlName={clientUrl} client_url_key={client_url_key} />
      <div className={classes.mainContainer}>
        <div>
          {clienturlproperty.map((item, index) => (
            <ClientUrlItem property={item} key={index} agent_id={agent_id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PublicClientUrl;
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    mainContainer: {
      width: '100%',
      padding: 30,
      paddingLeft: 80,
      overflow: 'auto',
      flex: 1,
      [theme.breakpoints.down('xs')]: {
        margin: 0,
        padding: 20,
      },
      '& > div': {
        display: 'flex',
        gap: '30px 20px',
        flexWrap: 'wrap',
        margin: 'auto',
      },
      '&::-webkit-scrollbar': {
        width: 12,
        background: theme.palette.background.white,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
  }),
);
