import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import date from 'date-and-time';

import { EnhancedModal } from 'components/Modal';
import { H4, H5 } from 'components/Typography';

import { ReactComponent as AvatarIcon } from 'assets/icons/avatar.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

const TaskNoteDetailModal = ({
  isModalOpen,
  closeModal,
  data = null,
  type,
}) => {
  const classes = useStyles();

  if (!data) return null;

  const dueDate = new Date(data.created_at);
  const modifiedAt = new Date(data.modified_at);

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={
        type !== 'note'
          ? `Task: ${data.task_type}`
          : data.note_type_id === 1
          ? 'Property Note'
          : data.note_type_id === 2
          ? 'Landlord Note'
          : ''
      }
    >
      <div className={classes.contiainer}>
        <H4 bold>{type !== 'note' ? data.task_title : data.title}</H4>
        <H5>{type !== 'note' ? data.description : data.body}</H5>
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
          gridColumnGap={20}
          gridRowGap={10}
        >
          <Box display="flex" alignItems="center" gridColumnGap={10}>
            <AvatarIcon width={20} height={20} />
            <H5>
              {type === 'note'
                ? `${data.first_name} ${data.last_name}`
                : data.landlord_name}
            </H5>
          </Box>
          <Box display="flex" alignItems="center" gridColumnGap={10}>
            <CalendarIcon width={20} height={20} />
            <H5>
              {date.format(
                type !== 'note' ? dueDate : modifiedAt,
                'MM/DD/YYYY HH:mm',
              )}
            </H5>
          </Box>
        </Box>
      </div>
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    contiainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 30,
      maxWidth: 500,
      [theme.breakpoints.down('xs')]: {
        rowGap: 20,
      },
    },
  }),
);

export default TaskNoteDetailModal;
