import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { EnhancedModal } from 'components/Modal';
import { H5 } from 'components/Typography';
import { ReactComponent as NoteIcon } from 'assets/icons/note.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/task.svg';
import { VARIANTS } from 'utils/constants';

import CreateNewNoteModal from './CreateEditNoteModal';
import { useDispatch } from 'react-redux';
import AddEditTaskModal from 'pages/Home/modals/AddEditTaskModal';
import { getAccessTypes } from 'store/noteStore';

const AddNewActionModal = ({
  isModalOpen,
  landlord_id,
  closeModal,
  onSuccess,
  onSuccessNote,
}) => {
  const [isOpenNewTaskModal, setIsOpenNewTaskModal] = useState(false);
  const [isOpenNewNoteModal, setIsOpenNewNoteModal] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOpenNewTaskModal = () => {
    setIsOpenNewTaskModal(true);
  };

  const handleOpenNewNoteModal = () => {
    dispatch(getAccessTypes());
    setIsOpenNewNoteModal(true);
  };

  const handleCloseNewTaskModal = () => {
    setIsOpenNewTaskModal(false);
  };

  const handleCloseNewNoteModal = () => {
    closeModal();
    setIsOpenNewNoteModal(false);
  };

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      variant={VARIANTS.SECONDARY}
      title="Select an action"
      hiddenHeaderDivider={true}
      className={classes.modal}
    >
      <div className={classes.contiainer}>
        <div className={classes.actionItem} onClick={handleOpenNewNoteModal}>
          <div className={classes.left}>
            <NoteIcon />
          </div>
          <div className={classes.right}>
            <H5 bold display="block" className={classes.itemTitle}>
              Create a note
            </H5>
          </div>
        </div>
        <div className={classes.actionItem} onClick={handleOpenNewTaskModal}>
          <div className={classes.left}>
            <TaskIcon />
          </div>
          <div className={classes.right}>
            <H5 bold display="block" className={classes.itemTitle}>
              Create a task
            </H5>
          </div>
        </div>
      </div>
      <CreateNewNoteModal
        isModalOpen={isOpenNewNoteModal}
        closeModal={handleCloseNewNoteModal}
        onSuccess={onSuccessNote}
        landlord_id={landlord_id}
      />
      <AddEditTaskModal
        isModalOpen={isOpenNewTaskModal}
        closeModal={handleCloseNewTaskModal}
        onSuccess={onSuccess}
        selectedLandlordId={landlord_id}
      />
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: { maxWidth: 500 },
    contiainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
      margin: 'auto',
      marginTop: 20,
      height: '100%',
      maxHeight: 480,
      overflow: 'auto',
      [theme.breakpoints.down('xs')]: {
        rowGap: 20,
      },
    },
    actionItem: {
      width: '100%',
      border: `1px solid ${theme.palette.grey.A100}`,
      height: 94,
      marginBottom: 10,
      display: 'flex',
      justifyContent: 'flex-start',
    },
    left: {
      width: 94,
      background: theme.palette.grey.A200,
      borderRight: `1px solid ${theme.palette.grey.A100}`,
      padding: 35,
    },
    right: {
      paddingTop: 36,
      paddingBottom: 36,
      paddingLeft: 20,
    },
  }),
);

export default AddNewActionModal;
