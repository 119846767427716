import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import authService from 'services/authService';
import agentService from 'services/agentService';

export const login = createAsyncThunk(
  'authSlice/login',
  async (payload, { rejectWithValue }) => {
    const { body, success, failure } = payload;
    try {
      const response = await authService.login(body);
      const token = response.access_token;
      authService.setAuthInStorage({
        token,
      });
      success();
      const userProfile = await authService.getProfle();
      authService.setAuthInStorage({
        token,
      });
      return { token, userProfile };
    } catch (e) {
      failure();
      return rejectWithValue(e);
    }
  },
);

export const sendRestPasswordRequest = createAsyncThunk(
  'authSlice/sendRestPasswordRequest',
  async (payload) => {
    const { body, success } = payload;
    const response = await authService.sendRestPasswordRequest(body);
    success();
    return response;
  },
);

export const resetPassword = createAsyncThunk(
  'authSlice/resetPassword',
  async (payload) => {
    const { body, success } = payload;
    const response = await authService.resetPassword(body);
    success();
    return response;
  },
);

export const getProfile = createAsyncThunk('authSlice/getProfile', async () => {
  const userProfile = await authService.getProfle();
  return userProfile;
});

export const logout = createAsyncThunk('authSlice/logout', async (payload) => {
  const { success } = payload;
  const response = await authService.logout();
  authService.removeAuthInStorage();
  success();
  return response;
});

export const updateProfile = createAsyncThunk(
  'authSlice/updateProfile',
  async (payload) => {
    const { userId, body, success } = payload;
    const response = await agentService.updateAgent(userId, body);
    success();
    return response;
  },
);

export const uploadAgentPhoto = createAsyncThunk(
  'authSlice/uploadAgentPhoto',
  async (payload) => {
    const { userId, formData, success } = payload;
    const data = await authService.uploadAgentPhoto(userId, formData);
    success();
    return data;
  },
);

const initialState = {
  token: authService.getAuthFromStorage()?.token || null,
  user: null,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      const { token, userProfile } = action.payload;
      state.token = token;
      state.user = userProfile;
      state.loading = false;
    },
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.rejected]: (state) => {
      state.loading = false;
    },
    [getProfile.fulfilled]: (state, action) => {
      const data = action.payload;
      state.user = data;
    },
    [logout.fulfilled]: (state) => {
      state.user = null;
      state.loading = false;
    },
    [logout.pending]: (state) => {
      state.loading = true;
    },
    [logout.rejected]: (state) => {
      state.loading = false;
    },
    [updateProfile.fulfilled]: (state, action) => {
      const { agent } = action.payload;
      state.user = { ...state.user, ...agent };
      state.loading = false;
    },
    [updateProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateProfile.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { setUser } = authSlice.actions;

export default authSlice.reducer;
