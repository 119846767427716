import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import agentService from 'services/agentService';

export const getPublicAgent = createAsyncThunk(
  'agentSlice/getPublicAgent',
  async (userId) => {
    const response = await agentService.getPublicAgent(userId);
    return response;
  },
);

export const getAgentList = createAsyncThunk(
  'agentSlice/getAgentList',
  async () => {
    const response = await agentService.getAgentList();
    return response;
  },
);

export const getAgentById = createAsyncThunk(
  'agentSlice/getAgentById',
  async (agentId) => {
    const response = await agentService.getAgentById(agentId);
    return response;
  },
);

export const updateAgent = createAsyncThunk(
  'agentSlice/updateAgent',
  async (payload) => {
    const { id, ...params } = payload;
    console.log('id', id);
    const response = await agentService.updateAgent(id, params);
    return response;
  },
);

export const createAgent = createAsyncThunk(
  'agentSlice/createAgent',
  async (payload) => {
    const { body, success } = payload;
    const response = await agentService.createAgent(body);
    success();
    return response;
  },
);

export const getTeamMembers = createAsyncThunk(
  'agentSlice/getTeamMembers',
  async (payload) => {
    const response = await agentService.getTeamMembers(payload);
    return response;
  },
);

export const updateTeamMembers = createAsyncThunk(
  'agentSlice/updateTeamMembers',
  async (payload) => {
    const { body, success } = payload;
    const response = await agentService.updateTeamMembers(body);
    success();
    return response;
  },
);

const initialState = {
  agentList: [],
  teamMemberList: [],
  data: {},
  publicAgentInfo: {},
  loading: false,
  listLoading: false,
};

const agentSlice = createSlice({
  name: 'agentSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getPublicAgent.fulfilled]: (state, action) => {
      state.publicAgentInfo = action.payload;
      state.loading = false;
    },
    [getPublicAgent.pending]: (state) => {
      state.loading = true;
    },
    [getPublicAgent.rejected]: (state) => {
      state.loading = false;
    },
    [getAgentList.fulfilled]: (state, action) => {
      state.agentList = action.payload;
      state.listLoading = false;
    },
    [getAgentList.pending]: (state) => {
      state.listLoading = true;
    },
    [getAgentList.rejected]: (state) => {
      state.listLoading = false;
    },
    [getAgentById.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getAgentById.pending]: (state) => {
      state.loading = true;
    },
    [getAgentById.rejected]: (state) => {
      state.loading = false;
    },
    [createAgent.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [createAgent.pending]: (state) => {
      state.loading = true;
    },
    [createAgent.rejected]: (state) => {
      state.loading = false;
    },
    [updateAgent.fulfilled]: (state, action) => {
      // state.data = action.payload.agent;
      state.loading = false;
    },
    [updateAgent.pending]: (state) => {
      state.loading = true;
    },
    [updateAgent.rejected]: (state) => {
      state.loading = false;
    },
    [getTeamMembers.fulfilled]: (state, action) => {
      state.teamMemberList = action.payload;
      state.loading = false;
    },
    [getTeamMembers.pending]: (state) => {
      state.loading = true;
    },
    [getTeamMembers.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default agentSlice.reducer;
