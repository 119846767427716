import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import date from 'date-and-time';

import { DropdownContent } from 'components/Dropdown';
import { H1, H5 } from 'components/Typography';
import { Pagination } from 'components/Pagination';
import InteractionItem from './InteractionItem';

import { getNotes } from 'store/noteStore';
import { getTasks } from 'store/taskStore';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowFillDown.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkMark.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import { LANDLORD_NOTE_TYPE_ID } from 'utils/constants';
import TaskNoteDetailModal from 'pages/Home/modals/TaskNoteDetailModal';
import AddNewActionModal from '../modals/AddNewActionModal';

const Interactions = ({ landlord_id }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [isOpenTypes, setIsOpenTypes] = useState(false);
  const [taskType, setTaskType] = useState('All Activity');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [isOpenNewActionModal, setIsOpenNewActionModal] = useState(false);
  const [selectedItemType, setSelectedItemType] = useState();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const { notes } = useSelector((state) => state.noteReducer);
  const { tasks } = useSelector((state) => state.taskReducer);
  const [list, setList] = useState([]);

  const handleChangeTaskType = (type) => {
    setTaskType(type);
    setIsOpenTypes(false);
  };

  React.useEffect(() => {
    if (user) {
      dispatch(
        getNotes({
          note_type_id: LANDLORD_NOTE_TYPE_ID,
          landlord_id,
        }),
      );
      dispatch(
        getTasks({
          userId: user?.user_key,
          propertyId: null,
          landlordId: landlord_id,
        }),
      );
    }
  }, [dispatch, user, landlord_id]);

  React.useEffect(() => {
    let arr = [];
    if (taskType === 'All Activity') {
      arr = [...notes, ...tasks];
    }
    if (taskType === 'Tasks') {
      arr = tasks;
    }
    if (taskType === 'Notes') {
      arr = notes;
    }
    setList(arr);
  }, [notes, tasks, taskType]);

  const onSuccess = () => {
    dispatch(
      getTasks({
        userId: user?.user_key,
        propertyId: null,
        landlordId: landlord_id,
      }),
    );
    setIsOpenNewActionModal(false);
  };

  const onSuccessNote = () => {
    dispatch(
      getNotes({
        note_type_id: LANDLORD_NOTE_TYPE_ID,
        landlord_id,
      }),
    );
    setIsOpenNewActionModal(false);
  };

  const handleOpenModal = (id) => {
    const task = list.filter((item) => item.task_id === id)[0];
    const note = list.filter((item) => item.note_id === id)[0];

    if (!!task) {
      setSelectedItemType('task');
      setSelectedItem(task);
    }
    if (!!note) {
      setSelectedItemType('note');
      setSelectedItem(note);
    }

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAddNewAction = () => {
    setIsOpenNewActionModal(true);
  };

  const handleCloseNewActionModal = () => {
    setIsOpenNewActionModal(false);
  };

  return (
    <div className={classes.container}>
      <H1 display="block">Interactions</H1>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        mb={2}
        gridColumnGap={8}
        className={classes.taskType}
        onClick={() => !isOpenTypes && setIsOpenTypes(true)}
      >
        <H5>{taskType}</H5>
        <ArrowDownIcon />
        {isOpenTypes && (
          <>
            <DropdownContent
              handleClickAway={() => setIsOpenTypes(false)}
              customStyles={classes.typesDropdown}
            >
              <div className={classes.dropwdown}>
                {sortTypes.map((item, index) => (
                  <Box
                    key={`sort-type-${index}`}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => handleChangeTaskType(item.title)}
                  >
                    <H5>{item.title}</H5>
                    {item.title === taskType && <CheckMarkIcon />}
                  </Box>
                ))}
              </div>
            </DropdownContent>
          </>
        )}
      </Box>

      <div className={classes.content}>
        {list.slice((page - 1) * 5, page * 5).map((item, index) => {
          const isTask = !!item?.task_id;
          const isNote = !!item?.note_id;

          return (
            <InteractionItem
              openDetailModal={handleOpenModal}
              date={date.format(
                new Date(isTask ? item.created_at : item.modified_at),
                'hh/mm A',
              )}
              description={isTask ? item.task_title : item.title}
              isNote={isNote}
              isTask={isTask}
              noteId={item?.note_id}
              taskId={item?.task_id}
              key={`notes-${index}`}
            />
          );
        })}
      </div>
      <div className={classes.footer}>
        <div className={classes.addProperty} onClick={handleAddNewAction}>
          <PlusIcon />
          <H5 bold display="block" className={classes.add}>
            Add new action
          </H5>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          lastPage={Math.ceil(list.length / 5)}
        />
      </div>
      <TaskNoteDetailModal
        isModalOpen={isModalOpen}
        closeModal={handleCloseModal}
        data={selectedItem}
        type={selectedItemType}
      />
      <AddNewActionModal
        isModalOpen={isOpenNewActionModal}
        landlord_id={landlord_id}
        closeModal={handleCloseNewActionModal}
        onSuccess={onSuccess}
        onSuccessNote={onSuccessNote}
      />
    </div>
  );
};

export default Interactions;
const sortTypes = [
  { title: 'All Activity' },
  { title: 'Tasks' },
  { title: 'Notes' },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      background: theme.palette.background.white,
      borderRadius: 5,
      padding: '1px 20px',
      maxWidth: 500,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      maxHeight: 800,
      [theme.breakpoints.down('md')]: {
        maxWidth: 820,
      },
    },
    mr20: {
      marginRight: 20,
    },
    content: {
      flex: 1,
      minHeight: 250,
      overflow: 'auto',
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 0,
      paddingBottom: 20,
    },
    addProperty: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
      cursor: 'pointer',
    },
    add: {
      color: `${theme.palette.action.active}`,
      marginTop: 3,
    },
    typesDropdown: {
      left: 'auto',
      right: 0,
      minWidth: 185,
      transform: 'translate(30px, 5px)',
    },
    taskType: {
      position: 'relative',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    dropwdown: {
      width: 200,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      '& > div': {
        padding: '8px 15px',
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
        },
      },
    },
  }),
);
