import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as Yup from 'yup';
import { Field } from 'formik';
import classNames from 'classnames';

import { H1, H5 } from 'components/Typography';
import { EnhancedForm, FormInputField } from 'components/Form';
import { Button } from 'components/Buttons';
import { updateProfile, uploadAgentPhoto } from 'store/authStore';
import { DropdownContent } from 'components/Dropdown';

import { ReactComponent as DefaultAvatarImg } from 'assets/defaultAvatar.svg';
import { ReactComponent as UploadPhotoIcon } from 'assets/icons/uploadPhoto.svg';
import { getProfile } from 'store/authStore';
import { useDebounce } from 'hooks/useDebounce';

const ProfilePage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const [uploadedFile, setUploadedFile] = useState(null);
  const { user } = useSelector((state) => state.authReducer);

  const cities = useSelector((state) => state.cityReducer.cities);
  const states = useSelector(
    (state) => state.propertyReducer.filterOptions.states,
  );
  const location = useLocation();

  const [isOpenCityModal, setOpenCityModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [citySearch, setCitySearch] = useState('');
  const [searchedCities, setSearchedCities] = useState([]);

  const [isOpenStateModal, setOpenStateModal] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [stateSearch, setStateSearch] = useState('');
  const [searchedStates, setSearchedStates] = useState([]);

  const debouncedCitySearch = useDebounce(citySearch, 750);
  const debouncedStateSearch = useDebounce(stateSearch, 750);

  useEffect(() => {
    if (debouncedCitySearch) {
      setSearchedCities(
        cities.filter((item) =>
          item.city_name
            .toLowerCase()
            .includes(debouncedCitySearch.toLowerCase()),
        ),
      );
    } else {
      setSearchedCities([]);
    }
  }, [debouncedCitySearch, cities]);

  useEffect(() => {
    if (debouncedStateSearch) {
      setSearchedStates(
        states.filter((item) =>
          item.state_name
            .toLowerCase()
            .includes(debouncedStateSearch.toLowerCase()),
        ),
      );
    } else {
      setSearchedStates([]);
    }
  }, [debouncedStateSearch, states]);

  const handleChangeAvatar = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      setUploadedFile(file);
    }
  };

  const handleSubmit = (values) => {
    const userId = user.user_id;
    const body = {
      first_name: values.firstName,
      last_name: values.lastName,
      title: values.title,
      primary_phone: values.primaryPhone,
      secondary_phone: values.secondaryPhone,
      mls_id: values.mlsId,
      email: values.email,
      fax: values.fax,
      address: values.address,
      city: values.city,
      state_id: values.state_id,
      postal_code: values.zip,
      unit: values.unit,
      website: values.website,
    };

    if (uploadedFile) {
      const formData = new FormData();
      formData.append('imgProfile', uploadedFile);

      dispatch(
        uploadAgentPhoto({
          userId,
          formData,
          success: () => {
            dispatch(getProfile());
          },
        }),
      );
    }

    dispatch(
      updateProfile({
        userId,
        body,
        success: () => {
          dispatch(getProfile());
          history.push(location?.state?.prevPath || '/');
        },
      }),
    );
  };

  const resetPassword = () => {
    history.push('/reset_password_request');
  };

  useEffect(() => {
    const item =
      states && states.find((item) => item.state_id === user?.state_id);
    setSelectedCity(user?.city);
    setSelectedState(item?.state_name);
  }, [states, user, cities]);

  if (!user) return null;

  return (
    <div className={classes.profilePage}>
      <div className={classes.container}>
        <H1>Edit profile</H1>
        <div className={classes.divider} />
        <div className={classes.content}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gridRowGap={30}
          >
            <Box position="relative" width="100px" height="100px">
              {!uploadedFile && user?.photo_url && (
                <img
                  className={classes.avatar}
                  src={user?.photo_url}
                  alt="profile"
                />
              )}
              {uploadedFile && (
                <img
                  className={classes.avatar}
                  src={URL.createObjectURL(uploadedFile)}
                  alt="profile"
                />
              )}
              {!uploadedFile && !user?.photo_url && (
                <DefaultAvatarImg className={classes.avatar} />
              )}
              <Box
                className={classes.uploadIcon}
                onClick={() => {
                  uploadRef.current.click();
                }}
              >
                <UploadPhotoIcon />
              </Box>
              <input
                ref={uploadRef}
                type="file"
                onChange={handleChangeAvatar}
                style={{ display: 'none' }}
              />
            </Box>
          </Box>
          <EnhancedForm
            initialValues={{
              firstName: user?.first_name || '',
              lastName: user?.last_name || '',
              email: user?.email || '',
              title: user?.title || '',
              primaryPhone: user?.primary_phone || '',
              secondaryPhone: user?.secondary_phone || '',
              mlsId: user?.mls_id || '',
              fax: user?.fax || '',
              address: user?.address || '',
              city: user?.city || '',
              state_id: user?.state_id || '',
              zip: user?.postal_code || '',
              unit: user?.unit || '',
              website: user?.website || '',
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required('Required'),
              lastName: Yup.string().required('Required'),
              primaryPhone: Yup.string().required('Required'),
              email: Yup.string().email('Invalid email').required('Required'),
              website: Yup.string().matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Invalid link',
              ),
            })}
            handleSubmit={handleSubmit}
            className={classes.form}
          >
            {() => (
              <Box display="flex" flexDirection="column" gridRowGap={20}>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  gridColumnGap={20}
                  gridRowGap={10}
                >
                  <FormInputField
                    label="First Name"
                    name="firstName"
                    className={classes.fieldItem}
                  />
                  <FormInputField
                    label="Last Name"
                    name="lastName"
                    className={classes.fieldItem}
                  />
                  <FormInputField
                    label="Title"
                    name="title"
                    className={classes.fieldItem}
                  />
                  <FormInputField
                    label="Primary Phone"
                    name="primaryPhone"
                    className={classes.fieldItem}
                  />
                  <FormInputField
                    label="Secondary Phone"
                    name="secondaryPhone"
                    className={classes.fieldItem}
                  />
                  <FormInputField
                    label="MLS ID"
                    name="mlsId"
                    className={classes.fieldItem}
                  />
                  <FormInputField
                    label="Email"
                    name="email"
                    className={classes.fieldItem}
                  />
                  <FormInputField
                    label="Fax"
                    name="fax"
                    className={classes.fieldItem}
                  />
                  <FormInputField
                    label="Website"
                    name="website"
                    className={classes.fieldItem}
                  />
                </Box>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  gridColumnGap={20}
                  gridRowGap={10}
                >
                  <FormInputField
                    label="Street Address"
                    name="address"
                    className={classes.fieldItem}
                  />
                  <div className={classes.inputWrapper}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gridRowGap={5}
                      mb={4}
                    >
                      <H5>City</H5>
                      <Field name="city">
                        {({ form: { setFieldValue }, meta }) => (
                          <div
                            className={classNames(classes.customField, {
                              error: meta.touched && meta.error,
                            })}
                            onClick={() =>
                              !isOpenCityModal && setOpenCityModal(true)
                            }
                          >
                            <input
                              className={classes.searchInput}
                              value={
                                isOpenCityModal ? citySearch : selectedCity
                              }
                              onChange={(e) => setCitySearch(e.target.value)}
                            />
                            {isOpenCityModal && (
                              <>
                                <DropdownContent
                                  handleClickAway={() => {
                                    setSearchedCities([]);
                                    setOpenCityModal(false);
                                    setCitySearch('');
                                  }}
                                  customStyles={classes.selectCustomDropdown}
                                >
                                  <div className={classes.customDropwdown}>
                                    {[{ city_id: null }, ...searchedCities].map(
                                      (item, index) => (
                                        <Box
                                          key={`city- ${index}`}
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="space-between"
                                          onClick={() => {
                                            setFieldValue(
                                              'city',
                                              item.city_name,
                                            );
                                            setSelectedCity(
                                              item.city_id
                                                ? item.city_name
                                                : 'None',
                                            );
                                            setOpenCityModal(false);
                                            setCitySearch('');
                                            setSearchedCities([]);
                                          }}
                                        >
                                          <H5>
                                            {item.city_id
                                              ? item.city_name
                                              : 'None'}
                                          </H5>
                                        </Box>
                                      ),
                                    )}
                                  </div>
                                </DropdownContent>
                              </>
                            )}
                          </div>
                        )}
                      </Field>
                    </Box>
                  </div>
                  <div className={classes.inputWrapper}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gridRowGap={5}
                      mb={4}
                    >
                      <H5>State</H5>
                      <Field name="state_id">
                        {({ form: { setFieldValue }, meta }) => (
                          <div
                            className={classNames(classes.customField, {
                              error: meta.touched && meta.error,
                            })}
                            onClick={() =>
                              !isOpenStateModal && setOpenStateModal(true)
                            }
                          >
                            <input
                              className={classes.searchInput}
                              value={
                                isOpenStateModal ? stateSearch : selectedState
                              }
                              onChange={(e) => setStateSearch(e.target.value)}
                            />
                            {isOpenStateModal && (
                              <>
                                <DropdownContent
                                  handleClickAway={() => {
                                    setSearchedStates([]);
                                    setOpenStateModal(false);
                                    setStateSearch('');
                                  }}
                                  customStyles={classes.selectCustomDropdown}
                                >
                                  <div className={classes.customDropwdown}>
                                    {[
                                      { state_id: null },
                                      ...searchedStates,
                                    ].map((item, index) => (
                                      <Box
                                        key={`state- ${index}`}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        onClick={() => {
                                          setFieldValue(
                                            'state_id',
                                            item.state_id,
                                          );
                                          setSelectedState(
                                            item.state_id
                                              ? item.state_name
                                              : 'None',
                                          );
                                          setOpenStateModal(false);
                                          setStateSearch('');
                                          setSearchedStates([]);
                                        }}
                                      >
                                        <H5>
                                          {item.state_id
                                            ? item.state_name
                                            : 'None'}
                                        </H5>
                                      </Box>
                                    ))}
                                  </div>
                                </DropdownContent>
                              </>
                            )}
                          </div>
                        )}
                      </Field>
                    </Box>
                  </div>
                  <FormInputField
                    label="Zip"
                    name="zip"
                    className={classes.fieldItem}
                  />
                  <FormInputField
                    label="Unit"
                    name="unit"
                    className={classes.fieldItem}
                  />
                  <div className={classes.fieldItem} />
                </Box>
                <H5
                  bold
                  onClick={resetPassword}
                  className={classes.resetPassword}
                >
                  Reset Password
                </H5>
                <Button type="submit" className={classes.submitButton}>
                  Save
                </Button>
              </Box>
            )}
          </EnhancedForm>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    profilePage: {
      padding: 50,
      [theme.breakpoints.down('xs')]: {
        padding: 20,
      },
    },
    container: {
      width: '100%',
      maxWidth: 1280,
      background: theme.palette.background.white,
      margin: 'auto',
      padding: '30px 60px',
      [theme.breakpoints.down('xs')]: {
        padding: 20,
      },
    },
    content: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      columnGap: 60,
      rowGap: 20,
      [theme.breakpoints.down('sm')]: {
        columnGap: 30,
      },
    },
    divider: {
      width: '100%',
      height: 0,
      borderWidth: 1,
      borderBottomStyle: 'solid',
      borderColor: theme.palette.grey.A100,
      margin: '30px 0',
      [theme.breakpoints.down('xs')]: {
        margin: '20px 0',
      },
    },
    avatar: {
      width: 100,
      height: 100,
      borderRadius: '100%',
      objectFit: 'cover',
    },
    uploadIcon: {
      width: 26,
      height: 26,
      borderRadius: '100%',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.background.white,
      position: 'absolute',
      right: 0,
      bottom: 0,
      filter: 'drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.2))',
    },
    userId: {
      color: theme.palette.grey[300],
      maxWidth: 100,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    fieldItem: {
      flex: 1,
      minWidth: 250,
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
      },
    },
    form: {
      flex: 1,
      minWidth: 250,
    },
    submitButton: {
      maxWidth: 98,
      [theme.breakpoints.down('xs')]: {
        margin: 'auto',
      },
    },
    resetPassword: {
      color: theme.palette.action.active,
      cursor: 'pointer',
      marginTop: 12,
      marginBottom: 12,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    inputWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      minWidth: 250,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
        marginBottom: 20,
      },
      position: 'relative',
    },
    input: {
      height: 40,
      border: `1px solid ${theme.palette.grey[300]}`,
      boxSizing: 'border-box',
      borderRadius: 5,
      padding: '9px 10px',
      marginTop: 5,
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
    },
    listItem: {
      display: 'flex',
      gap: 10,
      margin: 10,
      width: '100%',

      '& span': {
        minHeight: 20,
        lineHeight: '25px',
      },
    },
    selectDropdown: {
      width: '100%',
      border: `2px solid ${theme.palette.action.active}`,
      transform: 'translate(-50%, 0px)',
      maxHeight: 230,
      overflowY: 'auto',
      overflowX: 'hidden',
      top: '100%',
      left: '50%',
      '&::-webkit-scrollbar': {
        width: 4,
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.grey.A100,
        borderRadius: 2,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.action.active,
        borderRadius: 2,
        '&:hover': {
          background: theme.palette.action.hover,
        },
      },
    },
    searchInput: {
      width: '100%',
      fontSize: 16,
      border: 'none',
      height: '100%',
      padding: '0 10px',
      borderRadius: 5,
    },
    customField: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 40,
      padding: 0,
      background: theme.palette.background.white,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 5,
      color: theme.palette.text.primary,
      fontSize: 16,
      lineHeight: '22px',
      position: 'relative',
      cursor: 'pointer',
      '&.error': {
        border: `2px solid ${theme.palette.background.red}`,
      },
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
      '& > span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    selectCustomDropdown: {
      left: 0,
      right: 0,
      top: 'calc(100% + 5px)',
      transform: 'translate(0, 5px)',
    },
    customDropwdown: {
      width: '100%',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 300,
      overflowY: 'auto',
      '& > div': {
        padding: '8px 15px',
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
        },
      },
      '&::-webkit-scrollbar': {
        width: 6,
        background: theme.palette.background.white,
        borderRadius: 5,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
  }),
);

export default ProfilePage;
