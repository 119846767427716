import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';

import { H5 } from 'components/Typography';
import { EnhancedTable } from 'components/Table';
import { Pagination } from 'components/Pagination';
import { VARIANTS, SORTS } from 'utils/constants';
import { pinLandlord, unPinLandlord } from 'store/landlordStore';

import { ReactComponent as FlagMarkIcon } from 'assets/icons/flagMark.svg';
import { ReactComponent as UnPinnedIcon } from 'assets/icons/unPinned.svg';
import { ReactComponent as PinnedIcon } from 'assets/icons/pinned.svg';
import defaultAvatar from 'assets/defaultAvatar.svg';
import { useHistory } from 'react-router';

const Table_Headers = [
  {
    label: 'Name',
  },
  {
    label: 'Phone ',
  },
  {
    label: 'Email',
  },
  {
    label: 'Address',
  },
  {
    label: 'Website',
  },
  {
    label: '',
  },
];

const AgentTable = ({ data, handleSelect, selected }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);
  const [mobileTableData, setMobileTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [hoverPinId, setHoverPinId] = useState(null);
  const [nameOrder, setNameOrder] = useState(SORTS.DESC);

  useEffect(() => {
    if (data && data.length) {
      let newData = [];
      let newMobileData = [];

      const sortedData = [...data].sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          if (nameOrder === SORTS.DESC) {
            return 1;
          } else {
            return -1;
          }
        } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
          if (nameOrder === SORTS.DESC) {
            return -1;
          } else {
            return 1;
          }
        }
        return 0;
      });

      sortedData.forEach((row) => {
        const newDataItem = {
          id: row.user_id,
          cells: [
            {
              cell: (
                <Box display="flex" alignItems="center">
                  <Box sx={{ mr: 1.25 }}>
                    {row.photo_url && (
                      <img
                        src={row.photo_url}
                        className={classes.agentImage}
                        alt="agent"
                      />
                    )}
                    {!row.photo_url && (
                      <img
                        className={classes.agentImage}
                        src={defaultAvatar}
                        alt="avatar"
                      />
                    )}
                  </Box>
                  <H5>{row.name}</H5>
                </Box>
              ),
            },
            {
              cell: <H5>{row.phone}</H5>,
            },
            {
              cell: <H5 className={classes.ellipseField}>{row.email}</H5>,
            },
            {
              cell: <H5 className={classes.ellipseField}>{row.address}</H5>,
            },
            {
              cell: <H5 className={classes.ellipseField}>{row.website}</H5>,
            },
            {
              align: 'right',
              cell: (
                <Box
                  className={classes.actionField}
                  onMouseEnter={() => setHoverPinId(row.user_id)}
                  onMouseLeave={() => setHoverPinId(null)}
                >
                  {hoverPinId === row.user_id ? (
                    row.pinned ? (
                      <PinnedIcon
                        onClick={(e) => handleUnPinLandlord(e, row.user_id)}
                      />
                    ) : (
                      <UnPinnedIcon
                        onClick={(e) => handlePinLandlord(e, row.user_id)}
                      />
                    )
                  ) : (
                    !!row.review_pending && <FlagMarkIcon />
                  )}
                </Box>
              ),
            },
          ],
        };
        newData.push(newDataItem);
        const newMobileDataItem = row;
        newMobileData.push(newMobileDataItem);
      });

      setTableData(newData);
      setMobileTableData(newMobileData);
    }
    // eslint-disable-next-line
  }, [classes, data, nameOrder, hoverPinId]);

  const handleRowClick = (id) => {
    history.push(`/agent/${id}`);
  };

  const handleChangeOrder = (property) => {
    if (property === 'Name') {
      if (nameOrder === SORTS.DESC) {
        setNameOrder(SORTS.ASC);
      } else {
        setNameOrder(SORTS.DESC);
      }
    }
  };

  const handlePinLandlord = (e, id) => {
    e.stopPropagation();
    dispatch(pinLandlord(id));
  };

  const handleUnPinLandlord = (e, id) => {
    e.stopPropagation();
    dispatch(unPinLandlord(id));
  };

  return (
    <>
      {isTablet ? (
        <Box display="flex" flexDirection="column" gridRowGap={10}>
          {mobileTableData.slice((page - 1) * 10, page * 10).map((item) => (
            <div
              key={item.user_id}
              className={classes.landlordItem}
              onClick={() => handleRowClick(item.user_id)}
            >
              <Box display="flex" flexDirection="column" gridRowGap={5}>
                <H5 bold>{item.name}</H5>
                <H5>{item.phone}</H5>
              </Box>
              <Box
                className={classes.actionField}
                onMouseEnter={() => setHoverPinId(item.user_id)}
                onMouseLeave={() => setHoverPinId(null)}
              >
                {hoverPinId === item.user_id ? (
                  item.pinned ? (
                    <PinnedIcon
                      onClick={(e) => handleUnPinLandlord(e, item.user_id)}
                    />
                  ) : (
                    <UnPinnedIcon
                      onClick={(e) => handlePinLandlord(e, item.user_id)}
                    />
                  )
                ) : (
                  !!item.review_pending && <FlagMarkIcon />
                )}
              </Box>
            </div>
          ))}
        </Box>
      ) : (
        <EnhancedTable
          headCells={Table_Headers}
          rows={tableData.slice((page - 1) * 10, page * 10)}
          order={nameOrder}
          orderBy={'Name'}
          selected={selected}
          handleSelect={handleSelect}
          className={classes.tableStyles}
          variant={VARIANTS.SECONDARY}
          handleRowClick={handleRowClick}
          handleChangeOrder={handleChangeOrder}
        />
      )}
      <div className={classes.footer}>
        <Box>
          <H5 bold>Results: </H5>
          <H5>{`${1 + 10 * (page - 1)} - ${
            Math.ceil(tableData.length / 10) === page
              ? tableData.length
              : 10 * page
          } of ${tableData.length}`}</H5>
        </Box>
        <Pagination
          page={page}
          setPage={setPage}
          lastPage={Math.ceil(tableData.length / 10)}
          noPageNumber={true}
        />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    landlordItem: {
      background: theme.palette.background.white,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      borderRadius: 5,
      cursor: 'pointer',
    },
    tableStyles: {
      '& .MuiTableHead-root': {
        background: theme.palette.grey.A400,
      },
      '& .MuiTableRow-root': {
        marginBottom: 10,
      },
      '& .MuiTableCell-head div span': {
        textTransform: 'initial',
      },
      '& .MuiTableCell-root.MuiTableCell-body': {
        borderBottom: `10px solid ${theme.palette.grey.A400}`,
      },
      '& .MuiTableCell-root MuiTableCell-head': {
        borderBottom: 'none',
      },
      '& .MuiTableBody-root': {
        '& tr': {
          cursor: 'pointer',
        },
      },
      '& tr': {
        '&:last-child': {
          '& .MuiTableCell-root.MuiTableCell-body': {
            borderBottom: 'none',
          },
        },
      },
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      columnGap: 30,
      padding: 0,
      height: 55,
    },
    actionField: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 35,
      maxWidth: 35,
      minWidth: 35,
      minHeight: 35,
      marginRight: 0,
      cursor: 'pointer',
    },
    ellipseField: {
      maxWidth: 150,
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down(1500)]: {
        maxWidth: 130,
      },
    },
    agentImage: {
      width: 30,
      height: 30,
      objectFit: 'cover',
      borderRadius: 30,
    },
  }),
);

export default AgentTable;
