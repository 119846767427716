import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';

import { H2, H5 } from 'components/Typography';

import hourGlassIcon from 'assets/icons/hourGlass.svg';
import arrowRightIcon from 'assets/icons/arrowRight.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateNotification } from 'store/notificationStore';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: 500,
      padding: 20,
      background: theme.palette.background.white,
      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.25)',
      borderRadius: 5,
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        borderRadius: 0,
        height: '100vh',
      },
    },
    markText: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.grey[100],
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      maxHeight: 474,
      paddingRight: 20,
      overflow: 'auto',
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100% - 30px)',
        maxHeight: 'calc(100% - 30px)',
        paddingRight: 0,
      },
      '&::-webkit-scrollbar': {
        width: 4,
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.grey.A100,
        borderRadius: 2,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.action.active,
        borderRadius: 2,
        '&:hover': {
          background: theme.palette.action.hover,
        },
      },
    },
    backButton: {
      cursor: 'pointer',
      marginBottom: 2,
    },
    notification: {
      height: 60,
      minHeight: 60,
      background: theme.palette.grey.A200,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.grey.A100,
      marginLeft: 30,
      width: 'calc(100% - 30px)',
      position: 'relative',
      display: 'flex',
      cursor: 'pointer',
      '& .type': {
        width: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight: `1px solid ${theme.palette.grey.A100}`,
      },
      '& .description': {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
        '&.unread': {
          background: theme.palette.background.white,
        },
      },
      '& .unreadMark': {
        width: 6,
        height: 6,
        borderRadius: '50%',
        background: theme.palette.action.active,
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translate(-20px, -50%)',
      },
    },
  }),
);

const today = new Date();
today.setHours(0, 0, 0);
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);

const Notifications = ({ handleClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [list, setList] = useState([]);
  const [todayList, setTodayList] = useState([]);
  const [yesterdayList, setYesterdayList] = useState([]);
  const [olderList, setOlderList] = useState([]);
  const { notifications } = useSelector((state) => state.notificationReducer);
  const dispatch = useDispatch();

  React.useEffect(() => {
    // const mockdata = [
    //   {
    //     notification_id: 1,
    //     has_been_read: 0,
    //     title: 'notification 1',
    //     created_at: '2021-12-11T18:10:34.358Z',
    //   },
    //   {
    //     notification_id: 2,
    //     has_been_read: 1,
    //     title: 'notification 2',
    //     created_at: '2021-12-15T18:10:34.358Z',
    //   },
    //   {
    //     notification_id: 3,
    //     has_been_read: 1,
    //     title: 'notification 3',
    //     created_at: '2021-12-15T18:10:34.358Z',
    //   },
    //   {
    //     notification_id: 4,
    //     has_been_read: 0,
    //     title: 'notification 4',
    //     created_at: '2021-12-14T18:10:34.358Z',
    //   },
    // ];
    setList(notifications);
  }, [notifications]);

  const handleMarkAllAsRead = () => {
    const unreadList = notifications.filter((item) => !item.has_been_read);
    const ids = unreadList.map((item) => item.notification_id);

    if (ids.length > 0) {
      dispatch(updateNotification({ has_been_read_ids: ids }));
    }
  };

  const handleMark = (id) => {
    dispatch(updateNotification({ has_been_read_ids: [id] }));
    const updatedList = [];
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.notification_id === id) {
        updatedList.push({
          ...item,
          has_been_read: 1,
        });
      } else {
        updatedList.push(item);
      }
    }

    setList(updatedList);
  };

  useEffect(() => {
    const todayList = list.filter((item) => {
      const createdAt = new Date(item.created_at);
      return createdAt.getTime() >= today.getTime();
    });

    const yesterdayList = list.filter((item) => {
      const createdAt = new Date(item.created_at);
      return (
        createdAt.getTime() >= yesterday.getTime() &&
        createdAt.getTime() < today.getTime()
      );
    });

    const olderList = list.filter((item) => {
      const createdAt = new Date(item.created_at);
      return createdAt.getTime() < yesterday.getTime();
    });

    setTodayList(todayList);
    setYesterdayList(yesterdayList);
    setOlderList(olderList);
  }, [list]);

  return (
    <div className={classes.container}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Box
          display="flex"
          alignItems="center"
          gridColumnGap={10}
          cursor="pointer"
        >
          {isMobile && (
            <img
              onClick={handleClose}
              className={classes.backButton}
              src={arrowRightIcon}
              alt="back-button"
            />
          )}
          <H2>Notifications</H2>
        </Box>
        <H5 className={classes.markText} onClick={handleMarkAllAsRead}>
          Mark all as read
        </H5>
      </Box>
      <div className={classes.content}>
        {todayList.length > 0 && <H5 bold>Today</H5>}
        {todayList.map((item, index) => (
          <div
            key={index}
            className={classes.notification}
            onClick={() => handleMark(item.notification_id)}
          >
            <div className="type">
              <img src={hourGlassIcon} alt="type" />
            </div>
            <div
              className={classNames('description', {
                unread: item.has_been_read,
              })}
            >
              <H5>{item.title}</H5>
            </div>
            {!item.has_been_read && <div className="unreadMark" />}
          </div>
        ))}
        {yesterdayList.length > 0 && <H5 bold>Yesterday</H5>}
        {yesterdayList.map((item, index) => (
          <div
            key={index}
            className={classes.notification}
            onClick={() => handleMark(item.notification_id)}
          >
            <div className="type">
              <img src={hourGlassIcon} alt="type" />
            </div>
            <div
              className={classNames('description', {
                unread: item.has_been_read,
              })}
            >
              <H5>{item.title}</H5>
            </div>
            {!item.has_been_read && <div className="unreadMark" />}
          </div>
        ))}
        {olderList.length > 0 && <H5 bold>Older</H5>}
        {olderList.map((item, index) => (
          <div
            key={index}
            className={classes.notification}
            onClick={() => handleMark(item.notification_id)}
          >
            <div className="type">
              <img src={hourGlassIcon} alt="type" />
            </div>
            <div
              className={classNames('description', {
                unread: item.has_been_read,
              })}
            >
              <H5>{item.title}</H5>
            </div>
            {!item.has_been_read && <div className="unreadMark" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notifications;
