import React from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export const PDFDownloader = ({
  rootElementId,
  downloadFileName,
  children,
}) => {
  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a2');
      pdf.addImage(imgData, 'JPEG', 0, 0);
      pdf.save(`${downloadFileName}.pdf`);
    });
  };

  return <div onClick={downloadPdfDocument}>{children}</div>;
};
