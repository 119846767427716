import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { H1, H5 } from 'components/Typography';
import { Pagination } from 'components/Pagination';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { getTeamMembers, updateTeamMembers } from 'store/agentStore';
import AddTeamMember from '../modals/AddTeamMemberModal';

const TeamMembers = ({ id }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const dispatch = useDispatch();

  const { teamMemberList } = useSelector((state) => state.agentReducer);

  useEffect(() => {
    dispatch(getTeamMembers(id));
  }, [dispatch, id]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsAdded(false);
  };

  const handleAddTeamMember = async ({ selectedIds, success }) => {
    const body = {
      user_id: id,
      team_member_user_ids: selectedIds,
    };
    dispatch(
      updateTeamMembers({
        body,
        success: () => {
          dispatch(getTeamMembers(id));
          setIsAdded(true);
          success();
        },
      }),
    );
  };

  return (
    <div className={classes.container}>
      <Box sx={{ pl: 2.5 }}>
        <H1 display="block">Team Members</H1>
      </Box>
      <div className={classes.main}>
        {teamMemberList.length > 0 &&
          teamMemberList.slice((page - 1) * 7, page * 7).map((item, index) => (
            <Box
              key={`${item.team_member_user_id}-${index}`}
              display="flex"
              sx={{ py: 1.25, pl: 2.5, height: 80 }}
            >
              <Box sx={{ mr: 2.5 }}>
                <img
                  src={item.photo_url}
                  className={classes.agentImage}
                  alt="team member"
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height={70}
                gridRowGap={10}
                className="cursor-pointer"
                onClick={() => {}}
              >
                <H5 bold>
                  {item.first_name} {item.last_name}
                </H5>
                <H5>AGENT</H5>
              </Box>
            </Box>
          ))}
      </div>
      <div className={classes.footer}>
        <div
          className={classes.addProperty}
          onClick={() => setIsModalOpen(true)}
        >
          <PlusIcon />
          <H5 bold display="block" className={classes.add}>
            Add new
          </H5>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          lastPage={Math.ceil(teamMemberList.length / 7)}
        />
      </div>
      {isModalOpen && (
        <AddTeamMember
          isModalOpen={isModalOpen}
          closeModal={handleCloseModal}
          handleSave={handleAddTeamMember}
          isAdded={isAdded}
          setIsAdded={setIsAdded}
        />
      )}
    </div>
  );
};

export default TeamMembers;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      background: theme.palette.background.white,
      borderRadius: 5,
      maxWidth: 380,
      width: '100%',
      height: '100%',
      paddingBottom: 70,
      [theme.breakpoints.down('md')]: {
        maxWidth: 820,
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 500,
      },
      position: 'relative',
    },
    main: {
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 4,
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.grey.A100,
        borderRadius: 2,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.action.active,
        borderRadius: 2,
        '&:hover': {
          background: theme.palette.action.hover,
        },
      },
    },
    footer: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px',
      bottom: 20,
      width: '100%',
    },
    addProperty: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
      cursor: 'pointer',
    },
    add: {
      color: `${theme.palette.action.active}`,
      marginTop: 3,
    },
    typesDropdown: {
      left: 'auto',
      right: 0,
      minWidth: 185,
      transform: 'translate(30px, 5px)',
    },
    taskType: {
      position: 'relative',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    dropwdown: {
      width: 200,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      '& > div': {
        padding: '8px 15px',
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
        },
      },
    },
    agentImage: {
      width: 60,
      height: 60,
      objectFit: 'cover',
      borderRadius: 60,
    },
  }),
);
