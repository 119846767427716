import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';

import { H2, H4, H5 } from 'components/Typography';

import { ReactComponent as CopyIcon } from 'assets/icons/copyBtn.svg';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/externalLink.svg';

const Links = ({ property, user }) => {
  const classes = useStyles();
  const [timer, setTimer] = useState(null);
  const [copied, setCopied] = useState(false);
  const hostname = window.location.origin;

  const copyLink = (url) => {
    navigator.clipboard.writeText(url);
    if (timer) {
      clearTimeout(timer);
    }
    setCopied(true);
    setTimer(setTimeout(() => setCopied(false), 2500));
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <H2>Links</H2>
        <Box display="flex" flexWrap="wrap" gridColumnGap={20} gridRowGap={20}>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            mt={1}
            gridRowGap={8}
            className={classes.linkContainer}
          >
            <H4 bold>Public URL</H4>
            <Box display="flex" alignItems="center" gridColumnGap={20}>
              <a
                href={`${hostname}/public_property/${property?.property_url_key}?a=${user?.user_id}`}
                target="_blank"
                rel="noreferrer"
                className={classNames(classes.linkButton, classes.textEllipse)}
              >
                <H5
                  bold
                  className={classNames(classes.link, classes.textEllipse)}
                >
                  {`${hostname}/public_property/${property?.property_url_key}?a=${user?.user_id}`}
                </H5>
              </a>
              {copied ? (
                <H5 className={classes.link}>Copied to clipboard!</H5>
              ) : (
                <CopyIcon
                  className="cursor-pointer"
                  width={21}
                  height={21}
                  onClick={() =>
                    copyLink(
                      `${hostname}/public_property/${property?.property_url_key}?a=${user?.user_id}`,
                    )
                  }
                />
              )}
            </Box>
          </Box>
          {property?.source_id === 1 && (
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              mt={1}
              gridRowGap={8}
              className={classes.linkContainer}
            >
              <H4 bold>Broker Notes</H4>
              <Box display="flex" alignItems="center" gridColumnGap={20}>
                <a
                  href={`https://manage.yougotlistings.com/rentals/${property?.ygl_id}`}
                  target="_blank"
                  rel="noreferrer"
                  className={classNames(
                    classes.linkButton,
                    classes.textEllipse,
                  )}
                >
                  <H5
                    bold
                    className={classNames(classes.link, classes.textEllipse)}
                  >
                    {`https://manage.yougotlistings.com/rentals/${property?.ygl_id}`}
                  </H5>
                </a>
                <a
                  href={`https://manage.yougotlistings.com/rentals/${property?.ygl_id}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ height: 16 }}
                >
                  <ExternalLinkIcon
                    width={16}
                    height={16}
                    className="cursor-pointer"
                  />
                </a>
              </Box>
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

export default Links;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      columnGap: 30,
      rowGap: 20,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    content: {
      width: '100%',
      padding: 20,
      background: theme.palette.background.white,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        borderRadius: 0,
      },
    },
    transactions: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 4,
      maxHeight: 200,
      overflowY: 'auto',
      margin: '0 -15px',
      padding: '0 15px',
      '&::-webkit-scrollbar': {
        width: 8,
        background: theme.palette.background.white,
        borderRadius: 5,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
    link: {
      color: theme.palette.action.active,
    },
    linkContainer: {
      overflow: 'hidden',
      minWidth: 260,
    },
    textEllipse: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    linkButton: {
      flex: 1,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);
