import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as Yup from 'yup';
import { Field, ErrorMessage } from 'formik';

import { EnhancedModal } from 'components/Modal';
import { Button } from 'components/Buttons';

import { H5, H6 } from 'components/Typography';
import { DropdownContent } from 'components/Dropdown';

import {
  EnhancedForm,
  FormInputField,
  DropdownFormField,
} from 'components/Form';
import { INPUT_TYPES, SIZE_TYPES, VARIANTS } from 'utils/constants';
import { createNewTask, getTasks, updateTask } from 'store/taskStore';
import {
  searchAllProperties,
  resetSearchedProperties,
} from 'store/landlordStore';
import { useDebounce } from 'hooks/useDebounce';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

const AddEditTaskModal = ({
  isModalOpen,
  closeModal,
  task,
  onSuccess,
  selectedPropertyId = null,
  selectedLandlordId = null,
}) => {
  const classes = useStyles();
  const { taskTypes } = useSelector((state) => state.taskReducer);
  const { user } = useSelector((state) => state.authReducer);
  const { landlordList } = useSelector((store) => store.landlordReducer);
  const { searchedProperties } = useSelector((state) => state.landlordReducer);
  const [type, setType] = useState(task ? task.task_type : null);
  const [isOpenTypes, setIsOpenTypes] = useState(false);
  const [isOpenContacts, setIsOpenContacts] = useState(false);
  const [isOpenProperties, setIsOpenProperties] = useState(false);
  const [property, setProperty] = useState(
    task ? task.address || 'None' : 'None',
  );
  const [propertySearch, setPropertySearch] = useState('');
  const [landlord, setLandlord] = useState(
    task ? task.landlord_name || 'None' : 'None',
  );
  const [landlordSearch, setLandlordSearch] = useState('');
  const [searchedLandlords, setSearchedLandlords] = useState([]);
  const dispatch = useDispatch();

  const debouncedPropertySearch = useDebounce(propertySearch, 750);
  const debouncedLandlordSearch = useDebounce(landlordSearch, 750);

  useEffect(() => {
    if (debouncedPropertySearch) {
      dispatch(searchAllProperties(debouncedPropertySearch));
    } else {
      dispatch(resetSearchedProperties());
    }
  }, [debouncedPropertySearch, dispatch]);

  useEffect(() => {
    if (debouncedLandlordSearch) {
      setSearchedLandlords(
        landlordList.filter((item) =>
          item.landlord_name
            .toLowerCase()
            .includes(debouncedLandlordSearch.toLowerCase()),
        ),
      );
    } else {
      setSearchedLandlords([]);
    }
  }, [debouncedLandlordSearch, landlordList]);

  const handleSubmit = (values) => {
    const {
      typeId,
      title,
      description,
      landlordId,
      propertyId,
      taskDate,
      taskTime,
    } = values;

    const data = {
      user_id: user?.user_id,
      task_type_id: typeId,
      title,
      description,
      due_date: `${taskDate} ${taskTime}:00`,
    };

    if (selectedPropertyId) {
      data.property_id = selectedPropertyId;
    } else {
      data.property_id = propertyId;
    }

    if (selectedLandlordId) {
      data.landlord_id = selectedLandlordId;
    } else {
      data.landlord_id = landlordId;
    }

    if (task) {
      data.concept_property_id = data.property_id;
      data.task_id = task.task_id;
      delete data.property_id;
      dispatch(
        updateTask({
          body: data,
          success: () => {
            if (onSuccess) {
              onSuccess();
            } else {
              dispatch(
                getTasks({
                  userId: user?.user_key,
                  propertyId: selectedPropertyId ? selectedPropertyId : null,
                }),
              );
            }
            closeModal();
          },
        }),
      );
    } else {
      dispatch(
        createNewTask({
          body: data,
          success: () => {
            if (onSuccess) {
              onSuccess();
            } else {
              dispatch(
                getTasks({
                  userId: user?.user_key,
                  propertyId: selectedPropertyId ? selectedPropertyId : null,
                }),
              );
            }
            closeModal();
          },
        }),
      );
    }
  };

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={task?.task_id ? 'Edit Task' : 'Create New Task'}
      mobileFullWidth={true}
      className={classes.modal}
    >
      <div className={classes.contiainer}>
        <EnhancedForm
          initialValues={{
            title: task ? task.task_title : '',
            description: task ? task.description : '',
            taskDate: task ? task.due_date.split(' ')[0] : '',
            taskTime: task ? task.due_date.split(' ')[1] : '09:00',
            typeId: task
              ? taskTypes.find((item) => item.title === task.task_type)
                  .task_type_id
              : '',
            landlordId: task ? task.landlord_id : null,
            propertyId: task ? task.concept_property_id : null,
          }}
          validationSchema={Yup.object().shape(
            {
              typeId: Yup.string().required('Required'),
              description: Yup.string().required('Required'),
              landlordId: Yup.string()
                .nullable(true)
                .when(['propertyId'], {
                  is: (propertyId) =>
                    !selectedLandlordId && !selectedPropertyId && !propertyId,
                  then: Yup.string().required('Required').nullable(true),
                }),
              propertyId: Yup.string()
                .nullable(true)
                .when(['landlordId'], {
                  is: (landlordId) =>
                    !selectedLandlordId && !selectedPropertyId && !landlordId,
                  then: Yup.string().required('Required').nullable(true),
                }),
              taskDate: Yup.string().required('Required'),
            },
            [['landlordId', 'propertyId']],
          )}
          handleSubmit={handleSubmit}
          className={classes.contiainer}
        >
          {(props) => (
            <>
              <DropdownFormField
                label="Type"
                name="typeId"
                className={classes.shortField}
                value={type}
                isOpenDropdown={isOpenTypes}
                setIsOpenDropdown={setIsOpenTypes}
                dropdownList={taskTypes}
                selectItem={(item) => setType(item ? item.title : '')}
                itemId="task_type_id"
                itemValue="title"
              />
              <FormInputField label="Title" name="title" />
              <FormInputField
                label="Description"
                name="description"
                multiline
              />
              <Box
                display="flex"
                sx={{ columnGap: 20 }}
                className={classes.selectGroup}
              >
                <FormInputField
                  label="Due Date"
                  name="taskDate"
                  type={INPUT_TYPES.DATE}
                  className={classes.datetime}
                />
                <FormInputField
                  label="Time"
                  name="taskTime"
                  type={INPUT_TYPES.TIME}
                  className={classes.datetime}
                />
              </Box>
              {!selectedLandlordId && !selectedPropertyId && (
                <Box
                  display="flex"
                  sx={{ columnGap: 20 }}
                  className={classes.selectGroup}
                >
                  <div className={classes.inputWrapper}>
                    <Box display="flex" flexDirection="column" gridRowGap={5}>
                      <H5>Select a Landlord</H5>
                      <Field name="landlordId">
                        {({ form: { setFieldValue }, meta }) => (
                          <div
                            className={classNames(classes.customField, {
                              error: meta.touched && meta.error,
                            })}
                            onClick={() =>
                              !props.values.propertyId &&
                              !isOpenContacts &&
                              setIsOpenContacts(true)
                            }
                          >
                            <input
                              className={classes.searchInput}
                              value={isOpenContacts ? landlordSearch : landlord}
                              onChange={(e) =>
                                setLandlordSearch(e.target.value)
                              }
                              readOnly={props.values.propertyId}
                            />
                            {isOpenContacts && (
                              <>
                                <DropdownContent
                                  handleClickAway={() => {
                                    setSearchedLandlords([]);
                                    setIsOpenContacts(false);
                                    setLandlordSearch('');
                                  }}
                                  customStyles={classes.selectDropdown}
                                >
                                  <div className={classes.dropwdown}>
                                    {[
                                      { landlord_id: null },
                                      ...searchedLandlords,
                                    ].map((item, index) => (
                                      <Box
                                        key={`landlord- ${index}`}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        onClick={() => {
                                          setFieldValue(
                                            'landlordId',
                                            item.landlord_id,
                                          );
                                          setLandlord(
                                            item.landlord_id
                                              ? item.landlord_name
                                              : 'None',
                                          );
                                          setIsOpenContacts(false);
                                          setLandlordSearch('');
                                          setSearchedLandlords([]);
                                        }}
                                      >
                                        <H5>
                                          {item.landlord_id
                                            ? item.landlord_name
                                            : 'None'}
                                        </H5>
                                      </Box>
                                    ))}
                                  </div>
                                </DropdownContent>
                              </>
                            )}
                          </div>
                        )}
                      </Field>
                      <div className={classes.errorContainer}>
                        <ErrorMessage
                          name="landlordId"
                          render={(msg) => (
                            <Box
                              display="flex"
                              alignItems="center"
                              gridColumnGap={5}
                            >
                              <WarningIcon width={14} height={14} />
                              <H6 className={classes.error}>{msg}</H6>
                            </Box>
                          )}
                        />
                      </div>
                    </Box>
                  </div>
                  <div className={classes.inputWrapper}>
                    <Box display="flex" flexDirection="column" gridRowGap={5}>
                      <H5>Select a Property</H5>
                      <Field name="propertyId">
                        {({ form: { setFieldValue }, meta }) => (
                          <div
                            className={classNames(classes.customField, {
                              error: meta.touched && meta.error,
                            })}
                            onClick={() =>
                              !props.values.landlordId &&
                              !isOpenProperties &&
                              setIsOpenProperties(true)
                            }
                          >
                            <input
                              className={classes.searchInput}
                              value={
                                isOpenProperties ? propertySearch : property
                              }
                              onChange={(e) =>
                                setPropertySearch(e.target.value)
                              }
                              readOnly={props.values.landlordId}
                            />
                            {isOpenProperties && (
                              <>
                                <DropdownContent
                                  handleClickAway={() => {
                                    dispatch(resetSearchedProperties());
                                    setIsOpenProperties(false);
                                    setPropertySearch('');
                                  }}
                                  customStyles={classes.selectDropdown}
                                >
                                  <div className={classes.dropwdown}>
                                    {[
                                      { concept_property_id: null },
                                      ...searchedProperties,
                                    ].map((item, index) => (
                                      <Box
                                        key={`property- ${index}`}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        onClick={() => {
                                          setFieldValue(
                                            'propertyId',
                                            item.concept_property_id,
                                          );
                                          setProperty(
                                            item.concept_property_id
                                              ? `${item.street_number} ${item.street_name}, ${item.unit}, ${item.city}`
                                              : 'None',
                                          );
                                          setIsOpenProperties(false);
                                          setPropertySearch('');
                                          dispatch(resetSearchedProperties());
                                        }}
                                      >
                                        <H5>
                                          {item.concept_property_id
                                            ? `${item.street_number} ${item.street_name}, ${item.unit}, ${item.city}`
                                            : 'None'}
                                        </H5>
                                      </Box>
                                    ))}
                                  </div>
                                </DropdownContent>
                              </>
                            )}
                          </div>
                        )}
                      </Field>
                      <div className={classes.errorContainer}>
                        <ErrorMessage
                          name="propertyId"
                          render={(msg) => (
                            <Box
                              display="flex"
                              alignItems="center"
                              gridColumnGap={5}
                            >
                              <WarningIcon width={14} height={14} />
                              <H6 className={classes.error}>{msg}</H6>
                            </Box>
                          )}
                        />
                      </div>
                    </Box>
                  </div>
                </Box>
              )}
              <Box className={classes.actionButtons}>
                <Button
                  onClick={closeModal}
                  variant={VARIANTS.SECONDARY}
                  size={SIZE_TYPES.LARGE}
                >
                  Cancel
                </Button>
                <Button type="submit" size={SIZE_TYPES.LARGE}>
                  Save
                </Button>
              </Box>
            </>
          )}
        </EnhancedForm>
      </div>
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {},
    contiainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 500,
      rowGap: 10,
      overflow: 'auto',
      [theme.breakpoints.down('xs')]: {
        rowGap: 20,
        padding: 10,
        width: 'calc(100% + 20px)',
        marginLeft: -10,
        paddingBottom: 70,
      },

      '&::-webkit-scrollbar': {
        width: 8,
        background: theme.palette.background.white,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
    dropwdown: {
      width: '100%',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 300,
      overflowY: 'auto',
      '& > div': {
        padding: '8px 15px',
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
        },
      },
      '&::-webkit-scrollbar': {
        width: 6,
        background: theme.palette.background.white,
        borderRadius: 5,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
    actionButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
      columnGap: 20,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        background: theme.palette.background.white,
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.15)',
        height: 80,
        width: '100%',
      },
    },
    inputWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: 10,
      },
      position: 'relative',
    },
    searchInput: {
      width: '100%',
      fontSize: 16,
      border: 'none',
    },
    selectDropdown: {
      left: 0,
      right: 0,
      top: 'calc(100% + 5px)',
      transform: 'translate(0, 5px)',
    },
    listItem: {
      display: 'flex',
      gap: 10,
      margin: 10,
      width: '100%',

      '& span': {
        minHeight: 20,
        lineHeight: '25px',
      },
    },
    datetime: {
      width: '100%',
    },
    selectGroup: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        rowGap: 30,
      },
    },
    errorContainer: {
      display: 'block',
      minHeight: 20,
    },
    error: {
      color: theme.palette.background.red,
    },
    shortField: {
      maxWidth: 320,
    },
    customField: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 40,
      padding: '0 10px',
      background: theme.palette.background.white,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 5,
      color: theme.palette.text.primary,
      fontSize: 16,
      lineHeight: '22px',
      position: 'relative',
      cursor: 'pointer',
      '&.error': {
        border: `2px solid ${theme.palette.background.red}`,
      },
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
      '& > span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  }),
);

export default AddEditTaskModal;
