import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as Yup from 'yup';

import { EnhancedModal } from 'components/Modal';
import { EnhancedForm, FormInputField } from 'components/Form';
import { CheckBox } from 'components/CheckBox';
import { Button } from 'components/Buttons';
import { VARIANTS, MEDIA_TYPES } from 'utils/constants';
import { createLinkedMedia } from 'store/propertyStore';

const VideoTourUploadModal = ({
  isModalOpen,
  closeModal,
  type,
  propertyId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isUnit, setIsUnit] = useState(true);

  const handleSubmit = (values) => {
    const body = {
      title: values.title,
      url: values.url,
      media_subject: isUnit ? 'unit' : 'building',
      media_format: type,
    };
    dispatch(
      createLinkedMedia({ propertyId, body, success: () => closeModal() }),
    );
  };

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      variant={VARIANTS.SECONDARY}
      title={
        type === MEDIA_TYPES.VIDEO
          ? 'Enter Video URL'
          : 'Enter Virtual Tour URL'
      }
    >
      <EnhancedForm
        initialValues={{
          title: '',
          url: '',
          mediaSubject: 'unit',
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Required'),
          url: Yup.string().required('Required'),
        })}
        handleSubmit={handleSubmit}
        className={classes.contiainer}
      >
        {() => (
          <>
            <FormInputField label="Title" name="title" />
            <FormInputField label="URL" name="url" />
            <Box display="flex" alignItems="center" gridColumnGap={20}>
              <CheckBox
                variant={VARIANTS.SECONDARY}
                checked={isUnit}
                setChecked={() => setIsUnit(true)}
                label="Add to Unit"
              />
              <CheckBox
                variant={VARIANTS.SECONDARY}
                checked={!isUnit}
                setChecked={() => setIsUnit(false)}
                label="Add to Building"
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gridColumnGap={20}
              mt={4}
            >
              <Button
                type="button"
                variant={VARIANTS.SECONDARY}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </Box>
          </>
        )}
      </EnhancedForm>
    </EnhancedModal>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    contiainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 4,
      width: '100%',
      maxWidth: 720,
      padding: 0,
    },
  }),
);

export default VideoTourUploadModal;
