import React from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export const XScrollBar = ({ className, children }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.container, className)}>{children}</div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      overflowX: 'auto',
      paddingBottom: 5,
      '&::-webkit-scrollbar': {
        height: 8,
        background: theme.palette.background.white,
        borderRadius: 5,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
  }),
);
