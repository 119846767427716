import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
// import { Typography } from '@material-ui/core';
// import CloseIcon from '@material-ui/icons/Close';

import { H2, H5 } from 'components/Typography';
import {
  getProperties,
  setPropertiesFilters,
  setPropertiesSort,
  setInitProperties,
  setEnableFetching,
} from 'store/propertyStore';
import { Pagination } from 'components/Pagination';
import SubHeader from './components/PropertiesList/SubHeader';
import FilterContainer from './components/PropertiesList/FilterContainer';
// import SearchOptionList from './components/PropertiesList/SearchOptionList';

import ListView from './components/PropertiesList/ListView';
import MapView from './components/PropertiesList/MapView';
import PropertyCreateModal from './modals/PropertyCreateModal';

import { Modal } from './components/Modal';
import { Button } from 'components/Buttons';
// import { DropdownContent } from 'components/Dropdown';
// import { CheckBox } from 'components/CheckBox';

import filterIcon from 'assets/icons/filter-mobile.svg';
// import configIcon from 'assets/icons/config.svg';
import { PROPERTIES_PER_PAGE, VARIANTS } from 'utils/constants';
import ViewTab from './components/PropertiesList/ViewTab';

// const configOptions = [
//   'Listing details',
//   'Address',
//   'City',
//   'Unit',
//   'ID',
//   'Pricing',
//   'Rent',
//   'Fee',
//   'Landlord Details',
//   'Name',
//   'Address',
//   'Phone',
//   'Email',
// ];

const PropertyPage = () => {
  const {
    properties,
    total_pages,
    total_records,
    propertiesFilters,
    propertiesSort,
    enableFetching,
  } = useSelector((state) => state.propertyReducer);

  const firstRender = useRef(true);
  const [tab, setTab] = useState('list');
  const [view, setView] = useState('all');
  const [isOpenFilter, setOpenFilter] = useState(true);
  const [page, setPage] = useState(1);
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
  // const [isShowConfigModal, setIsShowConfigModal] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (enableFetching) {
        const params = {
          per_page: tab === 'map' ? 100 : PROPERTIES_PER_PAGE,
          page,
          view,
          ...propertiesFilters,
        };

        const { sort, order } = propertiesSort;

        if (sort) {
          params.sort = sort;
          params.order = order;
        }
        dispatch(getProperties(params));
      }
    }
  }, [
    dispatch,
    page,
    tab,
    view,
    propertiesFilters,
    propertiesSort,
    enableFetching,
    firstRender,
  ]);

  const classes = useStyles();

  const initPropertyList = () => {
    setPage(1);
    setView('all');
    setTab('list');
    dispatch(setPropertiesFilters({}));
    dispatch(setPropertiesSort({}));
    setIsOpenAddModal(false);
  };

  const handleOpenFilterModal = () => {
    setIsOpenFilterModal(true);
  };

  const handleCloseFilterModal = () => {
    setIsOpenFilterModal(false);
  };

  const toggleViewFilter = () => {
    setOpenFilter(!isOpenFilter);
  };

  // const toggleConfigModal = () => {
  //   setIsShowConfigModal(!isShowConfigModal);
  // };

  // const handleCloseConfigModal = () => {
  //   setIsShowConfigModal(false);
  // };

  const handleFIlterChange = (values) => {
    dispatch(setPropertiesFilters({ ...values }));
    dispatch(setEnableFetching(true));
  };

  const handleClearFilters = () => {
    setPage(1);
    dispatch(setPropertiesFilters({}));
    dispatch(setPropertiesSort({}));
    dispatch(setInitProperties());
    dispatch(setEnableFetching(false));
  };

  const handleTabChange = (tab) => {
    setView(tab);
    setPage(1);
  };

  return (
    <div>
      <SubHeader
        view={view}
        handleChangeView={handleTabChange}
        setIsOpenAddModal={setIsOpenAddModal}
        secondTabText="Listing search"
        addBtnText="Add property"
      />
      {isOpenFilter && (
        <FilterContainer
          customStyles={classes.hideOnMobile}
          handleFIlterChange={handleFIlterChange}
          handleClearFilters={handleClearFilters}
        />
      )}

      <div className={classes.propertyPage}>
        <div className={classes.settings}>
          {/* <SearchOptionList /> */}
          <div></div>

          <div className={classes.rightPanel}>
            <div className={classes.paginator}>
              <div style={{ lineHeight: '42px' }}>
                <span>Results:</span>
                {total_records > 0 ? (
                  <span>{` ${(page - 1) * PROPERTIES_PER_PAGE + 1} - ${
                    page * PROPERTIES_PER_PAGE > total_records
                      ? total_records
                      : page * PROPERTIES_PER_PAGE
                  } of ${total_records}`}</span>
                ) : (
                  <span>&nbsp;0</span>
                )}
              </div>
              <Pagination
                page={page}
                setPage={setPage}
                lastPage={total_pages}
                variant={VARIANTS.SECONDARY}
              />
            </div>

            <div className={classes.mobileAddProperty}>
              <ViewTab activeTab={tab} setTab={setTab} />
              <Button
                onClick={() => setIsOpenAddModal(true)}
                className={classes.addPropertyBtn}
              >
                Add Property
              </Button>
            </div>
            <div
              className={classes.filterOpenButton}
              onClick={toggleViewFilter}
            >
              Filters
            </div>
            {/* <div
              className={classes.openConfigButton}
              onClick={toggleConfigModal}
            >
              <img src={configIcon} alt="Config Icon" />
              {isShowConfigModal && (
                <DropdownContent
                  handleClickAway={handleCloseConfigModal}
                  customStyles={classes.selectDropdown}
                >
                  <div className={classes.modalTitle}>
                    <Typography component="h4" variant="h6">
                      Configurator
                    </Typography>
                    <CloseIcon
                      className={classes.closeButton}
                      onClick={handleCloseConfigModal}
                    />
                  </div>
                  {configOptions.map((value, index) => (
                    <div className={classes.listItem} key={`maxRent-${index}`}>
                      <span>{value}</span>
                      <CheckBox />
                    </div>
                  ))}
                  <div className={classes.buttonGroup}>
                    <Button
                      variant="secondary"
                      onClick={handleCloseConfigModal}
                    >
                      Clear
                    </Button>
                    <Button variant="primary" onClick={handleCloseConfigModal}>
                      Save
                    </Button>
                  </div>
                </DropdownContent>
              )}
            </div> */}
          </div>
        </div>
        {enableFetching ? (
          <>
            {tab === 'list' && (
              <div className={classes.listWrapper}>
                <ListView properties={properties} />
              </div>
            )}

            {tab === 'map' && <MapView properties={properties} />}

            {tab === 'list' && (
              <div className={classes.bottomPaginator}>
                <div style={{ lineHeight: '42px' }}>
                  <span>Results:</span>
                  {total_records > 0 ? (
                    <span>{` ${(page - 1) * PROPERTIES_PER_PAGE + 1} - ${
                      page * PROPERTIES_PER_PAGE > total_records
                        ? total_records
                        : page * PROPERTIES_PER_PAGE
                    } of ${total_records}`}</span>
                  ) : (
                    <span>&nbsp;0</span>
                  )}
                </div>
                <Pagination
                  page={page}
                  setPage={setPage}
                  lastPage={total_pages}
                  variant="properties"
                />
              </div>
            )}
          </>
        ) : (
          <div className={classes.noSearchContainer}>
            <H2>Start Your Search.</H2>
            <H5>Enter filters above to narrow your search.</H5>
          </div>
        )}
      </div>

      <div
        className={classes.mobileFilterButtonWrapper}
        onClick={handleOpenFilterModal}
      >
        <img src={filterIcon} alt="Filter Mobile Icon" />
      </div>

      {isOpenFilterModal && (
        <Modal
          open={isOpenFilterModal}
          title="Filters"
          handleClose={handleCloseFilterModal}
          fullWidth={true}
        >
          <FilterContainer />
        </Modal>
      )}
      {isOpenAddModal && (
        <PropertyCreateModal
          isModalOpen={isOpenAddModal}
          closeModal={() => setIsOpenAddModal(false)}
          initPropertyList={initPropertyList}
        />
      )}
    </div>
  );
};

export default PropertyPage;

const useStyles = makeStyles((theme) =>
  createStyles({
    propertyPage: {
      paddingBottom: 30,
      [theme.breakpoints.down('xs')]: {
        paddingBottom: 0,
      },
    },
    settings: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '30px 30px 0 30px',
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
        padding: '0 20px 0 20px',
        flexDirection: 'column',
      },
    },
    hideOnMobile: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    mobileFilterButtonWrapper: {
      position: 'fixed',
      width: 60,
      height: 60,
      right: 20,
      bottom: 160,
      background: theme.palette.background.white,
      boxShadow: '2px 5px 10px rgba(0, 0, 0, 0.25)',
      borderRadius: 35,
      padding: 12,
      userSelect: 'none',
      cursor: 'pointer',
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    rightPanel: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 20,
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        flexDirection: 'column-reverse',
      },
    },
    tabsWrapper: {
      display: 'flex',
    },
    tabItem1: {
      background: '#EBFFFE',
      padding: '10px 20px;   ',
      border: `1px solid ${theme.palette.action.active}`,
      borderRight: 'none',
      boxSizing: 'border-box',
      borderRadius: '5px 0px 0px 5px',
      color: theme.palette.action.active,
      cursor: 'pointer',
      width: 69,
      height: 42,
    },
    tabItem2: {
      background: theme.palette.background.white,
      padding: '10px 20px',
      border: `1px solid ${theme.palette.action.active}`,
      boxSizing: 'border-box',
      borderRadius: '0px 5px 5px 0px',
      color: theme.palette.action.active,
      cursor: 'pointer',
      width: 69,
      height: 42,
    },
    filterOpenButton: {
      width: 112,
      height: 42,
      background: theme.palette.background.white,
      border: `1px solid ${theme.palette.action.active}`,
      boxSizing: 'border-box',
      borderRadius: '5px',
      margin: 0,
      fontSize: 16,
      lineHeight: '42px',
      color: theme.palette.action.active,
      textAlign: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    openConfigButton: {
      width: 42,
      height: 42,
      background: theme.palette.background.white,
      border: `1px solid ${theme.palette.action.active}`,
      boxSizing: 'border-box',
      borderRadius: '5px',
      padding: '10px 8px',
      cursor: 'pointer',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    selectDropdown: {
      width: 375,
      transform: 'translate(-95%, 20px)',
      maxHeight: 570,
      overflowY: 'auto',
      overflowX: 'hidden',
      padding: 30,
      '&::-webkit-scrollbar': {
        width: 4,
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.grey.A100,
        borderRadius: 2,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.action.active,
        borderRadius: 2,
        '&:hover': {
          background: theme.palette.action.hover,
        },
      },
    },
    listItem: {
      display: 'flex',
      gap: 10,
      width: '100%',
      marginTop: 10,
      marginBottom: 10,
      justifyContent: 'space-between',

      '& span': {
        height: 20,
        lineHeight: '25px',
      },
    },
    modalTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {},
    buttonGroup: {
      display: 'flex',
      justifyContent: 'center',
      gap: 10,
    },
    addPropertyBtn: {
      height: 50,
      margin: 10,
      width: 168,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    mobileAddProperty: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paginator: {
      display: 'flex',
      gap: 20,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    listWrapper: {
      padding: '0 20px 0 20px',
    },
    bottomPaginator: {
      display: 'flex',
      gap: 20,
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: 20,
      padding: '0 30px 0 30px',
      '&::after': {
        clear: 'both',
      },
    },
    noSearchContainer: {
      width: '100%',
      height: 275,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      rowGap: 20,
      borderBottom: `1px solid ${theme.palette.grey.A200}`,
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% + 40px)',
        height: 345,
        marginLeft: -20,
        marginRight: -20,
      },
      '& > h2': {
        color: theme.palette.action.active,
      },
    },
  }),
);
