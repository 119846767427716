import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import propertyService from 'services/propertyService';
import landlordService from 'services/landlordService';
import { MEDIA_TYPES } from 'utils/constants';

export const getProperties = createAsyncThunk(
  'propertySlice/getProperties',
  async (payload) => {
    const response = await propertyService.getProperties(payload);
    return response;
  },
);

export const getLandlordProperties = createAsyncThunk(
  'propertySlice/getLandlordProperties',
  async (payload) => {
    const response = await propertyService.getProperties(payload);
    return response;
  },
);

export const createProperty = createAsyncThunk(
  'propertySlice/createProperty',
  async (payload) => {
    const { body, success } = payload;
    const response = await propertyService.createProperty(body);
    success();
    return response;
  },
);

export const updateProperty = createAsyncThunk(
  'propertySlice/updateProperty',
  async (payload) => {
    const { id, body, success } = payload;
    const response = await propertyService.updateProperty(id, body);
    success();
    return response;
  },
);

export const getProperty = createAsyncThunk(
  'propertySlice/getProperty',
  async (payload) => {
    const response = await propertyService.getProperty(payload);
    return response;
  },
);

export const getPublicProperty = createAsyncThunk(
  'propertySlice/getPublicProperty',
  async (payload) => {
    const response = await propertyService.getPublicProperty(payload);
    return response;
  },
);

export const getPublicPropertyMedia = createAsyncThunk(
  'propertySlice/getPublicPropertyMedia',
  async (payload) => {
    const { id, type } = payload;
    const data = await propertyService.getPublicPropertyMedia(id, type);
    return { data, type };
  },
);

export const getPropertyFilters = createAsyncThunk(
  'propertySlice/getPropertyFilters',
  async (filterByName) => {
    const data = await propertyService.getPropertyFilters(filterByName);
    return { data, filterByName };
  },
);

export const getPropertyMedia = createAsyncThunk(
  'propertySlice/getPropertyMedia',
  async (payload) => {
    const { id, type } = payload;
    const data = await propertyService.getPropertyMedia(id, type);
    return { data, type };
  },
);

export const getPropertyFeatures = createAsyncThunk(
  'propertySlice/getPropertyFeatures',
  async (payload) => {
    const data = await propertyService.getPropertyFeatures(payload);
    return data;
  },
);

export const getPropertyTags = createAsyncThunk(
  'propertySlice/getPropertyTags',
  async (payload) => {
    const data = await propertyService.getPropertyTags(payload);
    return data;
  },
);

export const getFeatures = createAsyncThunk(
  'propertySlice/getFeatures',
  async () => {
    const data = await propertyService.getFeatures();
    return data;
  },
);

export const getTags = createAsyncThunk('propertySlice/getTags', async () => {
  const data = await propertyService.getTags();
  return data;
});

export const createLinkedMedia = createAsyncThunk(
  'propertySlice/createLinkedMedia',
  async (payload) => {
    const { propertyId, body, success } = payload;
    const data = await propertyService.createLinkedMedia(propertyId, body);
    success();
    return { data, type: body.media_format };
  },
);

export const createMedias = createAsyncThunk(
  'propertySlice/createMedias',
  async (payload) => {
    const { propertyId, formData, success } = payload;
    const data = await propertyService.createMedias(propertyId, formData);
    success();
    return data;
  },
);

export const updateMedias = createAsyncThunk(
  'propertySlice/updateMedias',
  async (payload) => {
    const { propertyId, body, success } = payload;
    const response = await propertyService.updateMedias(propertyId, body);
    success();
    return response;
  },
);

export const attachProperties = createAsyncThunk(
  'propertySlice/attachProperties',
  async (payload) => {
    const { body, success } = payload;
    const response = await landlordService.attachProperties(body);
    success();
    return response;
  },
);

export const getPropertyConflicts = createAsyncThunk(
  'propertySlice/getPropertyConflicts',
  async (payload) => {
    const { params, success } = payload;
    const response = await propertyService.getPropertyConflicts(params);
    success(response);
    return response;
  },
);

export const createPropertyCollaborator = createAsyncThunk(
  'propertySlice/createPropertyCollaborator',
  async (payload) => {
    const { body, success } = payload;
    const response = await propertyService.createPropertyCollaborator(body);
    success();
    return response;
  },
);

const initialState = {
  filterOptions: {},
  properties: [],
  selectedProperty: null,
  publicProperty: null,
  selectedPropertyMedia: {
    photos: [],
    videos: [],
    tours: [],
  },
  selectedPropertyFeatures: [],
  selectedPropertyTags: [],
  total_pages: 0,
  total_records: 0,
  loading: false,
  propertiesLoading: false,
  features: [],
  tags: [],
  propertiesFilters: {},
  propertiesSort: {},
  enableFetching: false,
  landlordProperties: [],
  landlordTotalRecords: 0,
};

const propertySlice = createSlice({
  name: 'propertySlice',
  initialState,
  reducers: {
    setFilterByName: (state, action) => {
      state.filterByName = action.payload;
    },
    setPropertiesFilters: (state, action) => {
      state.propertiesFilters = action.payload;
    },
    setPropertiesSort: (state, action) => {
      state.propertiesSort = action.payload;
    },
    setInitProperties: (state) => {
      state.properties = [];
      state.total_pages = 0;
      state.total_records = 0;
    },
    setEnableFetching: (state, action) => {
      state.enableFetching = action.payload;
    },
  },
  extraReducers: {
    [getProperties.fulfilled]: (state, action) => {
      state.properties = action.payload.data;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.propertiesLoading = false;
    },
    [getProperties.pending]: (state) => {
      state.propertiesLoading = true;
    },
    [getProperties.rejected]: (state) => {
      state.propertiesLoading = false;
    },
    [getLandlordProperties.fulfilled]: (state, action) => {
      state.landlordProperties = action.payload.data;
      state.landlordTotalRecords = action.payload.total_records;
      state.propertiesLoading = false;
    },
    [getLandlordProperties.pending]: (state) => {
      state.propertiesLoading = true;
    },
    [getLandlordProperties.rejected]: (state) => {
      state.propertiesLoading = false;
    },
    [getProperty.fulfilled]: (state, action) => {
      state.selectedProperty = action.payload;
      state.loading = false;
    },
    [getProperty.pending]: (state) => {
      state.loading = true;
    },
    [getProperty.rejected]: (state) => {
      state.loading = false;
    },
    [getPublicProperty.fulfilled]: (state, action) => {
      state.publicProperty = action.payload;
      state.loading = false;
    },
    [getPublicProperty.pending]: (state) => {
      state.loading = true;
    },
    [getPublicProperty.rejected]: (state) => {
      state.loading = false;
    },
    [getPublicPropertyMedia.fulfilled]: (state, action) => {
      const { data, type } = action.payload;
      let mediaType = 'photos';
      if (type === MEDIA_TYPES.VIDEO) {
        mediaType = 'videos';
      } else if (type === MEDIA_TYPES.TOUR) {
        mediaType = 'tours';
      }
      state.selectedPropertyMedia[mediaType] = data;
    },
    [getPropertyFilters.fulfilled]: (state, action) => {
      state.filterOptions[action.payload.filterByName] = action.payload.data;
      state.loading = false;
    },
    [getPropertyFilters.pending]: (state) => {
      state.loading = true;
    },
    [getPropertyFilters.rejected]: (state) => {
      state.loading = false;
    },
    [getPropertyMedia.fulfilled]: (state, action) => {
      const { data, type } = action.payload;
      let mediaType = 'photos';
      if (type === MEDIA_TYPES.VIDEO) {
        mediaType = 'videos';
      } else if (type === MEDIA_TYPES.TOUR) {
        mediaType = 'tours';
      }
      state.selectedPropertyMedia[mediaType] = data;
    },
    [getPropertyFeatures.fulfilled]: (state, action) => {
      state.selectedPropertyFeatures = action.payload;
    },
    [getPropertyTags.fulfilled]: (state, action) => {
      state.selectedPropertyTags = action.payload;
    },
    [getFeatures.fulfilled]: (state, action) => {
      state.features = action.payload;
    },
    [getTags.fulfilled]: (state, action) => {
      state.tags = action.payload;
    },
    [createLinkedMedia.fulfilled]: (state, action) => {
      const { data, type } = action.payload;
      let mediaType = 'videos';
      if (type === MEDIA_TYPES.VIDEO) {
        mediaType = 'videos';
      } else if (type === MEDIA_TYPES.TOUR) {
        mediaType = 'tours';
      }
      state.selectedPropertyMedia[mediaType] = [
        ...state.selectedPropertyMedia[mediaType],
        { ...data.linked_media, hidden: 0 },
      ];
    },
    [createMedias.fulfilled]: (state, action) => {
      const data = action.payload.created_media.map((item) => ({
        ...item,
        hidden: 0,
      }));
      state.selectedPropertyMedia.photos = [
        ...state.selectedPropertyMedia.photos,
        ...data,
      ];
    },
  },
});

export const {
  setInitProperties,
  setFilterByName,
  setPropertiesFilters,
  setPropertiesSort,
  setEnableFetching,
} = propertySlice.actions;

export default propertySlice.reducer;
