import React from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';

import { H3 } from 'components/Typography';
import { Button } from 'components/Buttons';
import { EnhancedForm, FormInputField } from 'components/Form';
import { INPUT_TYPES } from 'utils/constants';
import { resetPassword } from 'store/authStore';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      maxWidth: 440,
      background: theme.palette.background.white,
      borderRadius: 5,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      padding: 60,
      paddingTop: 40,
      [theme.breakpoints.down('xs')]: {
        rowGap: 5,
        padding: 20,
        paddingTop: 0,
      },
    },
    submit: {
      width: '100%',
    },
    goBackToPage: {
      width: '100%',
      marginTop: 20,
    },
  }),
);

const ResetPasswordPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const email = new URLSearchParams(search).get('email');

  const handleSubmit = (values) => {
    const body = {
      email: email.replace(' ', '+'),
      token,
      password: values.password,
      password_confirmation: values.passwordConfirmation,
    };
    dispatch(
      resetPassword({
        body,
        success: () => {
          history.push('/login');
        },
      }),
    );
  };

  return (
    <div className={classes.container}>
      <EnhancedForm
        initialValues={{
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .min(8, 'Password must be at least 8 characters.')
            .required('Required'),
          passwordConfirmation: Yup.string()
            .min(8, 'Password must be at least 8 characters.')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Required'),
        })}
        handleSubmit={handleSubmit}
        className={classes.content}
      >
        {() => (
          <>
            <H3 bold>Reset Password</H3>
            <FormInputField
              label="Password"
              name="password"
              type={INPUT_TYPES.PASSWORD}
            />
            <FormInputField
              label="Confirm Password"
              name="passwordConfirmation"
              type={INPUT_TYPES.PASSWORD}
            />
            <Button type="submit" className={classes.submit}>
              Submit
            </Button>
          </>
        )}
      </EnhancedForm>
    </div>
  );
};

export default ResetPasswordPage;
