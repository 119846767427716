import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import { ReactComponent as Union } from 'assets/icons/union.svg';
import { ReactComponent as Pin } from 'assets/icons/pin.svg';
import { ReactComponent as SelectedUnion } from 'assets/icons/selected_union.svg';
import { ReactComponent as SelectedPin } from 'assets/icons/selected_pin.svg';
import { ReactComponent as MobilePin } from 'assets/icons/mobile_pin.svg';

const PinComponnet = ({ isActive }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className={classes.pinContainer}>
      {!isMobile && isActive && (
        <div className={classes.pin}>
          <SelectedUnion />
          <SelectedPin className={classes.icon} />
        </div>
      )}
      {!isMobile && !isActive && (
        <div className={classes.pin}>
          <Union />
          <Pin className={classes.icon} />
        </div>
      )}

      {isMobile && (
        <div className={classes.pin}>
          <MobilePin className={classes.icon} />
        </div>
      )}
    </div>
  );
};

export default PinComponnet;

const useStyles = makeStyles((theme) =>
  createStyles({
    pinContainer: {
      width: 80,
      height: 80,
      transform: 'translate(-50%, -50%)',
    },
    pin: {
      position: 'relative',
    },
    icon: {
      position: 'absolute',
      top: 13,
      left: 14,
    },
  }),
);
