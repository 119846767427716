import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';

import { Button } from 'components/Buttons';
import { EnhancedModal } from 'components/Modal';
import { H5 } from 'components/Typography';
import { SIZE_TYPES, VARIANTS } from 'utils/constants';

const ReviewModal = ({
  isModalOpen,
  closeModal,
  review = null,
  approveReview,
  declineReview,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [titles, setTitles] = useState([]);
  const [contents, setContents] = useState([]);

  useEffect(() => {
    if (review) {
      switch (review.review_type_id) {
        case 1:
          setTitles(['Address', 'Landlord', 'Unit']);
          setContents([
            [review.found_property_address, review.found_property_address],
            [review.found_property_landlord, review.found_property_landlord],
            [review.found_property_unit, review.found_property_unit],
          ]);
          break;
        case 2:
          setTitles(['Name', 'Phone', 'Email', 'Address', 'Website']);
          setContents([
            [review.found_landlord_name, review.new_landlord_name],
            [review.found_landlord_phone, review.new_landlord_phone],
            [review.found_landlord_email, review.new_landlord_email],
            [review.found_landlord_address, review.new_landlord_address],
            [review.found_landlord_website, review.new_landlord_website],
          ]);
          break;
        default:
          break;
      }
    }
  }, [review]);

  if (!review) return null;

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={`Review: ${review.title}`}
      mobileFullWidth={true}
      variant={VARIANTS.SECONDARY}
    >
      <div className={classes.contiainer}>
        <div className={classes.mainContent}>
          {isMobile ? (
            <>
              {titles.length &&
                contents.length &&
                titles.map((title, index) => (
                  <div key={index} className={classes.item}>
                    <Box display="flex" alignItems="center" columnGap={20}>
                      <Box width={1} maxWidth={70}></Box>
                      <Box flex={1}>
                        <H5 bold>{title}</H5>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" columnGap={20}>
                      <Box width={1} maxWidth={70}>
                        <H5 bold>New</H5>
                      </Box>
                      <Box flex={1}>
                        <H5>{contents[index][1] || 'NULL'}</H5>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" columnGap={20}>
                      <Box width={1} maxWidth={70}>
                        <H5 bold>Existing</H5>
                      </Box>
                      <Box flex={1}>
                        <H5>{contents[index][0] || 'NULL'}</H5>
                      </Box>
                    </Box>
                  </div>
                ))}
            </>
          ) : (
            <>
              <div className={classes.item}>
                <Box width={1} maxWidth={100}></Box>
                <Box flex={1} display="flex" columnGap={20}>
                  <Box flex={1}>
                    <H5 bold>New Landlord</H5>
                  </Box>
                  <Box flex={1}>
                    <H5 bold>Existing Landlord</H5>
                  </Box>
                </Box>
              </div>
              {titles.length &&
                contents.length &&
                titles.map((title, index) => (
                  <div key={index} className={classes.item}>
                    <Box width={1} maxWidth={100}>
                      <H5 bold>{title}</H5>
                    </Box>
                    <Box flex={1} display="flex" columnGap={20}>
                      <Box flex={1}>
                        <H5>{contents[index][0] || 'NULL'}</H5>
                      </Box>
                      <Box flex={1}>
                        <H5>{contents[index][1] || 'NULL'}</H5>
                      </Box>
                    </Box>
                  </div>
                ))}
            </>
          )}
        </div>
        <div className={classes.actionButtons}>
          <Button
            variant={VARIANTS.SECONDARY}
            size={isMobile ? SIZE_TYPES.MEDIUM : SIZE_TYPES.LARGE}
            onClick={approveReview}
          >
            Approve
          </Button>
          <Button
            size={isMobile ? SIZE_TYPES.MEDIUM : SIZE_TYPES.LARGE}
            onClick={declineReview}
          >
            Decline
          </Button>
        </div>
      </div>
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    contiainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 780,
      rowGap: 20,
      [theme.breakpoints.down('xs')]: {
        flex: 1,
      },
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
      [theme.breakpoints.down('xs')]: {
        overflow: 'auto',
        height: 'calc(100% - 80px)',
        MsOverflowStyle: 'none' /* IE and Edge */,
        scrollbarWidth: 'none' /* Firefox */,
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    item: {
      display: 'flex',
      columnGap: 20,
      rowGap: 10,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    actionButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: 20,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        background: theme.palette.background.white,
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.15)',
        height: 80,
        width: '100%',
      },
    },
  }),
);

export default ReviewModal;
