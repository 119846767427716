import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player/lazy';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';

import { H2, H5 } from 'components/Typography';
import { Pagination } from 'components/Pagination';
import { getPropertyMedia, getPublicPropertyMedia } from 'store/propertyStore';
import { DropdownContent } from 'components/Dropdown';
import { MEDIA_TYPES } from 'utils/constants';
// import { formatMMSS } from 'utils/time';
import VideoTourUploadModal from '../modals/VideoTourUploadModal';
import MediaEditModal from '../modals/MediaEditModal';

import { ReactComponent as PlayingIcon } from 'assets/icons/playerPlaying.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as ThreeDotsIcon } from 'assets/icons/threeDots.svg';
// import virtualTourDefaultImage from 'assets/images/virtualTourDefault.png';

const Videos = ({ isPublic = false }) => {
  const { id } = useParams();
  const ref = useRef();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const { selectedPropertyMedia } = useSelector(
    (state) => state.propertyReducer,
  );

  const [visibleData, setVisibleData] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [page, setPage] = useState(1);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isMenuDropdown, setIsMenuDropdown] = useState(false);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (selectedPropertyMedia?.videos) {
      setVisibleData(
        selectedPropertyMedia?.videos.filter((item) => item.hidden === 0),
      );
    }
  }, [selectedPropertyMedia]);

  useEffect(() => {
    if (location.pathname.includes('public') || isPublic) {
      dispatch(
        getPublicPropertyMedia({
          id,
          type: MEDIA_TYPES.VIDEO,
        }),
      );
    } else {
      dispatch(
        getPropertyMedia({
          id,
          type: MEDIA_TYPES.VIDEO,
        }),
      );
    }
  }, [dispatch, id, isPublic, location.pathname]);

  useEffect(() => {
    if (visibleData.length) {
      setSelectedVideo({
        ...visibleData[0],
        playing: false,
      });
    }
  }, [visibleData]);

  useEffect(() => {
    if (isMobile) {
      setSelectedVideo(visibleData[page - 1]);
    }
  }, [isMobile, page, visibleData]);

  const handleDuration = (duration) => {
    setSelectedVideo({
      ...selectedVideo,
      duration,
    });
  };

  const handlePlayPause = (e) => {
    e.stopPropagation();
    setSelectedVideo({
      ...selectedVideo,
      playing: !selectedVideo.playing,
    });
  };

  const handleEdit = () => {
    setIsEditModal(true);
    setIsMenuDropdown(false);
  };

  const handleUpload = () => {
    setIsUploadModal(true);
    setIsMenuDropdown(false);
  };

  return (
    <div className={classes.container}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <H2>Videos</H2>
        {!isPublic && isMobile && (
          <Box display="flex" alignItems="center" position="relative">
            <ThreeDotsIcon
              className="cursor-pointer"
              onClick={() => setIsMenuDropdown(true)}
            />
            {isMenuDropdown && (
              <>
                <DropdownContent
                  handleClickAway={() => setIsMenuDropdown(false)}
                >
                  <div className={classes.menuContainer}>
                    <H5 className="cursor-pointer" onClick={handleEdit}>
                      Edit
                    </H5>
                    <H5 className="cursor-pointer" onClick={handleUpload}>
                      Upload
                    </H5>
                  </div>
                </DropdownContent>
              </>
            )}
          </Box>
        )}
        {!isPublic && !isMobile && (
          <Box display="flex" alignItems="center" gridColumnGap={20}>
            <UploadIcon className="cursor-pointer" onClick={handleUpload} />
            <EditIcon className="cursor-pointer" onClick={handleEdit} />
          </Box>
        )}
      </Box>
      {!!visibleData.length && (
        <div className={classes.content}>
          <div>
            <ReactPlayer
              url={
                selectedVideo?.watermark_url ||
                selectedVideo?.original_url ||
                selectedVideo?.media_url
              }
              width="100%"
              height="100%"
              config={{
                youtube: {
                  playerVars: { showinfo: 1 },
                },
              }}
              ref={ref}
              controls={true}
              playing={selectedVideo?.playing}
              onDuration={handleDuration}
            />
          </div>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <div className={classes.videos}>
              {visibleData.slice((page - 1) * 5, page * 5).map((item) => (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  key={item.media_id}
                  onClick={() => setSelectedVideo(item)}
                >
                  {/* <Box display="flex" alignItems="center" gridColumnGap={10}>
                      <img
                        src={virtualTourDefaultImage}
                        alt="tour"
                        className={classes.videoPreview}
                      />
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-around"
                      >
                        <H5 bold className={classes.videoTitle}>
                          {item.title || item.media_subject}
                        </H5>
                        <H4>
                          {selectedVideo?.media_id === item.media_id &&
                            selectedVideo.duration &&
                            formatMMSS(selectedVideo.duration)}
                        </H4>
                      </Box>
                    </Box> */}
                  <Box height={78} display="flex" alignItems="center">
                    <H5 bold className="single-line-text">
                      {item.watermark_url ||
                        item.original_url ||
                        item.media_url}
                    </H5>
                  </Box>
                  {selectedVideo?.media_id === item.media_id && (
                    <PlayingIcon
                      className="cursor-pointer"
                      onClick={handlePlayPause}
                    />
                  )}
                </Box>
              ))}
            </div>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              mt={2}
            >
              <Pagination
                page={page}
                setPage={setPage}
                lastPage={
                  isMobile
                    ? visibleData.length
                    : Math.ceil(visibleData.length / 5)
                }
              />
            </Box>
          </Box>
        </div>
      )}
      {isEditModal && (
        <MediaEditModal
          isModalOpen={isEditModal}
          closeModal={() => setIsEditModal(false)}
          medias={selectedPropertyMedia?.videos}
          type={MEDIA_TYPES.VIDEO}
          propertyId={id}
        />
      )}
      {isUploadModal && (
        <VideoTourUploadModal
          propertyId={id}
          type={MEDIA_TYPES.VIDEO}
          isModalOpen={isUploadModal}
          closeModal={() => setIsUploadModal(false)}
        />
      )}
    </div>
  );
};

export default Videos;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: 30,
      background: theme.palette.background.white,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 30,
      [theme.breakpoints.down('sm')]: {
        padding: 20,
        rowGap: 20,
        borderRadius: 0,
      },
    },
    content: {
      display: 'flex',
      columnGap: 30,
      rowGap: 10,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      '& > div': {
        '&:first-child': {
          width: '55%',
          maxWidth: '55%',
          height: 500,
          '& > div': {
            width: '100% !important',
            height: '100% !important',
          },
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '100%',
            height: 360,
          },
          [theme.breakpoints.down('xs')]: {
            height: 180,
          },
        },
        '&:last-child': {
          width: '45%',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        },
      },
    },
    videos: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      '& > div': {
        padding: 10,
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
          borderRadius: 5,
        },
      },
    },
    videoTitle: {
      textTransform: 'capitalize',
    },
    videoPreview: {
      width: 140,
      height: 78,
      background: theme.palette.grey.A100,
    },
    menuContainer: {
      background: theme.palette.background.white,
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      padding: 10,
    },
  }),
);
