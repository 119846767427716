import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';
import date from 'date-and-time';

import { H2, H4, H5 } from 'components/Typography';
import { EnhancedTable, EnhancedMobileTable } from 'components/Table';
import { Pagination } from 'components/Pagination';
import DeleteConfirmModal from 'components/DeleteConfirmModal';
import {
  DEFAULT_ROWS_PER_PAGE,
  SORTS,
  TASK_DEFAULT_TYPE,
} from 'utils/constants';
import {
  getTasks,
  getTaskTypes,
  deleteTasks,
  updateTask,
} from 'store/taskStore';

import HomeTableHeader from './HomeTableHeader';
import TaskNoteDetailModal from '../modals/TaskNoteDetailModal';
import AddEditTaskModal from '../modals/AddEditTaskModal';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ExtensionIcon } from 'assets/icons/extension.svg';

const checkOrder = (a, b, order) => {
  if ((a?.toLowerCase() || '') < (b?.toLowerCase() || '')) {
    if (order === SORTS.DESC) {
      return 1;
    }
    return -1;
  } else if ((a || '') > (b || '')) {
    if (order === SORTS.DESC) {
      return -1;
    }
    return 1;
  } else {
    return 0;
  }
};

const TaskList = ({ propertyId = null }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const { activeTasks, taskTypes, tasksLoading } = useSelector(
    (state) => state.taskReducer,
  );

  const [tableData, setTableData] = useState([]);
  const [mobileTableData, setMobileTableData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isSelectAll, setIsSectAll] = useState(false);
  const [taskType, setTaskType] = useState(TASK_DEFAULT_TYPE);
  const [order, setOrder] = useState(SORTS.DESC);
  const [orderBy, setOrderBy] = useState('Due Date');
  const [page, setPage] = useState(1);
  const [isOpenTaskDetail, setIsOpenTaskDetail] = useState(false);
  const [isOpenAddEditTask, setIsOpenAddEditTask] = useState(false);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
  const [deleteIds, setDeleteIds] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    if (user) {
      dispatch(
        getTasks({
          userId: user?.user_key,
          propertyId,
        }),
      );
      dispatch(getTaskTypes());
    }
  }, [dispatch, user, propertyId]);

  useEffect(() => {
    let newData = [];
    let newMobileData = [];
    if (activeTasks && activeTasks.length) {
      const data = [...activeTasks].sort((a, b) => {
        if (orderBy === 'Due Date') {
          if (new Date(a.due_date).getTime() > new Date(b.due_date).getTime()) {
            if (order === SORTS.DESC) {
              return -1;
            }
            return 1;
          } else {
            if (order === SORTS.DESC) {
              return 1;
            }
            return -1;
          }
        } else if (orderBy === 'Property Address') {
          return checkOrder(a.address, b.address, order);
        } else if (orderBy === 'Landlord Name') {
          return checkOrder(a.landlord_name, b.landlord_name, order);
        } else {
          return checkOrder(a.task_type, b.task_type, order);
        }
      });
      data.forEach((row) => {
        if (taskType === TASK_DEFAULT_TYPE || row.task_type === taskType) {
          const dueDate = new Date(row.due_date);
          const newDataItem = {
            id: row.task_id,
            cells: [
              {
                cell: (
                  <H4
                    className="cursor-pointer"
                    onClick={() => handleOpenTaskNoteDetailModal(row)}
                  >
                    {row.task_type}
                  </H4>
                ),
              },
              {
                cell: <H4>{row.landlord_name}</H4>,
              },
              {
                cell: (
                  <H4>
                    {row.address && row.unit && `${row.address}, ${row.unit}`}
                    {row.address && !row.unit && `${row.address}`}
                    {!row.address && row.unit && `${row.unit}`}
                  </H4>
                ),
              },
              {
                cell: <H4>{date.format(dueDate, 'MM/DD/YYYY')}</H4>,
              },
              {
                align: 'right',
                cell: (
                  <Box
                    display="inline-flex"
                    alignItems="center"
                    gridColumnGap={20}
                  >
                    <EditIcon
                      width={16}
                      height={16}
                      className="cursor-pointer"
                      onClick={() => handleOpenAddEditTaskModal(row)}
                    />
                    <RemoveIcon
                      width={16}
                      height={16}
                      className="cursor-pointer"
                      onClick={() => {
                        setDeleteIds([row.task_id]);
                        setIsOpenDeleteConfirm(true);
                      }}
                    />
                  </Box>
                ),
              },
            ],
          };
          newData.push(newDataItem);
          const newMobileDataItem = {
            id: row.task_id,
            title: (
              <Box display="flex" flexDirection="column" gridRowGap={5}>
                <H4>{row.task_type}</H4>
                <H4 bold>{row.task_type}</H4>
              </Box>
            ),
            content: (
              <Box display="flex" flexDirection="column" gridRowGap={10}>
                <Box display="flex" gridColumnGap={20}>
                  <Box flex={1}>
                    <H4 bold>Landlord:</H4>
                  </Box>
                  <Box flex={1}>
                    <H4>{row.landlord_name}</H4>
                  </Box>
                </Box>
                <Box display="flex" gridColumnGap={20}>
                  <Box flex={1}>
                    <H4 bold>Due Date:</H4>
                  </Box>
                  <Box flex={1}>
                    <H4>{date.format(dueDate, 'MM/DD/YYYY')}</H4>
                  </Box>
                </Box>
                <Box display="flex" gridColumnGap={20}>
                  <Box flex={1}>
                    <H4 bold>Actions:</H4>
                  </Box>

                  <Box
                    display="inline-flex"
                    alignItems="center"
                    gridColumnGap={20}
                    flex={1}
                  >
                    <ExtensionIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenTaskNoteDetailModal(row);
                      }}
                      width={16}
                      height={16}
                      className="cursor-pointer"
                    />
                    <EditIcon
                      width={16}
                      height={16}
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenAddEditTaskModal(row);
                      }}
                    />
                    <RemoveIcon
                      width={16}
                      height={16}
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteIds([row.task_id]);
                        setIsOpenDeleteConfirm(true);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            ),
          };
          newMobileData.push(newMobileDataItem);
        }
      });
    }
    setTableData(newData);
    setMobileTableData(newMobileData);
    // eslint-disable-next-line
  }, [activeTasks, taskType, order, orderBy, dispatch]);

  const handleSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else {
      newSelected = selected.filter((item) => item !== id);
    }
    setSelected(newSelected);
    if (isSelectAll) {
      setIsSectAll(false);
    }
  };

  const handleSelectAll = () => {
    let newSelected = [];
    if (isSelectAll) {
      setIsSectAll(false);
    } else {
      newSelected = tableData.map((row) => row.id);
      setIsSectAll(true);
    }
    setSelected && setSelected(newSelected);
  };

  const handleDeleteItems = () => {
    setDeleteIds(selected);
    setIsOpenDeleteConfirm(true);
  };

  const handleChangeOrder = (property) => {
    setPage(1);
    setOrder(order === SORTS.DESC ? SORTS.ASC : SORTS.DESC);
  };

  const handleChangeOrderBy = (property) => {
    setPage(1);
    setOrderBy(property);
  };

  const handleMarkasDone = (ids) => {
    dispatch(
      updateTask({
        body: { is_completed_ids: ids },
        success: () => {
          dispatch(
            getTasks({
              userId: user?.user_key,
              propertyId,
            }),
          );
          setSelected([]);
        },
      }),
    );
  };

  const handleOpenTaskNoteDetailModal = (task) => {
    setSelectedTask(task);
    setIsOpenTaskDetail(true);
  };

  const handleCloseTaskNoteDetailModal = () => {
    setIsOpenTaskDetail(false);
    setSelectedTask(null);
  };

  const handleOpenAddEditTaskModal = (task) => {
    setSelectedTask(task);
    setIsOpenAddEditTask(true);
  };

  const handleCloseAddEditTaskModal = () => {
    setIsOpenAddEditTask(false);
    setSelectedTask(null);
  };

  const handleCloseDeleteConfirmModal = () => {
    setIsOpenDeleteConfirm(false);
    setDeleteIds(null);
  };

  const Task_Headers = [
    {
      label: 'Task Type',
    },
    {
      label: 'Landlord Name',
    },
    {
      label: 'Property Address',
    },
    {
      label: 'Due Date',
    },
    {
      label: '',
    },
  ];

  return (
    <div className={classes.container}>
      <HomeTableHeader
        title="Tasks"
        selected={selected}
        isSelectAll={isSelectAll}
        handleSelectAll={handleSelectAll}
        handleDeleteItems={handleDeleteItems}
        itemType={taskType}
        itemTypes={[TASK_DEFAULT_TYPE, ...taskTypes.map((item) => item.title)]}
        setItemType={setTaskType}
        isTask={true}
        handleMarkasDone={handleMarkasDone}
      />
      {isMobile ? (
        <EnhancedMobileTable
          rows={mobileTableData.slice(
            (page - 1) * DEFAULT_ROWS_PER_PAGE,
            page * DEFAULT_ROWS_PER_PAGE,
          )}
          enableSelect={true}
          selected={selected}
          handleSelect={handleSelect}
          loading={tasksLoading}
        />
      ) : (
        <EnhancedTable
          headCells={Task_Headers}
          rows={tableData.slice(
            (page - 1) * DEFAULT_ROWS_PER_PAGE,
            page * DEFAULT_ROWS_PER_PAGE,
          )}
          order={order}
          orderBy={orderBy}
          enableSelect={true}
          selected={selected}
          handleSelect={handleSelect}
          handleChangeOrder={handleChangeOrder}
          handleChangeOrderBy={handleChangeOrderBy}
          loading={tasksLoading}
        />
      )}
      {!tasksLoading && !tableData.length && (
        <div className={classes.noTaskContainer}>
          <H2>{isMobile ? 'No tasks yet.' : 'You’ve got no more tasks'}</H2>
          <H5>
            {isMobile
              ? 'Be sure to add your first task below!'
              : 'Well done you! To create another task, simply tap below.'}
          </H5>
        </div>
      )}
      <div className={classes.footer}>
        <Box
          display="flex"
          alignItems="center"
          gridColumnGap={20}
          className="cursor-pointer"
          onClick={() => handleOpenAddEditTaskModal(null)}
        >
          <PlusIcon />
          <H4 bold className={classes.newTask}>
            Create New Task
          </H4>
        </Box>
        <Pagination
          page={page}
          setPage={setPage}
          lastPage={Math.ceil(tableData.length / DEFAULT_ROWS_PER_PAGE)}
        />
      </div>
      <TaskNoteDetailModal
        isModalOpen={isOpenTaskDetail}
        closeModal={handleCloseTaskNoteDetailModal}
        data={selectedTask}
      />
      {isOpenAddEditTask && (
        <AddEditTaskModal
          isModalOpen={isOpenAddEditTask}
          closeModal={handleCloseAddEditTaskModal}
          task={selectedTask}
          selectedPropertyId={propertyId}
        />
      )}
      {isOpenDeleteConfirm && (
        <DeleteConfirmModal
          isModalOpen={isOpenDeleteConfirm}
          closeModal={handleCloseDeleteConfirmModal}
          confirm={() => {
            deleteIds &&
              dispatch(
                deleteTasks({
                  body: deleteIds,
                  success: () => {
                    if (selected.length) {
                      setSelected && setSelected([]);
                      setIsSectAll(false);
                    }
                    setIsOpenDeleteConfirm(false);
                  },
                }),
              );
          }}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: 30,
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
      },
    },
    noTaskContainer: {
      width: '100%',
      height: 275,
      background: theme.palette.background.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      rowGap: 20,
      borderBottom: `1px solid ${theme.palette.grey.A200}`,
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% + 40px)',
        height: 345,
        marginLeft: -20,
        marginRight: -20,
      },
      '& > h2': {
        color: theme.palette.action.active,
      },
      '& > span': {
        display: 'block',
        textAlign: 'center',
        maxWidth: 240,
      },
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px',
      height: 55,
      background: theme.palette.background.white,
      boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.down('xs')]: {
        height: 40,
        marginLeft: -20,
        marginRight: -20,
      },
    },
    newTask: {
      color: theme.palette.action.active,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  }),
);

export default TaskList;
