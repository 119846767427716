import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { H2, H5 } from 'components/Typography';

const ShowingInfo = ({ property }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <H2>Showing Info</H2>
      <H5>{property?.showing_information ?? ''}</H5>
    </div>
  );
};

export default ShowingInfo;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: 30,
      background: theme.palette.background.white,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 30,
      [theme.breakpoints.down('sm')]: {
        padding: 20,
        rowGap: 20,
        borderRadius: 0,
      },
    },
  }),
);
