import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Geocode from 'react-geocode';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import { useMediaQuery } from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { Spinner } from 'components/Spinner';
import Pin from './Pin';
import OverlayItem from './OverlalyItem';
import Item from './Item';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const MapView = ({ properties }) => {
  const classes = useStyles();
  const [list, setList] = useState(properties);
  const [overlayList, setOverlayList] = useState([]);
  const [avgLat, setAvgLat] = useState(39.11);
  const [avgLng, setAvgLng] = useState(-101.29);
  const [topLeft, setTopLeft] = useState([]);
  const [bottomRight, setBottomRight] = useState();
  const [isShowOverlay, setIsShowOverlay] = useState(false);
  const [selectedPinId, setSelectedPinId] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { propertiesLoading } = useSelector((state) => state.propertyReducer);

  useEffect(() => {
    const loadPosition = async () => {
      if (properties.length > 0) {
        Promise.all(
          properties.map(async (property) => {
            const position = await getPosition(property);
            return { ...property, ...position };
          }),
        ).then((values) => {
          let sumLat = 0;
          let sumLng = 0;
          values.forEach((value) => {
            sumLat += value.lat;
            sumLng += value.lng;
          });
          setAvgLat(sumLat / values.length);
          setAvgLng(sumLng / values.length);
          setList(values);
          setOverlayList(values);
        });
      }
    };

    loadPosition();
  }, [properties]);

  useEffect(() => {
    const newArray = list.filter((property) => {
      return (
        (property?.lat || 0) > (bottomRight?.lat || 0) &&
        (property?.lat || 0) < (topLeft?.lat || 0) &&
        (property?.lng || 0) < (bottomRight?.lng || 0) &&
        (property?.lng || 0) > (topLeft?.lng || 0)
      );
    });

    setOverlayList(newArray);
  }, [list, topLeft, bottomRight]);

  const handleChildClick = (key, childProps) => {
    setSelectedPinId(key);

    if (!selectedPinId) {
      setSelectedPinId(key);
      setIsShowOverlay(true);
      return;
    }
    if (key === selectedPinId) {
      setSelectedPinId(null);
      setIsShowOverlay(false);
      return;
    }
  };

  const onEnterOverlayItem = (id) => {
    setSelectedPinId(String(id));
  };

  const handleBoundsChange = (center, zoom, bounds, marginBounds) => {
    const topLeftPosition = { lat: bounds[0], lng: bounds[1] };
    const bottomRightPosition = { lat: bounds[2], lng: bounds[3] };
    setTopLeft(topLeftPosition);
    setBottomRight(bottomRightPosition);
  };

  const getPosition = async (property) => {
    const res = await Geocode.fromAddress(property.address);
    const { lat, lng } = res.results[0].geometry.location;
    return { lat, lng };
  };

  if (propertiesLoading) {
    return (
      <div className={classes.loadingContent}>
        <Spinner loading={propertiesLoading} />
      </div>
    );
  }

  return (
    <div className={classes.mapContainer}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_MAP_KEY}` }}
        center={{
          lat: avgLat,
          lng: avgLng,
        }}
        defaultZoom={isMobile ? 4 : 8}
        margin={[30, 30, 30, 30]}
        onChildClick={handleChildClick}
        onBoundsChange={handleBoundsChange}
        hoverDistance={30}
      >
        {list.map((item) => (
          <Pin
            isActive={selectedPinId === String(item.concept_property_id)}
            lat={item.lat}
            lng={item.lng}
            key={item.concept_property_id}
          />
        ))}
      </GoogleMapReact>

      {isShowOverlay && !isMobile && (
        <div className={classes.overlayWeb}>
          {overlayList.map((item, index) => (
            <div
              key={index}
              onMouseEnter={() => onEnterOverlayItem(item.concept_property_id)}
            >
              <OverlayItem
                key={index}
                data={item}
                isClicked={selectedPinId === String(item.concept_property_id)}
              />
              <hr />
            </div>
          ))}
        </div>
      )}

      {isMobile && (
        <div className={classes.wrapper}>
          <Carousel
            itemClass={classes.item}
            containerClass={classes.container}
            responsive={responsive}
            partialVisible
            slidesToSlide={2}
          >
            {overlayList.map((item, index) => (
              <Item property={item} key={index} />
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default MapView;

const useStyles = makeStyles((theme) =>
  createStyles({
    mapContainer: {
      marginTop: 20,
      width: '100%',
      height: 600,
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        height: 600,
        marginTop: 0,
      },
    },
    overlayWeb: {
      background: theme.palette.background.white,
      minWidth: 796,
      padding: '20px 18px',
      overflowX: 'hidden',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: 4,
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.grey.A100,
        borderRadius: 2,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.action.active,
        borderRadius: 2,
        '&:hover': {
          background: theme.palette.action.hover,
        },
      },
    },
    wrapper: {
      width: '100%',
      position: 'absolute',
      bottom: 55,
    },
    container: {
      height: 120,
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        width: 350,
      },
    },
    item: {
      padding: '20px 0 20px 20px',
    },
    loadingContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 250,
      width: '100%',
      background: 'transparent',
    },
  }),
);
