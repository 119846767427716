import React, { useState } from 'react';
import Geocode from 'react-geocode';
import { H1, H4 } from 'components/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import date from 'date-and-time';

import { updateLandlord } from 'store/landlordStore';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as WorldIcon } from 'assets/icons/world.svg';
import { ReactComponent as PinnedIcon } from 'assets/icons/pinned.svg';
import { ReactComponent as UnPinnedIcon } from 'assets/icons/unPinned.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useDispatch, useSelector } from 'react-redux';
import EditLandlordModal from 'pages/LandlordDetail/modals/EditLandlord';

const DetailsInfo = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [position, setPosition] = useState({});
  const [details, setDetails] = useState(data || {});
  const [pinned, setPinned] = useState(data.pinned);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const cities = useSelector((state) => state.cityReducer.cities);
  const [city, setCity] = useState();

  React.useEffect(() => {
    const initialCity =
      cities && cities.find((item) => item.city_id === data.city_id);

    setCity(initialCity);
  }, [data, cities]);

  React.useEffect(() => {
    setDetails(data);
    setPinned(data.pinned);
  }, [data]);

  React.useEffect(() => {
    if (details?.address) {
      Geocode.fromAddress(details?.address).then((res) => {
        const { lat, lng } = res.results[0].geometry.location;
        setPosition({ lat, lng });
      });
    }
  }, [details]);

  const handleTogglePin = (val) => {
    setPinned(val);
    dispatch(
      updateLandlord({
        id: data.landlord_id,
        pin_landlord: val,
      }),
    );
  };

  const handleOpenEdit = () => {
    setIsOpenEdit(true);
  };

  const handleCloseAddModal = () => {
    setIsOpenEdit(false);
  };

  return (
    <div className={classes.container}>
      <Box display="flex" justifyContent="space-between">
        <H1 display="block">{data.landlord_name}</H1>
        <div style={{ margin: '20px 0' }}>
          {!!pinned && (
            <PinnedIcon
              onClick={() => handleTogglePin(0)}
              style={{ cursor: 'pointer' }}
            />
          )}
          {!pinned && (
            <UnPinnedIcon
              onClick={() => handleTogglePin(1)}
              style={{ cursor: 'pointer' }}
            />
          )}
          <EditIcon
            style={{ width: 20, marginLeft: 20, cursor: 'pointer' }}
            onClick={() => handleOpenEdit()}
          />
        </div>
      </Box>
      <H4 display="block">
        Updated on {date.format(new Date(details.last_updated), 'MMM DD, YYYY')}
      </H4>

      <div className={classes.divider} />

      <H4 display="block" bold>
        CONTACT INFO
      </H4>
      <div className={classes.infoBox}>
        <div>
          <PhoneIcon style={{ marginRight: 22 }} />
          <H4>{details.primary_phone}</H4>
        </div>
        <div>
          <MailIcon style={{ marginRight: 22 }} />
          <H4>{details.email}</H4>
        </div>
        <div>
          <PhoneIcon style={{ marginRight: 22 }} />
          <H4>{details.secondary_phone}</H4>
        </div>
        <div>
          <WorldIcon style={{ marginRight: 22 }} />
          <H4>{details.website}</H4>
        </div>
      </div>

      <div className={classes.divider} />

      <div>
        <Box className={classes.address}>
          <H4 bold>Address:</H4>
          <H4> {details.address}</H4>
        </Box>
        <Box style={{ marginBottom: 20 }}>
          <H4 bold>City:</H4>
          <H4> {city?.city_name}</H4>
        </Box>
      </div>

      <div style={{ height: 350 }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_MAP_KEY}` }}
          center={{
            lat: position.lat,
            lng: position.lng,
          }}
          defaultZoom={15}
          margin={[30, 30, 30, 30]}
        ></GoogleMapReact>
      </div>

      <EditLandlordModal
        isModalOpen={isOpenEdit}
        closeModal={handleCloseAddModal}
        originalData={data}
      />
    </div>
  );
};

export default DetailsInfo;
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      background: theme.palette.background.white,
      borderRadius: 5,
      padding: '1px 20px 20px 20px',
      maxWidth: 820,
      width: '100%',
      height: 950,
      [theme.breakpoints.down('xs')]: {
        height: 805,
      },
    },
    infoBox: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: 550,
      width: '100%',
      gap: '30px 100px',
      marginTop: 30,
      marginBottom: 30,
      [theme.breakpoints.down('md')]: {
        gap: '10px 100px',
        marginTop: 20,
      },
    },
    divider: {
      marginTop: 30,
      marginBottom: 30,
      height: 1,
      border: `1px solid ${theme.palette.grey.A100}`,
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
        marginBottom: 20,
      },
    },
    address: {
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
      },
    },
  }),
);
