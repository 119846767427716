import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Button } from 'components/Buttons';
import { Modal } from 'pages/Property/components/Modal';
import { CheckBox } from 'components/CheckBox';
import { DropdownContent } from 'components/Dropdown';
import { MEDIA_FILTERS, MEDIA_FORMATS } from 'utils/constants';
import { H5 } from 'components/Typography';
import { useDebounce } from 'hooks/useDebounce';

const initialValues = {
  street: '',
  unit: '',
  id: '',
  streetName: '',
  zipCode: '',
  landlord_id: '',
  cities: '',
  pet_id: '',
  source_id: '',
  bed_information_id: '',
  minBaths: '',
  property_status_id: '',
  min_rent: '',
  available_from: '',
  available_to: '',
  media_format: '',
  max_rent: '',
  availTo: '',
  fee_information_id: '',
  advancedOptions: '',
};

const minRentOptions = [
  'All',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
  '1000',
];
const validationSchema = Yup.object({
  street: Yup.string(),
  unit: Yup.string(),
  id: Yup.string(),
});

const FilterContainer = ({
  customStyles,
  handleFIlterChange,
  handleClearFilters,
}) => {
  const [openCitiesModal, setOpenCitiesModal] = useState(false);

  const [isOpenBeds, setIsOpenBeds] = useState(false);
  const [isOpenMinRent, setIsOpenMinRent] = useState(false);
  const [isOpenMaxRent, setIsOpenMaxRent] = useState(false);
  const [isOpenFee, setIsOpenFee] = useState(false);
  const [isOpenPet, setIsOpenPet] = useState(false);
  const [isOpenMinBaths, setIsOpenMinBaths] = useState(false);
  const [isOpenAvailFrom, setIsOpenAvailFrom] = useState(false);
  const [isOpenAvailTo, setIsOpenAvailTo] = useState(false);
  const [isOpenSource, setIsOpenSource] = useState(false);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [isOpenMedia, setIsOpenMedia] = useState(false);
  const [isOpenLandlord, setIsOpenLandlord] = useState(false);
  const [landlordSearch, setLandlordSearch] = useState('');
  const [searchedLandlords, setSearchedLandlords] = useState([]);

  const [cityTempIds, setCityTempIds] = useState([]);
  const [neighborhoodTempIds, setNeighborhoodTempIds] = useState([]);
  const [cityIds, setCityIds] = useState([]);
  const [neighborhoodIds, setNeighborhoodIds] = useState([]);
  const [bedIds, setBedIds] = useState([]);
  const [feeIds, setFeeIds] = useState([]);
  const [petIds, setPetIds] = useState([]);
  const [sourceIds, setSourceIds] = useState([]);
  const [mediaTypeIds, setMediaTypeIds] = useState([]);
  const [statusIds, setStatusIds] = useState([]);
  const [landlordIds, setLandlordIds] = useState([]);
  const [availableFrom, setAvailableFrom] = useState('');
  const [availableTo, setAvailableTo] = useState('');
  const [minRent, setMinRent] = useState('');
  const [maxRent, setMaxRent] = useState('');
  const [minBaths, setMinBaths] = useState('');

  const {
    filterOptions: {
      neighborhoods: citiesList = [],
      propertystatus: statusOptions = [],
      sources: sourceOptions = [],
      // states = [],
      beds: bedsOptions = [],
      pets: petOptions = [],
      fees: feeOptions = [],
      landlords: landlordOptions = [],
    },
    propertiesFilters,
  } = useSelector((state) => state.propertyReducer);
  const classes = useStyles();

  const debouncedLandlordSearch = useDebounce(landlordSearch, 750);

  useEffect(() => {
    if (landlordOptions.length) {
      if (debouncedLandlordSearch) {
        setSearchedLandlords(
          landlordOptions.filter((item) =>
            item.landlord_name
              .toLowerCase()
              .includes(debouncedLandlordSearch.toLowerCase()),
          ),
        );
      } else {
        setSearchedLandlords([]);
      }
    }
  }, [debouncedLandlordSearch, landlordOptions]);

  useEffect(() => {
    if (propertiesFilters) {
      initForm(propertiesFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseCitiesModal = () => {
    setOpenCitiesModal(false);
  };

  const handleOpenCitiesModal = () => {
    setOpenCitiesModal(true);
    setNeighborhoodTempIds(neighborhoodIds);
    setCityTempIds(cityIds);
  };

  const handleCitySelect = (selected) => {
    const cityIndex = cityTempIds.indexOf(selected.city_id);
    const selectedCityNeighborhoodIds = citiesList
      .map((item) => {
        if (item.city_id === selected.city_id && item.neighborhood_id) {
          return item.neighborhood_id;
        }
        return null;
      })
      .filter((element) => element);
    if (selected.neighborhood_id) {
      const neighborhoodIndex = neighborhoodTempIds.indexOf(
        selected.neighborhood_id,
      );
      if (neighborhoodIndex > -1) {
        if (cityIndex > -1) {
          setCityTempIds(
            [...cityTempIds].filter((id) => id !== selected.city_id),
          );
        }
        setNeighborhoodTempIds(
          [...neighborhoodTempIds].filter(
            (id) => id !== selected.neighborhood_id,
          ),
        );
      } else {
        const newNeighborhoods = [
          ...neighborhoodTempIds,
          selected.neighborhood_id,
        ];
        setNeighborhoodTempIds(newNeighborhoods);
        let hasAllIds = true;
        selectedCityNeighborhoodIds.forEach((element) => {
          if (!newNeighborhoods.includes(element)) {
            hasAllIds = false;
          }
        });
        if (hasAllIds) {
          setCityTempIds([...cityTempIds, selected.city_id]);
        }
      }
    } else {
      if (cityIndex > -1) {
        setCityTempIds(
          [...cityTempIds].filter((id) => id !== selected.city_id),
        );
        setNeighborhoodTempIds(
          [...neighborhoodTempIds].filter(
            (id) => !selectedCityNeighborhoodIds.includes(id),
          ),
        );
      } else {
        setCityTempIds([...cityTempIds, selected.city_id]);
        const newNeighborhoods = [...neighborhoodTempIds];
        selectedCityNeighborhoodIds.forEach((element) => {
          if (!newNeighborhoods.includes(element)) {
            newNeighborhoods.push(element);
          }
        });
        setNeighborhoodTempIds(newNeighborhoods);
      }
    }
  };

  const handleMultiSelectItem = (
    id,
    ids,
    value,
    setIds,
    fieldName,
    setFieldValue,
    options,
    valueName,
  ) => {
    const newIds = [...ids];
    const index = newIds.indexOf(id);
    if (index > -1) {
      newIds.splice(index, 1);
    } else {
      newIds.push(id);
    }

    setIds(newIds);

    if (fieldName === 'media_format') {
      // this is temporary fix, will be fixed once the api is updated to include media_format in their response
      setFieldValue(
        fieldName,
        newIds.length > 0
          ? newIds.length === 1
            ? MEDIA_FORMATS[newIds[0]]
            : `${newIds.length} Items Selected`
          : 'All',
      );
    } else {
      setFieldValue(
        fieldName,
        newIds.length > 0
          ? newIds.length === 1
            ? options &&
              options.length > 0 &&
              options.find((item) => item[fieldName] === newIds[0]) &&
              options.find((item) => item[fieldName] === newIds[0])[valueName]
            : `${newIds.length} Items Selected`
          : 'All',
      );
    }
  };

  const handleSingleSelectItem = (
    value,
    setValue,
    fieldName,
    setFieldValue,
    isSelected,
  ) => {
    if (!isSelected) {
      setValue(value);
      setFieldValue(fieldName, value);
    } else {
      setValue('');
      setFieldValue(fieldName, 'All');
    }
  };

  const initForm = (filterOptions) => {
    if (filterOptions['pet_id[]'] && filterOptions['pet_id[]'].length > 0) {
      setFieldValue(filterOptions['pet_id[]'], setPetIds, 'pet_id');
    }
    if (
      filterOptions['source_id[]'] &&
      filterOptions['source_id[]'].length > 0
    ) {
      setFieldValue(filterOptions['source_id[]'], setSourceIds, 'source_id');
    }
    if (
      filterOptions['fee_information_id[]'] &&
      filterOptions['fee_information_id[]'].length > 0
    ) {
      setFieldValue(
        filterOptions['fee_information_id[]'],
        setFeeIds,
        'fee_information_id',
      );
    }
    if (
      filterOptions['bed_information_id[]'] &&
      filterOptions['bed_information_id[]'].length > 0
    ) {
      setFieldValue(
        filterOptions['bed_information_id[]'],
        setBedIds,
        'bed_information_id',
      );
    }
    if (
      filterOptions['neighborhood_id[]'] &&
      filterOptions['neighborhood_id[]'].length > 0
    ) {
      setFieldValue(
        filterOptions['neighborhood_id[]'],
        setNeighborhoodIds,
        'cities',
      );
    }
    if (
      filterOptions['source_id[]'] &&
      filterOptions['source_id[]'].length > 0
    ) {
      setFieldValue(filterOptions['source_id[]'], setSourceIds, 'source_id');
    }
    if (
      filterOptions['media_format[]'] &&
      filterOptions['media_format[]'].length > 0
    ) {
      setFieldValue(
        filterOptions['media_format[]'],
        setMediaTypeIds,
        'media_format',
      );
    }
    if (filterOptions['unit']) {
      initialValues['unit'] = filterOptions['unit'];
    }
    if (filterOptions['concept_property_id']) {
      initialValues['id'] = filterOptions['concept_property_id'];
    }
    if (
      filterOptions['landlord_id'] &&
      filterOptions['landlord_id'].length > 0
    ) {
      setFieldValue(
        filterOptions['landlord_id'],
        setLandlordIds,
        'landlord_id',
      );
    }
    if (filterOptions['street_number']) {
      initialValues['street'] = filterOptions['street_number'];
    }
    if (filterOptions['street_name']) {
      initialValues['streetName'] = filterOptions['street_name'];
    }
    if (filterOptions['zip']) {
      initialValues['zipCode'] = filterOptions['zip'];
    }
    if (filterOptions['min_rent']) {
      initialValues['min_rent'] = filterOptions['min_rent'];
      setMinRent(filterOptions['min_rent']);
    }
    if (filterOptions['max_rent']) {
      initialValues['max_rent'] = filterOptions['max_rent'];
      setMaxRent(filterOptions['max_rent']);
    }
    if (filterOptions['baths']) {
      initialValues['minBaths'] = filterOptions['baths'];
      setMinBaths(filterOptions['baths']);
    }
    if (filterOptions['available_from']) {
      initialValues['available_from'] = filterOptions['available_from'];
      setAvailableFrom(filterOptions['available_from']);
    }
    if (filterOptions['available_to']) {
      initialValues['available_to'] = filterOptions['available_to'];
      setAvailableTo(filterOptions['available_to']);
    }
    if (
      filterOptions['property_status_id[]'] &&
      filterOptions['property_status_id[]'].length > 0
    ) {
      setFieldValue(
        filterOptions['property_status_id[]'],
        setStatusIds,
        'property_status_id',
      );
    }
  };

  const setFieldValue = (ids, setIds, fieldName) => {
    setIds(ids);
    initialValues[fieldName] = `${ids.length} Items Selected`;
  };

  const onFIlterChange = (values) => {
    const filterOptions = {};
    if (petIds.length > 0) {
      filterOptions['pet_id[]'] = petIds;
    }
    if (feeIds.length > 0) {
      filterOptions['fee_information_id[]'] = feeIds;
    }
    if (bedIds.length > 0) {
      filterOptions['bed_information_id[]'] = bedIds;
    }
    if (neighborhoodIds.length > 0) {
      filterOptions['neighborhood_id[]'] = neighborhoodIds;
    }
    if (sourceIds.length > 0) {
      filterOptions['source_id[]'] = sourceIds;
    }
    if (mediaTypeIds.length > 0) {
      filterOptions['media_format[]'] = mediaTypeIds;
    }
    if (values.unit) {
      filterOptions['unit'] = values.unit;
    }
    if (values.id) {
      filterOptions['concept_property_id'] = values.id;
    }
    if (values.landlord_id) {
      filterOptions['landlord_id'] = landlordIds;
    }
    if (values.street) {
      filterOptions['street_number'] = values.street;
    }
    if (values.streetName) {
      filterOptions['street_name'] = values.streetName;
    }
    if (values.zipCode) {
      filterOptions['zip'] = values.zipCode;
    }
    if (values.min_rent) {
      filterOptions['min_rent'] = values.min_rent;
    }
    if (values.max_rent) {
      filterOptions['max_rent'] = values.max_rent;
    }
    if (values.minBaths) {
      filterOptions['baths'] = values.minBaths;
    }
    if (values.available_from) {
      filterOptions['available_from'] = availableFrom;
    }
    if (values.available_to) {
      filterOptions['available_to'] = availableTo;
    }
    if (statusIds.length > 0) {
      filterOptions['property_status_id[]'] = statusIds;
    }

    handleFIlterChange(filterOptions);
  };

  const handleClear = (setFormValue) => {
    setCityIds([]);
    setNeighborhoodIds([]);
    setBedIds([]);
    setFeeIds([]);
    setPetIds([]);
    setSourceIds([]);
    setMediaTypeIds([]);
    setStatusIds([]);
    setLandlordIds([]);
    setAvailableFrom('');
    setAvailableTo('');
    setMinRent('');
    setMaxRent('');
    setMinBaths('');

    setFormValue('street', '');
    setFormValue('unit', '');
    setFormValue('id', '');
    setFormValue('streetName', '');
    setFormValue('zipCode', '');
    setFormValue('landlord_id', '');
    setFormValue('cities', '');
    setFormValue('pet_id', '');
    setFormValue('source_id', '');
    setFormValue('bed_information_id', '');
    setFormValue('minBaths', '');
    setFormValue('status', '');
    setFormValue('min_rent', '');
    setFormValue('available_from', '');
    setFormValue('available_to', '');
    setFormValue('media_format', '');
    setFormValue('property_status_id', '');
    setFormValue('max_rent', '');
    setFormValue('availTo', '');
    setFormValue('fee_information_id', '');
    setFormValue('advancedOptions', '');
    handleClearFilters && handleClearFilters();
  };

  const dateFormatConverter = (val) => {
    if (!val) {
      return '';
    }

    if (isNaN(new Date(val).getTime())) {
      return '';
    }
    const arr = val.split('-');
    return `${arr[1]}/${arr[2]}/${arr[0]}`;
  };

  return (
    <div className={cx(customStyles)}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onFIlterChange(values);
        }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form className={classes.filterContainer}>
            <div className={classes.inputWrapper}>
              <label htmlFor="street" className={classes.label}>
                Street
              </label>
              <Field name="street" className={classes.input} />
            </div>
            <div className={classes.inputWrapper}>
              <label htmlFor="streetName" className={classes.label}>
                Street Name
              </label>
              <Field name="streetName" className={classes.input} />
            </div>
            <div className={classes.inputWrapper}>
              <label htmlFor="cities" className={classes.label}>
                Cities
              </label>
              <Field
                name="cities"
                className={classes.input}
                onClick={handleOpenCitiesModal}
                placeholder="All"
                autoComplete="off"
              />
              {/* Search Cities */}
              <Modal
                open={openCitiesModal}
                title="Select Cities"
                width={1080}
                handleClose={handleCloseCitiesModal}
                handleConfirm={() => {
                  setOpenCitiesModal(false);
                  setNeighborhoodIds(neighborhoodTempIds);
                  setCityIds(cityTempIds);
                  props.setFieldValue(
                    'cities',
                    neighborhoodTempIds.length > 0
                      ? `${neighborhoodTempIds.length} Items Selected`
                      : 'All',
                  );
                }}
                handleCancel={handleCloseCitiesModal}
                handleClear={() => {
                  setNeighborhoodTempIds([]);
                  setCityTempIds([]);
                }}
                maxWidth="lg"
              >
                <div className={classes.citiesGroup}>
                  <div className={classes.citySubGroup}>
                    {citiesList.map((city, index) => (
                      <div
                        className={classNames(classes.cityItem, {
                          longField: city.neighborhood_id === 0,
                        })}
                        key={`${city.label}-${index}`}
                      >
                        <CheckBox
                          subLabel={city.city_name}
                          label={city.neighborhood_name}
                          checked={
                            city.neighborhood_id
                              ? neighborhoodTempIds.includes(
                                  city.neighborhood_id,
                                )
                              : cityTempIds.includes(city.city_id)
                          }
                          setChecked={() => {
                            handleCitySelect(city);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Modal>
            </div>
            <div
              className={classes.inputWrapper}
              onClick={() => setIsOpenBeds(true)}
            >
              <label htmlFor="bed_information_id" className={classes.label}>
                Beds
              </label>
              <input
                name="bed_information_id"
                className={classes.input}
                placeholder="Any"
                value={props.values.bed_information_id}
                readOnly
              />
              {isOpenBeds && (
                <DropdownContent
                  handleClickAway={() => setIsOpenBeds(false)}
                  customStyles={classes.selectDropdown}
                >
                  {bedsOptions.map((item) => (
                    <div
                      className={classes.listItem}
                      key={`bed-${item.bed_information_id}`}
                    >
                      <CheckBox
                        label={item.title}
                        checked={bedIds.includes(item.bed_information_id)}
                        setChecked={() => {
                          handleMultiSelectItem(
                            item.bed_information_id,
                            bedIds,
                            item.title,
                            setBedIds,
                            'bed_information_id',
                            props.setFieldValue,
                            bedsOptions,
                            'title',
                          );
                        }}
                      />
                    </div>
                  ))}
                </DropdownContent>
              )}
            </div>
            <div
              className={classes.inputWrapper}
              onClick={() => setIsOpenMinRent(true)}
            >
              <label htmlFor="min_rent" className={classes.label}>
                Min Rent
              </label>
              <input
                name="min_rent"
                className={classes.input}
                placeholder="Number"
                value={props.values.min_rent}
                readOnly
              />
              {isOpenMinRent && (
                <DropdownContent
                  handleClickAway={() => setIsOpenMinRent(false)}
                  customStyles={classes.selectDropdown}
                >
                  {minRentOptions.map((value, index) => (
                    <div className={classes.listItem} key={`min_rent-${index}`}>
                      <CheckBox
                        label={value}
                        checked={value === minRent}
                        setChecked={() => {
                          handleSingleSelectItem(
                            value,
                            setMinRent,
                            'min_rent',
                            props.setFieldValue,
                            value === minRent,
                          );
                        }}
                      />
                    </div>
                  ))}
                </DropdownContent>
              )}
            </div>
            <div
              className={classes.inputWrapper}
              onClick={() => setIsOpenMaxRent(true)}
            >
              <label htmlFor="max_rent" className={classes.label}>
                Max Rent
              </label>
              <input
                name="max_rent"
                className={classes.input}
                placeholder="Number"
                value={props.values.max_rent}
                readOnly
              />
              {isOpenMaxRent && (
                <DropdownContent
                  handleClickAway={() => setIsOpenMaxRent(false)}
                  customStyles={classes.selectDropdown}
                >
                  {new Array(46).fill(1).map((v, index) => (
                    <div className={classes.listItem} key={`max_rent-${index}`}>
                      <CheckBox
                        label={index * 100 + 1000}
                        checked={index * 100 + 1000 === maxRent}
                        setChecked={() => {
                          handleSingleSelectItem(
                            index * 100 + 1000,
                            setMaxRent,
                            'max_rent',
                            props.setFieldValue,
                            index * 100 + 1000 === maxRent,
                          );
                        }}
                      />
                    </div>
                  ))}
                </DropdownContent>
              )}
            </div>
            <div
              className={classes.inputWrapper}
              onClick={() => setIsOpenFee(true)}
            >
              <label htmlFor="fee_information_id" className={classes.label}>
                Fee
              </label>
              <input
                name="fee_information_id"
                className={classes.input}
                placeholder="All"
                value={props.values.fee_information_id}
                readOnly
              />
              {isOpenFee && (
                <DropdownContent
                  handleClickAway={() => setIsOpenFee(false)}
                  customStyles={classes.selectDropdown}
                >
                  {feeOptions.map((item) => (
                    <div
                      className={classes.listItem}
                      key={`fee-${item.fee_information_id}`}
                    >
                      <CheckBox
                        label={item.title}
                        checked={feeIds.includes(item.fee_information_id)}
                        setChecked={() => {
                          handleMultiSelectItem(
                            item.fee_information_id,
                            feeIds,
                            item.title,
                            setFeeIds,
                            'fee_information_id',
                            props.setFieldValue,
                            feeOptions,
                            'title',
                          );
                        }}
                      />
                    </div>
                  ))}
                </DropdownContent>
              )}
            </div>
            <div className={classes.inputWrapper}>
              <label htmlFor="unit" className={classes.label}>
                Unit
              </label>
              <Field name="unit" className={classes.input} />
            </div>
            <div className={classes.inputWrapper}>
              <label htmlFor="zipCode" className={classes.label}>
                Zip Code
              </label>
              <Field name="zipCode" className={classes.input} />
            </div>
            <div
              className={classes.inputWrapper}
              onClick={() => setIsOpenPet(true)}
            >
              <label htmlFor="pet_id" className={classes.label}>
                Pet
              </label>
              <input
                name="pet_id"
                className={classes.input}
                placeholder="Any"
                value={props.values.pet_id}
                readOnly
              />
              {isOpenPet && (
                <DropdownContent
                  handleClickAway={() => setIsOpenPet(false)}
                  customStyles={classes.selectDropdown}
                >
                  {petOptions.map((item) => (
                    <div
                      className={classes.listItem}
                      key={`pet-${item.pet_id}`}
                    >
                      <CheckBox
                        label={item.title}
                        checked={petIds.includes(item.pet_id)}
                        setChecked={() => {
                          handleMultiSelectItem(
                            item.pet_id,
                            petIds,
                            item.title,
                            setPetIds,
                            'pet_id',
                            props.setFieldValue,
                            petOptions,
                            'title',
                          );
                        }}
                      />
                    </div>
                  ))}
                </DropdownContent>
              )}
            </div>
            <div
              className={classes.inputWrapper}
              onClick={() => setIsOpenMinBaths(true)}
            >
              <label htmlFor="minBaths" className={classes.label}>
                Min Baths
              </label>
              <input
                name="minBaths"
                className={classes.input}
                placeholder="Any"
                value={props.values.minBaths}
                readOnly
              />
              {isOpenMinBaths && (
                <DropdownContent
                  handleClickAway={() => setIsOpenMinBaths(false)}
                  customStyles={classes.selectDropdown}
                >
                  {new Array(32).fill(0).map((v, index) => (
                    <div className={classes.listItem} key={`minBaths-${index}`}>
                      <CheckBox
                        label={index * 0.5}
                        checked={index * 0.5 === minBaths}
                        setChecked={() => {
                          handleSingleSelectItem(
                            index * 0.5,
                            setMinBaths,
                            'minBaths',
                            props.setFieldValue,
                            index * 0.5 === minBaths,
                          );
                        }}
                      />
                    </div>
                  ))}
                </DropdownContent>
              )}
            </div>
            <div
              className={classes.inputWrapper}
              onClick={() => setIsOpenAvailFrom(true)}
            >
              <label htmlFor="available_from" className={classes.label}>
                Avail From
              </label>
              <input
                name="available_from"
                className={classes.input}
                placeholder="MM/DD/YYYY"
                value={dateFormatConverter(props.values.available_from)}
                readOnly
              />
              {isOpenAvailFrom && (
                <DropdownContent
                  handleClickAway={() => setIsOpenAvailFrom(false)}
                  customStyles={classes.selectDropdown}
                >
                  {new Array(24).fill(0).map((v, index) => {
                    const today = new Date();
                    const year = today.getFullYear();
                    const month = today.getMonth();
                    const date = new Date(
                      year,
                      month + Math.floor(index / 2),
                      index % 2 === 0 ? 1 : 15,
                    );
                    const dateStr = `${date.getFullYear()}-${
                      date.getMonth() + 1
                    }-${date.getDate()}`;
                    const label = `${
                      date.getMonth() + 1
                    }/${date.getDate()}/${date.getFullYear()}`;

                    return (
                      <div
                        className={classes.listItem}
                        key={`available_from-${index}`}
                      >
                        <CheckBox
                          label={label}
                          checked={dateStr === availableFrom}
                          setChecked={() => {
                            handleSingleSelectItem(
                              dateStr,
                              setAvailableFrom,
                              'available_from',
                              props.setFieldValue,
                              dateStr === availableFrom,
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </DropdownContent>
              )}
            </div>
            <div
              className={classes.inputWrapper}
              onClick={() => setIsOpenAvailTo(true)}
            >
              <label htmlFor="available_to" className={classes.label}>
                Avail To
              </label>
              <input
                name="available_to"
                className={classes.input}
                placeholder="MM/DD/YYYY"
                value={dateFormatConverter(props.values.available_to)}
                readOnly
              />
              {isOpenAvailTo && (
                <DropdownContent
                  handleClickAway={() => setIsOpenAvailTo(false)}
                  customStyles={classes.selectDropdown}
                >
                  {new Array(24).fill(0).map((v, index) => {
                    const today = new Date();
                    const year = today.getFullYear();
                    const month = today.getMonth();
                    const date = new Date(
                      year,
                      month + Math.floor(index / 2),
                      index % 2 === 0 ? 1 : 15,
                    );
                    const dateStr = `${date.getFullYear()}-${
                      date.getMonth() + 1
                    }-${date.getDate()}`;
                    const label = `${
                      date.getMonth() + 1
                    }/${date.getDate()}/${date.getFullYear()}`;

                    return (
                      <div
                        className={classes.listItem}
                        key={`available_to-${index}`}
                      >
                        <CheckBox
                          label={label}
                          checked={dateStr === availableTo}
                          setChecked={() => {
                            handleSingleSelectItem(
                              dateStr,
                              setAvailableTo,
                              'available_to',
                              props.setFieldValue,
                              dateStr === availableTo,
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </DropdownContent>
              )}
            </div>
            {/* <div className={classes.inputWrapper}>
              <label htmlFor="advancedOptions" className={classes.label}>
                Advanced Options
              </label>
              <Field
                name="advancedOptions"
                className={classes.input}
                placeholder="Any"
              />
            </div> */}
            <div className={classes.inputWrapper}>
              <label htmlFor="id" className={classes.label}>
                ID
              </label>
              <Field name="id" className={classes.input} />
            </div>
            <div className={classes.inputWrapper}>
              <label htmlFor="landlord_id" className={classes.label}>
                Landlord
              </label>
              <Field name="landlord_id">
                {({ form: { setFieldValue }, meta }) => (
                  <div
                    className={classNames(classes.customField, {
                      error: meta.touched && meta.error,
                    })}
                    onClick={() => !isOpenLandlord && setIsOpenLandlord(true)}
                  >
                    <input
                      className={classes.searchInput}
                      value={
                        isOpenLandlord
                          ? landlordSearch
                          : props.values.landlord_id
                      }
                      onChange={(e) => setLandlordSearch(e.target.value)}
                    />
                    {isOpenLandlord && (
                      <>
                        <DropdownContent
                          handleClickAway={() => {
                            setSearchedLandlords([]);
                            setIsOpenLandlord(false);
                            setLandlordSearch('');
                          }}
                          customStyles={classes.selectDropdown}
                        >
                          <div className={classes.dropwdown}>
                            {[{ landlord_id: null }, ...searchedLandlords].map(
                              (item, index) => (
                                <Box
                                  key={`landlord- ${index}`}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  onClick={() => {
                                    setFieldValue(
                                      'landlord_id',
                                      item.landlord_name,
                                    );
                                    setLandlordIds(
                                      item.landlord_id
                                        ? [item.landlord_id]
                                        : [],
                                    );
                                    setIsOpenLandlord(false);
                                    setLandlordSearch('');
                                    setSearchedLandlords([]);
                                  }}
                                >
                                  <H5>
                                    {item.landlord_id
                                      ? item.landlord_name
                                      : 'None'}
                                  </H5>
                                </Box>
                              ),
                            )}
                          </div>
                        </DropdownContent>
                      </>
                    )}
                  </div>
                )}
              </Field>
            </div>
            <div
              className={classes.inputWrapper}
              onClick={() => setIsOpenSource(true)}
            >
              <label htmlFor="source_id" className={classes.label}>
                Source
              </label>
              <input
                name="source_id"
                className={classes.input}
                placeholder="Any"
                value={props.values.source_id}
                readOnly
              />
              {isOpenSource && (
                <DropdownContent
                  handleClickAway={() => setIsOpenSource(false)}
                  customStyles={classes.selectDropdown}
                >
                  {sourceOptions.map((item) => (
                    <div
                      className={classes.listItem}
                      key={`source-${item.source_id}`}
                    >
                      <CheckBox
                        label={item.source_name}
                        checked={sourceIds.includes(item.source_id)}
                        setChecked={() => {
                          handleMultiSelectItem(
                            item.source_id,
                            sourceIds,
                            item.source_name,
                            setSourceIds,
                            'source_id',
                            props.setFieldValue,
                            sourceOptions,
                            'source_name',
                          );
                        }}
                      />
                    </div>
                  ))}
                </DropdownContent>
              )}
            </div>
            <div
              className={classes.inputWrapper}
              onClick={() => setIsOpenStatus(true)}
            >
              <label htmlFor="property_status_id" className={classes.label}>
                Status
              </label>
              <input
                name="property_status_id"
                className={classes.input}
                placeholder="Any"
                value={props.values.property_status_id}
                readOnly
              />
              {isOpenStatus && (
                <DropdownContent
                  handleClickAway={() => setIsOpenStatus(false)}
                  customStyles={classes.selectDropdown}
                >
                  {statusOptions.map((item) => (
                    <div
                      className={classes.listItem}
                      key={`status-${item.property_status_id}`}
                    >
                      <CheckBox
                        label={item.property_status_name}
                        checked={statusIds.includes(item.property_status_id)}
                        setChecked={() => {
                          handleMultiSelectItem(
                            item.property_status_id,
                            statusIds,
                            item.property_status_name,
                            setStatusIds,
                            'property_status_id',
                            props.setFieldValue,
                            statusOptions,
                            'property_status_name',
                          );
                        }}
                      />
                    </div>
                  ))}
                </DropdownContent>
              )}
            </div>
            <div
              className={classes.inputWrapper}
              onClick={() => !isOpenMedia && setIsOpenMedia(true)}
            >
              <label htmlFor="media_format" className={classes.label}>
                Media
              </label>
              <input
                name="media_format"
                className={classes.input}
                placeholder="Any"
                value={props.values.media_format}
                readOnly
              />
              {isOpenMedia && (
                <DropdownContent
                  handleClickAway={() => setIsOpenMedia(false)}
                  customStyles={classes.selectDropdown}
                >
                  {/* <Field name='media' className={classes.input} /> */}
                  {MEDIA_FILTERS.map((item, index) => (
                    <div className={classes.listItem} key={`media-${index}`}>
                      <CheckBox
                        className={classes.medias}
                        label={item}
                        checked={mediaTypeIds.includes(item)}
                        setChecked={() => {
                          handleMultiSelectItem(
                            item,
                            mediaTypeIds,
                            item,
                            setMediaTypeIds,
                            'media_format',
                            props.setFieldValue,
                            MEDIA_FILTERS,
                          );
                        }}
                      />
                    </div>
                  ))}
                </DropdownContent>
              )}
              {/* <label htmlFor='media' className={classes.label}>Media</label> */}
              {/* <Field name='media' className={classes.input} /> */}
              {/* <select name='status' className={classes.input}>
                <option value='All' label='All' />
                <option value='Photos' label='Photos' />
                <option value='Virtual Tours' label='Virtual Tours' />
              </select> */}
            </div>
            <div className={classes.emptyBox} />
            <div className={classes.buttonGroup}>
              <Button
                variant="secondary"
                className={classes.button}
                type="button"
                onClick={() => handleClear(props.setFieldValue)}
              >
                Clear
              </Button>
              <Button
                variant="primary"
                type="submit"
                className={classes.button}
              >
                Search
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FilterContainer;

const useStyles = makeStyles((theme) =>
  createStyles({
    filterContainer: {
      background: theme.palette.background.white,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: '20px 30px 0px 30px',
      padding: 20,
      minHeight: 261,
      gap: '10px 20px',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        height: 'auto',
        margin: 0,
        padding: 0,
      },
    },
    inputWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: 230,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: 10,
      },
      position: 'relative',
    },
    emptyBox: {
      width: 230,
      height: 67,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    label: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '22px',
      color: theme.palette.text.primary,
      marginBottom: 5,
    },
    input: {
      height: 40,
      border: `1px solid ${theme.palette.grey[300]}`,
      boxSizing: 'border-box',
      borderRadius: 5,
      padding: '9px 10px',
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
    },
    buttonGroup: {
      display: 'flex',
      gap: 20,
      width: 230,
      marginTop: 20,
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        display: 'none',
      },
    },
    button: {
      width: 114,
    },
    groupChar: {
      fontWeight: 'bold',
      fontSize: 20,
      lineHeight: '27px',
      color: theme.palette.text.primary,
      marginTop: 20,
      marginBottom: 20,
    },
    citySubGroup: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 10,
    },
    cityItem: {
      width: 320,
      height: 22,
      marginBottom: 10,
      '&.longField': {
        width: '100%',
        marginTop: 20,
      },
    },
    selectDropdown: {
      width: '100%',
      border: `2px solid ${theme.palette.action.active}`,
      transform: 'translate(-50%, 0px)',
      maxHeight: 230,
      overflowY: 'auto',
      overflowX: 'hidden',
      top: 'calc(100% + 8px)',
      left: '50%',
      '&::-webkit-scrollbar': {
        width: 4,
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.grey.A100,
        borderRadius: 2,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.action.active,
        borderRadius: 2,
        '&:hover': {
          background: theme.palette.action.hover,
        },
      },
    },
    listItem: {
      display: 'flex',
      gap: 10,
      margin: 10,
      width: '100%',

      '& span': {
        minHeight: 20,
        lineHeight: '25px',
      },
    },
    customField: {
      display: 'flex',
      alignItems: 'center',
      height: 40,
      padding: '0 10px',
      background: theme.palette.background.white,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 5,
      color: theme.palette.text.primary,
      fontSize: 16,
      lineHeight: '22px',
      position: 'relative',
      cursor: 'pointer',
      width: '100%',
      '&.error': {
        border: `2px solid ${theme.palette.background.red}`,
      },
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
      '& > span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    dropwdown: {
      '& > div': {
        padding: '8px 15px',
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
        },
      },
    },
    searchInput: {
      width: '100%',
      fontSize: 16,
      border: 'none',
    },
    medias: {
      '& > label': {
        textTransform: 'capitalize',
      },
    },
  }),
);
