import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
// import PropertyCreateModal from './modals/PropertyCreateModal';

import { Button } from 'components/Buttons';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';
import cx from 'classnames';

import { H5 } from 'components/Typography';
import PropertyItem from './PropertyItem';
import {
  addProperties,
  getClientUrlProperties,
  removeProperty,
} from 'store/clientStore';
import { resetSearchedProperties } from 'store/landlordStore';
import AddPropertiesModal from 'pages/LandlordDetail/modals/AddPropertiesModal';
import { EnhancedTable } from 'components/Table';
import { StatusMark } from 'components/StatusMark';
import { STATUSES, STATUS_TYPES } from 'utils/constants';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import DeleteConfirmModal from 'components/DeleteConfirmModal';

const SharedPropertiesListComponent = ({ clientId }) => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [mobileTableData, setMobileTableData] = useState([]);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
  const [deletingPropertyId, setDeletingPropertyId] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { clientProperties } = useSelector((store) => store.clientReducer);
  const { user } = useSelector((state) => state.authReducer);
  const [isAdded, setIsAdded] = useState(false);

  const origin = window.location.origin;

  React.useEffect(() => {
    dispatch(getClientUrlProperties(clientId));
  }, [dispatch, clientId]);

  React.useEffect(() => {
    let newData = [];
    let newMobileData = [];
    clientProperties.length > 0 &&
      clientProperties.forEach((row, index) => {
        const newDataItem = {
          id: row.concept_property_id,
          cells: [
            {
              cell: (
                <div className={cx(classes.area1, classes.area)}>
                  <H5 display="block" bold className={classes.line}>
                    {row.address}
                  </H5>
                  <div className={classes.line}>
                    <H5 bold className={classes.rightSpace}>
                      ID
                    </H5>
                    <H5>{row.concept_property_id}</H5>
                  </div>
                  <div className={classes.line}>
                    <H5 className={classes.rightSpace}>Rent</H5>
                    <H5 bold className={classes.rightSpace}>
                      ${Number(row.rent)}
                    </H5>
                    <H5 className={classes.rightSpace}>Fee:</H5>
                    <H5 bold>{row.fee_information}</H5>
                  </div>
                </div>
              ),
            },
            {
              cell: (
                <div className={cx(classes.area3, classes.area)}>
                  <div className={classes.line}>
                    <H5 className={classes.rightSpace}>Building type:</H5>
                    <H5 bold>{row.building_type}</H5>
                  </div>
                  <div className={classes.line}>
                    <H5 className={classes.rightSpace}>Beds:</H5>
                    <H5 className={classes.rightSpace} bold>
                      {Number(row.beds)}
                    </H5>
                    <H5 className={classes.rightSpace}>Baths:</H5>
                    <H5 className={classes.rightSpace} bold>
                      {Number(row.baths)}
                    </H5>
                    <H5 className={classes.rightSpace}>Square Footage:</H5>
                    <H5 className={classes.rightSpace} bold>
                      {Number(row.square_footage)}
                    </H5>
                    <H5 className={classes.rightSpace}>Parking:</H5>
                    <H5 className={classes.rightSpace} bold>
                      {row.parking}
                    </H5>
                  </div>
                  <div className={classes.line}>
                    <H5 className={classes.rightSpace}>Utilities Included:</H5>
                    <H5 className={classes.rightSpace} bold>
                      {row.include_heat ? 'Heat' : ''}{' '}
                      {row.include_hot_water ? 'Hot Water' : ''}{' '}
                      {row.include_gas ? 'Gas' : ''}{' '}
                      {row.include_electricity ? 'Electricity' : ''}
                    </H5>
                    <H5 className={classes.rightSpace}>Pet policy:</H5>
                    <H5 className={classes.rightSpace} bold>
                      {row.pet_policy}
                    </H5>
                  </div>
                </div>
              ),
            },
            {
              cell: (
                <div className={cx(classes.area4, classes.area)}>
                  <a
                    href={`${origin}/public_property/${row.property_url_key}${
                      user?.user_id ? '?a=' + user?.user_id : ''
                    }`}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    {`${origin}/public_property/${row.property_url_key}${
                      user?.user_id ? '?a=' + user?.user_id : ''
                    }`}
                  </a>
                </div>
              ),
            },
            {
              cell: (
                <div className={cx(classes.area5, classes.area)}>
                  <div style={{ display: 'flex' }} className={classes.line}>
                    <StatusMark
                      status={
                        STATUS_TYPES[STATUSES[row?.property_status_id - 1]]
                      }
                      className={classes.rightSpace}
                    />
                    <H5 bold>{row.status}</H5>
                  </div>
                  <H5 display="block">{row.available_date}</H5>
                </div>
              ),
            },
            {
              cell: (
                <div className={cx(classes.area5, classes.remove)}>
                  <RemoveIcon
                    onClick={() => handleRemove(row.client_url_property_id)}
                  />
                </div>
              ),
            },
          ],
        };
        newData.push(newDataItem);
        const newMobileDataItem = row;
        newMobileData.push(newMobileDataItem);
      });
    setTableData(newData);
    setMobileTableData(newMobileData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientProperties]);

  const backToList = () => {
    history.push('/property');
  };

  const handleAddProperties = async (properties) => {
    dispatch(
      addProperties({
        params: {
          client_url_id: clientId,
          concept_property_id: properties,
        },
        success: () => {
          setIsAdded(true);
          dispatch(resetSearchedProperties());
          dispatch(getClientUrlProperties(clientId));
        },
      }),
    );
  };

  const handleRemove = (id) => {
    setIsOpenDeleteConfirm(true);
    setDeletingPropertyId(id);
  };

  const handleCloseDeleteConfirmModal = () => {
    setIsOpenDeleteConfirm(false);
    setDeletingPropertyId(null);
  };

  const handleCloseAddModal = () => {
    dispatch(resetSearchedProperties());
    setIsOpenAddModal(false);
    setIsAdded(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Box
          flex={1}
          width={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gridColumnGap={20}
          onClick={backToList}
        >
          <Box className={classes.address}>
            {isMobile && <BackIcon style={{ marginRight: 8 }} />}
            <H5>Client URL - </H5>
            <H5>Name:</H5>&nbsp;
            <H5 bold>{clientProperties.length} Results</H5>
          </Box>
        </Box>
        <Button
          onClick={() => setIsOpenAddModal(true)}
          className={classes.addPropertyBtn}
        >
          Add Properties
        </Button>
      </div>
      <div className={classes.content}>
        {/* {clientProperties.map((property, index) => (
          <PropertyItem
            property={property}
            key={index}
            isShowsheetItem
            handleRemove={handleRemove}
          />
        ))} */}
        {isMobile ? (
          <Box display="flex" flexDirection="column" gridRowGap={10}>
            {mobileTableData.map((row, index) => (
              <PropertyItem
                property={row}
                key={index}
                isShowsheetItem
                handleRemove={() => handleRemove(row?.client_url_property_id)}
              />
            ))}
          </Box>
        ) : (
          <EnhancedTable
            headCells={headers}
            boldHeader
            rows={tableData}
            className={classes.tableStyles}
            variant="secondary"
          />
        )}
      </div>
      <AddPropertiesModal
        isModalOpen={isOpenAddModal}
        closeModal={handleCloseAddModal}
        handleSave={handleAddProperties}
        isAdded={isAdded}
        setIsAdded={setIsAdded}
      />
      <DeleteConfirmModal
        isModalOpen={isOpenDeleteConfirm}
        closeModal={handleCloseDeleteConfirmModal}
        confirm={() => {
          deletingPropertyId &&
            dispatch(
              removeProperty({
                body: { id: deletingPropertyId },
                success: () => {
                  dispatch(getClientUrlProperties(clientId));
                },
              }),
            );
          handleCloseDeleteConfirmModal();
        }}
      />
    </div>
  );
};

export default SharedPropertiesListComponent;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {},
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 30px',
      width: '100%',
      height: 70,
      columnGap: 20,
      rowGap: 20,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
      background: theme.palette.background.white,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: 'auto',
        padding: 20,
        rowGap: 10,
      },
    },
    content: {
      padding: '0 30px',
      marginTop: 30,
    },
    addPropertyBtn: {
      height: 50,
      margin: 10,
      width: 178,
    },
    tableStyles: {
      '& .MuiTableHead-root': {
        background: theme.palette.grey.A400,
      },
      '& .MuiTableRow-root': {
        marginBottom: 15,
      },
      '& .MuiTableBody-root .MuiTableRow-root': {
        borderLeft: `8px solid ${theme.palette.action.active}`,
        borderRadius: 5,
      },
      '& .MuiTableCell-head div span': {
        textTransform: 'initial',
      },
      '& .MuiTableCell-root.MuiTableCell-body': {
        borderBottom: `15px solid ${theme.palette.grey.A400}`,
      },
      '& .MuiTableCell-root MuiTableCell-head': {
        borderBottom: 'none',
      },
    },
    mobileTableStyles: {
      '& > div': {
        background: `${theme.palette.background.white} !important`,
        marginBottom: 10,
      },
    },
    area5: {
      width: 120,
    },
    remove: {
      padding: '25px 30px',
    },
    line: {
      marginBottom: 10,
    },
    rightSpace: {
      marginRight: 10,
    },
    link: {
      color: theme.palette.action.active,
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 700,
      textDecoration: 'none',
    },
  }),
);

const headers = [
  {
    label: 'Property',
  },
  {
    label: 'Listing Details',
  },
  {
    label: 'Public Link',
  },
  {
    label: 'Status',
  },
  {
    label: '',
  },
];
