import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';

import { H1, H4, H5 } from 'components/Typography';
import { Button } from 'components/Buttons';
import { EnhancedForm, FormInputField } from 'components/Form';
import { sendRestPasswordRequest } from 'store/authStore';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      maxWidth: 440,
      background: theme.palette.background.white,
      borderRadius: 5,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      padding: 60,
      paddingTop: 40,
      [theme.breakpoints.down('xs')]: {
        rowGap: 5,
        padding: 20,
        paddingTop: 0,
      },
    },
    submit: {
      width: '100%',
    },
    goBackToPage: {
      width: '100%',
      marginTop: 20,
    },
  }),
);

const ResetPasswordRequestPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSent, setIsSent] = useState(false);

  const handleSubmit = (body) => {
    dispatch(
      sendRestPasswordRequest({
        body,
        success: () => {
          setIsSent(true);
        },
      }),
    );
  };

  const goBackToPage = () => {
    history.push('/');
  };

  return (
    <div className={classes.container}>
      {isSent ? (
        <div className={classes.content}>
          <H4>
            Reset Password request was sent. Please check your email inbox.
          </H4>
          <Button
            type="button"
            className={classes.goBackToPage}
            onClick={goBackToPage}
          >
            Back to page
          </Button>
        </div>
      ) : (
        <EnhancedForm
          initialValues={{
            email: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Required'),
          })}
          handleSubmit={handleSubmit}
          className={classes.content}
        >
          {() => (
            <>
              <H1>
                Password Recovery
                <br />
                <H5>Please enter your email below.</H5>
              </H1>
              <FormInputField label="Email" name="email" />
              <Button type="submit" className={classes.submit}>
                Submit
              </Button>
            </>
          )}
        </EnhancedForm>
      )}
    </div>
  );
};

export default ResetPasswordRequestPage;
