import { get } from 'utils/api';

const getCities = () => {
  return get({
    path: 'cities',
  });
};

const cityService = {
  getCities,
};

export default cityService;
