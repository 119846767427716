import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import landlordService from 'services/landlordService';

export const getLandlordList = createAsyncThunk(
  'landlordSlice/getLandlordList',
  async () => {
    const response = await landlordService.getLandlordList();
    return response;
  },
);

export const getViewLandlordList = createAsyncThunk(
  'landlordSlice/getViewLandlordList',
  async (payload) => {
    const response = await landlordService.getLandlordList(payload);
    return response;
  },
);

export const getLandlordById = createAsyncThunk(
  'landlordSlice/getLandlordById',
  async (landlordId) => {
    const response = await landlordService.getLandlordById(landlordId);
    return response;
  },
);

export const updateLandlord = createAsyncThunk(
  'landlordSlice/updateLandlord',
  async (payload) => {
    const { body, success } = payload;
    const { id, ...params } = body;
    const response = await landlordService.updateLandlord(id, params);
    success();
    return response;
  },
);

export const createLandlord = createAsyncThunk(
  'landlordSlice/createLandlord',
  async (payload) => {
    const { body, success } = payload;
    const response = await landlordService.createLandlord(body);
    success();
    return response;
  },
);

export const pinLandlord = createAsyncThunk(
  'landlordSlice/pinLandlord',
  async (payload) => {
    const response = await landlordService.updateLandlord(payload, {
      pin_landlord: 1,
    });
    return response;
  },
);

export const unPinLandlord = createAsyncThunk(
  'landlordSlice/unPinLandlord',
  async (payload) => {
    const response = await landlordService.updateLandlord(payload, {
      pin_landlord: 0,
    });
    return response;
  },
);

export const searchAllProperties = createAsyncThunk(
  'landlordSlice/searchAllProperties',
  async (payload) => {
    const response = await landlordService.searchAllProperties(payload);
    return response;
  },
);

export const searchUnassignedProperties = createAsyncThunk(
  'landlordSlice/searchUnassignedProperties',
  async (payload) => {
    const response = await landlordService.searchUnassignedProperties(payload);
    return response;
  },
);

const initialState = {
  landlordList: [],
  viewLandlordList: [],
  pinnedLandlordList: [],
  data: {},
  listLoading: false,
  loading: false,
  searchedProperties: [],
};

const landlordSlice = createSlice({
  name: 'landlordSlice',
  initialState,
  reducers: {
    resetSearchedProperties: (state) => {
      state.searchedProperties = [];
    },
  },
  extraReducers: {
    [getLandlordList.fulfilled]: (state, action) => {
      state.landlordList = action.payload;
      state.pinnedLandlordList = action.payload.filter(
        (item) => item.pinned === 1,
      );
      state.listLoading = false;
    },
    [getLandlordList.pending]: (state) => {
      state.listLoading = true;
    },
    [getLandlordList.rejected]: (state) => {
      state.listLoading = false;
    },
    [getViewLandlordList.fulfilled]: (state, action) => {
      state.viewLandlordList = action.payload;
      state.loading = false;
    },
    [getViewLandlordList.pending]: (state) => {
      state.loading = true;
    },
    [getViewLandlordList.rejected]: (state) => {
      state.loading = false;
    },
    [getLandlordById.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getLandlordById.pending]: (state) => {
      state.loading = true;
    },
    [getLandlordById.rejected]: (state) => {
      state.loading = false;
    },
    [createLandlord.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [createLandlord.pending]: (state) => {
      state.loading = true;
    },
    [createLandlord.rejected]: (state) => {
      state.loading = false;
    },
    [updateLandlord.fulfilled]: (state, action) => {
      // state.data = action.payload.landlord;
      state.loading = false;
    },
    [updateLandlord.pending]: (state) => {
      state.loading = true;
    },
    [updateLandlord.rejected]: (state) => {
      state.loading = false;
    },
    [pinLandlord.fulfilled]: (state, action) => {
      const updatedLandlord = action.payload.landlord;
      const landlords = [...state.landlordList];
      const newLandlords = landlords.map((item) => {
        if (item.landlord_id === updatedLandlord.landlord_id) {
          return { ...item, pinned: 1 };
        } else {
          return item;
        }
      });
      state.landlordList = newLandlords;
      state.pinnedLandlordList = newLandlords.filter(
        (item) => item.pinned === 1,
      );
    },
    [unPinLandlord.fulfilled]: (state, action) => {
      const updatedLandlord = action.payload.landlord;
      const landlords = [...state.landlordList];
      const newLandlords = landlords.map((item) => {
        if (item.landlord_id === updatedLandlord.landlord_id) {
          return { ...item, pinned: 0 };
        } else {
          return item;
        }
      });
      state.landlordList = newLandlords;
      state.pinnedLandlordList = newLandlords.filter(
        (item) => item.pinned === 1,
      );
    },
    [searchAllProperties.fulfilled]: (state, action) => {
      state.searchedProperties = action.payload;
    },
    [searchUnassignedProperties.fulfilled]: (state, action) => {
      state.searchedProperties = action.payload;
    },
  },
});

export const { resetSearchedProperties } = landlordSlice.actions;
export default landlordSlice.reducer;
