import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import Geocode from 'react-geocode';
import { isEmpty } from 'lodash';

import { EnhancedModal } from 'components/Modal';
import { Button, ForwardButton } from 'components/Buttons';
import { H3, H5 } from 'components/Typography';
import { CheckBox } from 'components/CheckBox';
import { DropdownContent } from 'components/Dropdown';
import {
  CustomFormField,
  DropdownFormField,
  FormInputField,
} from 'components/Form';
import {
  VARIANTS,
  BATHS_OPTIONS,
  PET_CAT_OPTIONS,
  PET_DOG_OPTIONS,
  UNIT_LEVEL_OPTIONS,
  LAUNDRY_OPTIONS,
  BUILDING_TYPE_OPTIONS,
  STUDENT_POLICY_OPTIONS,
  HEAT_SOURCE_OPTIONS,
  PARKING_OPTIONS,
  PARKING_TYPE_OPTIONS,
  MOVE_COST_SECURITY_TYPE_OPTIONS,
  PROPERTY_DATABASE,
} from 'utils/constants';
import { createProperty, getPropertyConflicts } from 'store/propertyStore';
import { getViewLandlordList } from 'store/landlordStore';
import { Modal } from '../components/Modal';
import PropertyConflictModal from './PropertyConflictModal';
import { useDebounce } from 'hooks/useDebounce';
import AddLandlordModal from 'pages/Landlord/modals/AddLandlord';

const Wizard = ({
  children,
  initialValues,
  onSubmit,
  classes,
  conflictAccept = false,
  setConflictAccept = null,
}) => {
  const firstStepRef = useRef();
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  useEffect(() => {
    if (conflictAccept) {
      firstStepRef.current.click();
    }
  }, [conflictAccept]);

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (e, values) => {
    e.preventDefault();
    e.stopPropagation();
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit && !conflictAccept) {
      await step.props.onSubmit(values, bag);
    } else if (isLastStep) {
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      next(values);
      setConflictAccept && setConflictAccept(false);
    }
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {(formik) => (
        <Form>
          {step}
          <div className={classes.actionButtons}>
            <H5>
              {stepNumber + 1}/{totalSteps}
            </H5>
            <Box display="flex" alignItems="center" gridColumnGap={20}>
              {stepNumber === 0 ? (
                <ForwardButton
                  type="submit"
                  disabled={formik.isSubmitting}
                  ref={firstStepRef}
                >
                  Next
                </ForwardButton>
              ) : stepNumber === 1 ? (
                <>
                  <Button
                    onClick={(e) => previous(e, formik.values)}
                    type="button"
                    variant={VARIANTS.SECONDARY}
                  >
                    Back
                  </Button>
                  <Button type="submit" disabled={formik.isSubmitting}>
                    Next
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={(e) => previous(e, formik.values)}
                    type="button"
                    variant={VARIANTS.SECONDARY}
                  >
                    Back
                  </Button>
                  <Button type="submit" disabled={formik.isSubmitting}>
                    Save
                  </Button>
                </>
              )}
            </Box>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const WizardStep = ({ children }) => children;

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PropertyCreateModal = ({ isModalOpen, closeModal, initPropertyList }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [location, setLocation] = useState({});
  const [landlord, setLandlord] = useState('None');
  const [isOpenLandlordList, setIsOpenLandlordList] = useState(false);
  const [landlordSearch, setLandlordSearch] = useState('');
  const [searchedLandlords, setSearchedLandlords] = useState([]);
  const [isOpenDatabase, setIsOpenDatabase] = useState(false);
  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [isOpenCity, setIsOpenCity] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedTempCity, setSelectedTempCity] = useState(null);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isOpenBaths, setIsOpenBaths] = useState(false);
  const [selectedBaths, setSelectedBaths] = useState(null);
  const [isOpenBeds, setIsOpenBeds] = useState(false);
  const [selectedBeds, setSelectedBeds] = useState(null);
  const [isOpenPetCats, setIsOpenPetCats] = useState(false);
  const [selectedPetCats, setSelectedPetCats] = useState(null);
  const [isOpenPetDogs, setIsOpenPetDogs] = useState(false);
  const [selectedPetDogs, setSelectedPetDogs] = useState(null);
  const [isOpenUnitLevel, setIsOpenUnitLevel] = useState(false);
  const [selectedUnitLevel, setSelectedUnitLevel] = useState(null);
  const [isOpenLaundry, setIsOpenLaundry] = useState(false);
  const [selectedLaundry, setSelectedLaundry] = useState(null);
  const [isOpenBuildingType, setIsOpenBuildingType] = useState(false);
  const [selectedBuildingType, setSelectedBuildingType] = useState(null);
  const [isOpenStudentPolicy, setIsOpenStudentPolicy] = useState(false);
  const [selectedStudentPolicy, setSelectedStudentPolicy] = useState(null);
  const [isOpenBuildingFeatures, setIsOpenBuildingFeatures] = useState(false);
  const [selectedBuildingFeature, setSelectedBuildingFeature] = useState([]);
  const [isOpenUnitFeatures, setIsOpenUnitFeatures] = useState(false);
  const [selectedUnitFeature, setSelectedUnitFeature] = useState([]);
  const [isOpenTags, setIsOpenTags] = useState(false);
  const [selectedTag, setSelectedTag] = useState([]);
  const [isOpenHeatSource, setIsOpenHeatSource] = useState(false);
  const [selectedHeatSource, setSelectedHeatSource] = useState(null);
  const [isOpenParkingAvailability, setIsOpenParkingAvailability] =
    useState(false);
  const [selectedParkingAvailability, setSelectedParkingAvailability] =
    useState(null);
  const [isOpenParkingType, setIsOpenParkingType] = useState(false);
  const [selectedParkingType, setSelectedParkingType] = useState(null);
  const [isOpenFeeInfos, setIsOpenFeeInfos] = useState(false);
  const [selectedFeeInfo, setSelectedFeeInfo] = useState(null);
  const [isOpenSecurityTypes, setIsOpenSecurityTypes] = useState(false);
  const [selectedSecurityType, setSelectedSecurityType] = useState(null);
  const [rentIncludes, setRentIncludes] = useState({
    cable: false,
    internet: false,
    gas: false,
    hotWater: false,
    electricity: false,
    heat: false,
  });
  const [firstMonthRequired, setFirstMonthRequired] = useState(false);
  const [lastMonthRequired, setLastMonthRequired] = useState(false);

  const [isOpenConflict, setIsOpenConflict] = useState(false);
  const [foundProperty, setFoundProperty] = useState(null);
  const [conflictProperty, setConflictProperty] = useState(null);
  const [conflictAccept, setConflictAccept] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const landlordInputRef = useRef(null);

  const { viewLandlordList } = useSelector((store) => store.landlordReducer);

  const {
    filterOptions: {
      neighborhoods: citiesList = [],
      propertystatus: statusOptions = [],
      beds: bedsOptions = [],
      fees: feeOptions = [],
    },
    features: featureOptions = [],
    tags: tagOptions = [],
  } = useSelector((state) => state.propertyReducer);

  const debouncedLandlordSearch = useDebounce(landlordSearch, 750);

  useEffect(() => {
    if (selectedDatabase === PROPERTY_DATABASE[0]) {
      dispatch(getViewLandlordList('mainDatabase'));
    } else if (selectedDatabase === PROPERTY_DATABASE[1]) {
      dispatch(getViewLandlordList('myBookOfBusiness'));
    }
  }, [selectedDatabase, dispatch]);

  useEffect(() => {
    if (debouncedLandlordSearch) {
      setSearchedLandlords(
        viewLandlordList.filter((item) =>
          item.landlord_name
            .toLowerCase()
            .includes(debouncedLandlordSearch.toLowerCase()),
        ),
      );
    } else {
      setSearchedLandlords([]);
    }
  }, [debouncedLandlordSearch, viewLandlordList]);

  useEffect(() => {
    if (selectedCity?.city_name) {
      Geocode.fromAddress(
        `${selectedCity?.neighborhood_name} ${selectedCity?.city_name}`,
      ).then((res) => {
        const { lat, lng } = res.results[0].geometry.location;
        setLocation({ lat, lng });
      });
    } else if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            navigator.geolocation.getCurrentPosition((pos) => {
              var crd = pos.coords;
              setLocation({ lat: crd.latitude, lng: crd.longitude });
            });
          } else if (result.state === 'prompt') {
            navigator.geolocation.getCurrentPosition(
              (pos) => {
                var crd = pos.coords;
                setLocation({ lat: crd.latitude, lng: crd.longitude });
              },
              (err) => {
                console.warn(`ERROR(${err.code}): ${err.message}`);
              },
              {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
              },
            );
          }
        });
    } else {
      alert('Sorry Not available!');
    }
  }, [selectedCity]);

  const handleOpenAddModal = () => {
    setIsOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setIsOpenAddModal(false);
  };

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={'Add property'}
      mobileFullWidth={true}
    >
      <div className={classes.container}>
        <Wizard
          initialValues={{
            recordLocation: null,
            landlordId: null,
            rentalStreetNumber: null,
            rentalStreetName: null,
            rentalUnit: null,
            rentalCity: null,
            rentalZip: null,
            status: null,
            rent: null,
            beds: null,
            baths: null,
            unitLevel: null,
            squareFootage: null,
            availableDate: null,
            fee: null,
            feeInfo: null,
            feeAdditionalInfo: null,
            heatSource: null,
            laundry: null,
            student: null,
            petCatInfo: null,
            petDogInfo: null,
            parkingAvailability: null,
            parkingNumber: null,
            parkingPrice: null,
            parkingType: null,
            buildingDescription: null,
            buildingType: null,
            unitDescription: null,
            buildingFeatures: [],
            unitFeatures: [],
            tags: [],
            showingInfo: null,
            publicTitle: null,
            security: null,
            applicationFee: null,
            moveFee: null,
            keyDeposit: null,
            petDeposit: null,
            securityType: null,
          }}
          classes={classes}
          onSubmit={async (values) =>
            sleep(300).then(() =>
              dispatch(
                createProperty({
                  body: {
                    street_number: values.rentalStreetNumber,
                    street_name: values.rentalStreetName,
                    zip: values.rentalZip,
                    unit: values.rentalUnit,
                    bed_information_id: values.beds,
                    baths: values.baths,
                    available_date: values.availableDate,
                    price: values.rent,
                    fee: values.fee,
                    pet_cat: values.petInfo,
                    pet_dog: values.petInfo,
                    square_footage: values.squareFootage,
                    unit_level: values.unitLevel,
                    laundry: values.laundry,
                    parking_availability: values.parkingAvailability,
                    parking_number: values.parkingNumber,
                    parking_price: values.parkingPrice,
                    parking_type: values.parkingType,
                    showing_information: values.showingInfo,
                    public_title: values.publicTitle,
                    fee_information_id: values.feeInfo,
                    fee_additional_information: values.feeAdditionalInfo,
                    heat_source: values.heatSource,
                    building_description: values.buildingDescription,
                    building_type: values.buildingType,
                    move_in_cost_application_fee: values.applicationFee,
                    move_in_cost_fee: values.moveFee,
                    move_in_cost_is_first_month_required: firstMonthRequired,
                    move_in_cost_is_last_month_required: lastMonthRequired,
                    move_in_cost_key_deposit: values.keyDeposit,
                    move_in_cost_pet_deposit: values.petDeposit,
                    move_in_cost_security_deposit: values.security,
                    move_in_cost_security_deposit_type: values.securityType,
                    student_policy: values.student,
                    unit_description: values.unitDescription,
                    property_status_id: values.status,
                    neighborhood_id: selectedCity?.neighborhood_id
                      ? selectedCity.neighborhood_id
                      : null,
                    city_id: selectedCity?.city_id
                      ? selectedCity.city_id
                      : null,
                    landlord_id: values.landlordId,
                    building_features: values.buildingFeatures,
                    unit_features: values.unitFeatures,
                    tags: values.tags,
                    my_book_of_business:
                      values.recordLocation === PROPERTY_DATABASE[0]
                        ? false
                        : true,
                    include_electricity: rentIncludes.electricity,
                    include_gas: rentIncludes.gas,
                    include_heat: rentIncludes.heat,
                    include_hot_water: rentIncludes.hotWater,
                    include_internet: rentIncludes.internet,
                    include_cable: rentIncludes.cable,
                    latitude: location.lat,
                    longitude: location.lng,
                  },
                  success: () => {
                    initPropertyList();
                  },
                }),
              ),
            )
          }
          conflictAccept={conflictAccept}
          setConflictAccept={setConflictAccept}
        >
          <WizardStep
            onSubmit={(values) => {
              dispatch(
                getPropertyConflicts({
                  params: {
                    street_number: values.rentalStreetNumber,
                    street_name: values.rentalStreetName,
                    zip: values.rentalZip,
                    unit: values.rentalUnit,
                  },
                  success: (res) => {
                    if (!isEmpty(res)) {
                      setConflictProperty({
                        address: `${values.rentalStreetNumber} ${values.rentalStreetName}, ${selectedCity?.city_name}`,
                        unit: values.rentalUnit,
                        landlord: landlord?.landlord_name,
                      });
                      setFoundProperty({
                        address: `${res.found_street_number} ${res.found_street_name}, ${res.found_city}`,
                        unit: res.found_unit,
                        landlord: res?.found_landlord_name,
                      });
                      setIsOpenConflict(true);
                    } else {
                      setConflictAccept(true);
                    }
                  },
                }),
              );
            }}
            validationSchema={Yup.object({
              recordLocation: Yup.string().required('Required!').nullable(true),
              landlordId: Yup.string().required('Required!').nullable(true),
              rentalStreetNumber: Yup.string()
                .required('Required!')
                .nullable(true),
              rentalStreetName: Yup.string()
                .required('Required!')
                .nullable(true),
              rentalCity: Yup.string().required('Required!').nullable(true),
              rentalZip: Yup.string().required('Required!').nullable(true),
            })}
          >
            <Box
              display="flex"
              gridColumnGap={20}
              gridRowGap={5}
              flexWrap="wrap"
            >
              <DropdownFormField
                label="Record Location*"
                name="recordLocation"
                className={classes.fieldItem}
                value={selectedDatabase || ''}
                isOpenDropdown={isOpenDatabase}
                setIsOpenDropdown={setIsOpenDatabase}
                dropdownList={PROPERTY_DATABASE}
                selectItem={setSelectedDatabase}
              />
              {selectedDatabase === PROPERTY_DATABASE[1] && (
                <Box className={classes.fieldItem}>
                  <H5>
                    You must add a landlord to your book of business before
                    adding properties
                  </H5>
                </Box>
              )}
            </Box>
            <Box mb={3}>
              <H3 bold>Landlord selection</H3>
            </Box>
            <H5>Select Landlord*</H5>
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              mt={1}
              gridColumnGap={20}
              gridRowGap={10}
            >
              <Field name="landlordId">
                {({ form: { setFieldValue }, meta }) => (
                  <button
                    tabIndex="0"
                    type="button"
                    className={classNames(classes.customField, {
                      error: meta.touched && meta.error,
                    })}
                    onClick={() => {
                      if (selectedDatabase && !isOpenLandlordList) {
                        setIsOpenLandlordList(true);
                        landlordInputRef.current.focus();
                      }
                    }}
                  >
                    <input
                      tabIndex="-1"
                      ref={landlordInputRef}
                      className={classes.searchInput}
                      value={isOpenLandlordList ? landlordSearch : landlord}
                      onChange={(e) => setLandlordSearch(e.target.value)}
                      readOnly={!selectedDatabase}
                    />
                    {isOpenLandlordList && (
                      <>
                        <DropdownContent
                          handleClickAway={() => {
                            setSearchedLandlords([]);
                            setIsOpenLandlordList(false);
                            setLandlordSearch('');
                          }}
                          customStyles={classes.selectDropdown}
                        >
                          <div className={classes.dropwdown}>
                            {[{ landlord_id: null }, ...searchedLandlords].map(
                              (item, index) => (
                                <Box
                                  key={`landlord- ${index}`}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  onClick={() => {
                                    setFieldValue(
                                      'landlordId',
                                      item.landlord_id,
                                    );
                                    setLandlord(
                                      item.landlord_id
                                        ? item.landlord_name
                                        : 'None',
                                    );
                                    setIsOpenLandlordList(false);
                                    setLandlordSearch('');
                                    setSearchedLandlords([]);
                                  }}
                                >
                                  <H5>
                                    {item.landlord_id
                                      ? item.landlord_name
                                      : 'None'}
                                  </H5>
                                </Box>
                              ),
                            )}
                          </div>
                        </DropdownContent>
                      </>
                    )}
                  </button>
                )}
              </Field>
              <Box className={classes.fieldItem}>
                <H5>
                  or{' '}
                  <span
                    onClick={handleOpenAddModal}
                    className={classes.landlordButton}
                  >
                    Add New Landlord
                  </span>
                </H5>
              </Box>
            </Box>
            <Box mt={3} mb={3}>
              <H3 bold>Rental Address</H3>
            </Box>
            <Box
              display="flex"
              gridColumnGap={20}
              gridRowGap={5}
              flexWrap="wrap"
            >
              <FormInputField
                label="Street Number*"
                name="rentalStreetNumber"
                className={classes.fieldItem}
              />
              <FormInputField
                label="Street Name*"
                name="rentalStreetName"
                className={classes.fieldItem}
              />
              <FormInputField
                label="Unit"
                name="rentalUnit"
                className={classes.fieldItem}
              />
              <CustomFormField
                label="City*"
                name="rentalCity"
                value={
                  selectedCity
                    ? `${selectedCity.city_name}${
                        selectedCity.neighborhood_name
                          ? ' - ' + selectedCity.neighborhood_name
                          : ''
                      }`
                    : ''
                }
                className={classes.fieldItem}
                isOpenChildren={isOpenCity}
                setIsOpenChildren={(value) => {
                  setIsOpenCity(value);
                  setSelectedTempCity(selectedCity);
                }}
              >
                {(props) =>
                  isOpenCity && (
                    <Modal
                      open={isOpenCity}
                      title="Select Cities"
                      width={1080}
                      handleClose={() => {
                        setIsOpenCity(false);
                      }}
                      handleConfirm={() => {
                        setIsOpenCity(false);
                        setSelectedCity(selectedTempCity);
                        if (selectedTempCity) {
                          props.setFieldValue(
                            'rentalCity',
                            selectedTempCity.neighborhood_id ||
                              selectedTempCity.city_id,
                          );
                        } else {
                          props.setFieldValue('rentalCity', '');
                        }
                      }}
                      handleCancel={() => {
                        setIsOpenCity(false);
                      }}
                      handleClear={() => setSelectedTempCity(null)}
                      maxWidth="lg"
                    >
                      <div className={classes.citiesGroup}>
                        <div className={classes.citySubGroup}>
                          {citiesList.map((item, index) => (
                            <div
                              className={classNames(classes.cityItem, {
                                longField: item.neighborhood_id === 0,
                              })}
                              key={`${item.label}-${index}`}
                            >
                              <CheckBox
                                variant={VARIANTS.SECONDARY}
                                subLabel={item.city_name}
                                label={item.neighborhood_name}
                                checked={
                                  item.city_id === selectedTempCity?.city_id &&
                                  item.neighborhood_id ===
                                    selectedTempCity?.neighborhood_id
                                }
                                setChecked={() => {
                                  setSelectedTempCity(item);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </Modal>
                  )
                }
              </CustomFormField>
              <FormInputField
                label="Zip*"
                name="rentalZip"
                className={classes.fieldItem}
              />
              <Box className={classes.fieldItem} />
            </Box>
          </WizardStep>
          <WizardStep
            validationSchema={Yup.object({
              status: Yup.string().required('Required!').nullable(true),
              rent: Yup.string().required('Required!').nullable(true),
              availableDate: Yup.string().required('Required!').nullable(true),
              buildingType: Yup.string().required('Required!').nullable(true),
              showingInfo: Yup.string().required('Required!').nullable(true),
            })}
          >
            <Box mt={3} mb={3}>
              <H3 bold>Unit Info</H3>
            </Box>
            <Box
              display="flex"
              gridColumnGap={20}
              gridRowGap={5}
              flexWrap="wrap"
            >
              <DropdownFormField
                label="Status*"
                name="status"
                className={classes.fieldItem}
                value={
                  selectedStatus ? selectedStatus.property_status_name : ''
                }
                isOpenDropdown={isOpenStatus}
                setIsOpenDropdown={setIsOpenStatus}
                dropdownList={statusOptions}
                itemId="property_status_id"
                itemValue="property_status_name"
                selectItem={setSelectedStatus}
              />
              <FormInputField
                label="Rent*"
                name="rent"
                placeholder="Number"
                className={classes.fieldItem}
              />
              <DropdownFormField
                label="Beds"
                name="beds"
                className={classes.fieldItem}
                value={selectedBeds ? selectedBeds.title : ''}
                isOpenDropdown={isOpenBeds}
                setIsOpenDropdown={setIsOpenBeds}
                dropdownList={bedsOptions}
                selectItem={setSelectedBeds}
                itemId="bed_information_id"
                itemValue="title"
              />
              <DropdownFormField
                label="Baths"
                name="baths"
                className={classes.fieldItem}
                value={selectedBaths || 0}
                isOpenDropdown={isOpenBaths}
                setIsOpenDropdown={setIsOpenBaths}
                dropdownList={BATHS_OPTIONS}
                selectItem={setSelectedBaths}
              />
              <DropdownFormField
                label="Unit Level"
                name="unitLevel"
                className={classes.fieldItem}
                value={selectedUnitLevel || ''}
                isOpenDropdown={isOpenUnitLevel}
                setIsOpenDropdown={setIsOpenUnitLevel}
                dropdownList={UNIT_LEVEL_OPTIONS}
                selectItem={setSelectedUnitLevel}
              />
              <FormInputField
                label="Unit Description"
                name="unitDescription"
                className={classes.fieldItem}
              />
              <FormInputField
                label="Building Description"
                name="buildingDescription"
                className={classes.fieldItem}
              />
              <DropdownFormField
                label="Building Type*"
                name="buildingType"
                className={classes.fieldItem}
                value={selectedBuildingType || ''}
                isOpenDropdown={isOpenBuildingType}
                setIsOpenDropdown={setIsOpenBuildingType}
                dropdownList={BUILDING_TYPE_OPTIONS}
                selectItem={setSelectedBuildingType}
              />
              <FormInputField
                label="Square Footage"
                name="squareFootage"
                className={classes.fieldItem}
              />
              <FormInputField
                label="Available Date*"
                name="availableDate"
                type="date"
                placeholder="MM/DD/YYYY"
                className={classes.fieldItem}
              />
              <FormInputField
                label="Fee"
                name="fee"
                className={classes.fieldItem}
              />
              <DropdownFormField
                label="Fee Infomation"
                name="feeInfo"
                className={classes.fieldItem}
                value={selectedFeeInfo ? selectedFeeInfo.title : ''}
                isOpenDropdown={isOpenFeeInfos}
                setIsOpenDropdown={setIsOpenFeeInfos}
                dropdownList={feeOptions}
                selectItem={setSelectedFeeInfo}
                itemId="fee_information_id"
                itemValue="title"
              />
              <FormInputField
                label="Fee Additional Info"
                name="feeAdditionalInfo"
                className={classes.fieldItem}
              />
              <DropdownFormField
                label="Heat Source"
                name="heatSource"
                className={classes.fieldItem}
                value={selectedHeatSource || ''}
                isOpenDropdown={isOpenHeatSource}
                setIsOpenDropdown={setIsOpenHeatSource}
                dropdownList={HEAT_SOURCE_OPTIONS}
                selectItem={setSelectedHeatSource}
              />
              <DropdownFormField
                label="Laundry"
                name="laundry"
                className={classes.fieldItem}
                value={selectedLaundry || ''}
                isOpenDropdown={isOpenLaundry}
                setIsOpenDropdown={setIsOpenLaundry}
                dropdownList={LAUNDRY_OPTIONS}
                selectItem={setSelectedLaundry}
              />
              <DropdownFormField
                label="Student"
                name="student"
                className={classes.fieldItem}
                value={selectedStudentPolicy || ''}
                isOpenDropdown={isOpenStudentPolicy}
                setIsOpenDropdown={setIsOpenStudentPolicy}
                dropdownList={STUDENT_POLICY_OPTIONS}
                selectItem={setSelectedStudentPolicy}
              />
              <DropdownFormField
                label="Pet Cat Info"
                name="petCatInfo"
                className={classes.fieldItem}
                value={selectedPetCats || ''}
                isOpenDropdown={isOpenPetCats}
                setIsOpenDropdown={setIsOpenPetCats}
                dropdownList={PET_CAT_OPTIONS}
                selectItem={setSelectedPetCats}
              />
              <DropdownFormField
                label="Pet Dog Info"
                name="petDogInfo"
                className={classes.fieldItem}
                value={selectedPetDogs || ''}
                isOpenDropdown={isOpenPetDogs}
                setIsOpenDropdown={setIsOpenPetDogs}
                dropdownList={PET_DOG_OPTIONS}
                selectItem={setSelectedPetDogs}
              />
              <DropdownFormField
                label="Parking Availability"
                name="parkingAvailability"
                className={classes.fieldItem}
                value={
                  selectedParkingAvailability
                    ? selectedParkingAvailability.value
                    : ''
                }
                isOpenDropdown={isOpenParkingAvailability}
                setIsOpenDropdown={setIsOpenParkingAvailability}
                dropdownList={PARKING_OPTIONS}
                selectItem={setSelectedParkingAvailability}
                itemId="id"
                itemValue="value"
              />
              <FormInputField
                label="Parking Number"
                name="parkingNumber"
                className={classes.fieldItem}
              />
              <FormInputField
                label="Parking Price"
                name="parkingPrice"
                className={classes.fieldItem}
              />
              <DropdownFormField
                label="Parking Type"
                name="parkingType"
                className={classes.fieldItem}
                value={selectedParkingType || ''}
                isOpenDropdown={isOpenParkingType}
                setIsOpenDropdown={setIsOpenParkingType}
                dropdownList={PARKING_TYPE_OPTIONS}
                selectItem={setSelectedParkingType}
              />
              <FormInputField
                label="Public Title"
                name="publicTitle"
                className={classes.fieldItem}
              />
              <DropdownFormField
                label="Building Features"
                name="buildingFeatures"
                className={classes.longFieldItem}
                value={
                  selectedBuildingFeature.length
                    ? `${selectedBuildingFeature.length} item selected`
                    : ''
                }
                isOpenDropdown={isOpenBuildingFeatures}
                setIsOpenDropdown={setIsOpenBuildingFeatures}
                dropdownList={featureOptions.filter(
                  (item) => item.category === 'Building',
                )}
                selectItem={setSelectedBuildingFeature}
                selectedItem={selectedBuildingFeature}
                itemId="feature_id"
                itemValue="feature_name"
                multi={true}
              />
              <DropdownFormField
                label="Unit Features"
                name="unitFeatures"
                className={classes.longFieldItem}
                value={
                  selectedUnitFeature.length
                    ? `${selectedUnitFeature.length} item selected`
                    : ''
                }
                isOpenDropdown={isOpenUnitFeatures}
                setIsOpenDropdown={setIsOpenUnitFeatures}
                dropdownList={featureOptions.filter(
                  (item) => item.category === 'Unit',
                )}
                selectItem={setSelectedUnitFeature}
                selectedItem={selectedUnitFeature}
                itemId="feature_id"
                itemValue="feature_name"
                multi={true}
              />
              <DropdownFormField
                label="Tags"
                name="tags"
                className={classes.longFieldItem}
                value={
                  selectedTag.length
                    ? `${selectedTag.length} item selected`
                    : ''
                }
                isOpenDropdown={isOpenTags}
                setIsOpenDropdown={setIsOpenTags}
                dropdownList={tagOptions}
                selectItem={setSelectedTag}
                selectedItem={selectedTag}
                itemId="tag_id"
                itemValue="title"
                multi={true}
              />
              <FormInputField
                label="Showing & Tenant Info*"
                name="showingInfo"
                className={classes.longFieldItem}
              />
              <Box display="flex" flexDirection="column" gridRowGap={20}>
                <H5>Rent Includes</H5>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  mt={1}
                  gridColumnGap={20}
                  gridRowGap={10}
                >
                  <CheckBox
                    label="Cable"
                    className={classes.fieldItem}
                    checked={rentIncludes.cable}
                    setChecked={() =>
                      setRentIncludes({
                        ...rentIncludes,
                        cable: !rentIncludes.cable,
                      })
                    }
                  />
                  <CheckBox
                    label="Internet"
                    className={classes.fieldItem}
                    checked={rentIncludes.internet}
                    setChecked={() =>
                      setRentIncludes({
                        ...rentIncludes,
                        internet: !rentIncludes.internet,
                      })
                    }
                  />
                  <CheckBox
                    label="Gas"
                    className={classes.fieldItem}
                    checked={rentIncludes.gas}
                    setChecked={() =>
                      setRentIncludes({
                        ...rentIncludes,
                        gas: !rentIncludes.gas,
                      })
                    }
                  />
                  <CheckBox
                    label="Hot Water"
                    className={classes.fieldItem}
                    checked={rentIncludes.hotWater}
                    setChecked={() =>
                      setRentIncludes({
                        ...rentIncludes,
                        hotWater: !rentIncludes.hotWater,
                      })
                    }
                  />
                  <CheckBox
                    label="Electricity"
                    className={classes.fieldItem}
                    checked={rentIncludes.electricity}
                    setChecked={() =>
                      setRentIncludes({
                        ...rentIncludes,
                        electricity: !rentIncludes.electricity,
                      })
                    }
                  />
                  <CheckBox
                    label="Heat"
                    className={classes.fieldItem}
                    checked={rentIncludes.heat}
                    setChecked={() =>
                      setRentIncludes({
                        ...rentIncludes,
                        heat: !rentIncludes.heat,
                      })
                    }
                  />
                </Box>
              </Box>
            </Box>
          </WizardStep>
          <WizardStep>
            <Box mt={3} mb={3}>
              <H3 bold>Move in Cost</H3>
            </Box>
            <Box
              display="flex"
              gridColumnGap={20}
              gridRowGap={20}
              flexWrap="wrap"
              marginBottom="30px"
            >
              <CheckBox
                label="Require First Month"
                className={classes.fieldItem}
                checked={firstMonthRequired}
                setChecked={() => setFirstMonthRequired(!firstMonthRequired)}
              />
              <CheckBox
                label="Require Last Month"
                className={classes.fieldItem}
                checked={lastMonthRequired}
                setChecked={() => setLastMonthRequired(!lastMonthRequired)}
              />
            </Box>
            <Box
              display="flex"
              gridColumnGap={20}
              gridRowGap={5}
              flexWrap="wrap"
            >
              <FormInputField
                label="Application Fee"
                name="applicationFee"
                className={classes.fieldItem}
              />
              <FormInputField
                label="Move in Fee"
                name="moveFee"
                className={classes.fieldItem}
              />
              <FormInputField
                label="Key Deposit"
                name="keyDeposit"
                className={classes.fieldItem}
              />
              <FormInputField
                label="Pet Deposit"
                name="petDeposit"
                className={classes.fieldItem}
              />
              <DropdownFormField
                label="Security"
                name="securityType"
                className={classes.fieldItem}
                value={selectedSecurityType ? selectedSecurityType.value : ''}
                isOpenDropdown={isOpenSecurityTypes}
                setIsOpenDropdown={setIsOpenSecurityTypes}
                dropdownList={MOVE_COST_SECURITY_TYPE_OPTIONS}
                selectItem={setSelectedSecurityType}
                itemId="id"
                itemValue="value"
              />
              {selectedSecurityType?.id === 'F' ? (
                <FormInputField
                  label="Security"
                  name="security"
                  className={classes.fieldItem}
                />
              ) : (
                <div className={classes.fieldItem} />
              )}
            </Box>
          </WizardStep>
        </Wizard>
      </div>
      {isOpenConflict && (
        <PropertyConflictModal
          isModalOpen={isOpenConflict}
          closeModal={() => {
            setIsOpenConflict(false);
            setFoundProperty(null);
            setConflictProperty(null);
          }}
          confirm={() => {
            setIsOpenConflict(false);
            setFoundProperty(null);
            setConflictProperty(null);
            setConflictAccept(true);
          }}
          conflictProperty={conflictProperty}
          foundInfo={foundProperty}
        />
      )}
      {isOpenAddModal && (
        <AddLandlordModal
          isModalOpen={isOpenAddModal}
          closeModal={handleCloseAddModal}
        />
      )}
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 500,
      maxHeight: 600,
      rowGap: 10,
      overflowY: 'auto',
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        rowGap: 20,
        marginBottom: 0,
      },
      '&::-webkit-scrollbar': {
        width: 6,
        background: theme.palette.background.white,
        borderRadius: 5,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
    content: {
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
      [theme.breakpoints.down('xs')]: {
        maxHeight: '100%',
      },
    },
    fieldItem: {
      flex: 1,
      minWidth: 200,
      maxWidth: 240,
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
      },
    },
    longFieldItem: {
      flex: 1,
      minWidth: '100%',
    },
    landlord: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      position: 'relative',
      padding: '0 14px',
      height: 50,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 5,
      cursor: 'pointer',
      flex: 1,
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
      },
    },
    selectDropdown: {
      left: 0,
      right: 0,
      top: 'calc(100% + 5px)',
      transform: 'translate(0, 5px)',
    },
    dropwdown: {
      width: '100%',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 300,
      overflowY: 'auto',
      '& > div': {
        padding: '8px 15px',
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
        },
      },
      '&::-webkit-scrollbar': {
        width: 6,
        background: theme.palette.background.white,
        borderRadius: 5,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
    actionButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 20,
      columnGap: 20,
      padding: '0 60px',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 80,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        background: theme.palette.background.white,
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.15)',
        padding: '0 40px',
      },
    },
    groupChar: {
      fontWeight: 'bold',
      fontSize: 20,
      lineHeight: '27px',
      color: theme.palette.text.primary,
      marginTop: 20,
      marginBottom: 20,
    },
    citySubGroup: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 10,
    },
    cityItem: {
      width: 320,
      height: 22,
      marginBottom: 10,
      '&.longField': {
        width: '100%',
        marginTop: 20,
      },
    },
    customField: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 40,
      padding: '0 10px',
      background: theme.palette.background.white,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 5,
      color: theme.palette.text.primary,
      fontSize: 16,
      lineHeight: '22px',
      position: 'relative',
      cursor: 'pointer',
      minWidth: 200,
      maxWidth: 240,
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
      },
      '&.error': {
        border: `2px solid ${theme.palette.background.red}`,
      },
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
      '& > span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    searchInput: {
      width: '100%',
      fontSize: 16,
      border: 'none',
    },
    landlordButton: {
      cursor: 'pointer',
      '&:hover': {
        fontWeight: 'bold',
      },
    },
  }),
);

export default PropertyCreateModal;
