import React from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { VARIANTS } from 'utils/constants';
import { H5 } from './Typography';

import { ReactComponent as ArrowLastIcon } from 'assets/icons/arrowLeftLast.svg';
import { ReactComponent as ArrowFillIcon } from 'assets/icons/arrowFillDown.svg';
import { ReactComponent as DoubleArrowIcon } from 'assets/icons/doubleArrowLeft.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowRight.svg';

export const Pagination = ({
  page,
  setPage,
  lastPage,
  className,
  variant = VARIANTS.PRIMARY,
  noPageNumber = false,
}) => {
  const classes = useStyles();

  const handleMoveOnePage = (dir) => {
    const newPage = page + dir;
    if (newPage > 0 && newPage <= lastPage) {
      setPage(newPage);
    }
  };

  if (lastPage < 1) return null;

  return (
    <div
      className={classNames(
        variant === VARIANTS.PRIMARY
          ? classes.primaryContainer
          : classes.secondaryContainer,
        className,
      )}
    >
      {variant === VARIANTS.PRIMARY ? (
        <>
          <ArrowLastIcon className="leftLast" onClick={() => setPage(1)} />
          <ArrowFillIcon
            className="left"
            onClick={() => handleMoveOnePage(-1)}
          />
          {!noPageNumber && (
            <H5>
              {page} / {lastPage}
            </H5>
          )}
          <ArrowFillIcon
            className="right"
            onClick={() => handleMoveOnePage(1)}
          />
          <ArrowLastIcon
            className="rightLast"
            onClick={() => setPage(lastPage)}
          />
        </>
      ) : (
        <>
          <div>
            <DoubleArrowIcon className="leftLast" onClick={() => setPage(1)} />
          </div>
          <div>
            <ArrowIcon className="left" onClick={() => handleMoveOnePage(-1)} />
          </div>
          <div>
            <ArrowIcon className="right" onClick={() => handleMoveOnePage(1)} />
          </div>
          <div>
            <DoubleArrowIcon
              className="rightLast"
              onClick={() => setPage(lastPage)}
            />
          </div>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    primaryContainer: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 16,
      '& > svg': {
        cursor: 'pointer',
      },
      '& .leftLast': {
        transform: 'rotate(0)',
      },
      '& .left': {
        transform: 'rotate(90deg)',
      },
      '& .right': {
        transform: 'rotate(-90deg)',
      },
      '& .rightLast': {
        transform: 'rotate(180deg)',
      },
    },
    secondaryContainer: {
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        width: 42,
        height: 42,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        background: theme.palette.background.white,
        border: `1px solid ${theme.palette.action.active}`,
        borderRight: 'none',
        '&:first-child': {
          borderRadius: '5px 0px 0px 5px',
        },
        '&:last-child': {
          borderRight: `1px solid ${theme.palette.action.active}`,
          borderRadius: '0px 5px 5px 0px',
        },
        '&:hover': {
          background: theme.palette.grey.A700,
        },
      },
      '& .leftLast': {
        transform: 'rotate(0)',
      },
      '& .left': {
        transform: 'rotate(0)',
        '& > path': {
          fill: theme.palette.action.active,
        },
      },
      '& .right': {
        transform: 'rotate(180deg)',
        '& > path': {
          fill: theme.palette.action.active,
        },
      },
      '& .rightLast': {
        transform: 'rotate(180deg)',
      },
    },
  }),
);
