import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { H5 } from 'components/Typography';
import { ReactComponent as NoteIcon } from 'assets/icons/note.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/task.svg';

const InteractionItem = ({
  isNote,
  isTask,
  noteId,
  taskId,
  date,
  description,
  openDetailModal,
}) => {
  const classes = useStyles();

  const taskType = () => {
    if (isNote) return 'NOTE';
    if (isTask) return 'TASK';
  };

  return (
    <div
      className={classes.container}
      onClick={() => openDetailModal(isNote ? noteId : taskId)}
    >
      <div className={classes.left}>
        {isNote && <NoteIcon />}
        {isTask && <TaskIcon />}
      </div>
      <div className={classes.right}>
        <H5 bold display="block" className={classes.itemTitle}>
          {taskType()} {date}
        </H5>
        <H5 display="block">{description}</H5>
      </div>
    </div>
  );
};

export default InteractionItem;
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      border: `1px solid ${theme.palette.grey.A100}`,
      height: 110,
      marginBottom: 15,
      display: 'flex',
      justifyContent: 'flex-start',
    },
    left: {
      width: 94,
      background: theme.palette.grey.A200,
      borderRight: `1px solid ${theme.palette.grey.A100}`,
      padding: 35,
    },
    right: {
      padding: 20,
    },
    itemTitle: { marginBottom: 10 },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: 0,
      height: 55,
    },
  }),
);
