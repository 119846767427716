import { get, patch, post } from 'utils/api';
// import { REVIEW_TYPE_ID_LANDLORD } from 'utils/constants';

const getLandlordList = (view = '') => {
  return get({
    path: `landlords`,
    parameters: {
      view,
    },
  });
};

const getLandlordById = (id) => {
  return get({
    path: `landlords/${id}`,
  });
};

const createLandlord = (params) => {
  return post({
    path: 'landlords',
    body: params,
  });
};

const checkLandlordConflicts = (params) => {
  return get({
    path: 'landlord-conflicts',
    parameters: params,
  });
};

const searchAllProperties = (search_term) => {
  return get({
    path: 'landlord_properties_search',
    parameters: {
      unassigned: 0,
      search_term,
    },
  });
};

const searchUnassignedProperties = (search_term) => {
  return get({
    path: 'landlord_properties_search',
    parameters: {
      unassigned: 1,
      search_term,
    },
  });
};

// const reviewLandlord = (params) => {
//   const { id, review_status } = params;
//   return patch({
//     path: `review_item/${id}`,
//     review_type_id: REVIEW_TYPE_ID_LANDLORD,
//     review_status: review_status,
//   });
// };

const updateLandlord = (id, params) => {
  return patch({
    path: `landlords/${id}`,
    body: params,
  });
};

const attachProperties = (params) => {
  return post({
    path: 'landlord_properties',
    body: params,
  });
};

const landlordService = {
  getLandlordList,
  getLandlordById,
  createLandlord,
  checkLandlordConflicts,
  updateLandlord,
  attachProperties,
  searchAllProperties,
  searchUnassignedProperties,
};

export default landlordService;
