export const VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  PUBLIC: 'public',
};

export const SIZE_TYPES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const Z_INDEXS = {
  DEFAULT: 0,
  DROPDOWN: 100,
};

export const SORTS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const CONFIRM_TYPES = {
  APPROVE: 'approved',
  DECLINE: 'declined',
};

export const INPUT_TYPES = {
  NUMBER: 'number',
  PASSWORD: 'password',
  TEXT: 'text',
  DATE: 'date',
  TIME: 'time',
  RADIO: 'radio',
};

export const STATUS_TYPES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ON_MARKET: 'ON_MARKET',
  OFF_MARKET: 'OFF_MARKET',
  PENDING: 'PENDING',
};

export const STATUSES = ['ON_MARKET', 'PENDING', 'OFF_MARKET'];

export const MEDIA_TYPES = {
  PHOTO: 'photo',
  VIDEO: 'video',
  TOUR: '360',
};

export const TASK_DEFAULT_TYPE = 'All task types';

export const DEFAULT_ROWS_PER_PAGE = 5;

export const PROPERTIES_PER_PAGE = 100;

export const FILTER_BY_NAMES = {
  NEIGHBORHOODS: 'neighborhoods',
  PROPERTY_STATUS: 'propertystatus',
  SOURCES: 'sources',
  STATES: 'states',
  BEDS: 'beds',
  PETS: 'pets',
  FEES: 'fees',
  LANDLORDS: 'landlords',
  MEDIAS: 'medias',
};

export const NOTE_PRIVACIES = [
  'All Notes',
  'Shared with me',
  'Private',
  'Public',
];

export const PROPERTY_NOTE_TYPE_ID = 1;

export const LANDLORD_NOTE_TYPE_ID = 2;

export const REVIEW_TYPE_ID_PROPERTY = 1;

export const REVIEW_TYPE_ID_LANDLORD = 2;

export const BATHS_OPTIONS = new Array(32).fill().map((_, idx) => idx / 2);

export const PET_CAT_OPTIONS = ['Cats Allowed', 'Cats Negotiable', 'No Cat'];

export const PET_DOG_OPTIONS = [
  'Dogs Allowed',
  'Small Dogs Only',
  'Dogs Negotiable',
  'No Dog',
];

export const UNIT_LEVEL_OPTIONS = [
  'Basement',
  'Garden',
  'Penthouse',
  'Top Floor',
  ...new Array(32).fill().map((_, idx) => idx + 1),
];

export const LAUNDRY_OPTIONS = [
  'Washer/Dryer in Unit',
  'Laundry in Building',
  'Laundry on Site',
  'Washer/Dryer Hookups',
  'Laundry Services',
  'None',
];

export const BUILDING_TYPE_OPTIONS = [
  'Brownstone',
  'Condominium',
  'Multi-Family (1-4 units)',
  'Mid-Rise',
  'Luxury',
];

export const STUDENT_POLICY_OPTIONS = [
  'Graduate Ok',
  'No Student',
  'No Undergrad',
  'Student Ok',
];

export const HEAT_SOURCE_OPTIONS = [
  'Electricity',
  'Gas',
  'Oil',
  'Propane',
  'Solar',
  'Other',
];

export const PARKING_OPTIONS = [
  {
    id: 'AVL',
    value: 'Available',
  },
  { id: 'FOR', value: 'For Rent' },
  { id: 'INC', value: 'Included' },
  { id: 'NON', value: 'None' },
];

export const PARKING_TYPE_OPTIONS = [
  'Covered',
  'Outdoor',
  'Garage',
  'Tandem',
  'Permit',
  'Valet',
  'Street',
];

export const PROPERTY_DATABASE = ['Main Database', 'My Book of Business'];

export const MOVE_COST_SECURITY_TYPE_OPTIONS = [
  {
    id: '0',
    value: 'None',
  },
  { id: '0.25x', value: 'Quarter Rent' },
  { id: '0.5x', value: 'Half Rent' },
  { id: '1x', value: '1x Rent' },
  { id: '1.5x', value: '1.5x Rent' },
  { id: '2x', value: '2x Rent' },
  { id: 'F', value: 'Fixed Cost' },
];

export const REVIEW_TYPES = {
  PROPERTY: 1,
  LANDLORD: 2,
};

export const MEDIA_FILTERS = ['photo', 'video', 'virtual tour'];

export const MEDIA_FORMATS = {
  photo: 'Photo',
  video: 'Video',
  'virtual tour': 'Virtual Tour',
};
