import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import PropertyDetailPage from 'pages/PropertyDetail';

const ClientURLIndividual = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <PropertyDetailPage isPublic />
    </div>
  );
};

export default ClientURLIndividual;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
  }),
);
