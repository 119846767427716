import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  type: 'primary',
  palette: {
    background: {
      default: '#FFFFFF',
      black: '#222828',
      white: '#FEFEFE',
      green: '#00AA7F',
      red: '#DF5050',
      yellow: '#F0E938',
    },
    grey: {
      100: '#3C494B',
      300: '#728385',
      A100: '#C3C6C7',
      A200: '#F1F4F4',
      A400: '#F4F7F8',
      A700: '#EBFFFE',
    },
    text: {
      primary: '#222828',
      secondary: '#FEFEFE',
    },
    action: {
      active: '#457c79',
      hover: '#52908D',
      disabled: '#C3C6C7',
    },
  },
});

export default theme;
