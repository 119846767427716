import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router';

import PropertyList from './components/PropertyList';

const EditClientURLPage = () => {
  const classes = useStyles();
  const { id } = useParams();

  return (
    <div className={classes.container}>
      <PropertyList clientId={id} />
    </div>
  );
};

export default EditClientURLPage;

const useStyles = makeStyles((theme) => createStyles({}));
