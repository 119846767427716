import React from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { STATUS_TYPES } from 'utils/constants';

export const StatusMark = ({
  className,
  size,
  status = STATUS_TYPES.INACTIVE,
}) => {
  const classes = useStyles({ size, status });

  return <div className={classNames(classes.container, className)} />;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: (props) => props.size || 20,
      height: (props) => props.size || 20,
      border: (props) =>
        `1px solid ${
          props.status === STATUS_TYPES.ACTIVE ||
          props.status === STATUS_TYPES.ON_MARKET
            ? theme.palette.background.green
            : props.status === STATUS_TYPES.PENDING
            ? theme.palette.background.yellow
            : props.status === STATUS_TYPES.OFF_MARKET
            ? theme.palette.background.red
            : theme.palette.background.white
        }`,
      borderRadius: '50%',
      background: (props) =>
        props.status === STATUS_TYPES.ACTIVE ||
        props.status === STATUS_TYPES.ON_MARKET
          ? theme.palette.background.green
          : props.status === STATUS_TYPES.PENDING
          ? theme.palette.background.yellow
          : props.status === STATUS_TYPES.OFF_MARKET
          ? theme.palette.background.red
          : theme.palette.background.white,
    },
  }),
);
