import React from 'react';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { H1 } from 'components/Typography';
import ClientUrl from './components/ClientUrl';
import PendingReview from './components/PendingReview';
import TaskList from './components/TaskList';

const useStyles = makeStyles((theme) =>
  createStyles({
    homePage: {
      padding: 30,
      [theme.breakpoints.down('xs')]: {
        padding: 20,
      },
    },
  }),
);

const HomePage = () => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.authReducer);

  return (
    <div className={classes.homePage}>
      <H1>
        Welcome Home, {user?.first_name} {user?.last_name}
      </H1>
      <TaskList />
      <ClientUrl />
      <PendingReview />
    </div>
  );
};

export default HomePage;
