import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import propertyImage from 'assets/images/propertyImg.jpg';
import { Box } from '@material-ui/core';
import { H4 } from 'components/Typography';

const Item = ({ property }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <img src={propertyImage} alt="property" className={classes.image} />
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <H4 display="block" bold>
            {property.concept_property_id}
          </H4>
          <H4 display="block" bold>
            $ {property.rent}
          </H4>
        </Box>
        <div>
          <H4 display="block">{property.address}</H4>
        </div>
      </div>
    </div>
  );
};

export default Item;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: 280,
      height: 74,
      padding: 10,
      background: theme.palette.background.white,
      marginLeft: 10,
      marginRight: 10,
      display: 'flex',
    },
    image: {
      width: 80,
      height: 54,
      marginRight: 10,
      userSelect: 'non',
    },
  }),
);
