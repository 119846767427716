import React, { useRef } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-elastic-carousel';
import cx from 'classnames';
import { H5 } from 'components/Typography';
import Link from 'components/Link';
import { StatusMark } from 'components/StatusMark';
import { STATUS_TYPES } from 'utils/constants';
import { useHistory } from 'react-router';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { ReactComponent as RightIcon } from 'assets/icons/rvector.svg';

const PropertyItem = ({
  property,
  isShowsheetItem = false,
  hiddenRemove = false,
  handleRemove,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const carousel = useRef();

  const handleClick = () => {
    history.push(`/public_property/${property.concept_property_id}`);
  };

  const handleNextClick = () => {
    carousel.current.slideNext();
  };

  return (
    <div className={classes.container}>
      <Carousel
        itemsToShow={1}
        showArrows={false}
        pagination={false}
        ref={carousel}
      >
        <div style={{ width: '100%' }}>
          <div className={cx(classes.area1, classes.area)}>
            <H5 display="block" bold className={classes.line}>
              {property.address}
            </H5>
            <div className={classes.line}>
              <H5 bold className={classes.rightSpace}>
                ID
              </H5>
              <H5>{property.concept_property_id}</H5>
            </div>
            <div className={classes.line}>
              <H5 className={classes.rightSpace}>Rent</H5>
              <H5 bold className={classes.rightSpace}>
                ${Number(property.rent)}
              </H5>
              <H5 className={classes.rightSpace}>Fee:</H5>
              <H5 bold>{property.fee_information}</H5>
            </div>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <div className={cx(classes.area2, classes.area)}>
            <H5 bold display="block" className={classes.line}>
              {property.landlord_name}
            </H5>
            <H5 display="block" className={classes.line}>
              180 Eastern Ave.
            </H5>
            <H5 display="block" className={classes.line}>
              (312) 233-2323
            </H5>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <div className={cx(classes.area3, classes.area)}>
            <div className={classes.line}>
              <H5 className={classes.rightSpace}>Building type:</H5>
              <H5 bold>{property.building_type}</H5>
              <H5 className={classes.rightSpace}>Beds:</H5>
              <H5 className={classes.rightSpace} bold>
                {Number(property.beds)}
              </H5>
              <H5 className={classes.rightSpace}>Baths:</H5>
              <H5 className={classes.rightSpace} bold>
                {Number(property.baths)}
              </H5>
            </div>
            <div className={classes.line}>
              <H5 className={classes.rightSpace}>Square Footage:</H5>
              <H5 className={classes.rightSpace} bold>
                {Number(property.square_footage)}
              </H5>
              <H5 className={classes.rightSpace}>Parking:</H5>
              <H5 className={classes.rightSpace} bold>
                {property.parking}
              </H5>
            </div>
            <div className={classes.line}>
              <H5 className={classes.rightSpace}>Utilities Included:</H5>
              <H5 className={classes.rightSpace} bold>
                {property.include_heat ? 'Heat' : ''}{' '}
                {property.include_hot_water ? 'Hot Water' : ''}{' '}
                {property.include_gas ? 'Gas' : ''}{' '}
                {property.include_electricity ? 'Electricity' : ''}
              </H5>
            </div>
            <div className={classes.line}>
              <H5 className={classes.rightSpace}>Pet policy:</H5>
              <H5 className={classes.rightSpace} bold>
                {property.pet_policy}
              </H5>
            </div>
          </div>
        </div>

        {!isShowsheetItem && (
          <div style={{ width: '100%' }}>
            <div className={cx(classes.area4, classes.area)}>
              <Link onClick={handleClick}>https://ygl.is/2tb4/83726542</Link>
            </div>
          </div>
        )}

        {isShowsheetItem && (
          <div style={{ width: '100%' }}>
            <div className={cx(classes.area4, classes.area)}>
              <div className={classes.line}>
                <H5 className={classes.rightSpace} bold>
                  Showing Information
                </H5>
                <H5 className={classes.rightSpace}>
                  {property.showing_information}
                </H5>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className={cx(classes.area5, classes.area)}>
            <div style={{ display: 'flex' }} className={classes.line}>
              <StatusMark
                status={STATUS_TYPES.ACTIVE}
                className={classes.rightSpace}
              />
              <H5 bold>{property.status}</H5>
            </div>
            <H5 display="block">{property.available_date}</H5>
          </div>
          <div className={cx(classes.area5, classes.remove)}>
            <RemoveIcon
              onClick={() => handleRemove(property.concept_property_id)}
            />
          </div>
        </div>
      </Carousel>
      <div className={classes.nextBtn}>
        <RightIcon onClick={handleNextClick} />
      </div>
    </div>
  );
};

export default PropertyItem;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: 130,
      padding: '20px 30px',
      borderLeft: `8px solid ${theme.palette.action.active}`,
      borderRadius: 5,
      backgroundColor: theme.palette.background.white,
      marginBottom: 15,
      overflowX: 'auto',
      overflowY: 'hidden',
      [theme.breakpoints.down('xs')]: {
        padding: 10,
        height: 130,
      },
    },
    area1: {
      minWidth: 380,
    },
    area2: {
      minWidth: 180,
    },
    area3: {
      minWidth: 480,
    },
    area4: {
      minWidth: 340,
    },
    area5: {
      minWidth: 100,
    },
    remove: {
      padding: '25px 30px',
    },
    line: {
      marginBottom: 10,
    },
    rightSpace: {
      marginRight: 10,
    },
    nextBtn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
  }),
);
