import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { H1, H5 } from 'components/Typography';
import { Pagination } from 'components/Pagination';
import { DropdownContent } from 'components/Dropdown';
import { unPinLandlord } from 'store/landlordStore';

import { ReactComponent as UnPinnedIcon } from 'assets/icons/whitePin.svg';

const PinnedContacts = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [unPinnedButtonId, setUnPinnedButtonId] = useState(null);

  const handleUnPinLandlord = (e, id) => {
    e.stopPropagation();
    dispatch(unPinLandlord(id));
  };

  return (
    <div className={classes.container}>
      <Box margin="15px 0">
        <H1>Pinned contacts</H1>
      </Box>
      <Box display="flex" flexDirection="column" gridRowGap={8}>
        {data.slice((page - 1) * 7, page * 7).map((item) => (
          <Box key={item.landlord_id} position="relative">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height={70}
              gridRowGap={10}
              className="cursor-pointer"
              onClick={() => setUnPinnedButtonId(item.landlord_id)}
            >
              <H5 bold>{item.landlord_name}</H5>
              <H5>{item.address}</H5>
            </Box>
            {item.landlord_id === unPinnedButtonId && (
              <DropdownContent
                customStyles={classes.unPinnedDropdown}
                handleClickAway={() => setUnPinnedButtonId(null)}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  position="relative"
                  width={1}
                >
                  <Box
                    className={classes.unPinnedContainer}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    height={70}
                    gridRowGap={10}
                    flex={1}
                  >
                    <H5 bold>{item.landlord_name}</H5>
                    <H5 className="single-line-text">{item.address}</H5>
                  </Box>
                  <Box
                    className={classes.unPinnedButton}
                    onClick={(e) => handleUnPinLandlord(e, item.landlord_id)}
                  >
                    <UnPinnedIcon width={20} height={20} />
                    <H5 bold white>
                      Unpin
                    </H5>
                  </Box>
                </Box>
              </DropdownContent>
            )}
          </Box>
        ))}
      </Box>

      <div className={classes.footer}>
        <Pagination
          page={page}
          setPage={setPage}
          lastPage={Math.ceil(data.length / 7)}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      background: theme.palette.background.white,
      borderRadius: 5,
      padding: '1px 20px',
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: 0,
      height: 55,
    },
    unPinnedDropdown: {
      left: -100,
      right: 0,
      top: 0,
      transform: 'translate(0, 0)',
      [theme.breakpoints.down('xs')]: {
        left: -35,
      },
    },
    unPinnedContainer: {
      padding: '0 20px',
      maxWidth: 'calc(100% - 100px)'
    },
    unPinnedButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: 8,
      background: theme.palette.action.active,
      width: 100,
      height: 70,
      cursor: 'pointer',
    },
  }),
);

export default PinnedContacts;
