import React from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const LinkContent = ({ children, href = '#', onClick = () => {} }) => {
  const classes = useStyles();

  return (
    <Link to={href} onClick={onClick} className={classes.link}>
      {children}
    </Link>
  );
};

export default LinkContent;

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      color: theme.palette.action.active,
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 700,
      textDecoration: 'none',
    },
  }),
);
