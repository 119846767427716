import { get, patch, post } from 'utils/api';

const getPublicAgent = (userId) => {
  return get({
    path: `public_agents/${userId}`,
  });
};

const updateAgent = (userId, body) => {
  return patch({
    path: `agents/${userId}`,
    body,
  });
};

const getAgentList = () => {
  return get({
    path: `agents`,
  });
};

const getAgentById = (id) => {
  return get({
    path: `agents/${id}`,
  });
};

const createAgent = (params) => {
  return post({
    path: 'register',
    body: params,
  });
};

const getTeamMembers = (user_id) => {
  return get({
    path: `team_members`,
    parameters: { user_id },
  });
};

const updateTeamMembers = (body) => {
  return patch({
    path: `team_members`,
    body,
  });
};
const checkLandlordConflicts = (params) => {
  return get({
    path: 'landlord-conflicts',
    parameters: params,
  });
};

const attachProperties = (params) => {
  return post({
    path: 'landlord_properties',
    body: params,
  });
};

const agentService = {
  getPublicAgent,
  updateAgent,
  getAgentList,
  getAgentById,
  createAgent,
  checkLandlordConflicts,
  attachProperties,
  getTeamMembers,
  updateTeamMembers,
};

export default agentService;
