import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { VARIANTS } from 'utils/constants';

import Header from './components/Header';
import SideBar from './components/SideBar';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: '100vh',
      overflow: 'hidden',
    },
    content: {
      position: 'absolute',
      width: '100%',
      height: 'calc(100% - 70px)',
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100% - 50px)',
      },
    },
    mainContent: {
      overflow: 'auto',
      width: 'calc(100% - 100px)',
      height: '100%',
      marginLeft: 100,
      marginBottom: 0,
      background: theme.palette.grey.A400,
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 68px)',
        marginLeft: 68,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: 'calc(100% - 68px)',
        marginLeft: 0,
      },
      '&::-webkit-scrollbar': {
        width: 12,
        background: theme.palette.background.white,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
    secondaryContainer: {
      height: '100vh',
      padding: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.grey.A200,
    },
    publicContent: {
      position: 'absolute',
      width: '100%',
      height: 'calc(100% - 70px)',
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100% - 50px)',
      },
    },
    publicMainContent: {
      width: '100%',
      height: '100%',
      marginBottom: 0,
      background: theme.palette.grey.A400,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: 'calc(100% - 68px)',
        marginLeft: 0,
      },
    },
  }),
);

const Layout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();

  const [variant, setVariant] = useState(VARIANTS.PRIMARY);

  useEffect(() => {
    if (
      location.pathname.includes('login') ||
      location.pathname.includes('reset_password_request') ||
      location.pathname.includes('reset-password')
    ) {
      setVariant(VARIANTS.SECONDARY);
    } else {
      setVariant(VARIANTS.PRIMARY);
    }
    if (
      location.pathname.includes('public_client_url') ||
      location.pathname.includes('public_property')
    ) {
      setVariant(VARIANTS.PUBLIC);
    }
  }, [location.pathname]);

  if (variant === VARIANTS.SECONDARY) {
    return <div className={classes.secondaryContainer}>{children}</div>;
  }

  if (variant === VARIANTS.PUBLIC) {
    return (
      <div className={classes.container}>
        <Header isPublic />
        <div className={classes.publicContent}>
          <div className={classes.publicMainContent}>{children}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.content}>
        <SideBar />
        <div className={classes.mainContent}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
