import React from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { H5 } from 'components/Typography';

import DetailsInfo from './components/DetailsInfo';
import Interactions from './components/Interactions';
import OwnedProperties from './components/OwnedProperties';

import { getLandlordById } from 'store/landlordStore';

import { ReactComponent as FlagMarkIcon } from 'assets/icons/flagMark.svg';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

const LandlordDetail = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data } = useSelector((state) => state.landlordReducer);

  React.useEffect(() => {
    if (id) {
      dispatch(getLandlordById(id));
    }
  }, [dispatch, id]);

  const goBackToList = () => {
    history.push('/landlord');
  };

  return (
    <div className={classes.container}>
      <Box display="flex" justifyContent="space-between" mb={2.5}>
        <H5 onClick={goBackToList} className={classes.back}>
          Back to Landlord List
        </H5>
        {data?.review_pending === 1 && (
          <Box display="flex" alignItems="center">
            <H5 display={'inline'} className={classes.mr20}>
              Pending for review
            </H5>
            <FlagMarkIcon />
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        // style={{ height: '100%' }}
        className={classes.main}
      >
        <DetailsInfo data={data} />
        <Interactions landlord_id={id} />
        <OwnedProperties landlord_id={id} />
      </Box>
    </div>
  );
};

export default LandlordDetail;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: '50px 30px 30px 30px',
      // height: '100%',
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        padding: 20,
      },
    },
    main: {
      gap: 20,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: 20,
      },
    },
    back: {
      cursor: 'pointer',
    },
    mr20: {
      marginRight: 20,
    },
  }),
);
