import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import reviewService from 'services/reviewService';

export const getReviews = createAsyncThunk(
  'reviewSlice/getReviews',
  async () => {
    const response = await reviewService.getReviews();
    return response;
  },
);

export const reviewItem = createAsyncThunk(
  'reviewSlice/reviewItem',
  async (payload) => {
    const { id, reviewTypeId, reviewStatus, success } = payload;
    const response = await reviewService.reviewItem(
      id,
      reviewTypeId,
      reviewStatus,
    );
    success();
    return response;
  },
);

const initialState = {
  reviews: [],
  loading: false,
  reviewsLoading: false,
};

const reviewSlice = createSlice({
  name: 'reviewSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getReviews.fulfilled]: (state, action) => {
      state.reviews = action.payload;
      state.reviewsLoading = false;
    },
    [getReviews.pending]: (state) => {
      state.reviewsLoading = true;
    },
    [getReviews.rejected]: (state) => {
      state.reviewsLoading = false;
    },
    [reviewItem.fulfilled]: (state, action) => {
      state.reviews = [...state.reviews].filter(
        (item) =>
          item.review_item_id !== action.payload.reviewitem.review_item_id,
      );
    },
  },
});

export default reviewSlice.reducer;
