import React, { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';

import { H5 } from 'components/Typography';
import { DropdownContent } from 'components/Dropdown';
import Notifications from './Notifications';
import { Z_INDEXS } from 'utils/constants';
import { logout } from 'store/authStore';

import projectLogo from 'assets/projectLogo.svg';
import defaultAvatar from 'assets/defaultAvatar.svg';
import bellIcon from 'assets/icons/bell.svg';
import unreadBellIcon from 'assets/icons/unread_bell.svg';
// import settingIcon from 'assets/icons/setting.svg';
// import helpIcon from 'assets/icons/help.svg';
import arrowFillWhiteDownIcon from 'assets/icons/arrowFillWhiteDown.svg';
import { getNotifications } from 'store/notificationStore';

// const SETTINGS = ['Manage Tags', 'Default Cities'];

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: 70,
      width: '100%',
      background: theme.palette.grey[100],
      padding: '0 20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        height: 50,
      },
    },
    menuContent: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 20,
      '& img': {
        cursor: 'pointer',
      },
    },
    projectLogo: {
      width: 190,
      height: 50,
      marginLeft: 100,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
      [theme.breakpoints.down('xs')]: {
        width: 114,
        height: 30,
      },
    },
    profile: {
      padding: '0 20px',
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
      cursor: 'pointer',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    avatar: {
      width: 50,
      height: 50,
      borderRadius: '50%',
      objectFit: 'cover',
      [theme.breakpoints.down('xs')]: {
        width: 30,
        height: 30,
      },
    },
    menuIcon: {
      position: 'relative',
      height: 50,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        height: 30,
      },
    },
    dropwdown: {
      padding: '8px 0',
      display: 'flex',
      flexDirection: 'column',
      '& > span': {
        fontSize: 16,
        padding: '8px 15px',
        lineHeight: '22px',
        color: theme.palette.text.primary,
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
        },
      },
    },
    notificationDropdown: {
      left: 'auto',
      right: 20,
      top: 85,
      transform: 'translate(0, 0)',
    },
    mobileNotifications: {
      position: 'absolute',
      width: '100vw',
      display: 'none',
      left: '100%',
      top: 0,
      zIndex: Z_INDEXS.DROPDOWN,
      transition: 'all 0.8s ease-in-out',
      '&.open': {
        left: '0',
        display: 'block',
      },
    },
    dropdownProfile: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
      margin: '0 15px',
      paddingTop: 8,
      paddingBottom: 15,
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
    },
    userName: {
      textTransform: 'capitalize',
    },
  }),
);

const Header = ({ isPublic }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const { user } = useSelector((state) => state.authReducer);

  // const [isOpenSetting, setIsOpenSetting] = useState(false);
  const [isOpenAccountSetting, setIsOpenAccountSetting] = useState(false);
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const { notifications } = useSelector((state) => state.notificationReducer);
  const location = useLocation();

  React.useEffect(() => {
    let interval;
    if (!location.pathname.includes('public') && user) {
      dispatch(
        getNotifications({
          pnum: 1,
          per_page: 20,
          user_id: user.user_id,
        }),
      );
      interval = setInterval(() => {
        dispatch(
          getNotifications({
            pnum: 1,
            per_page: 20,
            user_id: user.user_id,
          }),
        );
      }, 60 * 1000);
    }

    return () => clearInterval(interval);
  }, [dispatch, location.pathname, user]);

  const handleLogout = () => {
    setIsOpenAccountSetting(false);
    dispatch(
      logout({
        success: () => {
          history.push('/login');
        },
      }),
    );
  };

  const handleProfile = () => {
    setIsOpenAccountSetting(false);
    console.log('location', location.pathname);
    history.push({
      pathname: '/profile',
      state: { prevPath: location.pathname },
    });
  };

  const hasUnreadNotification = useMemo(() => {
    if (notifications.length === 0) {
      return false;
    }

    return notifications.reduce((a, b) => a || !b.has_been_read, false);
  }, [notifications]);

  return (
    <div className={classes.container}>
      <img
        onClick={() => {
          history.push('/');
        }}
        className={classes.projectLogo}
        src={projectLogo}
        alt="project-logo"
      />

      {!isPublic && (
        <div className={classes.menuContent}>
          <Box display="flex" alignItems="center">
            <img
              src={hasUnreadNotification ? unreadBellIcon : bellIcon}
              alt="notification"
              onClick={() => setIsOpenNotification(true)}
            />
            {isOpenNotification && !isMobile && (
              <>
                <DropdownContent
                  customStyles={classes.notificationDropdown}
                  handleClickAway={() => setIsOpenNotification(false)}
                >
                  <Notifications />
                </DropdownContent>
              </>
            )}
            {isMobile && (
              <div
                className={classNames(classes.mobileNotifications, {
                  open: isOpenNotification,
                })}
              >
                <Notifications
                  handleClose={() => setIsOpenNotification(false)}
                />
              </div>
            )}
          </Box>
          {/* <div className={classes.menuIcon}>
            <img
              src={settingIcon}
              alt="setting"
              onClick={() => setIsOpenSetting(true)}
            />
            {isOpenSetting && (
              <>
                <DropdownContent
                  handleClickAway={() => setIsOpenSetting(false)}
                >
                  <div className={classes.dropwdown}>
                    {SETTINGS.map((item, index) => (
                      <span key={index}>{item}</span>
                    ))}
                  </div>
                </DropdownContent>
              </>
            )}
          </div> */}
          <div
            className={classes.profile}
            onClick={() =>
              !isOpenAccountSetting && setIsOpenAccountSetting(true)
            }
          >
            <img
              className={classes.avatar}
              src={user?.photo_url || defaultAvatar}
              alt="avatar"
            />
            {!isMobile && (
              <>
                <H5 className={classes.userName} white>
                  {user?.first_name} {user?.last_name}
                </H5>
                <img src={arrowFillWhiteDownIcon} alt="dropdown" />
              </>
            )}
            {isOpenAccountSetting && (
              <>
                <DropdownContent
                  handleClickAway={() => setIsOpenAccountSetting(false)}
                >
                  <div className={classes.dropwdown}>
                    {isMobile && (
                      <div className={classes.dropdownProfile}>
                        <img
                          className={classes.avatar}
                          src={user?.photo_url || defaultAvatar}
                          alt="avatar"
                        />
                        <H5 className={classes.userName} bold>
                          {user?.first_name} {user?.last_name}
                        </H5>
                      </div>
                    )}
                    <span onClick={handleProfile}>User Profile</span>
                    <span onClick={handleLogout}>Logout</span>
                  </div>
                </DropdownContent>
              </>
            )}
          </div>
          {/* {!isMobile && <img src={helpIcon} alt="help" />} */}
        </div>
      )}
    </div>
  );
};

export default Header;
