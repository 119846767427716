import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button } from 'components/Buttons';
import { VARIANTS, SIZE_TYPES } from 'utils/constants';
import { Typography } from '@material-ui/core';

export const Modal = ({
  open,
  children,
  className,
  onClick,
  variant = VARIANTS.PRIMARY,
  size = SIZE_TYPES.MEDIUM,
  width,
  maxWidth = 'md',
  fullWidth = false,
  title,
  handleConfirm,
  handleCancel,
  handleClear,
  handleClose,
  ...props
}) => {
  const classes = useStyles({ variant, size, width });
  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      className={classes.dialogContainer}
    >
      <div className={classes.modalTitle}>
        <Typography component="h4" variant="h6">
          {title}
        </Typography>
        {handleClose ? (
          <CloseIcon className={classes.closeButton} onClick={handleClose} />
        ) : null}
      </div>
      <Divider className={classes.titleDivider} />
      <DialogContent className={classes.modalContent}>{children}</DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="secondary" onClick={handleClear}>
          Clear
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogContainer: {
      '& .MuiPaper-root': {
        maxWidth: 1080,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
          position: 'fixed',
          bottom: 0,
          margin: 0,
          height: '90%',
        },
      },
    },
    modalTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '30px 50px 10px 30px',
    },
    closeButton: {
      position: 'absolute',
      right: 50,
      top: 30,
    },
    titleDivider: {
      marginLeft: 30,
      marginRight: 50,
    },
    modalContent: {
      width: '100%',
      height: 552,
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        width: 4,
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.grey.A100,
        borderRadius: 2,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.action.active,
        borderRadius: 2,
        '&:hover': {
          background: theme.palette.action.hover,
        },
      },
    },
    groupChar: {
      fontWeight: 'bold',
      fontSize: 20,
      lineHeight: '27px',
      color: '#222828',
    },
    actions: {
      '&.MuiDialogActions-root': {
        justifyContent: 'flex-start',
        padding: 30,
        [theme.breakpoints.down('xs')]: {
          justifyContent: 'center',
        },
      },
    },
  }),
);
