import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import cityService from 'services/cityService';

export const getCities = createAsyncThunk('citySlice/getCities', async () => {
  const response = await cityService.getCities();
  return response;
});

const initialState = {
  cities: [],
  loading: false,
};

const citySlice = createSlice({
  name: 'citySlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getCities.fulfilled]: (state, action) => {
      state.cities = action.payload;
      state.loading = false;
    },
    [getCities.pending]: (state) => {
      state.loading = true;
    },
    [getCities.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default citySlice.reducer;
