import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';

import Layout from 'components/Layout';

import HomePage from 'pages/Home';
import PropertyPage from 'pages/Property';
import LandlordPage from 'pages/Landlord';
import AgentPage from 'pages/Agent';
import LoginPage from 'pages/Login';
import PublicPropertyList from 'pages/ClientUrlList';
import PublicProperty from 'pages/ClientUrlIndividual';
import PropertyDetailPage from 'pages/PropertyDetail';
import LandlordDetailPage from 'pages/LandlordDetail';
import AgentDetailPage from 'pages/AgentDetail';
import ShowSheetPage from 'pages/ShowSheets';
import ProfilePage from 'pages/Profile';
import ResetPasswordRequestPage from 'pages/ResetPasswordRequest';
import ResetPasswordPage from 'pages/ResetPassword';
import theme from 'styles/theme';
import { getProfile } from 'store/authStore';
import { getPropertyFilters, getTags, getFeatures } from 'store/propertyStore';
import { getCities } from 'store/cityStore';
import { getLandlordList } from 'store/landlordStore';
import { FILTER_BY_NAMES } from 'utils/constants';

import { PrivateRoute } from './privateRoute';
import EditClientURLPage from 'pages/EditClientUrl';

const Routes = () => {
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (token) {
      dispatch(getPropertyFilters(FILTER_BY_NAMES.NEIGHBORHOODS));
      dispatch(getPropertyFilters(FILTER_BY_NAMES.PROPERTY_STATUS));
      dispatch(getPropertyFilters(FILTER_BY_NAMES.SOURCES));
      dispatch(getPropertyFilters(FILTER_BY_NAMES.STATES));
      dispatch(getPropertyFilters(FILTER_BY_NAMES.BEDS));
      dispatch(getPropertyFilters(FILTER_BY_NAMES.PETS));
      dispatch(getPropertyFilters(FILTER_BY_NAMES.FEES));
      dispatch(getPropertyFilters(FILTER_BY_NAMES.LANDLORDS));
      dispatch(getPropertyFilters(FILTER_BY_NAMES.MEDIAS));
      dispatch(getLandlordList());
      dispatch(getTags());
      dispatch(getFeatures());
      dispatch(getCities());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (token && !user) {
      dispatch(getProfile());
    }
  }, [token, user, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Switch>
          <PrivateRoute exact path="/" component={HomePage} />
          <Route exact path="/login" component={LoginPage} />
          <Route
            exact
            path="/reset_password_request"
            component={ResetPasswordRequestPage}
          />
          <Route path="/reset-password" component={ResetPasswordPage} />
          <PrivateRoute exact path="/property" component={PropertyPage} />
          <PrivateRoute
            exact
            path="/property/:id"
            component={PropertyDetailPage}
          />
          <PrivateRoute exact path="/landlord" component={LandlordPage} />
          <PrivateRoute
            exact
            path="/landlord/:id"
            component={LandlordDetailPage}
          />
          <PrivateRoute exact path="/agent" component={AgentPage} />
          <PrivateRoute exact path="/agent/:id" component={AgentDetailPage} />
          <Route
            path="/public_client_url_list"
            component={PublicPropertyList}
          />
          <Route path="/public_property/:id" component={PublicProperty} />
          <PrivateRoute path="/edit_client/:id" component={EditClientURLPage} />
          <PrivateRoute path="/showsheets" component={ShowSheetPage} />
          <PrivateRoute path="/profile" component={ProfilePage} />
          <Redirect to="/" />
        </Switch>
      </Layout>
    </ThemeProvider>
  );
};

export default Routes;
