import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';
import date from 'date-and-time';

import { H4, H5 } from 'components/Typography';
import { Button } from 'components/Buttons';
import { EnhancedTable, EnhancedMobileTable } from 'components/Table';
import { Pagination } from 'components/Pagination';
import {
  CONFIRM_TYPES,
  DEFAULT_ROWS_PER_PAGE,
  SORTS,
  REVIEW_TYPES,
} from 'utils/constants';
import { getReviews } from 'store/reviewStore';

import HomeTableHeader from './HomeTableHeader';
import ReviewModal from '../modals/ReviewModal';
import ReviewConfirmModal from '../modals/ReviewConfirmModal';

const Task_Headers = [
  {
    label: 'REVIEW APPROVAL',
  },
  {
    label: 'AGENT',
  },
  {
    label: 'REQUEST DATE',
  },
  {
    label: '',
  },
];

const checkOrder = (a, b, order) => {
  if ((a?.toLowerCase() || '') < (b?.toLowerCase() || '')) {
    if (order === SORTS.DESC) {
      return 1;
    }
    return -1;
  } else if ((a || '') > (b || '')) {
    if (order === SORTS.DESC) {
      return -1;
    }
    return 1;
  } else {
    return 0;
  }
};

const PendingReview = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const dispatch = useDispatch();
  const { reviews, reviewsLoading } = useSelector(
    (state) => state.reviewReducer,
  );
  const { user } = useSelector((state) => state.authReducer);

  const [tableData, setTableData] = useState([]);
  const [mobileTableData, setMobileTableData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isSelectAll, setIsSectAll] = useState(false);
  const [order, setOrder] = useState(SORTS.DESC);
  const [orderBy, setOrderBy] = useState('REVIEW APPROVAL');
  const [page, setPage] = useState(1);
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);
  const [isOpenReviewConfirmModal, setIsOpenReviewConfirmModal] =
    useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [confirmType, setConfirmType] = useState(null);

  useEffect(() => {
    dispatch(getReviews());
  }, [dispatch]);

  useEffect(() => {
    let newData = [];
    let newMobileData = [];
    if (reviews && reviews.length) {
      const data = [...reviews].sort((a, b) => {
        if (orderBy === 'REVIEW APPROVAL') {
          return checkOrder(
            a.review_type_id === REVIEW_TYPES.PROPERTY
              ? a.new_property_address
              : a.new_landlord_name,
            b.review_type_id === REVIEW_TYPES.PROPERTY
              ? b.new_property_address
              : b.new_landlord_name,
            order,
          );
        } else if (orderBy === 'AGENT') {
          return checkOrder(a.user, b.user, order);
        } else if (orderBy === 'REQUEST DATE') {
          if (
            new Date(a.request_date).getTime() >
            new Date(b.request_date).getTime()
          ) {
            if (order === SORTS.DESC) {
              return -1;
            }
            return 1;
          } else {
            if (order === SORTS.DESC) {
              return 1;
            }
            return -1;
          }
        }
        return 0;
      });
      data.forEach((row) => {
        const requestDate = new Date(row.request_date);
        const newDataItem = {
          id: row.review_item_id,
          cells: [
            {
              cell: (
                <H4 className="cursor-pointer">
                  {row.review_type_id === REVIEW_TYPES.PROPERTY
                    ? row.new_property_address
                    : row.new_landlord_name}
                </H4>
              ),
            },
            {
              cell: <H4>{row.user}</H4>,
            },
            {
              cell: <H4>{date.format(requestDate, 'MMM DD, YYYY')}</H4>,
            },
            {
              align: 'right',
              cell: (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    className={classes.reviewButton}
                    onClick={() => handleOpenReviewModal(row)}
                  >
                    <H5 bold white>
                      Review
                    </H5>
                  </Button>
                </Box>
              ),
            },
          ],
        };
        newData.push(newDataItem);
        const newMobileDataItem = {
          id: row.review_item_id,
          title: (
            <Box display="flex" flexDirection="column" gridRowGap={5}>
              <H4>{row.title}</H4>
              <Box display="flex" alignItems="center">
                <Button
                  className={classes.reviewButton}
                  onClick={() => handleOpenReviewModal(row)}
                >
                  <H5 bold white>
                    Review
                  </H5>
                </Button>
              </Box>
            </Box>
          ),
          content: (
            <Box display="flex" flexDirection="column" gridRowGap={10}>
              <Box display="flex" gridColumnGap={20}>
                <Box flex={1}>
                  <H4 bold>User:</H4>
                </Box>
                <Box flex={1}>
                  <H4>{row.user}</H4>
                </Box>
              </Box>
              <Box display="flex" gridColumnGap={20}>
                <Box flex={1}>
                  <H4 bold>Request date:</H4>
                </Box>
                <Box flex={1}>
                  <H4>{date.format(requestDate, 'MMM DD, YYYY')}</H4>
                </Box>
              </Box>
            </Box>
          ),
        };
        newMobileData.push(newMobileDataItem);
      });
    }
    setTableData(newData);
    setMobileTableData(newMobileData);
  }, [reviews, order, classes, orderBy]);

  const handleSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else {
      newSelected = selected.filter((item) => item !== id);
    }
    setSelected(newSelected);
    if (isSelectAll) {
      setIsSectAll(false);
    }
  };

  const handleSelectAll = () => {
    let newSelected = [];
    if (isSelectAll) {
      setIsSectAll(false);
    } else {
      newSelected = tableData.map((row) => row.id);
      setIsSectAll(true);
    }
    setSelected(newSelected);
  };

  const handleDeleteItems = () => {
    setSelected([]);
    setIsSectAll(false);
  };

  const handleChangeOrder = (property) => {
    setPage(1);
    setOrder(order === SORTS.DESC ? SORTS.ASC : SORTS.DESC);
  };

  const handleChangeOrderBy = (property) => {
    setPage(1);
    setOrderBy(property);
  };

  const handleOpenReviewModal = (item) => {
    setSelectedReview(item);
    setIsOpenReviewModal(true);
  };

  const handleOpenApproveReviewModal = () => {
    setIsOpenReviewModal(false);
    setConfirmType(CONFIRM_TYPES.APPROVE);
    setIsOpenReviewConfirmModal(true);
  };

  const handleOpenDeclineReviewModal = () => {
    setIsOpenReviewModal(false);
    setConfirmType(CONFIRM_TYPES.DECLINE);
    setIsOpenReviewConfirmModal(true);
  };

  const handleCloseReviewModal = () => {
    setSelectedReview(null);
    setIsOpenReviewModal(false);
  };

  const handleCloseReviewConfirmModal = () => {
    setIsOpenReviewConfirmModal(false);
    setSelectedReview(null);
    setConfirmType(null);
  };

  if (user?.role_id === 3) return null;

  return (
    <div className={classes.container}>
      <HomeTableHeader
        title="Pending Review"
        selected={selected}
        isSelectAll={isSelectAll}
        handleSelectAll={handleSelectAll}
        handleDeleteItems={handleDeleteItems}
      />
      {isMobile ? (
        <EnhancedMobileTable
          rows={mobileTableData.slice(
            (page - 1) * DEFAULT_ROWS_PER_PAGE,
            page * DEFAULT_ROWS_PER_PAGE,
          )}
          enableSelect={true}
          selected={selected}
          handleSelect={handleSelect}
          loading={reviewsLoading}
        />
      ) : (
        <EnhancedTable
          headCells={Task_Headers}
          rows={tableData.slice(
            (page - 1) * DEFAULT_ROWS_PER_PAGE,
            page * DEFAULT_ROWS_PER_PAGE,
          )}
          order={order}
          orderBy={orderBy}
          enableSelect={true}
          selected={selected}
          handleSelect={handleSelect}
          handleChangeOrder={handleChangeOrder}
          handleChangeOrderBy={handleChangeOrderBy}
          loading={reviewsLoading}
        />
      )}
      <div className={classes.footer}>
        <Pagination
          page={page}
          setPage={setPage}
          lastPage={Math.ceil(tableData.length / DEFAULT_ROWS_PER_PAGE)}
        />
      </div>
      <ReviewModal
        isModalOpen={isOpenReviewModal}
        closeModal={handleCloseReviewModal}
        review={selectedReview}
        approveReview={handleOpenApproveReviewModal}
        declineReview={handleOpenDeclineReviewModal}
      />
      <ReviewConfirmModal
        isModalOpen={isOpenReviewConfirmModal}
        closeModal={handleCloseReviewConfirmModal}
        review={selectedReview}
        type={confirmType}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: 30,
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
      },
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 20px',
      height: 55,
      background: theme.palette.background.white,
      boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.down('xs')]: {
        height: 40,
        marginLeft: -20,
        marginRight: -20,
      },
    },
    reviewButton: {
      height: 34,
    },
  }),
);

export default PendingReview;
