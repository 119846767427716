import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { H2, H4, H5 } from 'components/Typography';

const LandlordInfo = ({ property }) => {
  const classes = useStyles();

  const info = [
    {
      label: 'Name',
      value: property?.landlord_name || 'N/A',
    },
    {
      label: 'Address',
      value: property?.landlord_address || 'N/A',
    },
    {
      label: 'Phone',
      value: property?.landlord_primary_phone || 'N/A',
    },
    {
      label: 'Email',
      value: 'None',
    },
  ];

  return (
    <div className={classes.container}>
      <H2>Landlord info</H2>
      <Box display="flex" flexWrap="wrap" gridColumnGap={30} gridRowGap={20}>
        {info.map((item, index) => (
          <Box
            key={`Shipping-Info-${index}`}
            flex={1}
            display="flex"
            flexDirection="column"
            gridRowGap={10}
            minWidth={250}
          >
            <H5 bold>{item.label}</H5>
            <H4>{item.value}</H4>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default LandlordInfo;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: 30,
      background: theme.palette.background.white,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 30,
      [theme.breakpoints.down('sm')]: {
        padding: 20,
        rowGap: 20,
        borderRadius: 0,
      },
    },
  }),
);
