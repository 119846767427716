import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import clientService from 'services/clientService';

export const getClientUrls = createAsyncThunk(
  'clientUrlSlice/getClientUrls',
  async () => {
    const response = await clientService.getClientUrls();
    return response;
  },
);

export const getClientUrlProperties = createAsyncThunk(
  'clientUrlSlice/getClientUrlProperties',
  async (id) => {
    const response = await clientService.getClientUrlProperties(id);
    return response;
  },
);

export const getPublicClientUrlProperties = createAsyncThunk(
  'clientUrlSlice/getPublicClientUrlProperties',
  async (id) => {
    const response = await clientService.getPublicClientUrlProperties(id);
    return response;
  },
);

export const createClientUrl = createAsyncThunk(
  'clientUrlSlice/createClientUrl',
  async (payload) => {
    const { body, success } = payload;
    const response = await clientService.createClientUrl(body);
    success(response.clienturls.client_url_key);
    return response;
  },
);

export const addProperties = createAsyncThunk(
  'clientUrlSlice/addProperties',
  async (payload) => {
    const { params, success } = payload;
    const response = await clientService.addProperties(params);
    success();
    return response;
  },
);

export const removeProperty = createAsyncThunk(
  'clientUrlSlice/removeProperty',
  async (payload) => {
    const { body, success } = payload;
    const { id } = body;
    const response = await clientService.removeProperty(id);
    success();
    return response;
  },
);

export const deleteClientUrls = createAsyncThunk(
  'clientUrlSlice/deleteClientUrls',
  async (payload) => {
    const { body, success } = payload;
    await clientService.deleteClientUrls(body);
    success();
    return body;
  },
);

const initialState = {
  clients: [],
  clientProperties: [],
  publicClientUrlProperties: [],
  loading: false,
  clientsLoading: false,
  client_url_id: null,
};

const clientSlice = createSlice({
  name: 'clientSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getClientUrls.fulfilled]: (state, action) => {
      state.clients = action.payload;
      state.clientsLoading = false;
    },
    [getClientUrls.pending]: (state) => {
      state.clientsLoading = true;
    },
    [getClientUrls.rejected]: (state) => {
      state.clientsLoading = false;
    },
    [getPublicClientUrlProperties.fulfilled]: (state, action) => {
      state.publicClientUrlProperties = action.payload;
      state.loading = false;
    },
    [getClientUrlProperties.fulfilled]: (state, action) => {
      state.clientProperties = action.payload;
      state.loading = false;
    },
    [getClientUrlProperties.pending]: (state) => {
      state.loading = true;
    },
    [getClientUrlProperties.rejected]: (state) => {
      state.loading = false;
    },
    [createClientUrl.fulfilled]: (state, action) => {
      state.client_url_id = action.payload.clienturls.client_url_id;
      state.loading = false;
    },
    [createClientUrl.pending]: (state) => {
      state.loading = true;
    },
    [createClientUrl.rejected]: (state) => {
      state.loading = false;
    },
    [addProperties.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [addProperties.pending]: (state) => {
      state.loading = true;
    },
    [removeProperty.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [removeProperty.pending]: (state) => {
      state.loading = true;
    },
    [removeProperty.rejected]: (state) => {
      state.loading = false;
    },
    [deleteClientUrls.fulfilled]: (state, action) => {
      const newClientUrls = [...state.clients].filter(
        (item) => !action.payload.includes(item.client_url_id),
      );
      state.clients = newClientUrls;
    },
  },
});

export default clientSlice.reducer;
