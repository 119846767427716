import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';
import date from 'date-and-time';

import { H2, H4, H5 } from 'components/Typography';
import { EnhancedTable, EnhancedMobileTable } from 'components/Table';
import { Pagination } from 'components/Pagination';
import DeleteConfirmModal from 'components/DeleteConfirmModal';
import {
  DEFAULT_ROWS_PER_PAGE,
  SORTS,
  NOTE_PRIVACIES,
  PROPERTY_NOTE_TYPE_ID,
} from 'utils/constants';
import { getNotes, deleteNotes } from 'store/noteStore';
import { getAccessTypes } from 'store/noteStore';
import HomeTableHeader from 'pages/Home/components/HomeTableHeader';
import CreateEditNoteModal from 'pages/LandlordDetail/modals/CreateEditNoteModal';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import TaskNoteDetailModal from 'pages/Home/modals/TaskNoteDetailModal';

const Note_Headers = [
  {
    label: 'Note Title',
  },
  {
    label: 'Privacy',
  },
  {
    label: 'Agent',
  },
  {
    label: 'Created',
  },
  {
    label: '',
  },
];

const checkOrder = (a, b, order) => {
  if ((a?.toLowerCase() || '') < (b?.toLowerCase() || '')) {
    if (order === SORTS.DESC) {
      return 1;
    }
    return -1;
  } else if ((a || '') > (b || '')) {
    if (order === SORTS.DESC) {
      return -1;
    }
    return 1;
  } else {
    return 0;
  }
};

const NotesTable = ({ propertyId = null }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const dispatch = useDispatch();
  const { activeNotes } = useSelector((state) => state.noteReducer);

  const [tableData, setTableData] = useState([]);
  const [mobileTableData, setMobileTableData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isSelectAll, setIsSectAll] = useState(false);
  const [noteType, setNoteType] = useState(NOTE_PRIVACIES[0]);
  const [order, setOrder] = useState(SORTS.ASC);
  const [orderBy, setOrderBy] = useState('Note Title');
  const [isOpenNewNoteModal, setIsOpenNewNoteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [editingRow, setEditingRow] = useState();
  const [isNoteDetailOpen, setIsNoteDetailOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
  const [deleteIds, setDeleteIds] = useState(null);

  useEffect(() => {
    dispatch(
      getNotes({
        note_type_id: PROPERTY_NOTE_TYPE_ID,
        concept_property_id: propertyId,
      }),
    );
  }, [dispatch, propertyId]);

  const onSuccess = () => {
    dispatch(
      getNotes({
        note_type_id: PROPERTY_NOTE_TYPE_ID,
        concept_property_id: propertyId,
      }),
    );
  };

  const handleOpenEdit = (row) => {
    dispatch(getAccessTypes());
    setIsOpenNewNoteModal(true);
    setEditingRow(row);
  };

  const handleCloseNewNoteModal = () => {
    setIsOpenNewNoteModal(false);
  };

  const handleOpenNoteDetail = (id) => {
    const item = activeNotes.filter((item) => item.note_id === id)[0];
    setSelectedNote(item);
    setIsNoteDetailOpen(true);
  };

  useEffect(() => {
    let newData = [];
    let newMobileData = [];
    if (activeNotes && activeNotes.length) {
      const data = [...activeNotes].sort((a, b) => {
        if (orderBy === 'Note Title') {
          return checkOrder(a.title, b.title, order);
        } else if (orderBy === 'Privacy') {
          return checkOrder(a.privacy, b.privacy, order);
        } else if (orderBy === 'Agent') {
          return checkOrder(
            `${a.first_name} ${a.last_name}`,
            `${b.first_name} ${b.last_name}`,
            order,
          );
        } else if (orderBy === 'Created') {
          if (
            new Date(a.modified_at).getTime() >
              new Date(b.modified_at).getTime() &&
            order === SORTS.DESC
          ) {
            return 1;
          }
          return -1;
        } else {
        }

        return 0;
      });
      data.forEach((row) => {
        if (noteType === NOTE_PRIVACIES[0] || row.note_type === noteType) {
          const dueDate = new Date(row.modified_at);
          const newDataItem = {
            id: row.note_id,
            cells: [
              {
                cell: (
                  <H4
                    className="cursor-pointer"
                    onClick={() => handleOpenNoteDetail(row.note_id)}
                  >
                    {row.title}
                  </H4>
                ),
              },
              {
                cell: <H4>{row.privacy}</H4>,
              },
              {
                cell: (
                  <H4 className={classes.landlordName}>
                    {row.first_name} {row.last_name}
                  </H4>
                ),
              },
              {
                cell: <H4>{date.format(dueDate, 'MM/DD/YYYY')}</H4>,
              },
              {
                align: 'right',
                cell: (
                  <Box
                    display="inline-flex"
                    alignItems="center"
                    gridColumnGap={20}
                  >
                    <EditIcon
                      width={16}
                      height={16}
                      className="cursor-pointer"
                      onClick={() => handleOpenEdit(row)}
                    />
                    <RemoveIcon
                      width={16}
                      height={16}
                      className="cursor-pointer"
                      onClick={() => {
                        setDeleteIds([row.note_id]);
                        setIsOpenDeleteConfirm(true);
                      }}
                    />
                  </Box>
                ),
              },
            ],
          };
          newData.push(newDataItem);
          const newMobileDataItem = {
            id: row.note_id,
            title: (
              <Box display="flex" flexDirection="column" gridRowGap={5}>
                <H4>{row.title}</H4>
                <H4 bold>{row.note_type}</H4>
              </Box>
            ),
            content: (
              <Box display="flex" flexDirection="column" gridRowGap={10}>
                <Box display="flex" gridColumnGap={20}>
                  <Box flex={1}>
                    <H4 bold>Landlord:</H4>
                  </Box>
                  <Box flex={1}>
                    <H4 className={classes.landlordName}>
                      {row.first_name} {row.last_name}
                    </H4>
                  </Box>
                </Box>
                <Box display="flex" gridColumnGap={20}>
                  <Box flex={1}>
                    <H4 bold>Due Date:</H4>
                  </Box>
                  <Box flex={1}>
                    <H4>{date.format(dueDate, 'MM/DD/YYYY')}</H4>
                  </Box>
                </Box>
                <Box display="flex" gridColumnGap={20}>
                  <Box flex={1}>
                    <H4 bold>Actions:</H4>
                  </Box>

                  <Box
                    display="inline-flex"
                    alignItems="center"
                    gridColumnGap={20}
                    flex={1}
                  >
                    <EditIcon
                      width={16}
                      height={16}
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenEdit(row);
                      }}
                    />
                    <RemoveIcon
                      width={16}
                      height={16}
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteIds([row.note_id]);
                        setIsOpenDeleteConfirm(true);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            ),
          };
          newMobileData.push(newMobileDataItem);
        }
      });
    }
    setTableData(newData);
    setMobileTableData(newMobileData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNotes, noteType, order, classes]);

  const handleSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else {
      newSelected = selected.filter((item) => item !== id);
    }
    setSelected(newSelected);
    if (isSelectAll) {
      setIsSectAll(false);
    }
  };

  const handleSelectAll = () => {
    let newSelected = [];
    if (isSelectAll) {
      setIsSectAll(false);
    } else {
      newSelected = tableData.map((row) => row.id);
      setIsSectAll(true);
    }
    setSelected && setSelected(newSelected);
  };

  const handleDeleteItems = () => {
    setDeleteIds(selected);
    setIsOpenDeleteConfirm(true);
  };

  const handleChangeOrder = (property) => {
    setPage(1);
    setOrder(order === SORTS.DESC ? SORTS.ASC : SORTS.DESC);
  };

  const handleChangeOrderBy = (property) => {
    setPage(1);
    setOrderBy(property);
  };

  const handleOpenNewModal = () => {
    dispatch(getAccessTypes());
    setIsOpenNewNoteModal(true);
  };

  const handleCloseNoteDetail = () => {
    setIsNoteDetailOpen(false);
  };

  const handleCloseDeleteConfirmModal = () => {
    setIsOpenDeleteConfirm(false);
    setDeleteIds(null);
  };

  return (
    <div className={classes.container}>
      <HomeTableHeader
        title="Notes"
        selected={selected}
        isSelectAll={isSelectAll}
        handleSelectAll={handleSelectAll}
        handleDeleteItems={handleDeleteItems}
        itemType={noteType}
        itemTypes={NOTE_PRIVACIES}
        setItemType={setNoteType}
        isNote={true}
      />
      {isMobile ? (
        <EnhancedMobileTable
          rows={mobileTableData.slice(
            (page - 1) * DEFAULT_ROWS_PER_PAGE,
            page * DEFAULT_ROWS_PER_PAGE,
          )}
          enableSelect={true}
          selected={selected}
          handleSelect={handleSelect}
        />
      ) : (
        <EnhancedTable
          headCells={Note_Headers}
          rows={tableData.slice(
            (page - 1) * DEFAULT_ROWS_PER_PAGE,
            page * DEFAULT_ROWS_PER_PAGE,
          )}
          order={order}
          orderBy={orderBy}
          enableSelect={true}
          selected={selected}
          handleSelect={handleSelect}
          handleChangeOrder={handleChangeOrder}
          handleChangeOrderBy={handleChangeOrderBy}
        />
      )}
      {!tableData.length && (
        <div className={classes.noTaskContainer}>
          <H2>{isMobile ? 'No notes yet.' : 'You’ve got no more notes'}</H2>
          <H5>
            {isMobile
              ? 'Be sure to add your first note below!'
              : 'Well done you! To create another note, simply tap below.'}
          </H5>
        </div>
      )}
      <div className={classes.footer}>
        <Box
          display="flex"
          alignItems="center"
          gridColumnGap={20}
          className="cursor-pointer"
          onClick={handleOpenNewModal}
        >
          <PlusIcon />
          <H4 bold className={classes.newTask}>
            Create New Note
          </H4>
        </Box>
        <Pagination
          page={page}
          setPage={setPage}
          lastPage={Math.ceil(tableData.length / DEFAULT_ROWS_PER_PAGE)}
        />
      </div>
      <TaskNoteDetailModal
        isModalOpen={isNoteDetailOpen}
        closeModal={handleCloseNoteDetail}
        data={selectedNote}
        type={'note'}
      />
      <CreateEditNoteModal
        isModalOpen={isOpenNewNoteModal}
        closeModal={handleCloseNewNoteModal}
        propertyId={propertyId}
        onSuccess={onSuccess}
        data={editingRow}
      />
      {isOpenDeleteConfirm && (
        <DeleteConfirmModal
          isModalOpen={isOpenDeleteConfirm}
          closeModal={handleCloseDeleteConfirmModal}
          confirm={() => {
            deleteIds &&
              dispatch(
                deleteNotes({
                  body: deleteIds,
                  success: () => {
                    if (deleteIds && selected.length) {
                      setSelected && setSelected([]);
                      setIsSectAll(false);
                    }
                    setIsOpenDeleteConfirm(false);
                  },
                }),
              );
          }}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: 30,
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
      },
    },
    noTaskContainer: {
      width: '100%',
      height: 275,
      background: theme.palette.background.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      rowGap: 20,
      borderBottom: `1px solid ${theme.palette.grey.A200}`,
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% + 40px)',
        height: 345,
        marginLeft: -20,
        marginRight: -20,
      },
      '& > h2': {
        color: theme.palette.action.active,
      },
      '& > span': {
        display: 'block',
        textAlign: 'center',
        maxWidth: 240,
      },
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px',
      height: 55,
      background: theme.palette.background.white,
      boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.down('xs')]: {
        height: 40,
        marginLeft: -20,
        marginRight: -20,
      },
    },
    newTask: {
      color: theme.palette.action.active,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    landlordName: {
      textTransform: 'capitalize',
    },
  }),
);

export default NotesTable;
