import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { H5 } from 'components/Typography';
import propertyImage from 'assets/images/propertyImg.jpg';
import { CheckBox } from 'components/CheckBox';

const PropertyItem = ({ property, index, checked, setChecked }) => {
  const classes = useStyles({ index });
  const handleChecked = () => {
    setChecked(property.concept_property_id);
  };

  return (
    <div className={classes.container}>
      <CheckBox checked={checked} setChecked={handleChecked} />
      <div className={classes.property}>
        <div className={classes.left}>
          <img src={property?.photo_url || propertyImage} alt="property" />
        </div>
        <div className={classes.right}>
          <div>
            <H5 bold>
              {property.street_number} {property.street_name},&nbsp;
            </H5>
            <H5 bold>#{property.unit}</H5>
          </div>
          <div>
            <H5 bold>ID </H5>
            <H5>{property.concept_property_id}</H5>
          </div>
          <Box display="flex" gridColumnGap={12} flexWrap="wrap">
            <div>
              <H5>Rent: </H5>
              <H5 bold>${parseInt(property.price)}</H5>
            </div>
            <div>
              <H5>Fee: </H5>
              <H5 bold>{property.fee}</H5>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default PropertyItem;
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      columnGap: 20,
      [theme.breakpoints.down('xs')]: {
        columnGap: 5,
      },
    },
    property: {
      width: '100%',
      height: 100,
      display: 'flex',
      justifyContent: 'flex-start',
      border: `2px solid ${theme.palette.action.active}`,
      borderRadius: 5,
    },
    left: {
      '& > img': {
        width: 150,
        height: 96,
        borderRadius: '5px 0 0 5px',
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
    },
    right: {
      marginLeft: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      rowGap: 4,
    },
  }),
);
