import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { EnhancedModal } from 'components/Modal';
import { DropdownContent } from 'components/Dropdown';
import { H5, H6 } from 'components/Typography';
import { Button } from 'components/Buttons';
import { VARIANTS } from 'utils/constants';
import { getAgentList } from 'store/agentStore';
import { createPropertyCollaborator } from 'store/propertyStore';
import { useDebounce } from 'hooks/useDebounce';

import defalutAvatar from 'assets/defaultAvatar.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowFillDown.svg';
// import { ReactComponent as CopyLinkIcon } from 'assets/icons/copyLink.svg';

const PropertyShareModal = ({ isModalOpen, closeModal, propertyId }) => {
  const dispatch = useDispatch();
  const [collaborator, setCollaborator] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [result, setResult] = useState([]);
  const [timer, setTimer] = useState(null);
  const [shared, setShared] = useState(false);

  const classes = useStyles({ isOpened: !!result.length });
  const { agentList } = useSelector((state) => state.agentReducer);
  const debouncedKeyword = useDebounce(keyword, 750);

  // const copyLink = () => {
  //   navigator.clipboard.writeText(shareLink);
  // };

  useEffect(() => {
    dispatch(getAgentList());
  }, [dispatch]);

  useEffect(() => {
    if (debouncedKeyword) {
      setResult(
        agentList.filter((item) =>
          item.name.toLowerCase().includes(debouncedKeyword.toLowerCase()),
        ),
      );
    }
  }, [debouncedKeyword, agentList]);

  const handleShare = () => {
    dispatch(
      createPropertyCollaborator({
        body: {
          collaborator_user_id: collaborator.user_id,
          concept_property_id: propertyId,
        },
        success: () => {
          setCollaborator(null);
          if (timer) {
            clearTimeout(timer);
          }
          setShared(true);
          setTimer(setTimeout(() => setShared(false), 2500));
        },
      }),
    );
  };

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      variant={VARIANTS.SECONDARY}
      title={'Share'}
    >
      <div className={classes.contiainer}>
        <H5>Share with</H5>
        <Box
          mt={1}
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          gridColumnGap={20}
          gridRowGap={10}
        >
          <Box flex={1} minWidth={220} position="relative">
            {collaborator ? (
              <div className={classes.collaborator}>
                <div>
                  <CloseIcon
                    width="12"
                    cursor="pointer"
                    onClick={() => setCollaborator(null)}
                  />
                  <H6>{collaborator.name}</H6>
                </div>
              </div>
            ) : (
              <>
                <input
                  className={classes.input}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <SearchIcon className={classes.searchIcon} />
              </>
            )}
            {!!result.length && (
              <>
                <DropdownContent
                  handleClickAway={() => {
                    setResult([]);
                    setKeyword('');
                  }}
                  customStyles={classes.searchResultDropdown}
                >
                  <div className={classes.dropwdown}>
                    {result.map((item) => (
                      <Box
                        key={item.user_id}
                        className={classes.searchItem}
                        onClick={() => {
                          setCollaborator(item);
                          setResult([]);
                          setKeyword('');
                        }}
                      >
                        <img
                          src={item.photo_url || defalutAvatar}
                          alt="avatar"
                        />
                        <Box display="flex" flexDirection="column">
                          <H5>{item.name}</H5>
                          <H6 grey>{item.email}</H6>
                        </Box>
                      </Box>
                    ))}
                  </div>
                </DropdownContent>
              </>
            )}
          </Box>
          {shared ? (
            <H5>Property Shared</H5>
          ) : (
            <Button disabled={!collaborator} onClick={handleShare}>
              Share
            </Button>
          )}
        </Box>
        {/* <Box mt={3}>
          <Box
            className="cursor-pointer"
            display="inline-flex"
            alignItems="center"
            gridColumnGap={10}
          >
            <H5>See Members</H5>
            <ArrowDownIcon width={16} height={16} />
          </Box>
        </Box> */}
        {/* <Box mt={3} className={classes.shareLink}>
          <CopyLinkIcon />
          <H5 className="cursor-pointer" onClick={() => copyLink()}>
            Copy link
          </H5>
        </Box> */}
      </div>
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    contiainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 720,
      padding: '20px 0',
      [theme.breakpoints.down('xs')]: {
        padding: '10px 0',
      },
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 42,
      padding: '0 20px 0 50px',
      background: theme.palette.background.white,
      borderRadius: (props) => (props.isOpened ? '5px 5px 0 0' : 5),
      color: theme.palette.action.active,
      fontSize: 16,
      lineHeight: '22px',
      [theme.breakpoints.down('xs')]: {
        padding: '0 20px 0 40px',
      },
      '&:hover': {
        border: `1px solid ${theme.palette.background.black}`,
      },
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
    },
    collaborator: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 42,
      padding: '0 15px',
      borderRadius: 5,
      border: `2px solid ${theme.palette.action.active}`,
      '& > div': {
        padding: '0 8px',
        background: theme.palette.grey.A200,
        borderRadius: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        columnGap: 4,
      },
    },
    searchIcon: {
      width: 24,
      height: 24,
      position: 'absolute',
      left: 15,
      top: '50%',
      transform: 'translate(0, -50%)',
      [theme.breakpoints.down('xs')]: {
        left: 8,
      },
    },
    searchResultDropdown: {
      left: 0,
      right: 0,
      top: '100%',
      transform: 'translate(0, 0)',
    },
    dropwdown: {
      width: '100%',
      maxHeight: 300,
      overflow: 'auto',
      borderRadius: '0 0 5px 5px',
      display: 'flex',
      flexDirection: 'column',
      border: `2px solid ${theme.palette.action.active}`,
      borderTop: 'none',
      '& > div': {
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
        },
      },
      '&::-webkit-scrollbar': {
        width: 10,
        background: theme.palette.background.white,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
    searchItem: {
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
      '& > img': {
        width: 50,
        height: 50,
        borderRadius: '50%',
        [theme.breakpoints.down('xs')]: {
          width: 40,
          height: 40,
        },
      },
    },
    shareLink: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
      borderTop: `1px solid ${theme.palette.grey.A100}`,
      paddingTop: 24,
    },
  }),
);

export default PropertyShareModal;
