import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import noteService from 'services/noteService';

export const getNotes = createAsyncThunk(
  'noteSlice/getNotes',
  async (payload) => {
    const response = await noteService.getNotes(payload);
    return response;
  },
);

export const getAccessTypes = createAsyncThunk(
  'noteSlice/getAccessTypes',
  async () => {
    const response = await noteService.getAccessTypes();
    return response;
  },
);

export const createNewNote = createAsyncThunk(
  'noteSlice/createNote',
  async (payload) => {
    const { body, success } = payload;
    console.log('body', body);
    const response = await noteService.createNote(body);
    success();
    return response;
  },
);

export const updateNote = createAsyncThunk(
  'noteSlice/updateNote',
  async (payload) => {
    const { body, success } = payload;
    const { note_id, ...params } = body;
    const response = await noteService.updateNote(note_id, params);
    success();
    return response;
  },
);

export const deleteNotes = createAsyncThunk(
  'noteSlice/deleteNotes',
  async (payload) => {
    const { body, success } = payload;
    await noteService.deleteNotes(body);
    success();
    return body;
  },
);

const initialState = {
  notes: [],
  accessTypes: [],
  activeNotes: [],
  loading: false,
};

const noteSlice = createSlice({
  name: 'noteSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getNotes.fulfilled]: (state, action) => {
      state.notes = action.payload;
      state.activeNotes = action.payload;
      state.loading = false;
    },
    [getNotes.pending]: (state) => {
      state.loading = true;
    },
    [getNotes.rejected]: (state) => {
      state.loading = false;
    },
    [getAccessTypes.fulfilled]: (state, action) => {
      state.accessTypes = action.payload;
      state.loading = false;
    },
    [getAccessTypes.pending]: (state) => {
      state.loading = true;
    },
    [getAccessTypes.rejected]: (state) => {
      state.loading = false;
    },
    [deleteNotes.fulfilled]: (state, action) => {
      const newNotes = [...state.activeNotes].filter(
        (item) => !action.payload.includes(item.note_id),
      );
      state.activeNotes = newNotes;
    },
  },
});

export default noteSlice.reducer;
