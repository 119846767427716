import { get, patch } from 'utils/api';

const getNotifications = (pnum = 1, per_page = 20, user_id) => {
  return get({
    path: 'notifications',
    parameters: {
      pnum,
      per_page,
      user_id,
    },
  });
};

const updateNotification = (payload) => {
  return patch({ path: 'notifications', body: payload });
};

const taskService = {
  getNotifications,
  updateNotification,
};

export default taskService;
