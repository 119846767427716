import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';

import { H4, H5 } from 'components/Typography';
import { EnhancedTable } from 'components/Table';
import { Pagination } from 'components/Pagination';
import { VARIANTS, SORTS } from 'utils/constants';
import { pinLandlord, unPinLandlord } from 'store/landlordStore';

import { ReactComponent as FlagMarkIcon } from 'assets/icons/flagMark.svg';
import { ReactComponent as UnPinnedIcon } from 'assets/icons/unPinned.svg';
import { ReactComponent as PinnedIcon } from 'assets/icons/pinned.svg';
import { ReactComponent as StrokeIcon } from 'assets/icons/stroke.svg';
import { ReactComponent as BIcon } from 'assets/icons/bIcon.svg';

import { useHistory } from 'react-router';

const Table_Headers = [
  {
    label: '',
  },
  {
    label: 'Name',
  },
  {
    label: 'Phone',
  },
  {
    label: 'Email',
  },
  {
    label: 'Address',
  },
  {
    label: 'Website',
  },
  {
    label: 'Pinned',
  },
];

const checkOrder = (a, b, order) => {
  if ((a?.toLowerCase() || '') < (b?.toLowerCase() || '')) {
    if (order === SORTS.DESC) {
      return 1;
    }
    return -1;
  } else if ((a || '') > (b || '')) {
    if (order === SORTS.DESC) {
      return -1;
    }
    return 1;
  } else {
    return 0;
  }
};

const LandlordTable = ({ data, handleSelect, selected }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);
  const [mobileTableData, setMobileTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [hoverPinId, setHoverPinId] = useState(null);
  const [order, setOrder] = useState(SORTS.ASC);
  const [orderBy, setOrderBy] = useState('Name');
  const icons = [<BIcon />, <StrokeIcon />];

  useEffect(() => {
    if (Array.isArray(data)) {
      let newData = [];
      let newMobileData = [];

      const sortedData = [...data].sort((a, b) => {
        if (orderBy === 'Name') {
          return checkOrder(a.landlord_name, b.landlord_name, order);
        } else if (orderBy === 'Phone') {
          return checkOrder(a.phone, b.phone, order);
        } else if (orderBy === 'Email') {
          return checkOrder(a.email, b.email, order);
        } else if (orderBy === 'Address') {
          return checkOrder(a.address, b.address, order);
        } else if (orderBy === 'Website') {
          return checkOrder(a.website, b.website, order);
        } else if (orderBy === 'Pinned') {
          if ((a.pinned || 0) > (b.pinned || 0)) {
            if (order === SORTS.DESC) {
              return 1;
            }
            return -1;
          } else if ((a.pinned || 0) < (b.pinned || 0)) {
            if (order === SORTS.DESC) {
              return -1;
            }
            return 1;
          } else {
            return 0;
          }
        }

        return 0;
      });

      sortedData.forEach((row) => {
        const newDataItem = {
          id: row.landlord_id,
          cells: [
            {
              cell: (
                <H4 className="cursor-pointer">
                  {row.record_location === 'bookOfBusiness' && icons[0]}
                  {row.record_location === 'inThePond' && icons[1]}
                </H4>
              ),
            },
            {
              cell: <H5>{row.landlord_name}</H5>,
            },
            {
              cell: <H5>{row.phone}</H5>,
            },
            {
              cell: <H5 className={classes.ellipseField}>{row.email}</H5>,
            },
            {
              cell: <H5 className={classes.ellipseField}>{row.address}</H5>,
            },
            {
              cell: <H5 className={classes.ellipseField}>{row.website}</H5>,
            },
            {
              align: 'right',
              cell: (
                <Box
                  className={classes.actionField}
                  onMouseEnter={() => setHoverPinId(row.landlord_id)}
                  onMouseLeave={() => setHoverPinId(null)}
                >
                  {row.review_pending ? (
                    <FlagMarkIcon />
                  ) : row.pinned ? (
                    <PinnedIcon
                      onClick={(e) => handleUnPinLandlord(e, row.landlord_id)}
                    />
                  ) : (
                    hoverPinId === row.landlord_id && (
                      <UnPinnedIcon
                        onClick={(e) => handlePinLandlord(e, row.landlord_id)}
                      />
                    )
                  )}
                </Box>
              ),
            },
          ],
        };
        newData.push(newDataItem);
        const newMobileDataItem = row;
        newMobileData.push(newMobileDataItem);
      });

      setTableData(newData);
      setMobileTableData(newMobileData);
    }
    // eslint-disable-next-line
  }, [classes, data, order, orderBy, hoverPinId]);

  const handleRowClick = (id) => {
    history.push(`/landlord/${id}`);
  };

  const handleChangeOrder = (property) => {
    setPage(1);
    setOrder(order === SORTS.DESC ? SORTS.ASC : SORTS.DESC);
  };

  const handleChangeOrderBy = (property) => {
    setPage(1);
    setOrderBy(property);
  };

  const handlePinLandlord = (e, id) => {
    e.stopPropagation();
    dispatch(pinLandlord(id));
  };

  const handleUnPinLandlord = (e, id) => {
    e.stopPropagation();
    dispatch(unPinLandlord(id));
  };

  return (
    <>
      {isTablet ? (
        <Box display="flex" flexDirection="column" gridRowGap={10}>
          {mobileTableData.slice((page - 1) * 10, page * 10).map((item) => (
            <div
              key={item.landlord_id}
              className={classes.landlordItem}
              onClick={() => handleRowClick(item.landlord_id)}
            >
              <Box display="flex" flexDirection="column" gridRowGap={5}>
                <H5 bold>{item.landlord_name}</H5>
                <H5>{item.phone}</H5>
              </Box>
              <Box
                className={classes.actionField}
                onMouseEnter={() => setHoverPinId(item.landlord_id)}
                onMouseLeave={() => setHoverPinId(null)}
              >
                {hoverPinId === item.landlord_id ? (
                  item.pinned ? (
                    <PinnedIcon
                      onClick={(e) => handleUnPinLandlord(e, item.landlord_id)}
                    />
                  ) : (
                    <UnPinnedIcon
                      onClick={(e) => handlePinLandlord(e, item.landlord_id)}
                    />
                  )
                ) : (
                  !!item.review_pending && <FlagMarkIcon />
                )}
              </Box>
            </div>
          ))}
        </Box>
      ) : (
        <EnhancedTable
          headCells={Table_Headers}
          rows={tableData.slice((page - 1) * 10, page * 10)}
          order={order}
          orderBy={orderBy}
          enableSelect={true}
          selected={selected}
          handleSelect={handleSelect}
          className={classes.tableStyles}
          variant={VARIANTS.SECONDARY}
          handleRowClick={handleRowClick}
          handleChangeOrder={handleChangeOrder}
          handleChangeOrderBy={handleChangeOrderBy}
        />
      )}
      <div className={classes.footer}>
        <Box>
          <H5 bold>Results: </H5>
          <H5>{`${1 + 10 * (page - 1)} - ${
            Math.ceil(tableData.length / 10) === page
              ? tableData.length
              : 10 * page
          } of ${tableData.length}`}</H5>
        </Box>
        <Pagination
          page={page}
          setPage={setPage}
          lastPage={Math.ceil(tableData.length / 10)}
        />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    landlordItem: {
      background: theme.palette.background.white,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      borderRadius: 5,
      cursor: 'pointer',
    },
    tableStyles: {
      '& .MuiTableHead-root': {
        background: theme.palette.grey.A400,
      },
      '& .MuiTableRow-root': {
        marginBottom: 10,
      },
      '& .MuiTableCell-head div span': {
        textTransform: 'initial',
      },
      '& .MuiTableCell-root.MuiTableCell-body': {
        borderBottom: `10px solid ${theme.palette.grey.A400}`,
      },
      '& .MuiTableCell-root MuiTableCell-head': {
        borderBottom: 'none',
      },
      '& .MuiTableBody-root': {
        '& tr': {
          cursor: 'pointer',
        },
      },
      '& tr': {
        '&:last-child': {
          '& .MuiTableCell-root.MuiTableCell-body': {
            borderBottom: 'none',
          },
        },
      },
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      columnGap: 30,
      padding: 0,
      height: 55,
    },
    actionField: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 35,
      maxWidth: 35,
      minWidth: 35,
      minHeight: 35,
      marginRight: 0,
      cursor: 'pointer',
    },
    ellipseField: {
      maxWidth: 150,
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down(1500)]: {
        maxWidth: 130,
      },
    },
  }),
);

export default LandlordTable;
