import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware, connectRouter } from 'connected-react-router';

import { history } from 'utils/history';
import authReducer from './authStore';
import clientReducer from './clientStore';
import reviewReducer from './reviewStore';
import taskReducer from './taskStore';
import propertyReducer from './propertyStore';
import landlordReducer from './landlordStore';
import noteReducer from './noteStore';
import cityReducer from './cityStore';
import agentReducer from './agentStore';
import notificationReducer from './notificationStore';

const rootReducer = combineReducers({
  router: connectRouter(history),
  authReducer,
  clientReducer,
  reviewReducer,
  taskReducer,
  propertyReducer,
  landlordReducer,
  noteReducer,
  cityReducer,
  agentReducer,
  notificationReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    routerMiddleware(history),
  ],
});

export default store;
