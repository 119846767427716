import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import PropertyList from './components/PropertyList';

const ShowSheetPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <PropertyList />
    </div>
  );
};

export default ShowSheetPage;

const useStyles = makeStyles((theme) => createStyles({}));
