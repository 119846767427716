import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import notificationService from 'services/notificationService';

export const getNotifications = createAsyncThunk(
  'notificationSlice/getNotifications',
  async (payload) => {
    const { pnum, per_page, user_id } = payload;
    const response = await notificationService.getNotifications(
      pnum,
      per_page,
      user_id,
    );
    return response;
  },
);

export const updateNotification = createAsyncThunk(
  'notificationSlice/updateNotification',
  async (payload) => {
    const response = await notificationService.updateNotification(payload);
    return response;
  },
);

const initialState = {
  notifications: [],
  loading: false,
};

const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getNotifications.fulfilled]: (state, action) => {
      state.notifications = action.payload;
      state.loading = false;
    },
    [getNotifications.pending]: (state) => {
      state.loading = true;
    },
    [getNotifications.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default notificationSlice.reducer;
