import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import Box from '@material-ui/core/Box';
import * as Yup from 'yup';
import classNames from 'classnames';

import { EnhancedModal } from 'components/Modal';
import { EnhancedForm, FormInputField } from 'components/Form';
import { Button } from 'components/Buttons';
import { H5 } from 'components/Typography';
import { SIZE_TYPES } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownContent } from 'components/Dropdown';
import { createAgent, getAgentList } from 'store/agentStore';
import { useDebounce } from 'hooks/useDebounce';

const AddAgentModal = ({ isModalOpen, closeModal }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const cities = useSelector((state) => state.cityReducer.cities);
  const states = useSelector(
    (state) => state.propertyReducer.filterOptions.states,
  );

  const [isOpenCityModal, setOpenCityModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [citySearch, setCitySearch] = useState('');
  const [searchedCities, setSearchedCities] = useState([]);

  const [isOpenStateModal, setOpenStateModal] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [stateSearch, setStateSearch] = useState('');
  const [searchedStates, setSearchedStates] = useState([]);

  const debouncedCitySearch = useDebounce(citySearch, 750);
  const debouncedStateSearch = useDebounce(stateSearch, 750);

  useEffect(() => {
    if (debouncedCitySearch) {
      setSearchedCities(
        cities.filter((item) =>
          item.city_name
            .toLowerCase()
            .includes(debouncedCitySearch.toLowerCase()),
        ),
      );
    } else {
      setSearchedCities([]);
    }
  }, [debouncedCitySearch, cities]);

  useEffect(() => {
    if (debouncedStateSearch) {
      setSearchedStates(
        states.filter((item) =>
          item.state_name
            .toLowerCase()
            .includes(debouncedStateSearch.toLowerCase()),
        ),
      );
    } else {
      setSearchedStates([]);
    }
  }, [debouncedStateSearch, states]);

  const handleSubmit = async (body) => {
    dispatch(
      createAgent({
        body: {
          ...body,
          password: '111',
        },
        success: () => {
          dispatch(getAgentList());
        },
      }),
    );

    closeModal();
  };

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title="Add New Agent"
      mobileFullWidth={true}
      className={classes.modal}
    >
      <EnhancedForm
        initialValues={{
          first_name: '',
          last_name: '',
          address: '',
          primary_phone: '',
          email: '',
          website: '',
          postal_code: '',
          city: '',
          state_id: null,
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string().required('Required'),
          email: Yup.string().email('Invalid email'),
        })}
        handleSubmit={handleSubmit}
        className={classes.contiainer}
      >
        {() => (
          <>
            <Box className={classes.fieldsContent}>
              <FormInputField label="First Name" name="first_name" />
              <FormInputField label="Last Name" name="last_name" />
              <FormInputField label="Address" name="address" />
              <div className={classes.inputWrapper}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gridRowGap={5}
                  mb={4}
                >
                  <H5>City</H5>
                  <Field name="city">
                    {({ form: { setFieldValue }, meta }) => (
                      <div
                        className={classNames(classes.customField, {
                          error: meta.touched && meta.error,
                        })}
                        onClick={() =>
                          !isOpenCityModal && setOpenCityModal(true)
                        }
                      >
                        <input
                          className={classes.searchInput}
                          value={isOpenCityModal ? citySearch : selectedCity}
                          onChange={(e) => setCitySearch(e.target.value)}
                        />
                        {isOpenCityModal && (
                          <>
                            <DropdownContent
                              handleClickAway={() => {
                                setSearchedCities([]);
                                setOpenCityModal(false);
                                setCitySearch('');
                              }}
                              customStyles={classes.selectCustomDropdown}
                            >
                              <div className={classes.customDropwdown}>
                                {[{ city_id: null }, ...searchedCities].map(
                                  (item, index) => (
                                    <Box
                                      key={`city- ${index}`}
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      onClick={() => {
                                        setFieldValue('city', item.city_name);
                                        setSelectedCity(
                                          item.city_id
                                            ? item.city_name
                                            : 'None',
                                        );
                                        setOpenCityModal(false);
                                        setCitySearch('');
                                        setSearchedCities([]);
                                      }}
                                    >
                                      <H5>
                                        {item.city_id ? item.city_name : 'None'}
                                      </H5>
                                    </Box>
                                  ),
                                )}
                              </div>
                            </DropdownContent>
                          </>
                        )}
                      </div>
                    )}
                  </Field>
                </Box>
              </div>
              <div className={classes.inputWrapper}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gridRowGap={5}
                  mb={4}
                >
                  <H5>State</H5>
                  <Field name="state_id">
                    {({ form: { setFieldValue }, meta }) => (
                      <div
                        className={classNames(classes.customField, {
                          error: meta.touched && meta.error,
                        })}
                        onClick={() =>
                          !isOpenStateModal && setOpenStateModal(true)
                        }
                      >
                        <input
                          className={classes.searchInput}
                          value={isOpenStateModal ? stateSearch : selectedState}
                          onChange={(e) => setStateSearch(e.target.value)}
                        />
                        {isOpenStateModal && (
                          <>
                            <DropdownContent
                              handleClickAway={() => {
                                setSearchedStates([]);
                                setOpenStateModal(false);
                                setStateSearch('');
                              }}
                              customStyles={classes.selectCustomDropdown}
                            >
                              <div className={classes.customDropwdown}>
                                {[{ state_id: null }, ...searchedStates].map(
                                  (item, index) => (
                                    <Box
                                      key={`state- ${index}`}
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      onClick={() => {
                                        setFieldValue(
                                          'state_id',
                                          item.state_id,
                                        );
                                        setSelectedState(
                                          item.state_id
                                            ? item.state_name
                                            : 'None',
                                        );
                                        setOpenStateModal(false);
                                        setStateSearch('');
                                        setSearchedStates([]);
                                      }}
                                    >
                                      <H5>
                                        {item.state_id
                                          ? item.state_name
                                          : 'None'}
                                      </H5>
                                    </Box>
                                  ),
                                )}
                              </div>
                            </DropdownContent>
                          </>
                        )}
                      </div>
                    )}
                  </Field>
                </Box>
              </div>
              <FormInputField label="Postal Code" name="postal_code" />
              <FormInputField label="Phone" name="primary_phone" />
              <FormInputField label="Email" name="email" />
              <FormInputField label="Website" name="website" />
              <div></div>
            </Box>
            <Box className={classes.actionButtons}>
              <Button type="submit" size={SIZE_TYPES.LARGE}>
                Create User and Send Password Link
              </Button>
            </Box>
          </>
        )}
      </EnhancedForm>
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      maxWidth: 900,
      [theme.breakpoints.down('xs')]: {
        height: '90%',
        top: '10% !important',
      },
    },
    contiainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 30,
      [theme.breakpoints.down('xs')]: {
        rowGap: 20,
        height: 'calc(90% - 80px)',
        overflow: 'auto',
        padding: '0 20px',
        width: 'calc(100% + 40px)',
        marginLeft: -20,
      },
    },
    fieldsContent: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: 20,
      rowGap: 5,
      '& > div': {
        flex: 1,
        minWidth: 260,
      },
    },
    actionButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: 20,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        background: theme.palette.background.white,
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.15)',
        height: 80,
        width: '100%',
      },
    },
    mobileActionButtons: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        background: theme.palette.background.white,
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.15)',
        height: 112,
        width: '100%',
      },
    },
    mobileButtons: {
      columnGap: 20,
    },
    value: {
      height: 22,
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
      },
    },
    leftBox: { width: 70, marginRight: 20 },
    title: { marginBottom: 10 },
    field: {
      width: 100,
    },
    sameValue: {
      color: theme.palette.background.red,
    },
    selectDropdown: {
      width: '100%',
      border: `2px solid ${theme.palette.action.active}`,
      transform: 'translate(-50%, 0px)',
      maxHeight: 230,
      overflowY: 'auto',
      overflowX: 'hidden',
      top: '100%',
      left: '50%',
      '&::-webkit-scrollbar': {
        width: 4,
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.grey.A100,
        borderRadius: 2,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.action.active,
        borderRadius: 2,
        '&:hover': {
          background: theme.palette.action.hover,
        },
      },
    },
    inputWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: 230,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: 10,
      },
      position: 'relative',
    },
    input: {
      height: 40,
      border: `1px solid ${theme.palette.grey[300]}`,
      boxSizing: 'border-box',
      borderRadius: 5,
      padding: '9px 10px',
      marginTop: 5,
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
    },
    listItem: {
      display: 'flex',
      gap: 10,
      margin: 10,
      width: '100%',

      '& span': {
        minHeight: 20,
        lineHeight: '25px',
      },
    },
    errorContainer: {
      display: 'block',
      minHeight: 20,
    },
    searchInput: {
      width: '100%',
      fontSize: 16,
      border: 'none',
      height: '100%',
      padding: '0 10px',
      borderRadius: 5,
    },
    customField: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 40,
      padding: 0,
      background: theme.palette.background.white,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 5,
      color: theme.palette.text.primary,
      fontSize: 16,
      lineHeight: '22px',
      position: 'relative',
      cursor: 'pointer',
      '&.error': {
        border: `2px solid ${theme.palette.background.red}`,
      },
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
      '& > span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    selectCustomDropdown: {
      left: 0,
      right: 0,
      top: 'calc(100% + 5px)',
      transform: 'translate(0, 5px)',
    },
    customDropwdown: {
      width: '100%',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 300,
      overflowY: 'auto',
      '& > div': {
        padding: '8px 15px',
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
        },
      },
      '&::-webkit-scrollbar': {
        width: 6,
        background: theme.palette.background.white,
        borderRadius: 5,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
  }),
);

export default AddAgentModal;
