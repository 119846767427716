import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import agentMenuIcon from 'assets/icons/agentMenu.svg';
import homeMenuIcon from 'assets/icons/homeMenu.svg';
import landlordMenuIcon from 'assets/icons/landlordMenu.svg';
import propertyMenuIcon from 'assets/icons/propertyMenu.svg';

const MENUS = [homeMenuIcon, propertyMenuIcon, landlordMenuIcon, agentMenuIcon];

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: 100,
      height: '100%',
      background: theme.palette.background.white,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      rowGap: 36,
      padding: '12px 0',
      [theme.breakpoints.down('md')]: {
        width: 68,
      },
      [theme.breakpoints.down('xs')]: {
        height: 68,
        width: '100%',
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0 30px',
      },
    },
    menuIcon: {
      width: 52,
      height: 52,
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.grey.A700,
      },
      '&.active': {
        background: theme.palette.grey.A700,
      },
    },
  }),
);

const SideBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (location.pathname.includes('property')) {
      setSelectedIndex(1);
    } else if (location.pathname.includes('landlord')) {
      setSelectedIndex(2);
    } else if (location.pathname.includes('agent')) {
      setSelectedIndex(3);
    } else {
      setSelectedIndex(0);
    }
  }, [location.pathname]);

  const handleMenuClick = (index) => {
    setSelectedIndex(index);
    switch (index) {
      case 1:
        history.push('/property');
        break;
      case 2:
        history.push('/landlord');
        break;
      case 3:
        history.push('/agent');
        break;
      default:
        history.push('/');
        break;
    }
  };

  return (
    <div className={classes.container}>
      {MENUS.map(
        (item, index) =>
          (user?.role_id !== 3 || index !== 3) && (
            <div
              key={`menu-icon-${index}`}
              className={classNames(classes.menuIcon, {
                active: index === selectedIndex,
              })}
              onClick={() => handleMenuClick(index)}
            >
              <img src={item} alt="menu-icon" />
            </div>
          ),
      )}
    </div>
  );
};

export default SideBar;
