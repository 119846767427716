import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { EnhancedModal } from 'components/Modal';
import { DropdownContent } from 'components/Dropdown';
import { H5, H6 } from 'components/Typography';
import { Pagination } from 'components/Pagination';
import { VARIANTS } from 'utils/constants';

import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';

const PhotoExtensionModal = ({
  isModalOpen,
  closeModal,
  photos = [],
  selectedIndex = 0,
}) => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.authReducer);
  const [page, setPage] = useState(selectedIndex + 1);
  const [isMenuDropdown, setIsMenuDropdown] = useState(false);

  useEffect(() => {
    setPage(selectedIndex + 1);
  }, [selectedIndex]);

  const download = (e, downloadUrl) => {
    e.preventDefault();
    fetch(downloadUrl, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'image.jpg'); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title="Photos"
      variant={VARIANTS.SECONDARY}
      className={classes.photoModal}
    >
      <div className={classes.contiainer}>
        <img
          className={classes.img}
          src={
            photos[page - 1].watermark_url ||
            photos[page - 1].original_url ||
            photos[page - 1].media_url
          }
          alt=""
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <H6>
            {page}/{photos.length}
          </H6>
          <Pagination page={page} setPage={setPage} lastPage={photos.length} />
        </Box>
        {user.role_id === 1 || user.role_id === 2 ? (
          <Box className={classes.downloadBtn}>
            <DownloadIcon
              className="cursor-pointer"
              onClick={() => setIsMenuDropdown(true)}
            />
            {isMenuDropdown && (
              <>
                <DropdownContent
                  handleClickAway={() => setIsMenuDropdown(false)}
                  customStyles={classes.dropdownContainer}
                >
                  <div className={classes.menuContainer}>
                    <a
                      href={photos[page - 1].watermark_url}
                      download
                      onClick={(e) =>
                        download(e, photos[page - 1].watermark_url)
                      }
                    >
                      <H5>With Watermark</H5>
                    </a>
                    <a
                      href={photos[page - 1].original_url}
                      download
                      onClick={(e) =>
                        download(e, photos[page - 1].original_url)
                      }
                    >
                      <H5>Without Watermark</H5>
                    </a>
                  </div>
                </DropdownContent>
              </>
            )}
          </Box>
        ) : (
          <a
            href={
              photos[page - 1].watermark_url ||
              photos[page - 1].original_url ||
              photos[page - 1].media_url
            }
            className={classes.downloadBtn}
            download
            onClick={(e) =>
              download(
                e,
                photos[page - 1].watermark_url ||
                  photos[page - 1].original_url ||
                  photos[page - 1].media_url,
              )
            }
          >
            <DownloadIcon />
          </a>
        )}
      </div>
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    contiainer: {
      position: 'relative',
      width: '100%',
      '& > img': {
        width: '100%',
        [theme.breakpoints.down('xs')]: {
          margin: '0 -20px',
          width: 'calc(100% + 40px)',
        },
      },
    },
    downloadBtn: {
      position: 'absolute',
      top: 20,
      right: 20,
      width: 40,
      height: 40,
      background: theme.palette.background.white,
      cursor: 'pointer',
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        right: 0,
      },
    },
    photoModal: {
      width: 'auto',
      maxWidth: 1400,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    img: {
      maxWidth: 1400,
      maxHeight: 700,
      minHeight: 400,
      objectFit: 'cover',
    },
    dropdownContainer: {
      transform: `translate(10px, 10px)`,
      left: 'auto',
      right: 0,
    },
    menuContainer: {
      background: theme.palette.background.white,
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      padding: 10,
      '& > a': {
        textDecoration: 'none',
      },
    },
  }),
);

export default PhotoExtensionModal;
