import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { Button } from 'components/Buttons';
import { CheckBox } from 'components/CheckBox';
import { H1, H5 } from 'components/Typography';
import { Spinner } from 'components/Spinner';
import { SIZE_TYPES } from 'utils/constants';
import { getAgentList } from 'store/agentStore';

import AgentTable from './components/AgentTable';
import TeamMembers from 'pages/AgentDetail/components/TeamMembers';
import AddAgentModal from './modals/AddAgent';
import SearchField from './components/SearchField';

import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';

const AgentListPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [isSelectAll, setIsSectAll] = useState(false);

  const { user } = useSelector((state) => state.authReducer);
  const { listLoading, agentList } = useSelector((store) => store.agentReducer);

  useEffect(() => {
    dispatch(getAgentList());
  }, [dispatch]);

  const handleOpenAddModal = () => {
    setIsOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setIsOpenAddModal(false);
  };

  const handleSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else {
      newSelected = selected.filter((item) => item !== id);
    }
    setSelected(newSelected);
    if (isSelectAll) {
      setIsSectAll(false);
    }
  };

  const handleSelectAll = () => {
    let newSelected = [];
    if (isSelectAll) {
      setIsSectAll(false);
    } else {
      newSelected = agentList.map((row) => row.landlord_id);
      setIsSectAll(true);
    }
    setSelected && setSelected(newSelected);
  };

  const handleDeleteItems = () => {
    setSelected && setSelected([]);
    setIsSectAll(false);
  };

  return (
    <div className={classes.landlordPage}>
      <H1 display="block">Agent List</H1>
      <Box className={classes.content} marginBottom={3}>
        {selected.length ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={1}
            maxWidth={350}
          >
            <Box display="flex" alignItems="center" gridColumnGap={10}>
              <CheckBox checked={isSelectAll} setChecked={handleSelectAll} />
              <H5>All</H5>
            </Box>
            <H5>{selected.length} Selected</H5>
            <Box
              className="cursor-pointer"
              display="flex"
              alignItems="center"
              gridColumnGap={10}
              onClick={handleDeleteItems}
            >
              <RemoveIcon width={20} height={20} />
              <H5>Delete</H5>
            </Box>
          </Box>
        ) : (
          <Box className={classes.headerBar}>
            <Box flex={1}>
              <SearchField data={agentList} />
            </Box>
            <Box>
              <Button size={SIZE_TYPES.LARGE} onClick={handleOpenAddModal}>
                Add New Agent
              </Button>
            </Box>
          </Box>
        )}
        <Box width={1} maxWidth={380} />
      </Box>
      {listLoading ? (
        <Box className={classes.loadingContent}>
          <Spinner loading={listLoading} />
        </Box>
      ) : (
        <Box className={classes.content}>
          <div className={classes.landloadList}>
            <AgentTable
              data={agentList}
              handleSelect={handleSelect}
              selected={selected}
            />
          </div>
          <Box className={classes.pinndedContacts}>
            <TeamMembers id={user?.user_id} />
          </Box>
        </Box>
      )}
      {isOpenAddModal && (
        <AddAgentModal
          isModalOpen={isOpenAddModal}
          closeModal={handleCloseAddModal}
        />
      )}
    </div>
  );
};

export default AgentListPage;

const useStyles = makeStyles((theme) =>
  createStyles({
    landlordPage: {
      padding: 30,
      [theme.breakpoints.down('xs')]: {
        padding: '0 20px',
      },
    },
    headerBar: {
      width: '100%',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: 20,
      rowGap: 10,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
        '& > div': {
          width: '100%',
        },
      },
    },
    loadingContent: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '20%',
    },
    content: {
      display: 'flex',
      columnGap: 30,
      rowGap: 30,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    landloadList: {
      flex: 1,
    },
    pinndedContacts: {
      width: '100%',
      maxWidth: 380,
      minWidth: 380,
      margin: '55px 0',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        minWidth: '100%',
      },
    },
  }),
);
