import React, { useRef } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { VARIANTS } from 'utils/constants';

import CheckedIcon from 'assets/icons/checked.svg';
import NonCheckedIcon from 'assets/icons/not_checked.svg';

export const CheckBox = ({
  label,
  subLabel,
  checked = false,
  setChecked = null,
  className,
  variant = VARIANTS.PRIMARY,
}) => {
  const classes = useStyles();
  const checkboxRef = useRef();

  const handleToggle = (e, isChecked) => {
    e.stopPropagation();
    setChecked && setChecked(isChecked);
  };

  return (
    <div
      className={classNames(classes.checkbox, className)}
      onClick={(e) => handleToggle(e, !checked)}
    >
      <input type="checkbox" ref={checkboxRef} style={{ display: 'none' }} />
      {variant === VARIANTS.PRIMARY ? (
        <img src={checked ? CheckedIcon : NonCheckedIcon} alt="check-box" />
      ) : (
        <div className={classes.radioContent}>
          {checked && <div className={classes.radio} />}
        </div>
      )}
      {subLabel && (
        <label className={classes.subLabel}>
          {subLabel}
          {label ? ' - ' : ''}
        </label>
      )}
      {label && <label className={classes.label}>{label}</label>}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    checkbox: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: 10,
      cursor: 'pointer',
    },
    checked: {
      display: 'block',
    },
    nonChecked: {},
    hide: {
      display: 'none',
    },
    subLabel: {
      fontSize: 16,
      height: 20,
      lineHeight: '22px',
      color: theme.palette.grey[300],
    },
    label: {
      fontSize: 16,
      minHeight: 20,
      lineHeight: '22px',
      cursor: 'pointer',
    },
    radioContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 18,
      height: 18,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.action.active,
      borderRadius: '50%',
    },
    radio: {
      width: 10,
      height: 10,
      background: theme.palette.action.active,
      borderRadius: '50%',
    },
  }),
);
