import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import { EnhancedModal } from 'components/Modal';
import { Button } from 'components/Buttons';
import { H5 } from 'components/Typography';
import MoveCard from 'components/MoveCard';
import { VARIANTS, MEDIA_TYPES } from 'utils/constants';
import { getPropertyMedia, updateMedias } from 'store/propertyStore';

import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { ReactComponent as VisibleIcon } from 'assets/icons/visible.svg';
import { ReactComponent as InVisibleIcon } from 'assets/icons/inVisible.svg';
import { ReactComponent as MoveIcon } from 'assets/icons/move.svg';

const MediaEditModal = ({
  isModalOpen,
  closeModal,
  medias,
  propertyId,
  type = MEDIA_TYPES.PHOTO,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deletes, setDeletes] = useState([]);
  const [showIds, setShowIds] = useState([]);
  const [hideIds, setHideIds] = useState([]);
  const [data, setData] = useState(medias);
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (user.role_id === 1 || user.role_id === 2) {
      const tempShow = [];
      const tempHidden = [];
      medias.forEach((item) => {
        if (item.hidden) {
          tempHidden.push(item.media_id);
        } else {
          tempShow.push(item.media_id);
        }
      });
      setData(medias);
      setHideIds(tempHidden);
      setShowIds(tempShow);
    } else {
      const visibleData = medias.filter((item) => item.hidden === 0);
      setData(visibleData);
    }
  }, [medias, user]);

  const handleDelete = (id) => {
    setDeletes([...deletes, id]);
    const newData = [...data].filter((item) => item.media_id !== id);
    setData(newData);
  };

  const handleSubmit = () => {
    const order = data.map((item) => item.media_id);

    const body = {
      property_order: order,
      hide: hideIds,
      show: showIds,
      delete: deletes,
      media_format: type,
    };
    dispatch(
      updateMedias({
        propertyId,
        body,
        success: () => {
          dispatch(
            getPropertyMedia({
              id: propertyId,
              type,
            }),
          );
          closeModal();
        },
      }),
    );
  };

  const hiddenMedia = (id) => {
    setHideIds((previous) => [...previous, id]);
    setShowIds((previous) => previous.filter((item) => item !== id));
  };

  const showMedia = (id) => {
    setShowIds((previous) => [...previous, id]);
    setHideIds((previous) => previous.filter((item) => item !== id));
  };

  const moveCard = (dragIndex, hoverIndex) => {
    const dragCard = data[dragIndex];
    setData(
      update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      }),
    );
  };

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={'Edit'}
      mobileFullWidth={true}
    >
      <div className={classes.contiainer}>
        <DndProvider backend={HTML5Backend}>
          <div className={classes.content}>
            {data.map((item, i) => (
              <MoveCard
                key={item.media_id}
                index={i}
                id={item.media_id}
                moveCard={moveCard}
              >
                <Box display="flex" alignItems="center" gridColumnGap={10}>
                  <Box display="flex" alignItems="center" gridColumnGap={20}>
                    <MoveIcon className={classes.mobileHidden} />
                    {type === MEDIA_TYPES.PHOTO ? (
                      <img
                        src={
                          item.watermark_url ||
                          item.original_url ||
                          item.media_url
                        }
                        alt=""
                        width={100}
                        height={50}
                      />
                    ) : (
                      <Box display="flex" alignItems="center" height={30}>
                        <H5 className="single-line-text">
                          {item.watermark_url ||
                            item.original_url ||
                            item.media_url}
                        </H5>
                      </Box>
                    )}
                  </Box>
                  <Box
                    flex={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    gridColumnGap={12}
                    mr={1}
                  >
                    {(user.role_id === 1 || user.role_id === 2) &&
                      (hideIds.includes(item.media_id) ? (
                        <InVisibleIcon
                          className="cursor-pointer"
                          onClick={() => showMedia(item.media_id)}
                        />
                      ) : (
                        <VisibleIcon
                          className="cursor-pointer"
                          onClick={() => hiddenMedia(item.media_id)}
                        />
                      ))}
                    <RemoveIcon
                      className="cursor-pointer"
                      onClick={() => handleDelete(item.media_id)}
                    />
                  </Box>
                </Box>
              </MoveCard>
            ))}
          </div>
        </DndProvider>
        <Box className={classes.actionButtons}>
          <Button onClick={closeModal} variant={VARIANTS.SECONDARY}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Save</Button>
        </Box>
      </div>
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    contiainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 500,
      rowGap: 10,
      [theme.breakpoints.down('xs')]: {
        rowGap: 20,
      },
    },
    content: {
      minHeight: 200,
      maxHeight: 300,
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      [theme.breakpoints.down('xs')]: {
        maxHeight: '100%',
      },
      '&::-webkit-scrollbar': {
        width: 6,
        background: theme.palette.background.white,
        borderRadius: 5,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
    photoTitle: {
      textTransform: 'capitalize',
    },
    actionButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
      columnGap: 20,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        background: theme.palette.background.white,
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.15)',
        height: 80,
        width: '100%',
      },
    },
    mobileHidden: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  }),
);

export default MediaEditModal;
