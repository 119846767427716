import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { DropdownContent } from 'components/Dropdown';
import { H5, H6 } from 'components/Typography';

import defalutAvatar from 'assets/defaultAvatar.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';

const SearchField = ({ data, className, ...props }) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState('');
  const [result, setResult] = useState([]);
  const classes = useStyles({ isOpened: !!result.length });

  useEffect(() => {
    if (keyword && data && data.length) {
      const searchResult = data.filter((item) =>
        item.landlord_name.toLowerCase().includes(keyword.toLowerCase()),
      );
      setResult(searchResult);
    } else {
      setResult([]);
    }
  }, [keyword, data]);

  const handleRowClick = (id) => {
    history.push(`/landlord/${id}`);
  };

  return (
    <Box width={1} maxWidth={600} position="relative" className={className}>
      <input
        className={classes.input}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        {...props}
      />
      <SearchIcon className={classes.searchIcon} />
      {!!result.length && (
        <>
          <DropdownContent
            handleClickAway={() => setResult([])}
            customStyles={classes.typesDropdown}
          >
            <div className={classes.dropwdown}>
              {result.map((item, index) => (
                <Box
                  key={index}
                  className={classes.searchItem}
                  onClick={() => handleRowClick(item.landlord_id)}
                >
                  <img src={item.image || defalutAvatar} alt="avatar" />
                  <Box display="flex" flexDirection="column">
                    <H5>{item.landlord_name}</H5>
                    <H6 grey>
                      {item.email} | {item.phone}
                    </H6>
                  </Box>
                </Box>
              ))}
            </div>
          </DropdownContent>
        </>
      )}
    </Box>
  );
};

export default SearchField;

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 50,
      padding: '0 20px 0 50px',
      background: theme.palette.background.white,
      borderRadius: (props) => (props.isOpened ? '5px 5px 0 0' : 5),
      color: theme.palette.action.active,
      fontSize: 16,
      lineHeight: '22px',
      [theme.breakpoints.down('xs')]: {
        padding: '0 20px 0 40px',
      },
      '&:hover': {
        border: `1px solid ${theme.palette.background.black}`,
      },
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
    },
    searchIcon: {
      width: 24,
      height: 24,
      position: 'absolute',
      left: 15,
      top: 13,
      [theme.breakpoints.down('xs')]: {
        left: 8,
      },
    },
    typesDropdown: {
      left: 0,
      right: 0,
      top: '100%',
      transform: 'translate(0, 0)',
    },
    dropwdown: {
      width: '100%',
      borderRadius: '0 0 5px 5px',
      display: 'flex',
      flexDirection: 'column',
      border: `2px solid ${theme.palette.action.active}`,
      borderTop: 'none',
      maxHeight: 350,
      overflow: 'auto',
      '& > div': {
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
        },
      },
    },
    searchItem: {
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
      '& > img': {
        width: 50,
        height: 50,
        borderRadius: '50%',
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
    },
  }),
);
