import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';

import { H1, H4, H5 } from 'components/Typography';
import { Button } from 'components/Buttons';
import { XScrollBar } from 'components/XScrollBar';
import { SIZE_TYPES } from 'utils/constants';
import {
  getProperty,
  getPropertyFeatures,
  getPropertyTags,
  getPublicProperty,
} from 'store/propertyStore';
import { DropdownContent } from 'components/Dropdown';

import Overview from './components/Overview';
import ShowingInfo from './components/ShowingInfo';
import LandlordInfo from './components/LandlordInfo';
import Photos from './components/Photos';
import Videos from './components/Videos';
import VirtualTours from './components/VirtualTours';
import Links from './components/Links';
import NotesTable from './components/NotesTable';
import TaskList from 'pages/Home/components/TaskList';
import PropertyShareModal from './modals/PropertyShareModal';

import { ReactComponent as ThreeDotsIcon } from 'assets/icons/threeDots.svg';

const AnchorLinks = [
  {
    id: 'details',
    label: 'Details',
  },
  {
    id: 'info',
    label: 'Landlord info',
  },
  {
    id: 'photos',
    label: 'Photos',
  },
  {
    id: 'tours',
    label: 'Virtual Tours',
  },
  {
    id: 'links',
    label: 'Links',
  },
];

const PublicAnchorLinks = [
  {
    id: 'details',
    label: 'Details',
  },
  {
    id: 'photos',
    label: 'Photos',
  },
  {
    id: 'tours',
    label: 'Virtual Tours',
  },
];

const PropertyDetailPage = ({ isPublic = false }) => {
  const ref = useRef();
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const {
    selectedProperty,
    publicProperty,
    selectedPropertyFeatures,
    selectedPropertyTags,
  } = useSelector((state) => state.propertyReducer);
  const { id } = useParams();
  const [initScroll, setIntiScroll] = useState(false);
  const [userId, setUserId] = useState();
  const [selectedLinkIndex, setSelectedLinkIndex] = useState(
    isPublic ? PublicAnchorLinks[0] : AnchorLinks[0],
  );
  const [isShareModal, setIsShareModal] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  useEffect(() => {
    setTimeout(() => setIntiScroll(true), 1000);
  }, []);

  useEffect(() => {
    const scrollToHashElement = () => {
      const { hash } = window.location;
      const elementToScroll = document.getElementById(hash?.replace('#', ''));
      if (!elementToScroll || !hash || hash === '#details') return;

      ref.current.scrollTo({
        top: elementToScroll.offsetTop - 180,
        behavior: 'smooth',
      });
    };

    if (initScroll) {
      scrollToHashElement();
    }
  }, [initScroll]);

  useEffect(() => {
    if (id) {
      if (isPublic) {
        dispatch(getPublicProperty(id));
      } else {
        dispatch(getProperty(id));
        dispatch(getPropertyFeatures(id));
        dispatch(getPropertyTags(id));
      }
    }
  }, [id, dispatch, isPublic]);

  useEffect(() => {
    if (location.hash.includes('photos')) {
      setSelectedLinkIndex('photos');
    } else if (location.hash.includes('tours')) {
      setSelectedLinkIndex('tours');
    } else if (location.hash.includes('info')) {
      setSelectedLinkIndex('info');
    } else if (location.hash.includes('links')) {
      setSelectedLinkIndex('links');
    } else {
      setSelectedLinkIndex('details');
    }
  }, [location.hash]);

  useEffect(() => {
    const searchQuery = new URLSearchParams(location.search);
    setUserId(searchQuery.get('a'));
  }, [location, location?.search]);

  const backToList = () => {
    history.push('/property');
  };

  return (
    <Box display="flex" flexDirection="column" height={1}>
      <div>
        <div className={classes.header}>
          {!isPublic && (
            <H4 className={classes.backLink} onClick={backToList}>
              Back
            </H4>
          )}
          <Box
            flex={1}
            width={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gridColumnGap={20}
          >
            <Box className={classes.address}>
              <H1>
                {isPublic ? publicProperty?.address : selectedProperty?.address}
              </H1>
            </Box>
            {!isPublic &&
              selectedProperty?.record_location === 'bookOfBusiness' &&
              isTablet && (
                <Box
                  display="flex"
                  alignItems="center"
                  position="relative"
                  onClick={() => !isOpenMenu && setIsOpenMenu(true)}
                >
                  <ThreeDotsIcon cursor="pointer" />
                  {isOpenMenu && (
                    <>
                      <DropdownContent
                        handleClickAway={() => setIsOpenMenu(false)}
                        customStyles={classes.menuDropdown}
                      >
                        <div className={classes.dropwdown}>
                          <H5
                            onClick={() => {
                              setIsShareModal(true);
                              setIsOpenMenu(false);
                            }}
                          >
                            Share
                          </H5>
                          {/* <H5>Deactivate</H5> */}
                          <H5>Add to main DB</H5>
                          {/* <H5>Key Check in</H5> */}
                        </div>
                      </DropdownContent>
                    </>
                  )}
                </Box>
              )}
            {!isPublic &&
              selectedProperty?.record_location === 'bookOfBusiness' &&
              !isTablet && (
                <Box display="flex" alignItems="center" gridColumnGap={20}>
                  {/* <Button size={SIZE_TYPES.LARGE}>Deactivate</Button> */}
                  <Button size={SIZE_TYPES.LARGE}>Add to main DB</Button>
                  {/* <Button size={SIZE_TYPES.LARGE}>Key Check in</Button> */}
                </Box>
              )}
          </Box>
        </div>
        <XScrollBar className={classes.xscroll}>
          <div className={classes.nav}>
            <Box display="flex" alignItems="center" height={1}>
              {(isPublic ? PublicAnchorLinks : AnchorLinks).map((link) => (
                <a
                  href={
                    !isPublic
                      ? `/property/${id}#${link.id}`
                      : `/public_property/${id}?a=${userId}#${link.id}`
                  }
                  className={
                    selectedLinkIndex === link.id
                      ? classes.selectedLink
                      : undefined
                  }
                  key={link.id}
                >
                  <H4>{link.label}</H4>
                </a>
              ))}
            </Box>
            {!isPublic &&
              selectedProperty?.record_location === 'bookOfBusiness' &&
              !isTablet && (
                <Button
                  size={SIZE_TYPES.LARGE}
                  onClick={() => setIsShareModal(true)}
                >
                  Share
                </Button>
              )}
          </div>
        </XScrollBar>
      </div>
      <div className={classes.content} ref={ref}>
        <div id="details">
          <Overview
            property={isPublic ? publicProperty : selectedProperty}
            features={selectedPropertyFeatures}
            tags={selectedPropertyTags}
            isPublic={isPublic}
            userId={userId}
          />
        </div>
        {!isPublic && (
          <div>
            <ShowingInfo
              property={isPublic ? publicProperty : selectedProperty}
            />
          </div>
        )}
        {!isPublic && (
          <div id="info">
            <LandlordInfo
              property={isPublic ? publicProperty : selectedProperty}
            />
          </div>
        )}
        <div id="photos">
          <Photos isPublic={isPublic} />
        </div>
        <div>
          <Videos isPublic={isPublic} />
        </div>
        <div id="tours">
          <VirtualTours isPublic={isPublic} />
        </div>
        {!isPublic && (
          <div id="links">
            <Links property={selectedProperty} user={user} />
          </div>
        )}
        {!isPublic && (
          <div className={classes.tableContent}>
            <TaskList propertyId={id} />
          </div>
        )}
        {!isPublic && (
          <div className={classes.tableContent}>
            <NotesTable propertyId={id} />
          </div>
        )}
      </div>
      {isShareModal && (
        <PropertyShareModal
          isModalOpen={isShareModal}
          closeModal={() => setIsShareModal(false)}
          propertyId={selectedProperty?.concept_property_id}
        />
      )}
    </Box>
  );
};

export default PropertyDetailPage;

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 30px',
      width: '100%',
      height: 100,
      columnGap: 20,
      rowGap: 20,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
      background: theme.palette.background.white,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: 'auto',
        padding: 20,
      },
    },
    backLink: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
      padding: '0 40px',
      [theme.breakpoints.down('md')]: {
        padding: '0 20px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    address: {
      flex: 1,
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: 4,
      rowGap: 4,
      '& > h1': {
        margin: 0,
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    xscroll: {
      paddingBottom: 0,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
      background: theme.palette.background.white,
    },
    nav: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 80,
      paddingRight: 30,
      background: theme.palette.background.white,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
      '& a': {
        textDecoration: 'none',
        padding: '0 60px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '& > span': {
          whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('md')]: {
          padding: '0 40px',
        },
        [theme.breakpoints.down('sm')]: {
          padding: '0 30px',
        },
        [theme.breakpoints.down('xs')]: {
          padding: '0 20px',
        },
      },
    },
    selectedLink: {
      borderBottom: `2px solid ${theme.palette.action.active}`,
      '& > span': {
        fontWeight: 'bold',
        color: theme.palette.action.active,
      },
    },
    content: {
      flex: 1,
      padding: 30,
      paddingTop: 0,
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      scrollBehavior: 'smooth',
      [theme.breakpoints.down('xs')]: {
        padding: '20px 0',
        paddingTop: 0,
      },
      '& > div': {
        paddingTop: 30,
        [theme.breakpoints.down('xs')]: {
          paddingTop: 20,
        },
      },
      '&::-webkit-scrollbar': {
        width: 12,
        background: theme.palette.background.white,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
    tableContent: {
      paddingTop: '0 !important',
      [theme.breakpoints.down('xs')]: {
        padding: '0 20px',
      },
    },
    menuDropdown: {
      right: 0,
      top: 30,
      minWidth: 160,
    },
    dropwdown: {
      width: 160,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      '& > span': {
        padding: '8px 15px',
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey.A200,
        },
      },
    },
  }),
);
