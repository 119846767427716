import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { H5 } from 'components/Typography';

import DetailsInfo from './components/DetailsInfo';
import TeamMembers from './components/TeamMembers';

import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentList, getAgentById } from 'store/agentStore';

const AgentDetail = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data } = useSelector((state) => state.agentReducer);

  useEffect(() => {
    dispatch(getAgentList());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getAgentById(id));
    }
  }, [dispatch, id]);

  const goBackToList = () => {
    history.push('/agent');
  };

  return (
    <div className={classes.container}>
      <Box display="flex" justifyContent="space-between" mb={2.5}>
        <H5 onClick={goBackToList} className={classes.back}>
          Back to Agent List
        </H5>
        {/* <Box display="flex" alignItems="center">
          <H5 display={'inline'} className={classes.mr20}>
            Pending for review
          </H5>
          {false ? <UnPinnedIcon /> : <FlagMarkIcon />}
        </Box> */}
      </Box>
      <Box display="flex" justifyContent="flex-start" className={classes.main}>
        <DetailsInfo data={data} id={id} />
        <TeamMembers id={id} />
      </Box>
    </div>
  );
};

export default AgentDetail;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: '50px 30px 30px 30px',
      // height: '100%',
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        padding: 20,
      },
    },
    main: {
      gap: 20,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: 20,
      },
    },
    back: {
      cursor: 'pointer',
    },
    mr20: {
      marginRight: 20,
    },
  }),
);
