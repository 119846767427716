import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { H5, H3 } from 'components/Typography';
import propertyImage from 'assets/images/propertyImg.jpg';
import { ReactComponent as BedIcon } from 'assets/icons/bed.svg';
import { ReactComponent as BathIcon } from 'assets/icons/bath.svg';
import { ReactComponent as AreaIcon } from 'assets/icons/area.svg';

const ClientUrlItem = ({ property, agent_id }) => {
  const classes = useStyles();
  const hostname = window.location.origin;

  return (
    <div className={classes.container}>
      <img
        src={property?.photo_url || propertyImage}
        alt="Property Client Item"
        className={classes.propertyImage}
      />
      <div className={classes.detailContainer}>
        <H3 bold display="block">
          {property.address}
        </H3>
        <Box
          display="flex"
          justifyContent="flex-start"
          gap={15}
          className={classes.secondCol}
        >
          <H5>Rent</H5>
          <H5 bold>${Number(property.rent)}</H5>
          <H5>Fee</H5>
          <H5 bold>{property.fee_information}</H5>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          gap={15}
          className={classes.thirdCol}
        >
          <BathIcon />
          <H5>{Number(property.baths)}</H5>
          <BedIcon />
          <H5>{isNaN(Number(property.beds)) ? 0 : Number(property.beds)}</H5>
          <AreaIcon />
          <H5>{Number(property.square_footage)}</H5>
        </Box>
        <a
          href={`${hostname}/public_property/${property.property_url_key}${
            agent_id ? '?a=' + agent_id : ''
          }`}
          className={classes.link}
        >
          {`${hostname}/public_property/${property.property_url_key}${
            agent_id ? '?a=' + agent_id : ''
          }`}
        </a>
      </div>
    </div>
  );
};

export default ClientUrlItem;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: 407,
      background: theme.palette.background.white,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    detailContainer: {
      height: 185,
      padding: 20,
    },
    propertyImage: {
      width: 407,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    secondCol: {
      marginTop: 10,
      marginBottom: 10,
      gap: 15,
    },
    thirdCol: {
      marginBottom: 10,
      gap: 20,
    },
    link: {
      color: theme.palette.action.active,
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 700,
      textDecoration: 'none',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);
