import React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { H2 } from 'components/Typography';
import { VARIANTS } from 'utils/constants';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

Modal.setAppElement('#root');

export const EnhancedModal = ({
  isModalOpen,
  closeModal,
  children,
  title = '',
  hiddenHeader = false,
  hiddenHeaderDivider = false,
  mobileFullWidth = false,
  variant = VARIANTS.PRIMARY,
  className,
}) => {
  const classes = useStyles({ variant, hiddenHeaderDivider });

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      shouldCloseOnEsc={false}
      className={classNames(classes.modal, className, {
        mobileFullWidth,
      })}
      overlayClassName={classes.overlay}
      contentLabel="Modal"
    >
      {!hiddenHeader && (
        <div
          className={classNames(classes.header, {
            mobileFullWidth,
          })}
        >
          <H2>{title}</H2>
          <CloseIcon onClick={closeModal} />
        </div>
      )}
      {children}
    </Modal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      borderRadius: 5,
      width: 'calc(100% - 30px)',
      maxWidth: 620,
      background: theme.palette.background.white,
      padding: (props) => (props.variant === VARIANTS.PRIMARY ? 60 : 30),
      [theme.breakpoints.down('xs')]: {
        padding: '20px !important',
        '&.mobileFullWidth': {
          top: 30,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 0,
          width: '100%',
          transform: 'translate( 0, 0)',
          padding: '0 20px',
          borderRadius: '5px 5px 0px 0px',
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: 10,
      paddingBottom: 10,
      borderBottom: (props) =>
        props.hiddenHeaderDivider
          ? 'none'
          : `1px solid ${theme.palette.grey.A100}`,
      marginBottom: (props) => (props.variant === VARIANTS.PRIMARY ? 30 : 20),
      [theme.breakpoints.down('xs')]: {
        marginBottom: 20,
        '&.mobileFullWidth': {
          background: theme.palette.background.white,
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
          borderRadius: '5px 5px 0px 0px',
          padding: 20,
          marginLeft: -20,
          marginRight: -20,
          marginTop: -20,
        },
      },
      '& > svg': {
        width: 16,
        minWidth: 16,
        cursor: 'pointer',
      },
    },
  }),
);
