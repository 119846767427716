import React, { useEffect, useRef } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';

import { H5, H6 } from 'components/Typography';
import { INPUT_TYPES } from 'utils/constants';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { DropdownContent } from 'components/Dropdown';
import useRoveFocus from 'hooks/useRoveFocus';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowFillDown.svg';

export const EnhancedForm = ({
  children,
  className,
  initialValues,
  validationSchema,
  handleSubmit,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
  >
    {(props) => <Form className={className}>{children(props)}</Form>}
  </Formik>
);

export const FormInputField = ({
  className,
  disabled = false,
  label = '',
  name,
  placeholder = '',
  type = INPUT_TYPES.TEXT,
  multiline = false,
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap={5}
      className={className}
    >
      {!!label && <H5>{label}</H5>}
      <Field name={name}>
        {({ field, meta }) => (
          <div>
            {multiline ? (
              <textarea
                placeholder={placeholder}
                disabled={disabled}
                {...field}
                rows={5}
                className={classNames(classes.input, {
                  error: meta.touched && meta.error,
                })}
                style={{ height: 'auto' }}
              />
            ) : (
              <input
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                {...field}
                className={classNames(classes.input, {
                  error: meta.touched && meta.error,
                })}
              />
            )}
          </div>
        )}
      </Field>
      <div className={classes.errorContainer}>
        <ErrorMessage
          name={name}
          render={(msg) => (
            <Box display="flex" alignItems="center" gridColumnGap={5}>
              <WarningIcon width={14} height={14} />
              <H6 className={classes.error}>{msg}</H6>
            </Box>
          )}
        />
      </div>
    </Box>
  );
};

export const CustomFormField = ({
  className,
  label = '',
  name,
  value = '',
  placeholder = '',
  isOpenChildren,
  setIsOpenChildren,
  children,
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap={5}
      className={className}
    >
      {!!label && <H5>{label}</H5>}
      <Field name={name}>
        {({ form: { setFieldValue }, meta }) => (
          <button
            className={classNames(classes.customField, {
              error: meta.touched && meta.error,
            })}
            onClick={() => !isOpenChildren && setIsOpenChildren(true)}
            tabIndex="0"
            type="button"
          >
            <span>{value || placeholder}</span>
            {isOpenChildren && children({ setFieldValue })}
          </button>
        )}
      </Field>
      <div className={classes.errorContainer}>
        <ErrorMessage
          name={name}
          render={(msg) => (
            <Box display="flex" alignItems="center" gridColumnGap={5}>
              <WarningIcon width={14} height={14} />
              <H6 className={classes.error}>{msg}</H6>
            </Box>
          )}
        />
      </div>
    </Box>
  );
};

export const DropdownFormField = ({
  className,
  label = '',
  name,
  value = '',
  placeholder = '',
  isOpenDropdown,
  setIsOpenDropdown,
  dropdownList,
  itemId = null,
  itemValue = null,
  selectItem,
  selectedItem = null,
  disabled = false,
  multi = false,
}) => {
  const classes = useStyles();
  const [focus, setFocus] = useRoveFocus(dropdownList.length);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap={5}
      className={className}
    >
      {!!label && <H5>{label}</H5>}
      <Field name={name} disabled={disabled}>
        {({ form: { setFieldValue, values }, meta }) => (
          <button
            className={classNames(classes.customField, {
              error: meta.touched && meta.error,
            })}
            onClick={() =>
              !disabled && !isOpenDropdown && setIsOpenDropdown(true)
            }
            tabIndex="0"
            type="button"
          >
            <span>{value || placeholder}</span>
            <ArrowDownIcon className={classes.dropdownIcon} />
            {isOpenDropdown && (
              <>
                <DropdownContent
                  handleClickAway={() => setIsOpenDropdown(false)}
                  customStyles={classes.selectDropdown}
                >
                  <div className={classes.dropwdown}>
                    {dropdownList.map((item, index) => (
                      <DropdownItem
                        key={`${itemId ? item[itemId] : item} - ${index}`}
                        index={index}
                        focus={focus === index}
                        setFocus={setFocus}
                        setFieldValue={setFieldValue}
                        setIsOpenDropdown={setIsOpenDropdown}
                        selectItem={selectItem}
                        itemId={itemId}
                        multi={multi}
                        item={item}
                        itemValue={itemValue}
                        values={values}
                        name={name}
                        selectedItem={selectedItem}
                      />
                    ))}
                  </div>
                </DropdownContent>
              </>
            )}
          </button>
        )}
      </Field>
      <div className={classes.errorContainer}>
        <ErrorMessage
          name={name}
          render={(msg) => (
            <Box display="flex" alignItems="center" gridColumnGap={5}>
              <WarningIcon width={14} height={14} />
              <H6 className={classes.error}>{msg}</H6>
            </Box>
          )}
        />
      </div>
    </Box>
  );
};

const DropdownItem = ({
  index,
  focus,
  setFocus,
  selectItem,
  setFieldValue,
  setIsOpenDropdown,
  itemId,
  multi,
  item,
  itemValue,
  values,
  name,
  selectedItem,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (focus) {
      ref.current.focus();
    }
  }, [focus]);

  return (
    <button
      ref={ref}
      tabIndex="0"
      className={`${
        multi &&
        values[name] &&
        values[name].includes(itemId ? item[itemId] : item)
          ? 'selected'
          : ''
      }`}
      type="button"
      onClick={() => {
        if (multi) {
          if (
            values[name] &&
            values[name].includes(itemId ? item[itemId] : item)
          ) {
            setFieldValue(
              name,
              [...values[name]].filter((i) =>
                itemId ? i !== item[itemId] : i !== item,
              ),
            );
            selectItem(
              [...selectedItem].filter((i) =>
                itemId ? i[itemId] !== item[itemId] : i !== item,
              ),
            );
          } else {
            setFieldValue(
              name,
              itemId
                ? [...values[name], item[itemId]]
                : [...values[name], item],
            );
            selectItem([...selectedItem, item]);
          }
        } else {
          setFieldValue(name, itemId ? item[itemId] : item);
          selectItem(item);
          setIsOpenDropdown(false);
        }
      }}
      onKeyPress={() => setFocus(index)}
    >
      <H5>{itemId ? item[itemValue] : item}</H5>
    </button>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 40,
      padding: '0 10px',
      background: theme.palette.background.white,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 5,
      color: theme.palette.text.primary,
      fontSize: 16,
      lineHeight: '22px',
      '&.error': {
        border: `2px solid ${theme.palette.background.red}`,
      },
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
      '&:disabled': {
        background: theme.palette.grey.A200,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
    },
    customField: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 40,
      padding: '0 10px',
      background: theme.palette.background.white,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 5,
      color: theme.palette.text.primary,
      fontSize: 16,
      lineHeight: '22px',
      position: 'relative',
      cursor: 'pointer',
      '&.error': {
        border: `2px solid ${theme.palette.background.red}`,
      },
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
      '& > span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    errorContainer: {
      display: 'block',
      minHeight: 20,
    },
    error: {
      color: theme.palette.background.red,
    },
    dropdownIcon: {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translate(-5px, -50%)',
    },
    selectDropdown: {
      left: 0,
      right: 0,
      top: 'calc(100% + 5px)',
      transform: 'translate(0, 5px)',
    },
    dropwdown: {
      width: '100%',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 300,
      overflowY: 'auto',
      '& > button': {
        padding: '8px 15px',
        cursor: 'pointer',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.palette.background.white,
        '&:hover': {
          background: theme.palette.grey.A200,
          '&.selected': {
            background: theme.palette.grey.A100,
          },
        },
        '&:focus': {
          background: theme.palette.grey.A200,
          '&.selected': {
            background: theme.palette.grey.A100,
          },
        },
      },
      '& .selected': {
        background: theme.palette.grey.A100,
      },
      '&::-webkit-scrollbar': {
        width: 6,
        background: theme.palette.background.white,
        borderRadius: 5,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
  }),
);
