import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { Button } from 'components/Buttons';
import { EnhancedModal } from 'components/Modal';
import { H5 } from 'components/Typography';
import TeamMemberItem from './TeamMemberItem';
import { CheckBox } from 'components/CheckBox';
import { SIZE_TYPES, VARIANTS } from 'utils/constants';
import { useDebounce } from 'hooks/useDebounce';

const AddTeamMemberModal = ({
  isModalOpen,
  isAdded,
  setIsAdded,
  closeModal,
  handleSave,
}) => {
  const classes = useStyles();
  const [term, setTerm] = useState('');
  const [searchedAgents, setSearchedAgents] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const { agentList } = useSelector((store) => store.agentReducer);

  const debouncedTerm = useDebounce(term, 750);

  useEffect(() => {
    setTerm('');
    setIsAllSelected(false);
    setSelectedIds([]);
    setSearchedAgents([]);
  }, [isModalOpen]);

  useEffect(() => {
    if (debouncedTerm) {
      const temp = [...agentList].filter((item) =>
        item.name.toLowerCase().includes(debouncedTerm.toLowerCase()),
      );
      setSearchedAgents(temp);
    } else {
      setSearchedAgents([]);
    }
  }, [agentList, debouncedTerm]);

  const handleChange = (e) => {
    setTerm(e.target.value);
    setIsAdded(false);
  };

  const handleAgentSelect = (id) => {
    const arr = [...selectedIds];

    let newArr;
    if (arr.indexOf(id) > -1) {
      arr.splice(arr.indexOf(id), 1);
      newArr = [...arr];
    } else {
      newArr = [...arr, id];
    }
    setSelectedIds(newArr);
  };

  useEffect(() => {
    if (
      searchedAgents.length > 0 &&
      selectedIds.length === searchedAgents.length
    ) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [selectedIds, searchedAgents]);

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedIds([]);
    } else {
      const list = [];
      searchedAgents.forEach((item) => list.push(item.user_id));
      setSelectedIds([...list]);
    }
    setIsAllSelected(!isAllSelected);
  };

  return (
    <EnhancedModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      variant={VARIANTS.SECONDARY}
      title="Add team members"
      className={classes.modal}
    >
      <div className={classes.select}>
        <CheckBox
          label="All"
          checked={isAllSelected}
          setChecked={() => handleSelectAll(!isAllSelected)}
        />
        <H5
          className={classes.selectedCount}
        >{`${selectedIds.length} Selected`}</H5>
        <input
          name="term"
          onChange={handleChange}
          className={classes.input}
          placeholder="Search by Name"
          value={term}
          disabled={!!selectedIds.length}
        />
      </div>
      <div className={classes.contiainer}>
        {isAdded ? (
          <H5>Team Members are Added!</H5>
        ) : searchedAgents.length ? (
          searchedAgents.map((agent) => (
            <TeamMemberItem
              agent={agent}
              key={agent.user_id}
              checked={selectedIds.includes(agent.user_id)}
              setChecked={(value) => {
                handleAgentSelect(agent.user_id, value);
              }}
            />
          ))
        ) : (
          <H5>Search for agents by Name using the field above.</H5>
        )}
      </div>
      <Box className={classes.actionButtons}>
        <Button
          onClick={closeModal}
          variant={VARIANTS.SECONDARY}
          size={SIZE_TYPES.LARGE}
        >
          Cancel
        </Button>
        <Button
          size={SIZE_TYPES.LARGE}
          onClick={() => {
            handleSave({
              selectedIds,
              success: () => {
                setTerm('');
                setIsAllSelected(false);
                setSelectedIds([]);
              },
            });
          }}
        >
          Save
        </Button>
      </Box>
    </EnhancedModal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {},
    contiainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
      margin: 'auto',
      marginTop: 20,
      height: '100%',
      maxHeight: 480,
      overflow: 'auto',
      paddingRight: 8,
      minHeight: 280,
      [theme.breakpoints.down('xs')]: {
        rowGap: 20,
      },
      '&::-webkit-scrollbar': {
        width: 8,
        background: theme.palette.background.white,
        border: `1px solid ${theme.palette.grey.A100}`,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: theme.palette.grey.A100,
        '&:hover': {
          background: theme.palette.grey[300],
        },
      },
    },
    select: {
      display: 'flex',
      justifyContent: 'space-between',
      columnGap: 20,
      flexWrap: 'wrap',
    },
    selectedCount: { lineHeight: '40px' },
    input: {
      height: 40,
      border: `1px solid ${theme.palette.grey[300]}`,
      boxSizing: 'border-box',
      borderRadius: 5,
      padding: '9px 10px',
      flex: 1,
      '&:focus': {
        border: `2px solid ${theme.palette.action.active}`,
      },
    },
    actionButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      columnGap: 20,
      marginTop: 10,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        background: theme.palette.background.white,
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.15)',
        height: 92,
        width: '100%',
      },
    },
  }),
);

export default AddTeamMemberModal;
