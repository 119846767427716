import { get, post, patch, filePost } from 'utils/api';

const getProperties = (params) => {
  return get({
    path: 'properties',
    parameters: params,
  });
};

const getProperty = (id) => {
  return get({
    path: `properties/${id}`,
  });
};

const getPublicProperty = (public_property_key) => {
  return get({
    path: `public_properties/${public_property_key}`,
  });
};

const getPublicAgent = (user_id) => {
  return get({
    path: `public_agents/${user_id}`,
  });
};

const createProperty = (body) => {
  return post({
    path: `properties`,
    body,
  });
};

const updateProperty = (id, body) => {
  return patch({
    path: `properties/${id}`,
    body,
  });
};

const getPropertyMedia = (id, type) => {
  return get({
    path: `property_media/${id}`,
    parameters: {
      media_format: type,
    },
  });
};

const getPublicPropertyMedia = (id, type) => {
  return get({
    path: `public_property_media/${id}`,
    parameters: {
      media_format: type,
    },
  });
};

const getPropertyFeatures = (id) => {
  return get({
    path: `property_features/${id}`,
  });
};

const getPropertyFilters = (name) => {
  return get({
    path: `property_filters/${name}`,
  });
};

const getPropertyTags = (id) => {
  return get({
    path: `property_tags/${id}`,
  });
};

const getFeatures = () =>
  get({
    path: 'features',
  });

const getTags = () =>
  get({
    path: 'tags',
  });

const createLinkedMedia = (id, body) =>
  post({
    path: `linked_media/${id}`,
    body,
  });

const createPropertyCollaborator = (body) =>
  post({
    path: `property_collaborators`,
    body,
  });

const createMedias = (id, formData) =>
  filePost({
    path: `property_media/${id}`,
    formData,
  });

const updateMedias = (id, body) =>
  patch({
    path: `property_media/${id}`,
    body,
  });

const getPropertyConflicts = (params) =>
  get({
    path: `property-conflicts`,
    parameters: params,
  });

const propertyService = {
  getProperties,
  getProperty,
  getPropertyMedia,
  getPropertyFeatures,
  getPropertyFilters,
  getPropertyTags,
  createProperty,
  updateProperty,
  getFeatures,
  getTags,
  createLinkedMedia,
  createMedias,
  updateMedias,
  getPublicProperty,
  getPublicAgent,
  getPropertyConflicts,
  createPropertyCollaborator,
  getPublicPropertyMedia,
};

export default propertyService;
