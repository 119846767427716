import { get, post, filePost } from 'utils/api';

const getProfle = () => {
  return get({
    path: 'profile',
  });
};

const login = (body) => {
  return post({
    path: 'login',
    body,
  });
};

const logout = () => {
  return post({
    path: 'logout',
  });
};

const sendRestPasswordRequest = (body) =>
  post({
    path: 'forgot-password',
    body,
  });

const resetPassword = (body) =>
  post({
    path: 'reset-password',
    body,
  });

const uploadAgentPhoto = (id, formData) => {
  filePost({
    path: `user_media/${id}`,
    formData,
  });
};

const setAuthInStorage = (userAuth) => {
  sessionStorage.setItem('auth', JSON.stringify(userAuth));
};

const getAuthFromStorage = () => {
  return JSON.parse(sessionStorage.getItem('auth'));
};

const removeAuthInStorage = () => {
  sessionStorage.removeItem('auth');
};

const authService = {
  getAuthFromStorage,
  removeAuthInStorage,
  setAuthInStorage,
  getProfle,
  login,
  logout,
  sendRestPasswordRequest,
  resetPassword,
  uploadAgentPhoto,
};

export default authService;
