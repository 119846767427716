import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Button } from 'components/Buttons';

const SubHeader = ({
  view,
  handleChangeView,
  setIsOpenAddModal,
  secondTabText,
  addBtnText,
}) => {
  const [activeTab, setActiveTab] = useState('all');
  const classes = useStyles();

  useEffect(() => {
    setActiveTab(view);
  }, [view]);

  const handleSelect = (tab) => {
    setActiveTab(tab);
    handleChangeView(tab);
  };

  return (
    <div className={classes.subHeader}>
      <div className={classes.menuItemGroup}>
        <div
          className={cx(
            classes.subHeaderItem,
            activeTab === 'myBookOfBusiness' && classes.activeTab,
          )}
          onClick={() => handleSelect('myBookOfBusiness')}
        >
          Book of Business
        </div>
        <div
          className={cx(
            classes.subHeaderItem,
            activeTab === 'all' && classes.activeTab,
          )}
          onClick={() => handleSelect('all')}
        >
          {secondTabText}
        </div>
        <div
          className={cx(
            classes.subHeaderItem,
            activeTab === 'thePond' && classes.activeTab,
          )}
          onClick={() => handleSelect('thePond')}
        >
          The Pond
        </div>
      </div>

      <Button
        onClick={() => setIsOpenAddModal(true)}
        className={classes.addPropertyBtn}
      >
        {addBtnText}
      </Button>
    </div>
  );
};

export default SubHeader;

const useStyles = makeStyles((theme) =>
  createStyles({
    subHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      background: theme.palette.background.white,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
      height: 70,
      borderRadius: 5,
      [theme.breakpoints.down('xs')]: {},
    },
    menuItemGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    subHeaderItem: {
      width: 170,
      textAlign: 'center',
      lineHeight: '70px',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 170,
        paddingLeft: 10,
        paddingRight: 10,
        width: 'auto',
      },
    },
    activeTab: {
      borderBottom: `2px solid ${theme.palette.action.active}`,
    },
    addPropertyBtn: {
      height: 50,
      margin: 10,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }),
);
