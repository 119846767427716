import { get, post, patch, del } from 'utils/api';

const getTasks = (user_key, property_id = null, landlord_id = null) => {
  return get({
    path: 'tasks',
    parameters: {
      user_key,
      property_id,
      landlord_id,
    },
  });
};

const getTaskTypes = () => {
  return get({ path: 'task_type' });
};

const createTask = (payload) => {
  return post({ path: 'tasks', body: payload });
};

const updateTask = (payload) => {
  return patch({ path: 'tasks', body: payload });
};

const deleteTasks = (ids) =>
  del({
    path: 'tasks',
    body: {
      is_deleted_ids: ids,
    },
  });

const taskService = {
  getTasks,
  getTaskTypes,
  createTask,
  updateTask,
  deleteTasks,
};

export default taskService;
